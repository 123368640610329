import { Fragment, useRef, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useTranslation } from 'react-i18next';
import GlobalVars from '../../../../Config';


// job list options 
export default function JobListOptions({open, setOpen}) {
    const { t } = useTranslation();
    // states
    const [dateSort, setDateSort] = useState("createdAt");

    // handle submission of list setting form
    const handleSubmit = (e) => {
        let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/jobs/user-job-list-display-setting';

        // get form data
        let submitData = {
            "dateSort": dateSort,
            "userid": localStorage.getItem("userid")
        }

        // send data to backend
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": sessionStorage.getItem("idToken")
            },
            body: JSON.stringify(submitData),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
            setOpen(false);

            // refresh the page
            window.location.reload();
        })
        .catch((error) => {
            console.error('Error:', error);
            alert("請求失敗")
        });

    }

    const cancelButtonRef = useRef(null)

    // retrieve current job list settings 
    useEffect(() => {
        let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/jobs/user-job-list-display-setting?userID=' + localStorage.getItem("userid");

        fetch(url, {
            headers: {
              "Authorization": sessionStorage.getItem("idToken")
            }
          })
            .then(response => response.json())
            .then(data => {
                if (data.hasOwnProperty("displaySetting")) {
                    setDateSort(data["displaySetting"]["dateSort"]);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []);

    return (
        <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={setOpen}>
            <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    {/* Title */}
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        {t('列表設定')}
                    </Dialog.Title>
                    {/* Setting content */}
                    <div className="mt-2">
                        <p className="text-sm text-gray-500">
                            {t('設定列表顯示的資料')}
                        </p>
                    </div>
                    {/* Date display setting */}
                    <div className="mt-4">
                        <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900">
                            {t('日期顯示')}
                        </label>
                        <select
                            id="location"
                            name="location"
                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            defaultValue="Canada"
                            value={dateSort}
                            onChange={(e) => setDateSort(e.target.value)}
                        >
                            <option value="">{t('請選擇')}</option>
                            <option value="createdAt">{t('接單日期')}</option>
                            <option value="meta.estimatedDate">{t('預計工作日期')}</option>
                            <option value="latestStatus.changedAt">{t('最新狀態日期')}</option>
                        </select>
                    </div>
                    {/* Secondary screen setting */}
                    <div className="mt-4">
                        <label htmlFor="secondaryScreen" className="block text-sm font-medium leading-6 text-gray-900">
                            {t('副屏顯示')}
                        </label>
                        <p className="text-sm text-gray-500">
                            {t('啟用副屏顯示後，工作詳細資料會在副屏顯示')}
                        </p>
                        <div className="mt-2">
                            <div className="flex items-center">
                                <div className="flex items-center flex-1">
                                    <div className="flex items-center h-6">
                                        <input
                                            id="secondaryScreen"
                                            name="secondaryScreen"
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                            defaultChecked={localStorage.getItem("jobListPreference_secondaryScreen") === "true"}
                                            onChange={(e) => {
                                                localStorage.setItem("jobListPreference_secondaryScreen", e.target.checked);
                                            }}
                                        />
                                    </div>
                                    <div className="ml-3">
                                        <label htmlFor="secondaryScreen" className="text-sm font-medium leading-6 text-gray-900">
                                            {t('啟用副屏顯示')}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* View mode setting */}
                    <div className="mt-4">
                        <label htmlFor="viewMode" className="block text-sm font-medium leading-6 text-gray-900">
                            {t('顯示模式')}
                        </label>
                        <p className="text-sm text-gray-500">
                            {t('選擇工作列表的顯示方式')}
                        </p>
                        <div className="mt-2">
                            <div className="flex items-center">
                                <div className="flex items-center flex-1">
                                    <div className="flex items-center h-6">
                                        <input
                                            id="stackedView"
                                            name="viewMode"
                                            type="radio"
                                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                            defaultChecked={localStorage.getItem("jobListPreference_viewMode") !== "table"}
                                            onChange={() => {
                                                localStorage.setItem("jobListPreference_viewMode", "stacked");
                                            }}
                                        />
                                    </div>
                                    <div className="ml-3">
                                        <label htmlFor="stackedView" className="text-sm font-medium leading-6 text-gray-900">
                                            {t('堆疊視圖')}
                                        </label>
                                    </div>
                                </div>
                                <div className="flex items-center flex-1">
                                    <div className="flex items-center h-6">
                                        <input
                                            id="tableView" 
                                            name="viewMode"
                                            type="radio"
                                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                            defaultChecked={localStorage.getItem("jobListPreference_viewMode") === "table"}
                                            onChange={() => {
                                                localStorage.setItem("jobListPreference_viewMode", "table");
                                            }}
                                        />
                                    </div>
                                    <div className="ml-3">
                                        <label htmlFor="tableView" className="text-sm font-medium leading-6 text-gray-900">
                                            {t('表格視圖')}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Save button */}
                    <div className="mt-5 sm:mt-6">
                        <button
                            type="button"
                            className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 sm:text-sm"
                            onClick={handleSubmit}
                        >
                            {t('儲存')}
                        </button>
                    </div>
                </Dialog.Panel>
                </Transition.Child>
            </div>
            </div>
        </Dialog>
        </Transition.Root>
    )

}
