import ApplicationShell from '../../Components/ApplicationShell/ApplicationShell';
import { Fragment } from 'react'
import { 
  Transition, 
} from '@headlessui/react'
import { 
  BriefcaseIcon,
  XMarkIcon,
  ArrowPathIcon,
} from '@heroicons/react/20/solid'
import SlideOvers from '../../Components/SlideOvers';
import { useState, useEffect, useCallback, useRef } from 'react'
import GlobalVars from '../../Config';
// import {GetTodayDateStr} from './Helper/GetTodayDateStr';

import "./IssueReporting.css";


// components
import SettingDropdown from './Components/SettingDropdown';
import NewJobForm from '../../Components/NewJobForm';
import StackedListView from './Components/StackedListView';
import TableView from './Components/TableView';
import IssueForm from './Components/IssueForm';
import SearchBarContainer from './Components/SearchBarContainer';



const statuses = {
    Pending: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
    Resolved: 'text-green-700 bg-green-50 ring-green-600/20',
    'In Progress': 'text-red-600 bg-red-50 ring-red-500/10',
    Canceled: 'text-gray-800 bg-gray-50 ring-gray-600/20',
    "Cancel Requested": 'text-gray-800 bg-gray-50 ring-gray-600/20',
  }

const statusDisplayNamePair = {
    Pending: '待處理',
    Resolved: '已解決',
    'In Progress': '處理中',
    Canceled: '已取消',
    "Cancel Requested": '客戶提出工單取消',
}

// Add this debounce utility function near the top of the file
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export default function IssueReporting() {
    // set page title to be 事件紀錄
    document.title = "事件紀錄";

    const [openForm, setOpenForm] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editId, setEditId] = useState("");
    const [title, setTitle] = useState("新加事件");
    const [remarks, setRemarks] = useState("");
    const [statusUpdate, setIssueUpdateDesc] = useState("");
    const [requestId, setRequestId] = useState("");
    const [locationList, setLocationList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedNotifyUser, setSelectedNotifyUser] = useState([]);
    const [files, setFiles] = useState([]);
    const [currentFiles, setCurrentFiles] = useState([]);
    const [editItemInfo, setEditItemInfo] = useState({});
    const [filterObj, setFilterObj] = useState({
      "status": "",
      "description": "",
      "humanReadableId": "",
      "location": {"_id": "all", "name": "所有地點"},
      "productType": {"label": "所有產品", "value": ""},
    });

    const [newJobForm, setNewJobForm] = useState(false);
    const [jobDetail, setJobDetail] = useState({});
    const [issueDetail, setIssueDetail] = useState({
      "meta": {},
      "contactInfo": {
        "contactList": []
      }
    });
    useEffect(() => {
      // clear job detail when newJobForm is closed
      if (!newJobForm) {
        setJobDetail({});
      }
    }, [newJobForm]);

    const [issueList, setIssueList] = useState([]);
    const [page, setPage] = useState(1);  // page for issueList
    

    // retrieve extra issue list when scroll to bottom of page
    // useEffect(() => {
    //   // add window scroll event listener to load more issues
    //   window.addEventListener('scroll', handleScroll);
    //   return () => window.removeEventListener('scroll', handleScroll);
    // }
    // , [page]);

    // handle scroll event
    // const handleScroll = () => {
    //   if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
    //     let newPage = page + 1;
    //     retrieveIssueList({setIssueList, page:newPage, filterObj});
    //     setPage(newPage);
    //   }
    // }
    const [isLoading, setIsLoading] = useState(false);
    const loadingIndicatorRef = useRef(null);
    const [hasMore, setHasMore] = useState(true); // Add this to track if there's more data
    const [noNewData, setNoNewData] = useState(false);

    // Effect for filter changes - should reset page and list
    useEffect(() => {
        setPage(1); // Reset page when filter changes
        setIssueList([]); // Clear existing list
        setHasMore(true); // Reset hasMore state
        retrieveIssueList({
            setIssueList, 
            page: 1, 
            filterObj,
            setHasMore,
            append: false
        });
    }, [filterObj]);

    // Effect for infinite scroll observation
    const [displayingLoadingIndicator, setDisplayingLoadingIndicator] = useState(false);
    
    const debouncedLoadMore = useCallback(
      debounce(() => {
        if (!isLoading && hasMore) {
          setIsLoading(true);
          const nextPage = page + 1;
          retrieveIssueList({
            setIssueList,
            page: nextPage,
            filterObj,
            setHasMore,
            append: true,
            onComplete: () => {
              setIsLoading(false);
              setPage(nextPage);
            },
            setNoNewData
          });
        }
      }, 300), // 300ms debounce delay
      [isLoading, hasMore, page, filterObj]
    );

    useEffect(() => {
        if (!loadingIndicatorRef.current || !hasMore) return;

        const observer = new IntersectionObserver(
            (entries) => {
                const target = entries[0];
                if (target.isIntersecting) {
                    debouncedLoadMore();
                }
            },
            {
                root: null,
                rootMargin: '100px',
                threshold: 0.1,
            }
        );

        observer.observe(loadingIndicatorRef.current);

        return () => {
            if (loadingIndicatorRef.current) {
                observer.unobserve(loadingIndicatorRef.current);
            }
        };
    }, [loadingIndicatorRef.current, debouncedLoadMore]);

    useEffect(() => {
        retrieveIssueList({setIssueList, page});
    }, []);

    // boolean for prompting users to complete issue related job 
    const [relatedJobId, setRelatedJobId] = useState("");
    const [promptCompleteIssueRelatedJob, setPromptCompleteIssueRelatedJob] = useState(false);

    // clear form
    const ClearForm = () => {
      setRemarks("");
      setRequestId("");

      // clear selected location
      setSelectedLocation(null);

      // clear files
      setFiles([]);

      // clear current files
      setCurrentFiles([]);

      // set issueUpdateDesc to default
      setIssueUpdateDesc("");

      // clear editId and editItemInfo
      setIsEdit(false);
      setEditId(null);
      setEditItemInfo(null);

      // setissueDetail to default
      setIssueDetail({
        "meta": {},
        "contactInfo": {
          "contactList": []
        }
      });
    }
    
    return (
        <>
            <ApplicationShell>
                <PageHeading 
                  setOpen={setOpenForm} 
                  setIsEdit={setIsEdit} 
                  setTitle={setTitle} 
                  setRemarks={setRemarks}
                  setRequestId={setRequestId}
                  setSelectedLocation={setSelectedLocation}
                  setFiles={setFiles}
                  setCurrentFiles={setCurrentFiles}
                  setIssueUpdateDesc={setIssueUpdateDesc}
                  setIssueDetail={setIssueDetail}
                  ClearForm={ClearForm}
                />
                <SearchBarContainer 
                  filterObj={filterObj}
                  setFilterObj={setFilterObj}
                />
                <IssueList  
                  setIsEdit={setIsEdit} 
                  setEditId={setEditId} 
                  setOpen={setOpenForm} 
                  issueList={issueList} 
                  setIssueList={setIssueList} 
                  setNewJobForm={setNewJobForm}
                  setJobDetail={setJobDetail}
                  setEditItemInfo={setEditItemInfo}
                  filterObj={filterObj}
                  setPromptCompleteIssueRelatedJob={setPromptCompleteIssueRelatedJob}
                  setRelatedJobId={setRelatedJobId}
                  loadingIndicatorRef={loadingIndicatorRef}
                  isLoading={isLoading}
                  noNewData={noNewData}
                />
            </ApplicationShell>
            <IssueFormContainer 
              open={openForm} 
              setOpen={setOpenForm}  
              isEdit={isEdit} 
              editId={editId} 
              title={title}
              setTitle={setTitle}
              remarks={remarks}
              setRemarks={setRemarks}
              requestId={requestId}
              setRequestId={setRequestId}
              selectedLocation={selectedLocation}
              setSelectedLocation={setSelectedLocation}
              files={files}
              setFiles={setFiles}
              locationList={locationList}
              setLocationList={setLocationList}
              userList={userList}
              setUserList={setUserList}
              selectedNotifyUser={selectedNotifyUser}
              setSelectedNotifyUser={setSelectedNotifyUser}
              currentFiles={currentFiles}
              setCurrentFiles={setCurrentFiles}
              setIssueList={setIssueList}
              statusUpdate={statusUpdate}
              setIssueUpdateDesc={setIssueUpdateDesc}
              setNewJobForm={setNewJobForm}
              setJobDetail={setJobDetail}
              editItemInfo={editItemInfo}
              issueDetail={issueDetail}
              setIssueDetail={setIssueDetail}
              
            />
            <NewJobForm state={newJobForm} setEditState={setNewJobForm} jobPrefill={jobDetail}  />
            <PromptCompleteIssueRelatedJob show={promptCompleteIssueRelatedJob} setShow={setPromptCompleteIssueRelatedJob} relatedJobId={relatedJobId} />
        </>
    )
}



// new issue or edit issue container
function IssueFormContainer({
  open, 
  setOpen, 
  isEdit, 
  editId, 
  title, 
  setTitle,
  remarks,
  setRemarks,
  requestId,
  setRequestId,
  selectedLocation,
  setSelectedLocation,
  files,
  setFiles,
  locationList,
  setLocationList,
  userList,
  setUserList,
  selectedNotifyUser,
  setSelectedNotifyUser,
  currentFiles,
  setCurrentFiles,
  setIssueList,
  statusUpdate,
  setIssueUpdateDesc,
  setNewJobForm,
  setJobDetail,
  editItemInfo,
  issueDetail,
  setIssueDetail
}) {
    
    
    const [manualNotifyUser, setManualNotifyUser] = useState([]);
    

    const handleSubmission = () => {
        let confirmText = "確定要新增事件嗎？";
        if (title.includes("編輯事件")) {
            confirmText = "確定要編輯事件嗎？";
        }

        // error if location is not selected
        if (!selectedLocation) {
          alert("請選擇地點");
          return;
        }

        // prompt user for confirmation
        if (window.confirm(confirmText)) {
            // submit form
            console.log("submitting form");

            let submitData = {
                "userID": localStorage.getItem("userid"),
                "organizationID": localStorage.getItem("organizationId"),
                "remarks": remarks,
                "requestID": requestId,
                "locationID": selectedLocation.value,
                "notifyUserIDs": selectedNotifyUser.map((item) => item.value),
                "contactInfo": issueDetail.contactInfo,
                "meta": issueDetail.meta,
                "statusUpdate": statusUpdate,
                "productType": issueDetail.productType,
                "customerPhoneForEnquiry": issueDetail.customerPhoneForEnquiry,
                "fromJobManagement": true, // set to true to indicate that the issue is created from job management system 
            }

            let apiMethod = "POST";
            if (title.includes("編輯事件")) {
                apiMethod = "PUT";
                submitData["issueID"] = editId;

                // remove userID from submitData
                delete submitData["userID"];

                // delete fromJobManagement from submitData
                delete submitData["fromJobManagement"];
            } else {
                submitData["createdBy"] = localStorage.getItem("userid");
            }
            
            // create POST request to submit issue
            let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/issues";
            let body = submitData;
            let headers = {
              "Content-Type": "application/json"
            };
            fetch(url, 
                {
                    method: apiMethod,
                    body: JSON.stringify(body),
                    headers: headers
                }
            ).then(response => {
                if (response.status === 200) {
                    response.json().then(rawData => {
                      let data = rawData['issueID']
                      
                      // check if there are files to be uploaded
                      if (files.length > 0) {
                        
                        // get signed urls and upload files
                        getSignedUrls(files, data);

                      } else {
                        // close slide over
                        setOpen(false);

                        // refetch issue list
                        retrieveIssueList({setIssueList});

                        // check if it's edit mode
                        if (title.includes("編輯事件")) {
                          alert("工單編輯成功!");
                        } else {
                          if(window.confirm("工單新增成功, 你希望新增工作嗎？")) {
                            

                            let jobData = {
                              "issueID": data,
                              "locationName": selectedLocation ? selectedLocation.label : "",
                              "assignedTo": localStorage.getItem("userid"),
                              "meta": {
                                "clientRemark": remarks + " (工單編號: " + rawData['humanReadableId'] + ")",
                                "estimatedDate": GetTodayDateStr(),
                              }
                            }

                            if (selectedLocation) {
                              jobData["locationIDs"] = [selectedLocation.value];
                            }

                            setJobDetail(jobData);


                            // open new job form
                            setNewJobForm(true);
                          }
                        }
                        
                      }

                    });

                    // close slide over
                    // setOpen(false);
                    // // reload page
                    // window.location.reload();
                } else {
                    alert("新增事件失敗");
                }
            }).catch(error => {
                console.log(error);
                alert("新增事件失敗");
            });

        }
    }

    // fetch for location list
    useEffect(() => {
      // create a fetch request with a given id
      let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/locations?organizationID=" + localStorage.getItem("organizationId");
      fetch(url, {
        headers: {
          "Authorization": sessionStorage.getItem("idToken")
        }
      })
      .then(response => {
          if (response.status === 200) {
              response.json().then(rawData => {
                  let data = rawData['locations'];

                  // convert data to react-select format and add traditional chinese name if available
                  data = data.map((item) => {
                      return {
                          value: item._id,
                          label: `${item.name}${item.langVar?.tc ? ` (${item.langVar.tc})` : ''}`
                      };
                  });
                  
                  setLocationList(data);
              });
          }
      }).catch(error => {
          console.log(error);
          alert("獲取地點列表失敗")
      });
    }, []);

    // fetch for user list 
    useEffect(() => {
      // create a fetch request with a given id
      let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/users?organizationID=" + localStorage.getItem("organizationId");
      fetch(url, {
        headers: {
          "Authorization": sessionStorage.getItem("idToken")
        }
      })
      .then(response => {
          if (response.status === 200) {
              response.json().then(rawData => {
                  let data = rawData['users'];

                  // convert data to react-select format
                  data = data.map((item) => {
                      return {
                          value: item._id,
                          label: item.displayName
                      }
                  });
                  
                  setUserList(data);
              } 
              );
          }
      }).catch(error => {
          console.log(error);
          alert("獲取用戶列表失敗")
      });
    }, []);


    // auto populate when edit mode is on
    useEffect(() => {
        if (isEdit) { 
            // reset input 
            setRemarks("");
            setRequestId("");
            setSelectedLocation(null);  // clear selected location
            setFiles([]);  // clear files
            setCurrentFiles([]);  // clear current files
            setIssueDetail({
              "meta": {},
              "contactInfo": {
                "contactList": []
              }
            });
            setIssueUpdateDesc("");

            console.log("edit mode on");
            // create a fetch request with a given id
            let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/issues?issueID=" + editId;
            fetch(url)
            .then(response => {
                if (response.status === 200) {
                    response.json().then(rawData => {
                        setOpen(true);
                        let data = rawData['issue']

                        // populate empty contact info if contactInfo is not present or contactList not present within contactInfo
                        if (!data.hasOwnProperty("contactInfo")) {
                          data.contactInfo = {
                            "contactList": [],
                          }
                        } else if (!data.contactInfo.hasOwnProperty("contactList")) {
                          data.contactInfo.contactList = [];
                        }

                        // populate medium if not present
                        if (!data.hasOwnProperty("contactInfo")) {
                          data.contactInfo = {
                            "medium": "sms"
                          };
                        } else if (!data.contactInfo.hasOwnProperty("medium")) {
                          data.contactInfo.medium = "sms";
                        }

                        setIssueDetail(data);

                        let title = "編輯事件";
                        if (data.hasOwnProperty("humanReadableId")) {
                          title += " (編號: " + data.humanReadableId + ")";
                        }
                        
                        setTitle(title);
                        setRemarks(data.remarks);

                        setIssueUpdateDesc(data.statusUpdate);

                        // set location
                        if (data.hasOwnProperty("locationID")) {
                          // from locationList, find the location with the same id
                          for (let i = 0; i < locationList.length; i++) {
                            if (locationList[i].value === data.locationID) {
                              setSelectedLocation(locationList[i]);
                              break;
                            }
                          }
                        }

                        if (data.hasOwnProperty("requestID") && data.requestID) {
                            setRequestId(data.requestID);
                        } else {
                            setRequestId("");
                        }

                        // if notifyUserIDs is not empty
                        if (data.hasOwnProperty("notifyUserIDs") && data.notifyUserIDs.length > 0) {
                          // from userList, find the user with the same id  
                          let notifyUserIDs = data.notifyUserIDs;
                          let selectedNotifyUser = [];
                          for (let i = 0; i < notifyUserIDs.length; i++) {
                            for (let j = 0; j < userList.length; j++) {
                              if (userList[j].value === notifyUserIDs[i]) {
                                selectedNotifyUser.push(userList[j]);
                                break;
                              }
                            }
                          }
                          setSelectedNotifyUser(selectedNotifyUser);
                        } else {
                          setSelectedNotifyUser([]);
                        }
                        
                        // get signed urls for file download
                        GetSignedUrlsForDownload(editId);
                    });
                }
            }).catch(error => {
                console.log(error);
                alert("獲取事件失敗")
            });

            setTitle("編輯事件");
            
        } else {
            setTitle("新加事件");
            setRemarks("");
            setRequestId("");
            
            // clear selected location
            setSelectedLocation(null);

            // clear files
            setFiles([]);

            // clear current files
            setCurrentFiles([]);

            // set issueDetail to default
            setIssueDetail({
              "meta": {},
              "contactInfo": {
                "contactList": []
              }
            });

            // set issueUpdateDesc to default
            setIssueUpdateDesc("");

            // clear selected notify user
            setSelectedNotifyUser([]);

            // clear manual notify user
            setManualNotifyUser([]);
        }

    }, [editId]);   

    // get signed urls for file download 
    
    function GetSignedUrlsForDownload(editId) {
      let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/issue/signed-url-for-file-upload?issueID=" + editId;
      fetch(url)
        .then(response => {
          if (response.status === 200) {
              response.json().then(rawData => {
                  let data = rawData['attachments'];

                  setCurrentFiles(data);
              });
          }
      }).catch(error => {
          console.log(error);
          // alert("獲取檔案失敗敗")
      });
    }

    // option signed urls to upload files
    function getSignedUrls(files, issueID) {
      // convert files to array
      let filesArray = Array.from(files).map(file => ({
        name: file.name,
        size: file.size,
        type: file.type,
        lastModified: file.lastModified
      }));
    
      // create a fetch request with a given id
      let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/issue/signed-url-for-file-upload";
      let body = {
        "files": filesArray,
        "issueID": issueID,
        "organizationID": localStorage.getItem("organizationId"),
      };
      let headers = {
        "Content-Type": "application/json"
      };
      fetch(url, 
          {
              method: "POST",
              body: JSON.stringify(body),
              headers: headers
          }
      ).then(response => {
          if (response.status === 200) {
              response.json().then(rawData => {
                  let data = rawData['url'];
                  
                  // upload files using signed urls
                  UploadFiles(files, data);
              });
          }
      }).catch(error => {
          console.log(error);
          alert("獲取簽名失敗")
      });
    }

    // upload files with signed urls and file list
    function UploadFiles(files, signedUrls) { 

      // upload files using signed urls
      Array.from(files).forEach((file, index) => {
        let signedUrl = signedUrls[index];
        let headers = {
          "Content-Type": file.type
        };
        fetch(signedUrl, 
            {
                method: "PUT",
                body: file,
                headers: headers
            }
        ).then(response => {
            if (response.status === 200) {
                console.log("file uploaded");

                if (index == files.length - 1) {
                  alert("上載成功, 現在重新載入頁面");

                  // close slide over
                  setOpen(false);
                  // reload page
                  window.location.reload();
                }
            }
        }).catch(error => {
            console.log(error);
            alert("上載失敗")
        });
      });
    }

    return(
        <>
            <SlideOvers 
                title={title} 
                open={open} 
                setOpen={setOpen} 
                content={<IssueForm 
                    remarks={remarks} 
                    setRemarks={setRemarks} 
                    requestId={requestId} 
                    setRequestId={setRequestId} 
                    selectedLocation={selectedLocation}
                    setSelectedLocation={setSelectedLocation}
                    files={files}
                    setFiles={setFiles}
                    locationList={locationList}
                    setLocationList={setLocationList}
                    userList={userList}
                    selectedNotifyUser={selectedNotifyUser}
                    setSelectedNotifyUser={setSelectedNotifyUser}
                    currentFiles={currentFiles}
                    setManualNotifyUser={setManualNotifyUser}
                    issueDetail={issueDetail}
                    setIssueDetail={setIssueDetail}
                    statusUpdate={statusUpdate}
                    setIssueUpdateDesc={setIssueUpdateDesc}
                    setNewJobForm={setNewJobForm}
                    setJobDetail={setJobDetail}
                    editId={editId}
                    editItemInfo={editItemInfo}
                  />} 
                submitAction={handleSubmission} />
        </>
    )
}



// page headings 
function PageHeading({
  setOpen, 
  setIsEdit, 
  setTitle, 
  ClearForm
}) {
    return (
      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            事件紀錄
          </h2>
        </div>
        <div className="mt-4 flex md:ml-4 md:mt-0 items-center gap-x-2">
          {/* <button
            type="button"
            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Edit
          </button> */}
          <button
            type="button"
            className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => {
                setOpen(true)
                setIsEdit(false);
                setTitle("新加事件");
                
                // clear form
                ClearForm();
            }}
          >
            新加
          </button>
          <SettingDropdown />
        </div>
      </div>
    )
}

// issue list 
function IssueList({
  setIsEdit, 
  setEditId, 
  setOpen, 
  issueList, 
  setIssueList, 
  setNewJobForm, 
  setJobDetail, 
  setEditItemInfo, 
  filterObj, 
  setPromptCompleteIssueRelatedJob,
  setRelatedJobId,
  loadingIndicatorRef,
  isLoading,
  noNewData
}) {
  

    // handle issue resolve 
    const handleIssueResolve = (e, item) => {
        let issueID = e.target.dataset.id;

        // create POST request to submit issue
        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/issue/status";
        let body = {
            "issueID": issueID,
            "status": "Resolved",
            "staffID": localStorage.getItem("userid"),
        };

        if (item.status == 'Pending') {
          body["status"] = "In Progress";
        }
        
        let headers = {
          "Content-Type": "application/json"
        };
        fetch(url, 
            {
                method: "PUT",
                body: JSON.stringify(body),
                headers: headers
            }
        ).then(response => {
            if (response.status === 200) {
                if (body["status"] == "Resolved") {
                  alert("工單狀態已更新")

                  return response.json();

                } else {
                  
                  if (window.confirm("工單狀態已更新, 需要生成工作嗎?")) {

                    let jobData = {
                      "issueID": issueID,
                      "locationName": item.locationName,
                      "assignedTo": localStorage.getItem("userid"),
                      "meta": {
                        "clientRemark": item.remarks + " (工單編號: " + item.humanReadableId + ")",
                        "estimatedDate": GetTodayDateStr(),
                      }
                    }

                    if (item.locationID) {
                      jobData["locationIDs"] = [item.locationID];
                    }
                    
                    // set job detail
                    setJobDetail(jobData)
                    setNewJobForm(true);
                  }
                }
                
                // refetch issue list
                retrieveIssueList({setIssueList});
                
            } else {
                alert("事件解決失敗");
            }
        }).then(data => {
          console.log(data);
          // check if jobID is available then prompt user to complete job
          if (data && data.jobID) {
            setPromptCompleteIssueRelatedJob(true);
            setRelatedJobId(data.jobID);
          }

          // refetch issue list
          retrieveIssueList({setIssueList});
        }).catch(error => {
            console.log(error);
            alert("事件解決失敗");
        });
    }

    // handle job creation 
    const handleJobCreation = (e, item) => {
    
      let jobData = {
        "issueID": item._id,
        "locationName": item.locationName,
        "assignedTo": localStorage.getItem("userid"),
        "meta": {
          "clientRemark": item.remarks + " (工單編號: " + item.humanReadableId + ")",
          "estimatedDate": GetTodayDateStr(),
        }
      }

      if (item.locationID) {
        jobData["locationIDs"] = [item.locationID];
      }
      
      // set job detail
      setJobDetail(jobData)
      setNewJobForm(true);

    }

    // handle issue cancellation
    const handleIssueCancel = (e) => {
        let issueID = e.target.dataset.id;
        
        // create POST request to submit issue
        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/issue/status";
        let body = {
            "issueID": issueID,
            "status": "Canceled"
        };
        let headers = {
          "Content-Type": "application/json"
        };
        fetch(url, 
            {
                method: "PUT",
                body: JSON.stringify(body),
                headers: headers
            }
        ).then(response => {
            if (response.status === 200) {
                alert("事件已取消");
                // refetch issue list
                retrieveIssueList({setIssueList});
            } else {
                alert("事件取消失敗");
            }
        }).catch(error => {
            console.log(error);
            alert("事件取消失敗");
        });
    }

    // handle reopening of issue
    const handleIssueReopen = (e) => {
        let issueID = e.target.dataset.id;
        

        // create POST request to submit issue
        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/issue/status";
        let body = {
            "issueID": issueID,
            "status": "Pending"
        };
        let headers = {
          "Content-Type": "application/json"
        };
        fetch(url, 
            {
                method: "PUT",
                body: JSON.stringify(body),
                headers: headers
            }
        ).then(response => {
            if (response.status === 200) {
                alert("事件已重開");
                // refetch issue list
                retrieveIssueList({setIssueList});
                
            } else {
                alert("事件重開失敗");
            }
        }).catch(error => {
            console.log(error);
            alert("事件重開失敗");
        });
    }

    // check if users in mobile view
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        if (window.innerWidth < 640) {
            setIsMobile(true);
        }
    }, []);

    // check local storage for view type
    const [viewType, setViewType] = useState(localStorage.getItem("issueReporting_viewType") || "list");


    return (
        <>
          {issueList.length === 0 ? (
            <div className="text-center py-12">
              <h3 className="mt-2 text-sm font-semibold text-gray-900">沒有事件</h3>
              <p className="mt-1 text-sm text-gray-500">目前沒有任何事件需要處理</p>
            </div>
          ) : viewType === "list" ? (
            <StackedListView 
              issueList={issueList}
              statuses={statuses}
              statusDisplayNamePair={statusDisplayNamePair}
              filterObj={filterObj}
              isMobile={isMobile}
              setIsEdit={setIsEdit}
              setEditId={setEditId}
              setOpen={setOpen}
              setEditItemInfo={setEditItemInfo}
              handleIssueResolve={handleIssueResolve}
              handleJobCreation={handleJobCreation}
              handleIssueCancel={handleIssueCancel}
              handleIssueReopen={handleIssueReopen}
            />
          ) : (
            <TableView 
              data={issueList}
              statuses={statuses}
              statusDisplayNamePair={statusDisplayNamePair}
              setIsEdit={setIsEdit}
              setEditId={setEditId}
              setOpen={setOpen}
              setEditItemInfo={setEditItemInfo}
              handleIssueResolve={handleIssueResolve}
              handleJobCreation={handleJobCreation}
              handleIssueCancel={handleIssueCancel}
              handleIssueReopen={handleIssueReopen}
            />
          )}
          {(isLoading || issueList.length > 0) && (
            <div className="flex justify-center items-center py-8" ref={loadingIndicatorRef}>
              <ArrowPathIcon className="h-5 w-5 animate-spin" />
              <span className="ml-2 text-sm text-gray-500">載入中...</span>
            </div>
          )}
          {noNewData && (
            <div className="text-center py-4">
              <p className="text-sm text-gray-500">沒有更多事件了</p>
            </div>
          )}
        </>
    )
}

// fetch issue list
const retrieveIssueList = ({
    setIssueList, 
    page = 1, 
    filterObj = {}, 
    setHasMore,
    append = false,
    onComplete,
    setNoNewData=false
}) => {
    let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/issues?organizationID=" + localStorage.getItem("organizationId") + "&page=" + page;
    let headers = {
        "Content-Type": "application/json"
    };

    // check if filterObj has description
    if (filterObj.hasOwnProperty("description") && filterObj.description !== "") {
        url += "&description=" + filterObj.description;
    }

    // check if filterObj has location
    if (filterObj.hasOwnProperty("location")) {
        if (filterObj['location'] && filterObj['location']["_id"] !== "all") {
            url += "&location=" + filterObj['location']["_id"];
        }
    }

    // check if productType is available
    if (filterObj.hasOwnProperty("productType")) {
        if (filterObj['productType'] && filterObj['productType']["value"] !== "") {
            url += "&productType=" + filterObj['productType']["value"];
        }
    }

    // check if filterObj has status
    if (filterObj.hasOwnProperty("status") && filterObj.status !== "") {
        url += "&status=" + filterObj.status;
    }

    // check if filterObj has humanReadableId
    if (filterObj.hasOwnProperty("humanReadableId") && filterObj.humanReadableId !== "") {
        url += "&humanReadableId=" + filterObj.humanReadableId;
    }

    fetch(url, {
        method: "GET",
        headers: headers
    })
    .then(response => {
        if (response.status === 200) {
            response.json().then(rawData => {
                const data = rawData['issues'];
                
                // Update hasMore based on whether we received any data
                if (setHasMore) {
                    setHasMore(data.length > 0);
                }

                // Append or replace based on the append flag
                setIssueList(prevList => {
                  if (append) {
                    // If data length is same as current list, don't append
                    if (data.length === prevList.length) {
                      setNoNewData(true);
                      setHasMore(false);
                      return prevList;
                    }
                    setNoNewData(false);
                    return [...prevList, ...data];
                  }
                  return data;
                });
                
                if (onComplete) {
                    onComplete();
                }
            });
        } else {
            console.log("Error: " + response.status);
            if (onComplete) {
                onComplete();
            }
        }
    })
    .catch(error => {
        console.log(error);
        if (onComplete) {
            onComplete();
        }
    });
}

// prompt users to complete issue related job 
function PromptCompleteIssueRelatedJob({show, setShow, relatedJobId}) {
  // setShow to false after a set time
  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setShow(false);
      }, 5000);
    }
  }, [show]);

  // handle job progress change
  const handleJobProgressChange = (e) => {
    // if relatedJobId is "" return error and close the prompt
    if (!relatedJobId) {
      alert("工作編號不存在");
      setShow(false);
      return;
    }

    // create PUT request to update job status
    var url = GlobalVars.BACKEND_DOMAIN + "/api/v1/jobs/progress";
    var data = {
      jobID: relatedJobId,
      status: "完成",
      assignedTo: localStorage.getItem('userid'),
      userID: localStorage.getItem('userid')
    };
    
    fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('idToken')
      },
      body: JSON.stringify(data)
    })
    .then(response => {
      if (response.status === 200) {
        alert("工作狀態已更新");
        setShow(false);
      } else {
        // set error
        alert("工作狀態更新失敗");
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      alert("工作狀態更新失敗");
    });
  }

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition show={show}>
            <div className="z-50 pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition data-[closed]:data-[enter]:translate-y-2 data-[enter]:transform data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:data-[enter]:sm:translate-x-2 data-[closed]:data-[enter]:sm:translate-y-0">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <BriefcaseIcon aria-hidden="true" className="h-6 w-6 text-gray-400" />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">更改工作狀態</p>
                    <p className="mt-1 text-sm text-gray-500">
                      你的工作狀態未完成, 需要我們為你更改工作狀態嗎?
                    </p>
                    <div className="mt-3 flex space-x-7">
                      <button
                        type="button"
                        className="rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={handleJobProgressChange}
                      >
                        是
                      </button>
                      <button
                        type="button"
                        className="rounded-md bg-white text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => setShow(false)}
                      >
                        否
                      </button>
                    </div>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      onClick={() => {
                        setShow(false)
                      }}
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon aria-hidden="true" className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
}


// get today's date in YYYY-MM-DD format
function GetTodayDateStr() {
  // get today's date in YYYY-MM-DD format
  let today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth() + 1;
  let day = today.getDate();
  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }
  let todayString = year + "-" + month + "-" + day;

  return todayString;
}



