import { useEffect, Fragment } from 'react';
import GlobalVars from "../../../../Config";

import { 
    Menu, 
    Transition, 
    MenuItems,
    MenuItem,
    MenuButton
} from '@headlessui/react'
import { 
    BellIcon as BellIconSolid,
    EllipsisVerticalIcon
} from '@heroicons/react/20/solid'
import {
    BellIcon
} from '@heroicons/react/24/outline'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

// fetch request to get user list
const fetchUserList = (setPeople) => {
    // fetch data from backend
    let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/organization/users?organizationID=' + sessionStorage.getItem("organizationId");
          
    fetch(url, {
      headers: {
        "Authorization": sessionStorage.getItem("idToken")
      }
    })
    .then(response => response.json())
    .then(data => {
        // setting email key for meta.email and delete meta.email
        data['users'].forEach((user) => {
            user['email'] = user['meta']['email'];
            delete user['meta'];
  
            // convert displayName to name
            user['name'] = user['displayName'];
            delete user['displayName'];
            
            user['show'] = true;
        });
  
        setPeople(data['users']);
  
        // query for users latest status
        QueryUserLatestStatus({setPeople}, data['users']);
    })
  
}

// query for users latest status
function QueryUserLatestStatus({setPeople}, people) {
    let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/users/latest-status?organizationID=' + sessionStorage.getItem("organizationId");

    fetch(url, {
      headers: {
        "Authorization": sessionStorage.getItem("idToken")
      }
    })
        .then(response => response.json())  
        .then(data => {
            let statuses = data['statuses'];

            // Create a new array with the updated people
            let updatedPeople = people.map(person => {
              let updatedPerson = { ...person }; // Create a copy of the person object


              statuses.forEach(status => {
                  if (updatedPerson['_id'] === status['assignedTo']) {
                      updatedPerson['location'] = status['locationName'];
                      updatedPerson['lastSeenDateTime'] = status['latestStatus']['changedAt'];

                      // if it's within 3 hours, show difference in hours, but if it's within 1 hour, show difference in minutes
                      let now = new Date();
                      let lastSeen = new Date(updatedPerson['lastSeenDateTime']);
                      let diff = now - lastSeen;
                      let diffHours = Math.round(diff / 1000 / 60 / 60);
                      let diffMinutes = Math.round(diff / 1000 / 60);
                      
                      if (diffHours <= 3 && diffHours >= 1) {
                          updatedPerson['lastSeen'] = diffHours + ' 小時前';
                      } else if (diffMinutes <= 60) {
                          updatedPerson['lastSeen'] = diffMinutes + ' 分鐘前';
                      } else {
                          updatedPerson['lastSeen'] = null;
                      }
                  }
              });

              return updatedPerson; // Return the updated person object
            });

            // Update the people state with the new array
            setPeople(updatedPeople);
            
            // retrieve fcmToken from backend
            RetrieveFCMTokens({setPeople});
        });
}

// fetch fcmToken from backend
function RetrieveFCMTokens({setPeople}) {
    let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/users/fcm?organizationID=' + localStorage.getItem("organizationId");
  
    fetch(url)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      } 
      return response.json()
    })
    .then(data => {
      let fcmTokens = data['fcmTokens']  // it's an object with userID as keys and each key contain a key named allowNotify
  
      // go through each users in people and set AllowNotify based on fcm
      setPeople(prevPeople => {
        const updatedPeople = prevPeople.map(person => {
          if (fcmTokens[person._id]) {
            return { ...person, AllowNotify: fcmTokens[person._id]['allowNotify'] };
          } else {
            return { ...person, AllowNotify: false };
          }
        });
        return updatedPeople;
      });
    })
}

// component for user list
export default function UserList({people, setPeople, handleEditClickUser, handleDeleteClickUser}) { 
    // fetch data from backend
    useEffect(() => {
        fetchUserList(setPeople);
    }, []);

    // change status of user between active and inactive
    const handleStatusChange = (e) => {
        let userId = e.target.getAttribute('data-person');
        let status = e.target.getAttribute('data-status');
        
        // PUT request to /api/v1/users/status to change status
        let submitData = {
            "userID": userId,
            "status": status === 'active' ? 'inactive' : 'active',
            "organizationID": sessionStorage.getItem("organizationId")
        };

        fetch(GlobalVars.BACKEND_DOMAIN + '/api/v1/users/status', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(submitData),
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Success:', data);
                alert("更改成功, 現在為你更新頁面資料");
                fetchUserList(setPeople);
            })
            .catch((error) => {
                alert("更改失敗");
                console.error('Error:', error);
            });
            
    };

    // handle test notify users
    const handleTestNotify = (e) => {
      // prompt users for confirmation
      let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/notification/push';
      fetch(url, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            "body": "測試訊息",
            "userID": e.target.getAttribute('data-person'),
            "organizationID": sessionStorage.getItem("organizationId")
          }),
          })
          .then(response => {
              if (!response.ok) {
                  throw new Error('Network response was not ok');
              }
              return response.json();
          })
          .then(data => {
              console.log('Success:', data);
              alert("提送成功");
          })
          .catch((error) => {
              alert("不能提送");
              console.error('Error:', error);
          });

    }

    // handle users copy registration link. when users click the button a url will be copied to clipboard
    const handleUserCopyRegistrationLink = (person) => {
      const registrationLink = `${window.location.origin}/create-account?organizationid=${sessionStorage.getItem("organizationId")}&email=${person.email}&name=${person.name}`;

      // replace empty space with %20
      const replacedLink = registrationLink.replace(/ /g, "%20");
  
      navigator.clipboard.writeText(replacedLink)
        .then(() => {
          alert("已複製用戶登記連結到你的剪貼簿");
        })
        .catch(err => {
          console.error('Failed to copy: ', err);
        });

    }

    return (
        <ul role="list" className="divide-y divide-gray-100 dynamic-comp">
          {people.map((person, index, array) => (
            <li key={person.email} className="skip-trans flex justify-between gap-x-6 py-5">
                <div className="flex min-w-0 gap-x-4">
                    {/* <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={person.imageUrl} alt="" /> */}
                    <div className="min-w-0 flex-auto">
                    <p className="text-sm font-semibold leading-6 text-gray-900">
                        <a href={person.href} className="hover:underline flex items-center">
                        {person.name}
                        {
                            person.AllowNotify ? 
                            (
                            <>
                                <BellIconSolid className="ml-1 h-5 w-5 text-yellow-400" aria-hidden="true" />
                            </>
                            ) : (
                            <BellIcon className="ml-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                            )
                        }
                        
                        </a>
                    </p>
                    <p className="mt-1 flex text-xs leading-5 text-gray-500">
                        <a href={`mailto:${person.email}`} className="truncate hover:underline">
                        {person.email}
                        </a>
                        <span className="mx-1">|</span>
                        <span className="text-gray-700">{person._id}</span>
                    </p>
                    </div>
                </div>
                <div className="flex shrink-0 items-center gap-x-6">
                    <div className="hidden sm:flex sm:flex-col sm:items-end">
                    <p className="text-sm leading-6 text-gray-900">{person.role}</p>
                    {person.lastSeen ? (
                        <p className="mt-1 text-xs leading-5 text-gray-500">
                        Last seen <time dateTime={person.lastSeenDateTime}>{person.lastSeen}</time>
                        </p>
                    ) : (
                        <div className="mt-1 flex items-center gap-x-1.5">
                        <div className={`flex-none rounded-full ${person.status === 'active' ? 'bg-emerald-500/20' : 'bg-gray-500/20' } p-1`}>
                            <div className={`h-1.5 w-1.5 rounded-full ${person.status === 'active' ? 'bg-emerald-500' : 'bg-gray-500' } `} />
                        </div>
                        <p className="text-xs leading-5 text-gray-500">{person.status === 'active' ? '已啟動' : '未啟動'}</p>
                        </div>
                    )}
                    </div>
                    <SettingList 
                        person={person}
                        isLastItem={index === array.length - 1}
                        handleEditClickUser={handleEditClickUser}
                        handleDeleteClickUser={handleDeleteClickUser}
                        handleTestNotify={handleTestNotify}
                        handleStatusChange={handleStatusChange}
                        handleUserCopyRegistrationLink={handleUserCopyRegistrationLink}
                    />
                </div>
            </li>
          ))}
        </ul>
    )
}

function SettingList({
    person,
    isLastItem,
    handleEditClickUser,
    handleDeleteClickUser,
    handleTestNotify,
    handleStatusChange,
    handleUserCopyRegistrationLink
}) {
    return (
        <Menu as="div" className="relative flex-none dynamic-comp">
            <MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                <span className="sr-only">Open options</span>
                <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
            </MenuButton>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <MenuItems className={`dynamic-comp absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none ${isLastItem ? 'bottom-0' : 'top-0'}`}>
                <MenuItem>  
                    {({ active }) => (
                    <a
                        onClick={handleEditClickUser}
                        className={classNames(
                        active ? 'bg-gray-50' : '',
                        'block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer'
                        )}
                        data-person={person._id}
                    >
                        修改<span className="sr-only">, {person.name}</span>
                    </a>
                    )}
                </MenuItem>
                <MenuItem>
                    {({ active }) => (
                    <a
                        href="#"
                        className={classNames(
                        active ? 'bg-gray-50' : '',
                        'block px-3 py-1 text-sm leading-6 text-gray-900'
                        )}
                    >
                        發送短信<span className="sr-only">, {person.name}</span>
                    </a>
                    )}
                </MenuItem>
                <MenuItem>
                    {({ active }) => (
                    <button
                        data-person={person._id}
                        onClick={handleTestNotify}
                        className={classNames(
                        active ? 'bg-gray-50' : '',
                        'block px-3 py-1 text-sm leading-6 text-gray-900 w-full text-left'
                        )}
                    >
                        測試提醒
                    </button>
                    )}
                </MenuItem>
                <MenuItem>
                    {({ active }) => (
                    <button
                        href="#"
                        className={classNames(
                        active ? 'bg-gray-50' : '',
                        'block px-3 py-1 text-sm leading-6 text-gray-900 w-full text-left'
                        )}
                        data-person={person._id}
                        data-status={person.status}
                        onClick={handleStatusChange}
                    >
                        {person.status === 'active' ? '停用' : '啟動' }<span className="sr-only">, {person.name}</span>
                    </button>
                    )}
                </MenuItem>
                <MenuItem>
                    {({ active }) => (
                    <button
                        href="#"
                        className={classNames(
                        active ? 'bg-gray-50' : '',
                        'block px-3 py-1 text-sm leading-6 text-gray-900 w-full text-left'
                        )}
                        data-person={person._id}
                        onClick={handleDeleteClickUser}
                    >
                        刪除
                    </button>
                    )}
                </MenuItem>
                <MenuItem>
                    {({ active }) => (
                    <button
                        href="#"
                        className={classNames(
                        active ? 'bg-gray-50' : '',
                        'block px-3 py-1 text-sm leading-6 text-gray-900 w-full text-left'
                        )}
                        data-person={person._id}
                        onClick={() => handleUserCopyRegistrationLink(person)}
                    >
                        複製用戶登記連結
                    </button>
                    )}
                </MenuItem>
                </MenuItems>
            </Transition>
        </Menu>
    )
}

