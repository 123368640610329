import { useState, useEffect } from "react";
import GlobalVars from "../../../../Config";
import {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
  } from '@headlessui/react'
import {
    ChevronDownIcon,
    CheckIcon,
    PlusIcon
} from '@heroicons/react/24/outline'


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export default function OrganizationSelection({
    setOpenNewOrgDialog
  }) {
    const [orgId, setOrgId] = useState("");
    const [orgName, setOrgName] = useState("Loading...");
    const [orgList, setOrgList] = useState([]);

    // Fetch organization name by ID
    const getOrgName = async (id) => {
      if (!id) {
        setOrgName("Loading...");
        return;
      }

      try {
        const url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/organization?organizationID=${id}`;
        const response = await fetch(url, {
          headers: {
            'Authorization': sessionStorage.getItem('idToken')
          }
        });
        const data = await response.json();
        setOrgName(data.organization.name);
      } catch (error) {
        console.error("Error fetching organization name:", error);
        setOrgName("Error loading organization");
      }
    };

    // Fetch list of organizations for user
    const fetchOrgList = async () => {
      try {
        const userId = localStorage.getItem("userid");
        const url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/organization/list?userID=${userId}`;
        const response = await fetch(url, {
          headers: {
            'Authorization': sessionStorage.getItem('idToken')
          }
        });
        const data = await response.json();
        setOrgList(data.organizations);
      } catch (error) {
        console.error("Error fetching organization list:", error);
      }
    };

    // Initialize organization data
    useEffect(() => {
      const storedOrgId = localStorage.getItem("organizationId");
      if (storedOrgId) {
        setOrgId(storedOrgId);
        getOrgName(storedOrgId);
      }
      fetchOrgList();
    }, []);

    // Update organization name when ID changes
    useEffect(() => {
      if (orgId) {
        getOrgName(orgId);
      }
    }, [orgId]);

    // Handle organization switch
    const handleOrgSwitch = (newOrgId) => {
      if (!newOrgId) return;

      // Update storage
      setOrgId(newOrgId);
      localStorage.setItem("organizationId", newOrgId);
      sessionStorage.setItem("organizationId", newOrgId);

      // Clear job list preferences
      Object.keys(sessionStorage)
        .filter(key => key.startsWith("jobListPreference"))
        .forEach(key => sessionStorage.removeItem(key));

      // Navigate to appropriate page
      const newUrl = window.location.pathname.includes('/jobs/') 
        ? '/job-list' 
        : window.location.pathname;
      window.location.assign(newUrl);
    };
  
    return (
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <MenuButton className="pr-5 overflow-hidden whitespace-nowrap justify-start inline-flex w-full max-w-[155px] gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            <span className="overflow-hidden text-ellipsis whitespace-nowrap">{orgName}</span> {/* Add truncate class to orgName for ellipsis */}
            <ChevronDownIcon aria-hidden="true" className="ml-2 h-5 w-5 text-gray-400" /> {/* Ensure visibility and adjust positioning if needed */}
          </MenuButton>
        </div>
  
        <MenuItems
          transition
          className="truncate absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in divide-y divide-gray-100"
        >
          <div className="py-1">
            {/* list out organization */}
            {orgList.map((org) => (
              <MenuItem key={org._id}>
                {({ active }) => (
                  <a
                    onClick={() => handleOrgSwitch(org._id)}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'flex px-4 py-2 text-sm font-semibold cursor-pointer justify-between ',
                      org.name === orgName ? 'font-semibold' : ''
                    )}
                    disabled={org.name === orgName}
                  >
                    <span>{org.name}</span>
                    {org.name === orgName && (
                      <span className="text-indigo-600 flex items-center pl-3">
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </a>
                )}
              </MenuItem>
            ))}
          </div>
          <MenuItem>
            {/* add new organization */}
              <a
                onClick={() => setOpenNewOrgDialog(true)}
                className="flex px-4 py-2 text-sm font-semibold text-gray-700 cursor-pointer justify-between hover:bg-slate-100 duration-300"
              >
                <span>新增組織</span>
                <span className="text-indigo-600 flex items-center pl-3">
                  <PlusIcon className="h-5 w-5" aria-hidden="true" />
                </span>
              </a>
            </MenuItem>
        </MenuItems>
      </Menu>
    )
}