import { useEffect, useState } from 'react'
import GlobalVars from '../../../../Config'
import { 
    ChevronLeftIcon, 
    ChevronRightIcon, 
    PencilIcon
} from '@heroicons/react/24/outline'

// components
import PrioritySelect from './Components/PrioritySelect'
// import StatusSelect from './Components/StatusSelect'
import FileDialog from '../BoardsComp/SubComponents/FileDialog';
import RemarkDialog from '../RemarkDialog';
import ItemEditDialog from './Components/ItemEditDialog';
import SelfDisappearNotification from '../../../../Components/SelfDisappearNotification'
import Datepicker from 'react-tailwindcss-datepicker'
import ProgressRemarkDialog from './Components/ProgressRemarkDialog';

const monthNameMap = {
    "January": "一月",
    "February": "二月",
    "March": "三月",
    "April": "四月",
    "May": "五月",
    "June": "六月",
    "July": "七月",
    "August": "八月",
    "September": "九月",
    "October": "十月",
    "November": "十一月",
    "December": "十二月"
}

const dayOfTheWeekMap = {
    "Sun": "日",
    "Mon": "一",
    "Tue": "二",
    "Wed": "三",
    "Thu": "四",
    "Fri": "五",
    "Sat": "六"
}

const dateProgressMap = {
    "inProgress": { 
        label: "工作中", 
        class: "bg-yellow-500",
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zm0 16a3 3 0 01-3-3h6a3 3 0 01-3 3z" />
            </svg>
        )
    },
    "stuck": { 
        label: "卡住", 
        class: "bg-red-500",
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
            </svg>
        )
    },
    "completed": { 
        label: "完成", 
        class: "bg-green-500",
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
            </svg>
        )
    }
}

export default function ProjectCalendar({
    board,
    boardId
}) {
    const [boardItems, setBoardItems] = useState([])
    const [currentDate, setCurrentDate] = useState(new Date())
    const [editingDate, setEditingDate] = useState(null); // { itemId: string, type: 'start' | 'deadline' }
    const [holidays, setHolidays] = useState([])
    // const [editingTitle, setEditingTitle] = useState(null);
    const [showNotification, setShowNotification] = useState(false);
    const [displayDayNum, setDisplayDayNum] = useState(21)
    const [isCustomRange, setIsCustomRange] = useState(false);
    const [customDateRange, setCustomDateRange] = useState({
        startDate: new Date(),
        endDate: new Date(new Date().setDate(new Date().getDate() + 20))
    });
    const [progressRemarkDialog, setProgressRemarkDialog] = useState({ open: false, itemId: null, date: null });

    // Fetch holidays when currentDate changes
    useEffect(() => {
        const dates = getDatesForWeek(currentDate);
        const startYear = dates[0].getFullYear();
        const endYear = dates[dates.length - 1].getFullYear();
        const years = startYear === endYear ? [startYear] : [startYear, endYear];
        
        const fetchHolidays = async () => {
            try {
                const response = await fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/calendar/hong-kong-holiday`);
                
                if (!response.ok) {
                    throw new Error('Failed to fetch holidays');
                }
                
                const data = await response.json();
                if (data.vcalendar && data.vcalendar[0].vevent) {
                    const events = data.vcalendar[0].vevent;
                    const filteredEvents = events.filter(event => {
                        const eventYear = parseInt(event.dtstart[0].substring(0,4));
                        return years.includes(eventYear);
                    });

                    setHolidays(filteredEvents);
                }
            } catch (error) {
                console.error('Error fetching holidays:', error);
            }
        };

        fetchHolidays();
    }, [currentDate, displayDayNum]);


    // Generate array of dates for the calendar
    const getDatesForWeek = (date) => {
        const dates = [];
        const startDate = new Date(date);
        
        if (isCustomRange) {
            let currentDate = new Date(customDateRange.startDate);
            while (currentDate <= customDateRange.endDate) {
                dates.push(new Date(currentDate));
                currentDate.setDate(currentDate.getDate() + 1);
            }
        } else {
            startDate.setDate(startDate.getDate() - startDate.getDay()); // Start from Sunday
            for (let i = 0; i < displayDayNum; i++) {
                const date = new Date(startDate);
                date.setDate(startDate.getDate() + i);
                dates.push(date);
            }
        }
        return dates;
    }

    const handlePrevWeek = () => {
        const newDate = new Date(currentDate);
        newDate.setDate(newDate.getDate() - displayDayNum);
        setCurrentDate(newDate);
    }

    const handleNextWeek = () => {
        const newDate = new Date(currentDate);
        newDate.setDate(newDate.getDate() + displayDayNum);
        setCurrentDate(newDate);
    }

    // Check if date is within task's date range
    const isDateInRange = (date, startDate, deadline) => {
        if (!startDate && !deadline) return false;
        const currentDate = new Date(date);
        currentDate.setHours(0, 0, 0, 0);

        if (startDate && !deadline) {
            const start = new Date(startDate);
            start.setHours(0, 0, 0, 0);
            return currentDate.getTime() === start.getTime();
        }

        if (!startDate && deadline) {
            const end = new Date(deadline);
            end.setHours(0, 0, 0, 0);
            return currentDate.getTime() === end.getTime();
        }

        const start = new Date(startDate);
        const end = new Date(deadline);
        start.setHours(0, 0, 0, 0);
        end.setHours(0, 0, 0, 0);

        return currentDate >= start && currentDate <= end;
    };

    // Add this new helper function near the top with other helper functions
    const isDateInRangeForType = (date, startDate, deadline, type) => {
        if (!startDate && !deadline) return false;
        const currentDate = new Date(date);
        currentDate.setHours(0, 0, 0, 0);

        if (startDate && !deadline) {
            const start = new Date(startDate);
            start.setHours(0, 0, 0, 0);
            return currentDate.getTime() === start.getTime();
        }

        if (!startDate && deadline) {
            const end = new Date(deadline);
            end.setHours(0, 0, 0, 0);
            return currentDate.getTime() === end.getTime();
        }

        const start = new Date(startDate);
        const end = new Date(deadline);
        start.setHours(0, 0, 0, 0);
        end.setHours(0, 0, 0, 0);

        return currentDate >= start && currentDate <= end;
    };

    // Fetch board items...
    let FetchBoardItems = async () => {
        let url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/board/items?boardID=${boardId}&organizationID=${localStorage.getItem('organizationId')}`
        fetch(url, {
            headers: {
                'Authorization': `${sessionStorage.getItem('idToken')}`
            }
        })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Failed to fetch items')
            }
        })
        .then(data => {
            // Transform the items into calendar events if they exist
            const events = data.items ? data.items.map(item => ({
                id: item._id,
                itemTitle: item.itemTitle,
                startDate: item.startDate,
                deadline: item.deadline,
                priority: item.priority,
                status: item.status,
                assignedTo: item.assignedTo,
                remarks: item.remarks,
                attachments: item.attachments,
                forecastedStartDate: item.forecastedStartDate,
                forecastedDeadline: item.forecastedDeadline,
                ...(Object.keys(item.progress || {}).length > 0 && { progress: item.progress })
            })) : [];

            setBoardItems(events);
        })
        .catch(error => console.error('Error fetching items:', error));
    }

    // Fetch board items when boardId changes
    useEffect(() => {
        FetchBoardItems();
    }, [boardId, ]);

    const dates = getDatesForWeek(currentDate);

    const handleJumpToToday = () => {
        setCurrentDate(new Date());
    };

    // Add handler for updating dates
    const handleDateUpdate = async (itemId, newDate, type) => {
        const item = boardItems.find(item => item.id === itemId);
        if (!item) return;
        
        // Create date objects for comparison using forecasted dates
        const startDate = type === 'start' ? new Date(newDate) : (item.forecastedStartDate ? new Date(item.forecastedStartDate) : null);
        const deadline = type === 'deadline' ? new Date(newDate) : (item.forecastedDeadline ? new Date(item.forecastedDeadline) : null);
        
        // Validate date selection if both dates exist
        if (startDate && deadline && startDate > deadline) {
            alert(type === 'start' ? '預測開始日期不能在預測截止日期之後' : '預測截止日期不能在預測開始日期之前');
            return;
        }

        // Format date as YYYY-MM-DD
        const formattedDate = new Date(newDate.getTime() - (newDate.getTimezoneOffset() * 60000))
            .toISOString()
            .split('T')[0];

        try {
            const response = await fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/board/items`, {
                method: 'PUT',
                headers: {
                    'Authorization': `${sessionStorage.getItem('idToken')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    itemID: itemId,
                    [type === 'start' ? 'forecastedStartDate' : 'forecastedDeadline']: formattedDate
                })
            });

            if (!response.ok) throw new Error('Failed to update date');

            setBoardItems(prevItems => 
                prevItems.map(item => 
                    item.id === itemId 
                        ? { 
                            ...item, 
                            [type === 'start' ? 'forecastedStartDate' : 'forecastedDeadline']: formattedDate
                        } 
                        : item
                )
            );
        } catch (error) {
            console.error('Error updating date:', error);
        } finally {
            setEditingDate(null);
        }
    };

    // Add handler for updating progress for each date 
    const handleProgressUpdate = async (itemId, date, progress) => {
        try {
            const response = await fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/board/item-progress`, {
                method: 'POST',
                headers: {
                    'Authorization': `${sessionStorage.getItem('idToken')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    itemID: itemId,
                    date: date.toISOString().split('T')[0],
                    progress: progress
                })
            });

            if (!response.ok) {
                throw new Error('Failed to update progress');
            }

            setShowNotification(true);
            FetchBoardItems();

        } catch (error) {
            console.error('Error updating progress:', error);
        }
    };

    // Add this helper function to check if a date is a holiday
    const getHolidayInfo = (date) => {
        return holidays.find(holiday => {
            const holidayDate = new Date(
                holiday.dtstart[0].substring(0, 4),
                parseInt(holiday.dtstart[0].substring(4, 6)) - 1,
                holiday.dtstart[0].substring(6, 8)
            );
            return date.toDateString() === holidayDate.toDateString();
        });
    };

    const [memberList, setMemberList] = useState([])
    useEffect(() => {
        // fetch member list
        fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/users?organizationID=${localStorage.getItem('organizationId')}`, {
            headers: {
                'Authorization': `${sessionStorage.getItem('idToken')}`
            }
        })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Failed to fetch members')
            }
        })
        .then(data => {
            setMemberList(data['users'].map(user => ({
                displayName: user.displayName,
                _id: user._id
            })))
        })
        .catch(error => console.error('Error fetching members:', error));
    }, [])

    const [openFileDialog, setOpenFileDialog] = useState(false)
    const [openRemarkDialog, setOpenRemarkDialog] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [editingItem, setEditingItem] = useState(null);

    const jumpToItemDate = (date) => {
        if (!date) return;
        const targetDate = new Date(date);
        // Adjust the date to be in the middle of the view
        targetDate.setDate(targetDate.getDate() - Math.floor(displayDayNum / 2));
        setCurrentDate(targetDate);
    };

    // Add this effect to re-fetch when dialog closes
    useEffect(() => {
        if (!editingItem && boardId) {
            FetchBoardItems();
        }
    }, [editingItem, boardId]);

    // Add these helper functions
    const getFirstProgressDate = (progress, type) => {
        if (!progress) return null;
        const entries = Object.entries(progress);
        const found = entries.find(([_, data]) => data.status === type);
        return found ? found[0] : null;
    };

    const getActualStartDate = (progress) => {
        return getFirstProgressDate(progress, 'inProgress');
    };

    const getWorkingDays = (progress) => {
        if (!progress) return [];
        return Object.entries(progress)
            .filter(([_, data]) => data.status === 'inProgress' || data.status === 'completed')
            .map(([date]) => date);
    };

    const handleCustomRangeChange = (newValue) => {
        if (!newValue?.startDate || !newValue?.endDate) return;
        
        setCustomDateRange({
            startDate: new Date(newValue.startDate),
            endDate: new Date(newValue.endDate)
        });
        
        // Update the dates view to start from the selected start date
        setCurrentDate(new Date(newValue.startDate));
        
        // Calculate and set the display day number based on the date range
        const diffTime = Math.abs(new Date(newValue.endDate) - new Date(newValue.startDate));
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
        setDisplayDayNum(diffDays);
    };

    return (
        <>
        <div className="space-y-4">
            <div className="flex justify-between items-center">
                <div className="text-sm text-gray-500">
                    {monthNameMap[dates[0].toLocaleDateString('en-US', { month: 'long' })] + ' ' + dates[0].getFullYear()}
                    {dates[0].getMonth() !== dates[displayDayNum - 1].getMonth() && 
                        ` - ${monthNameMap[dates[displayDayNum - 1].toLocaleDateString('en-US', { month: 'long' })] + ' ' + dates[displayDayNum - 1].getFullYear()}`
                    }
                </div>
                <div className="flex gap-2 items-center">
                    <button 
                        onClick={handlePrevWeek}
                        className="p-2 hover:bg-gray-100 rounded-full"
                    >
                        <ChevronLeftIcon className="h-5 w-5" />
                    </button>
                    <button
                        onClick={handleJumpToToday}
                        className="px-3 py-1 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-md border border-gray-300"
                    >
                        今天
                    </button>
                    
                    {isCustomRange ? (
                        <div className="w-64 z-50">
                            <Datepicker
                                value={customDateRange}
                                onChange={handleCustomRangeChange}
                                showShortcuts={true}
                                primaryColor="blue"
                                inputClassName="px-2 py-1 text-sm font-medium text-gray-700 bg-white rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent z-50"
                                displayFormat="YYYY/MM/DD"
                                i18n="zh"
                            />
                        </div>
                    ) : (
                        <select
                            value={displayDayNum / 7}
                            onChange={(e) => setDisplayDayNum(e.target.value * 7)}
                            className="px-2 py-1 pr-8 text-sm font-medium text-gray-700 bg-white rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        >
                            <option value={1}>1週</option>
                            <option value={2}>2週</option>
                            <option value={3}>3週</option>
                            <option value={4}>4週</option>
                        </select>
                    )}
                    
                    <button
                        onClick={() => setIsCustomRange(!isCustomRange)}
                        className="px-3 py-1 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-md border border-gray-300"
                    >
                        {isCustomRange ? '週視圖' : '自訂範圍'}
                    </button>
                    
                    <button 
                        onClick={handleNextWeek}
                        className="p-2 hover:bg-gray-100 rounded-full"
                    >
                        <ChevronRightIcon className="h-5 w-5" />
                    </button>
                </div>
            </div>
            <div className="overflow-auto pb-[250px]">
                <table className={`min-w-full w-[${500 + (displayDayNum * 60)}px] table-fixed border-collapse`}>
                    <thead className="sticky top-0 bg-white z-10">
                        <tr>
                            <th className="w-[300px] px-4 py-2 border bg-gray-50">工作</th>
                            <th className="w-[100px] px-2 py-2 border bg-gray-50">優先</th>
                            {/* <th className="w-[100px] px-2 py-2 border bg-gray-50">狀態</th> */}
                            {dates.map((date, index) => {
                                const isWeekend = date.getDay() === 0 || date.getDay() === 6;
                                const isToday = date.toDateString() === new Date().toDateString();
                                const holiday = getHolidayInfo(date);
                                
                                return (
                                    <th 
                                        key={index} 
                                        className="w-[60px] px-2 py-2 border bg-gray-50 relative"
                                    >
                                        <div className="text-sm text-center">
                                            {dayOfTheWeekMap[date.toLocaleDateString('en-US', { weekday: 'short' })]}
                                        </div>
                                        <div className="text-sm text-center flex items-center justify-center gap-1 relative">
                                            {date.getDate()}
                                            {holiday && (
                                                <div className="relative group">
                                                    <div className="absolute -top-1 -right-1 w-2 h-2 rounded-full bg-red-500 cursor-help" />
                                                    <div className="invisible group-hover:visible absolute z-50 top-full left-1/2 -translate-x-1/2 
                                                        px-2 py-1 mt-2 bg-gray-800 text-white text-xs rounded whitespace-nowrap">
                                                        {holiday.summary}
                                                        <div className="absolute -top-1 left-1/2 -translate-x-1/2 
                                                            border-4 border-transparent border-b-gray-800"></div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {boardItems.map((item, itemIdx) => (
                            <tr key={item._id} className="h-20">
                                <td className="w-[300px] px-4 py-2 border">
                                    <div className="max-h-[4.5rem] overflow-hidden group relative">
                                        <div
                                            onClick={() => setEditingItem(item)}
                                            className="cursor-pointer group space-y-1"
                                        >
                                            <div className="font-medium text-sm line-clamp-1 group-hover:text-blue-600">
                                                {item.itemTitle}
                                            </div>
                                            <div className="text-xs text-gray-500 flex items-center gap-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor">
                                                    <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                                                </svg>
                                                <div className="flex items-center max-w-[120px]" 
                                                    title={item.assignedTo?.length > 0 ? item.assignedTo.map(user => user.displayName).join(', ') : '未分配'}
                                                >
                                                    {item.assignedTo?.length > 0 ? (
                                                        <div className="flex items-center space-x-1 truncate">
                                                            <span className="whitespace-nowrap text-ellipsis overflow-hidden">{item.assignedTo[0].displayName}</span>
                                                            {item.assignedTo.length > 1 && (
                                                                <span className="shrink-0 bg-gray-200 text-gray-700 rounded-full px-1.5 leading-none text-[10px]">
                                                                    +{item.assignedTo.length - 1}
                                                                </span>
                                                            )}
                                                        </div>
                                                    ) : '未分配'}
                                                </div>
                                            </div>
                                            {item.startDate && (
                                                <div className="text-xs text-gray-500">
                                                    <span
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            jumpToItemDate(item.startDate);
                                                        }}
                                                        className="inline-flex items-center gap-1 text-blue-600 hover:text-blue-800 cursor-pointer"
                                                        title="點擊跳轉至開始日期"
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor">
                                                            <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
                                                        </svg>
                                                        {new Date(item.startDate).toLocaleDateString('zh-TW')}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </td>
                                <td className="w-[100px] px-2 py-2 border text-center">
                                    <PrioritySelect
                                        itemId={item.id}
                                        priority={item.priority}
                                        setBoardItems={setBoardItems}
                                    />
                                </td>
                                {/* <td className="w-[100px] px-2 py-2 border text-center">
                                    <StatusSelect
                                        itemId={item.id}
                                        status={item.status}
                                        setBoardItems={setBoardItems}
                                    />
                                </td> */}
                                {dates.length === 0 ? (
                                    <td className="w-[60px] px-2 py-2 border text-center text-gray-500">
                                        No dates available
                                    </td>
                                ) : dates.map((date, index) => {
                                    const isInRangePlanned = isDateInRangeForType(
                                        date, 
                                        item.startDate,
                                        item.deadline,
                                        'planned'
                                    );
                                    
                                    const isInRangeForecasted = isDateInRangeForType(
                                        date,
                                        item.forecastedStartDate,
                                        item.forecastedDeadline,
                                        'forecasted'
                                    );

                                    const isForecastedDeadline = item.forecastedDeadline && 
                                        new Date(item.forecastedDeadline)?.toDateString() === date.toDateString();
                                    const isForecastedStart = item.forecastedStartDate && 
                                        new Date(item.forecastedStartDate)?.toDateString() === date.toDateString();
                                    
                                    const isPlannedDeadline = item.deadline && 
                                        new Date(item.deadline)?.toDateString() === date.toDateString();
                                    const isPlannedStart = item.startDate && 
                                        new Date(item.startDate)?.toDateString() === date.toDateString();

                                    const isWeekend = date.getDay() === 0 || date.getDay() === 6;
                                    const isToday = date.toDateString() === new Date().toDateString();
                                    const holiday = getHolidayInfo(date);
                                    const dateString = date.toISOString().split('T')[0];
                                    const dateProgress = item.progress?.[dateString]?.status;

                                    return (
                                        <td 
                                            key={index} 
                                            className={`w-[60px] px-1 py-1 border relative overflow-visible ${
                                                isToday ? 'bg-blue-50' :
                                                isWeekend ? 'bg-gray-50' : 
                                                ''
                                            } ${
                                                editingDate ? 'cursor-pointer hover:bg-gray-100/50' : ''
                                            } ${
                                                holiday ? 'bg-red-50' : ''
                                            }`}
                                            onClick={() => {
                                                if (editingDate) {
                                                    handleDateUpdate(item.id, date, editingDate.type);
                                                }
                                            }}
                                        >
                                            <div className="flex flex-col gap-1">
                                                {/* Planned Date Bar (Top) */}
                                                {isInRangePlanned && (
                                                    <div className="relative group h-5">
                                                        <div 
                                                            className={`h-full ${
                                                                isPlannedDeadline ? 'bg-red-300' : 
                                                                isPlannedStart ? 'bg-green-300' : 
                                                                'bg-gray-300'
                                                            } rounded-sm flex justify-center items-center opacity-50`}
                                                        >
                                                            {/* No edit functionality for planned dates */}
                                                        </div>
                                                    </div>
                                                )}

                                                {/* Forecasted Date Bar (Bottom) */}
                                                {isInRangeForecasted && (
                                                    <div className="relative group h-5">
                                                        <div 
                                                            className={`h-full ${
                                                                isForecastedDeadline ? 'bg-red-500' : 
                                                                isForecastedStart ? 'bg-green-500' : 
                                                                dateProgress ? dateProgressMap[dateProgress]?.class : 
                                                                'bg-blue-500'
                                                            } rounded-sm flex justify-center items-center cursor-pointer`}
                                                            onClick={(e) => {
                                                                if (!editingDate && (isForecastedStart || isForecastedDeadline)) {
                                                                    e.stopPropagation();
                                                                    setEditingDate({ 
                                                                        itemId: item.id, 
                                                                        type: isForecastedStart ? 'start' : 'deadline' 
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                            {(isForecastedStart || isForecastedDeadline) && !editingDate && (
                                                                <PencilIcon className="h-3 w-3 text-white opacity-0 group-hover:opacity-100" />
                                                            )}
                                                        </div>

                                                        {/* Progress Indicator and Dropdown */}
                                                        <div className={`absolute ${
                                                            index >= dates.length - 2 
                                                                ? 'right-0 origin-top-right' 
                                                                : 'left-1/2 -translate-x-1/2 origin-top'
                                                            } mt-1 bg-white rounded-lg shadow-lg opacity-0 invisible 
                                                            group-hover:opacity-100 group-hover:visible transition-all duration-200 z-[60] min-w-[150px]`}
                                                        >
                                                            <div className="p-2">
                                                                {/* Date Info */}
                                                                <div className="text-xs text-gray-500 mb-2 px-2">
                                                                    {date.toLocaleDateString('zh-TW')}
                                                                </div>

                                                                {/* Progress Status Section */}
                                                                <div className="mb-2">
                                                                    <div className="text-xs font-medium text-gray-500 px-2 mb-1">進度狀態</div>
                                                                    <div className="space-y-1">
                                                                        {Object.entries(dateProgressMap).map(([key, value]) => (
                                                                            <button
                                                                                key={key}
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    handleProgressUpdate(item.id, date, key);
                                                                                }}
                                                                                className={`w-full px-3 py-1.5 text-sm ${
                                                                                    dateProgress === key 
                                                                                        ? `${value.class} text-white` 
                                                                                        : 'text-gray-700 hover:bg-gray-100'
                                                                                } rounded-md flex items-center gap-2 transition-colors`}
                                                                            >
                                                                                <div className={`w-4 h-4 rounded-full ${value.class} flex items-center justify-center`}>
                                                                                    {value.icon}
                                                                                </div>
                                                                                <span>{value.label}</span>
                                                                            </button>
                                                                        ))}
                                                                    </div>
                                                                </div>

                                                                {/* Remarks Section */}
                                                                {dateProgress && (
                                                                    <>
                                                                        <div className="border-t my-2"></div>
                                                                        <div className="text-xs font-medium text-gray-500 px-2 mb-1">備註</div>
                                                                        <button
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                setProgressRemarkDialog({
                                                                                    open: true,
                                                                                    itemId: item.id,
                                                                                    date: date
                                                                                });
                                                                            }}
                                                                            className="w-full px-3 py-1.5 text-sm text-gray-700 hover:bg-gray-100 rounded-md flex items-center gap-2"
                                                                        >
                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                                                <path fillRule="evenodd" d="M18 13V5a2 2 0 00-2-2H4a2 2 0 00-2 2v8a2 2 0 002 2h3l3 3 3-3h3a2 2 0 002-2zM5 7a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h3a1 1 0 100-2H6z" clipRule="evenodd" />
                                                                            </svg>
                                                                            <span>
                                                                                {item.progress?.[dateString]?.remark ? '查看備註' : '添加備註'}
                                                                            </span>
                                                                        </button>
                                                                    </>
                                                                )}

                                                                {/* Actions Section */}
                                                                {dateProgress && (
                                                                    <>
                                                                        <div className="border-t my-2"></div>
                                                                        <div className="text-xs font-medium text-gray-500 px-2 mb-1">操作</div>
                                                                        <button
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                handleProgressUpdate(item.id, date, null);
                                                                            }}
                                                                            className="w-full px-3 py-1.5 text-sm text-gray-700 hover:bg-gray-100 rounded-md flex items-center gap-2"
                                                                        >
                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                                                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                                                            </svg>
                                                                            <span>清除進度</span>
                                                                        </button>
                                                                    </>
                                                                )}

                                                                {/* Summary Section */}
                                                                {getActualStartDate(item.progress) && (
                                                                    <>
                                                                        <div className="border-t my-2"></div>
                                                                        <div className="px-2">
                                                                            <div className="text-xs text-gray-500">開始日期: {new Date(getActualStartDate(item.progress)).toLocaleDateString('zh-TW')}</div>
                                                                            <div className="text-xs text-gray-500">工作天數: {getWorkingDays(item.progress).length} 天</div>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            {editingDate?.itemId === item.id && (
                                                <div className="absolute inset-0 bg-gray-100 bg-opacity-0 hover:bg-opacity-30 transition-colors duration-150" />
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {editingDate && (
                <div className="fixed bottom-4 right-4 bg-white px-4 py-2 rounded-lg shadow-lg border border-gray-200">
                    <span className="text-sm text-gray-600">
                        選擇新的{editingDate.type === 'start' ? '開始' : '截止'}日期
                    </span>
                    <button
                        onClick={() => setEditingDate(null)}
                        className="ml-4 text-sm text-gray-500 hover:text-gray-700"
                    >
                        取消
                    </button>
                </div>
            )}
        </div>
        <FileDialog
            open={openFileDialog}
            setOpen={setOpenFileDialog}
            activeBoardID={selectedItem}
        />
        <RemarkDialog
            open={openRemarkDialog}
            setOpen={setOpenRemarkDialog}
            activeBoardID={selectedItem}
        />
        <ItemEditDialog
            open={editingItem !== null}
            setOpen={(open) => {
                if (!open) {
                    setEditingItem(null);
                    // No need to call FetchBoardItems here as the useEffect will handle it
                }
            }}
            item={editingItem}
            memberList={memberList}
            onUpdate={(updatedItem) => {
                // We can remove this onUpdate logic since we'll be fetching fresh data
                setEditingItem(null);
            }}
        />
        <SelfDisappearNotification
            title="成功"
            body="狀態已成功更新"
            show={showNotification}
            setShow={setShowNotification}
        />
        <ProgressRemarkDialog
            open={progressRemarkDialog.open}
            setOpen={(open) => setProgressRemarkDialog({ open, itemId: null, date: null })}
            itemId={progressRemarkDialog.itemId}
            date={progressRemarkDialog.date}
            onSave={FetchBoardItems}
        />
        </>
    )
}