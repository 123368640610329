import { useState } from 'react'
import GlobalVars from '../../../../../../Config'

const priorityOpt = [
    { 
        name: '高', 
        value: 'high', 
        color: 'bg-red-500',
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z" clipRule="evenodd" />
            </svg>
        )
    },
    { 
        name: '中', 
        value: 'medium', 
        color: 'bg-yellow-500',
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
            </svg>
        )
    },
    { 
        name: '低', 
        value: 'low', 
        color: 'bg-green-500',
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M14.707 12.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l2.293-2.293a1 1 0 011.414 0z" clipRule="evenodd" />
            </svg>
        )
    },
]

export default function PrioritySelect({ itemId, priority, setBoardItems }) {
    const [isOpen, setIsOpen] = useState(false);
    const currentPriority = priorityOpt.find(opt => opt.value === priority);

    const handlePriorityChange = async (priorityName) => {
        try {
            const response = await fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/board/items`, {
                headers: {
                    'Authorization': `${sessionStorage.getItem('idToken')}`,
                    'Content-Type': 'application/json'
                },
                method: 'PUT',
                body: JSON.stringify({
                    itemID: itemId,
                    priority: priorityName
                })
            });

            if (!response.ok) {
                throw new Error('Failed to update priority');
            }

            setBoardItems(prevItems => 
                prevItems.map(item => 
                    item.id === itemId 
                        ? { ...item, priority: priorityName }
                        : item
                )
            );
        } catch (error) {
            console.error('Error updating priority:', error);
        } finally {
            setIsOpen(false);
        }
    }
    
    return (
        <div className="relative group">
            <button 
                className={`inline-flex w-full justify-center gap-x-1.5 rounded-md px-2 py-1 text-xs text-white ${currentPriority?.color || 'bg-gray-200'}`}
                onClick={() => setIsOpen(!isOpen)}
            >
                {currentPriority?.name || '未設定'}
            </button>
            
            <div className={`absolute left-1/2 -translate-x-1/2 mt-1 bg-white rounded-lg shadow-lg 
                ${isOpen ? 'opacity-100 visible' : 'opacity-0 invisible'} 
                transition-all duration-200 z-[60] min-w-[120px]`}
            >
                <div className="p-2">
                    <div className="text-xs font-medium text-gray-500 px-2 mb-1">優先級</div>
                    <div className="space-y-1">
                        {priorityOpt.map((opt) => (
                            <button
                                key={opt.value}
                                onClick={() => handlePriorityChange(opt.value)}
                                className={`w-full px-3 py-1.5 text-sm ${
                                    priority === opt.value 
                                        ? `${opt.color} text-white` 
                                        : 'text-gray-700 hover:bg-gray-100'
                                } rounded-md flex items-center gap-2 transition-colors`}
                            >
                                <div className={`w-4 h-4 rounded-full ${opt.color} flex items-center justify-center`}>
                                    {opt.icon}
                                </div>
                                <span>{opt.name}</span>
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
} 