import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import GlobalVars from '../../../../Config';
import SlideOvers from '../../../../Components/SlideOvers'
import SubModuleSelection from '../SubModuleSelection'
import { MultiSelect } from "react-multi-select-component"

import { Menu, MenuButton, MenuItems } from '@headlessui/react'

// New Job Type form container 
export default function NewTypeFormContainer({openForm, setOpenForm, tabs, editMode, formContent, setFormContent, editID, reminderInputs, setReminderInputs}) {
    

    const handleUploadNewType = (e) => {
        // check for current tabs 
        let currentTab = tabs.find((tab) => tab.current);

        // create a post request to /api/v1/jobtypes
        let url;
        if (currentTab['name'] == "工作要求") {  // handle job types
            url = GlobalVars.BACKEND_DOMAIN + "/api/v1/jobtypes";
        } else {  // handle main types
            url = GlobalVars.BACKEND_DOMAIN + "/api/v1/maintypes";
        }

        let body = {
            "organizationID": sessionStorage.getItem("organizationId"),
            'name': document.getElementById("eng-name").value,
            "langVar": {
                "tc": document.getElementById("name").value,
            },
            "reminder": reminderInputs,
            "itemCode": formContent['itemCode']
        };

        // if it's job type, add main type id   
        if (currentTab['name'] == "工作要求") {
            body['mainTypeID'] = document.getElementById("main-type").value;
        }

        let headers = {
            "Content-Type": "application/json",
            "Authorization": sessionStorage.getItem("idToken")
        };

        // check if users are in edit mode
        let apiMethod = 'POST';
        if (editMode) {
            apiMethod = "PUT";
            body['_id'] = editID;
        } 

        fetch(url, {
            method: apiMethod,
            body: JSON.stringify(body),
            headers: headers
        }).then(response => {
            if (response.status === 200) {
                // alert
                if (editMode) {
                    alert("成功修改類別");
                } else {
                    alert("成功新增類別");
                }
                // close the form
                setOpenForm(false);
                
                // reload the page
                window.location.href = window.location.origin + window.location.pathname + "?t=" + currentTab['name'];

            } else {
                console.log("Error: " + response.status);
                if (editMode) {
                    alert("修改類別失敗");
                } else {
                    alert("新增類別失敗");
                }
            }
        }).catch(error => {
            console.log(error);
            alert("新增類別失敗");
        });
    };

    // if edit mode is true, set the form title
    const formTitle = editMode ? "修改類別" : "新加類別";

    return (
        <SlideOvers 
            open={openForm} 
            setOpen={setOpenForm} 
            title={formTitle}
            submitAction={handleUploadNewType}
            content={<NewTypeFormContent 
                        setFormContent={setFormContent} 
                        formContent={formContent} 
                        reminderInputs={reminderInputs} 
                        setReminderInputs={setReminderInputs} 
                        tabs={tabs} 
                        editMode={editMode} 
                        editID={editID}
                    />} 
            extraBtn={<DeleteTypeButton typeID={editID} setOpen={setOpenForm} />}
        />
    )
}

// delete type button 
function DeleteTypeButton({typeID, setOpen}) {
    const handleDeleteType = (e) => {
        // prompt confirmation
        if (!window.confirm("確認刪除？")) {
            return;
        }

        let url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/jobtypes`;
        fetch(url, {
            method: "DELETE",
            headers: {
                "Authorization": sessionStorage.getItem("idToken"),
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "typeID": typeID
            })
        }).then(response => {
            if (response.status === 200) {
                alert("刪除成功");
                setOpen(false);
            } else {
                throw new Error("刪除失敗");
            }
        }).catch(error => {
            console.log(error);
            alert("刪除失敗");
        });
    }

    // check if the type already deleted
    const [isDeleted, setIsDeleted] = useState(false);
    useEffect(() => {
        let url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/jobtypes?typeID=${typeID}`;
        fetch(url, {
            headers: {
                "Authorization": sessionStorage.getItem("idToken")
            }
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error("刪除失敗");
            }
        }).then(data => {
            if (data['type'].hasOwnProperty("deletedAt")) {
                setIsDeleted(true);
            }
        }).catch(error => {
            console.log(error);
        });
    }, [typeID]);


    return (
        <button
            type="button"
            className={`inline-flex items-center rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 ${isDeleted ? "opacity-50 cursor-not-allowed bg-red-400" : "bg-red-600"}`}
            onClick={handleDeleteType}
            disabled={isDeleted}
        >
            {isDeleted ? "已刪除" : "刪除"}
        </button>
    )
}

// New Job Type form content
function NewTypeFormContent({formContent, setFormContent, reminderInputs, setReminderInputs, tabs, editMode, editID}) {
    const [userList, setUserList] = useState([]);

    const [currentTabName, setCurrentTabName] = useState("工作要求");

    useEffect(() => {
        // get current tabs
        let currentTab = tabs.find((tab) => tab.current);
        setCurrentTabName(currentTab['name']);
    }, [tabs]);
    
    // fetch user list from backend
    useEffect(() => {
        let url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/users?organizationID=${sessionStorage.getItem("organizationId")}`;
        fetch(url, {
            headers: {
              "Authorization": sessionStorage.getItem("idToken")
            }
          })
        .then((response) => response.json())
        .then((rawData) => {
            let data = rawData['users'];
            // convert displayNames to name
            data = [
                {
                    "_id": "assignedTo",
                    "name": "施工人員",
                },
                {
                    "_id": "assignedBy",
                    "name": "接單人員",
                },
                ...data.map((user) => {
                    let name = user.displayName;
                    if (!user.hasOwnProperty("displayName") || name === "") {
                        name = user['meta']['email'].substring(0, user['meta']['email'].indexOf("@"));
                    }
                    return {
                        "_id": user._id,
                        "name": name
                    }
                })
            ];

            // convert to {label: name, value: _id} format
            data = data.map((user) => {
                return {
                    "label": user.name,
                    "value": user._id
                }
            });

            setUserList(data);
        }).catch(error => {
            console.log(error);
            alert("獲取用戶列表失敗");
        });
    }, []);

    // handle adding another set of reminder
    const handleAddAnotherReminder = (e) => {
        e.preventDefault();
        setReminderInputs([...reminderInputs, {time: "", targets: []}]);
    };

    // handle removing last set of reminder
    const handleRemoveLastReminder = (e) => {
        e.preventDefault();
        setReminderInputs(reminderInputs.slice(0, -1));
    };

    // handle input change for reminder inputs
    const handleReminderInputChange = (index, e) => {
        const values = [...reminderInputs];
        values[index][e.target.name] = e.target.value;
        setReminderInputs(values);
    };

    // handle input change when users interact with multiselect
    const handleMultiUserSelect = (ind, selectedOptions) => {
        const values = [...reminderInputs];
        
        values[ind]['targets'] = selectedOptions;
        setReminderInputs(values);
    }

    // maintype List dropdown for associating with job types
    const [mainTypeList, setMainTypeList] = useState([]);
    useEffect(() => {
        if (currentTabName !== "工作要求") {
            return;
        }

        let url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/maintypes?organizationID=${sessionStorage.getItem("organizationId")}`;
        fetch(url)
        .then((response) => response.json())
        .then((rawData) => {
            let data = rawData['types'];

            // use langVar to display the name
            data = data.map((type) => {
                return {
                    "_id": type._id,
                    "name": type.langVar.tc
                }
            });

            setMainTypeList(data);
        }).catch(error => {
            console.log(error);
            alert("獲取主類別列表失敗");
        });

    }, [currentTabName]);

    // check if 工作代號 is not duplicated
    const [isDuplicatedCode, setIsDuplicatedCode] = useState(false);
    const [codeSuggest, setCodeSuggest] = useState("");
    useEffect(() => {
        let url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/jobtypes?organizationID=${sessionStorage.getItem("organizationId")}&itemCode=${formContent['itemCode']}`;
        fetch(url, {
            headers: {
                "Authorization": sessionStorage.getItem("idToken")
            }
          })
        .then((response) => {
            if (response.status === 200) {
                setIsDuplicatedCode(true);
            } else {
                setIsDuplicatedCode(false);
            }
        }).catch(error => {
            console.log(error);
        });
    }, [formContent['itemCode']]);


    return (
        <>
            <div className="mt-1">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                    中文名稱
                </label>
                <div className="mt-1">
                    <input
                        type="text"
                        name="name"
                        id="name"
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder=""
                        value={formContent['chiName']}
                        onChange={(e) => setFormContent({...formContent, "chiName": e.target.value})}
                    />
                </div>
            </div>
            <div className="mt-5">
                <label htmlFor="eng-name" className="block text-sm font-medium text-gray-700">
                    英文名稱
                </label>
                <div className="mt-1">
                    <input
                        type="text"
                        name="eng-name"
                        id="eng-name"
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder=""
                        value={formContent['engName']}
                        onChange={(e) => setFormContent({...formContent, "engName": e.target.value})}
                    />
                </div>
            </div>
            <div className="mt-5">
                <label htmlFor="item-code" className="block text-sm font-medium text-gray-700">
                    工作代號
                </label>
                <div className="mt-1">
                    <input
                        type="text"
                        name="item-code"
                        id="item-code"
                        className={`shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md ${(isDuplicatedCode && !editMode)? "border-red-500" : ""}`}
                        placeholder=""
                        value={formContent['itemCode']}
                        onChange={(e) => setFormContent({...formContent, "itemCode": e.target.value})}
                    />
                    {/* input alert in red font */}
                    <div
                        className="mt-2 flex items-center justify-between"
                    >
                    {(isDuplicatedCode && !editMode) ?
                        <p className=" text-sm text-red-600" id="email-error">
                            這個工作代號已經存在
                        </p>
                        :
                        <p
                            className="text-sm text-gray-500"
                        >建議代號: {codeSuggest}</p>
                    }
                    <ViewPastJobCode tabs={tabs} setCodeSuggest={setCodeSuggest} />
                    </div>
                </div>
            </div>
            
            {currentTabName === "工作要求" && 
                <div>
                    <hr className="my-5"></hr>
                    <label htmlFor="main-type" className="block text-sm font-medium text-gray-700">
                        主類別
                    </label>
                    <div className="mt-1">
                        <select
                            id="main-type"
                            name="main-type"
                            value={formContent['mainType']}
                            onChange={(e) => setFormContent({...formContent, "mainType": e.target.value})}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        >
                            <option value="">請選擇</option>
                            {mainTypeList.map((type) => (
                                <option key={type._id} value={type._id}>{type.name}</option>
                            ))}
                        </select>
                    </div>
                    
                    {/* <hr className="my-5"></hr>
                    <JobIndividualEscalationSettings 
                        reminderInputs={reminderInputs}
                        userList={userList}
                        handleReminderInputChange={handleReminderInputChange}
                        handleMultiUserSelect={handleMultiUserSelect}
                        handleAddAnotherReminder={handleAddAnotherReminder}
                        handleRemoveLastReminder={handleRemoveLastReminder}
                    /> */}

                    <hr className='my-5'></hr>
                    <SubModuleSelection 
                        typeID={editID}
                    />

                </div>
            }
        </>
    )
}


// Job individual escalation settings
function JobIndividualEscalationSettings({
    reminderInputs, 
    userList, 
    handleReminderInputChange,
    handleMultiUserSelect,
    handleAddAnotherReminder,
    handleRemoveLastReminder
}) {
    return (
        <div>
            <h3 className="leading-6 font-medium text-gray-900">工作逾時提醒設定</h3>
            <p className="mt-1 text-sm text-gray-500">每個提醒是按順序, 而非同時計時. </p>
            {/* create initial reminder sets */}
            {reminderInputs.map((reminder, index) => (
                <div key={index} className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                        <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                            時間 (分鐘)
                        </label>
                        <div className="mt-2">
                            <input
                            onChange={event => handleReminderInputChange(index, event)}
                            value={reminder.time}
                            name="time"
                            type="number"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                    <div className="sm:col-span-3">
                        <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                            目標提醒用戶
                        </label>
                        <div className="mt-2">
                            {/* <select
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                value={reminder.target}
                                name="target"
                                onChange={event => handleReminderInputChange(index, event)}
                            >   
                                <option value="">請選擇</option>
                                {userList.map((user) => (
                                    <option key={user._id} value={user._id}>{user.name}</option>
                                ))}
                            </select> */}
                            <MultiSelect 
                                options={userList}
                                value={reminder.targets}
                                data-id={index}
                                labelledBy={"Select"}
                                onChange={(e) => {
                                    handleMultiUserSelect(index, e);
                                }}
                            />
                        </div>
                    </div>
                </div>
            ))}
            
            <button
                onClick={handleAddAnotherReminder}
                className='mt-2 inline-flex items-center px-4 py-2 border border-transparent text-xs font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700'
            >
                加上另一個逾時提醒
            </button>
            {/* a secondary button to remove the last reminder */}
            <button
                onClick={handleRemoveLastReminder}
                className='ml-2 mt-2 inline-flex items-center px-4 py-2 border border-transparent text-xs font-medium rounded-md shadow-sm text-indigo ring-1 ring-outset ring-indigo-300 hover:bg-indigo-600 hover:text-white'
            >
                移除上一個逾時提醒
            </button>
        </div>
    )
}

// view past job code 
function ViewPastJobCode({tabs, setCodeSuggest}) {
    const [pastJobCodes, setPastJobCodes] = useState([]);
    // query for past job codes
    useEffect(() => {
        let selectedTab = tabs.find((tab) => tab.current)['name'];
        let url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/jobtypes/latest-three?organizationID=${sessionStorage.getItem("organizationId")}&type=${selectedTab == "工作要求" ? "job" : "main"}`;
        fetch(url, {
            headers: {
              "Authorization": sessionStorage.getItem("idToken")
            }
          })
        .then((response) => response.json())
        .then((rawData) => {
            setPastJobCodes(rawData['jobTypeCode']);

            // get the first item code and suggest the next one
            let firstItemCode = rawData['jobTypeCode'][0]['itemCode'];

            // use regex to detect any integer within the string
            let regex = /\d+/;
            let match = firstItemCode.match(regex);
            let number = parseInt(match[0]);
            let nextNumber = number + 1;
            let nextItemCode = firstItemCode.replace(regex, nextNumber.toString());
            setCodeSuggest(nextItemCode);

        }).catch(error => {
            console.log(error);
        });
    }, [])

    return (
        <Menu as="div" className="relative inline-block text-left">
      <div>
        <MenuButton className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100 px-2">
          {/* <EllipsisVerticalIcon aria-hidden="true" className="h-5 w-5" /> */}
          <span
            className="text-sm"
          >
            查看過去工作代號
          </span>
        </MenuButton>
      </div>

      <MenuItems
        transition
        className="max-h-[200px] overflow-y-scroll absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="py-1">
          {pastJobCodes.map((code) => (
            <MenuButton as="button" key={code} className="w-full flex items-center justify-between px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">
              <p>{code.name.length > 14 ?  code.name.slice(0, 14) + "..." : code.name}</p>
              <p>{code.itemCode}</p>
            </MenuButton>
          ))}
        </div>
      </MenuItems>
    </Menu>
    )
}

