import { useState, useEffect } from 'react';
import GlobalVars from '../../../../Config';
import Datepicker from 'react-tailwindcss-datepicker'
import { BarChart, XAxis, YAxis, Bar, Brush, Tooltip, ResponsiveContainer } from 'recharts'

// components
import KPIDisplay from '../IssueReportingDashboard/SubComponents/KPIDisplay'

export default function IssueReportingDashboard() {
    const [filter, setFilter] = useState({
        startDate: null,
        endDate: null,
    });

    useEffect(() => {
        // set startDate as first day of current month and endDate as today
        const startDate = new Date();
        startDate.setDate(1);
        const endDate = new Date();
        setFilter({ 
            ...filter, 
            startDate: startDate.toISOString().split('T')[0],
            endDate: endDate.toISOString().split('T')[0]
        });
    }, []);

    return (
        <div>
            <Filter filter={filter} setFilter={setFilter} />
            <div className='flex flex-col gap-4 mt-4 w-full'>
                <KPIDisplay filter={filter} />
                <LocationRank filter={filter} />
            </div>
        </div>
    )
}

function Filter({ filter, setFilter }) {

    return (
        <div>
            <label className="block text-sm font-medium text-gray-700 mt-4 mb-2">日期範圍</label>
            <Datepicker
                value={{
                    startDate: filter.startDate,
                    endDate: filter.endDate
                }}
                onChange={(date) => {
                    setFilter({ ...filter, startDate: date.startDate, endDate: date.endDate })
                }}
                configs={{
                    shortcuts: {
                      today: "今天",
                      yesterday: "昨日", 
                      past: (period) => `過去 ${period} 天`,
                      currentMonth: "本月",
                      pastMonth: "上月",
                    }
                  }}
                showShortcuts={true}
                placeholder='選擇日期範圍'
            />
        </div>
    )
}



function LocationRank({
    filter
}) {
    const [locStats, setLocStats] = useState([]);

    useEffect(() => {
        // return if startDate or endDate is not set
        if (!filter?.startDate || !filter?.endDate) {
            return;
        }

        let url = GlobalVars.ANALYTIC_DOMAIN + '/api/v1/issue-report/location';
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': sessionStorage.getItem('idToken'),
            },
            body: JSON.stringify({
                startDate: filter.startDate,
                endDate: filter.endDate,
                organizationID: localStorage.getItem('organizationId'),
            }),
        })
            .then(response => response.json())
            .then(rawData => {
                let data = rawData['data'];
                const transformedData = transformLocData(data);
                const processedData = processSortData(transformedData);
                setLocStats(processedData);
            })
            .catch(error => console.error('Error fetching location list:', error));
    }, [filter]);

    const [isMobile, setIsMobile] = useState(false);
    const [brushY, setBrushY] = useState(400);
    useEffect(() => {
        setIsMobile(window.innerWidth <= 768);
        setBrushY(window.innerWidth <= 768 ? 330 : 400);
    }, []);

    return (
        <ResponsiveContainer width="100%" height={460}>
            <h1
                className='text-xl font-bold mb-4'
            >地點排名</h1>
            <BarChart 
                data={locStats}
                margin={{
                    top: 20, right: 0, left: 5, bottom: 60,
                }}
                padding={{
                    top: 20, right: 0, left: 0, bottom: 40,
                }}
            >
                <XAxis 
                    dataKey="name" 
                    angle={300}
                    textAnchor="end"
                    height={60}
                    className={`text-[10px] ${window.innerWidth <= 768 ? 'hidden' : ''}`}
                    tickFormatter={(value) => value.length > 10 ? value.substring(0, 10) + '...' : value}
                />
                <YAxis 
                    label={{ value: '次數', angle: -90, position: 'insideLeft' }} 
                />
                <Bar dataKey="count" fill="#8884d8" />
                <Tooltip content={<CustomTooltip />} />
                <Brush 
                    dataKey="location" 
                    height={30} 
                    stroke="#8884d8"
                    startIndex={0}
                    endIndex={8}       // Show first 5 items initially
                    gap={1}
                    travellerWidth={10}
                    y={brushY}
                />
            </BarChart>
        </ResponsiveContainer>
    )
}

function CustomTooltip({active, payload, label}) {
    if (active && payload && payload.length) {
      return (
        <div className="bg-gray-700 rounded p-2 shadow-inner text-white w-[140px] text-sm">
          <p className="text-gray-300 text-xs">{`${label}`}</p>
          <p className="text-white text-lg">{payload[0].value}</p>
          {/* <p className="intro">{getIntroOfPage(label)}</p> */}
          {/* <p className="desc">Anything you want can be displayed here.</p> */}
        </div>
      )
    }
  
    return null;
}

// aggregate the data (format: [{createdAt: <date YYYY-MM-DD HH:MM:SS>, locationName: <name>}, ...]) so the format becomes [{'month': <month>, 'location': {<location>: <count>, ...}}, ...]
function transformLocData(data) {
    const result = [];

    // Group by locationName and month
    const groups = data.reduce((acc, item) => {
        // Extract the year and month from createdAt
        const month = item.createdAt.slice(0, 7); // "YYYY-MM"
        const key = `${item.location}-${month}`;

        // Initialize if not present
        if (!acc[key]) {
        acc[key] = {
            month,
            name: item.location,
            count: 0
        };
        }

        // Increment the count for the group
        acc[key].count += 1;

        return acc;
    }, {});

    // Convert the grouped object into an array
    for (const group of Object.values(groups)) {
        result.push(group);
    }

    return result;
}

function processSortData(rawLocStats, displayPoints=15) {

    // check locationName character length, if more than 10, then shorten it
    for (let i = 0; i < rawLocStats.length; i++) {
      // skip if it's undefined
      if (rawLocStats[i].name === undefined) {
          continue;
      }

      let lengthLimit = 20;
      if (rawLocStats[i].name.length > lengthLimit) {
          rawLocStats[i].name = rawLocStats[i].name.slice(0, lengthLimit) + "...";
      }
    }
    
    // sort by count and limit to specified number of display points
    rawLocStats.sort((a, b) => b.count - a.count);
    rawLocStats = rawLocStats.slice(0, displayPoints);
    
    return rawLocStats;
}
