import GlobalVars from "../../Config";
import { useLocation } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged, setPersistence, browserSessionPersistence, browserLocalPersistence} from "firebase/auth";
import { Scanner} from '@yudiel/react-qr-scanner';
import {
  EyeIcon,
  EyeSlashIcon
} from '@heroicons/react/24/outline';



// redirect users based on requested page
function RedirectUserBasedOnRequestedPage() {
  let requestedPage = sessionStorage.getItem("requestedPage");
  if (requestedPage !== null && requestedPage !== "" && requestedPage !== "/authentication" && requestedPage !== "/create-account") {
    // redirect to users requested page
    window.location.href = requestedPage;
  } else {
    // redirect to /job-list
    window.location.href = "/job-list";
  }
}


export default function CreateAccount() {
    // set page title to "Authentication"
    document.title = "身分認證";

    // find url query arguments for organizationid
    const query = new URLSearchParams(useLocation().search);

    const organizationid = query.get("organizationid");
    const attemptedEmail = query.get("email");
    const attemptedName = query.get("name")
    const attemptedPw = query.get("pw")


    // set state for email and password
    const [email, setEmail] = useState(attemptedEmail? attemptedEmail : "");
    const [password, setPassword] = useState(attemptedPw? attemptedPw : "");
    const [displayname, setDisplayname] = useState(attemptedName? attemptedName : "");
    const [organizationID, setOrganizationID] = useState(organizationid? organizationid : "");

    const [rememberPassword, setRememberPassword] = useState(false);

    // handle signups
    const submitBtnRef = useRef(null);
    const handleSignup = () => {
        // set button text to "登入中..."
        submitBtnRef.current.innerHTML = "登入中...";

        // disable button 
        submitBtnRef.current.disabled = true;

        // check all fields are filled
        if (email === "" || password === "" || displayname === "") {
            alert("請填寫所有欄位");
            // reset button 
            submitBtnRef.current.innerHTML = "註冊";
            submitBtnRef.current.disabled = false;
            return;
        }

        // validate users password
        if (!validatePassword()) {
          // reset button 
          submitBtnRef.current.innerHTML = "註冊";
          submitBtnRef.current.disabled = false;
          return 
        }


        // initialize firebase app
        const firebaseApp = initializeApp(GlobalVars.firebase);

        // Signup with email and password
        const auth = getAuth(firebaseApp);
        setPersistence(auth, rememberPassword? browserLocalPersistence:browserSessionPersistence)
          .then(() => {
          createUserWithEmailAndPassword(auth, email, password)
              .then((userCredential) => {
                  // Signed in 
                  const user = userCredential.user;

                  // set sessionStorage for firebaseUid
                  sessionStorage.setItem("firebaseUid", user.uid);

                  // get users' ID token and store to sessionStorage
                  user.getIdToken()
                    .then((idToken) => {
                        sessionStorage.setItem("idToken", idToken); 
                        // update lastIdTokenUpdateTime
                        sessionStorage.setItem("lastIdTokenUpdateTime", new Date().getTime());

                        // POST request to /api/v1/users
                        let submitData = {
                            "email": email,
                            "displayName": displayname,
                            "organizationID": organizationID,
                            "firebaseID": user.uid
                        };
                        
                        if (organizationID != "") {
                          localStorage.setItem("organizationId", organizationID);
                          sessionStorage.setItem("organizationId", organizationID);
                        }
                        sessionStorage.setItem("displayName", displayname);

                        CreateUserAccount(submitData, submitBtnRef);
                    })
                    .catch((error) => {
                        console.log(error);

                        
                    });

              })
              .catch((error) => {
                  // if it's a auth/user-not-found error, prompt users to create an account and redirect to /create-account
                  console.log(error);
                  console.log(error.code);
                  // alert users if email already in use
                  if (error.code == "auth/email-already-in-use") {
                    alert("電郵已經存在, 會自動導向登入頁面");
                    window.location.href = "/authentication";
                  }

              });
          })
    };

    // check users login state
    const checkUserState = () => {
      const auth = getAuth();
      onAuthStateChanged(auth, (user) => {
          if (user) {
              // set sessionStorage for firebaseUid and organizationId
              sessionStorage.setItem("firebaseUid", user.uid);

              // check from localStorage if organizationId exist
              let organizationId = localStorage.getItem("organizationId");
              sessionStorage.setItem("organizationId", organizationId);
              
              // check if users have a requested page in sessionStorage
              RedirectUserBasedOnRequestedPage();
          }
      });
    }

    // states for qr code scanner
    const [openScanner, setOpenScanner] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);

    // view password
    const pwField = useRef(null);
    const ViewPassword = () => {
      // toggle between password and text type
      setPasswordVisible(!passwordVisible);
      pwField.current.type = passwordVisible ? 'text' : 'password';

    }

    return (
      <>
        {/*
          This example requires updating your template:
  
          ```
          <html class="h-full bg-white">
          <body class="h-full">
          ```
        */}
        <div className="flex min-h-full flex-1 h-[100vh]">
          <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full max-w-sm lg:w-96">
              <div>
                <img
                  className="h-10 w-auto"
                  src="/logo.png"
                  alt="Your Company"
                />
                <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                  註冊新帳號
                </h2>
                {/* <p className="mt-2 text-sm leading-6 text-gray-500">
                  Not a member?{' '}
                  <a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">
                    Start a 14 day free trial
                  </a>
                </p> */}
              </div>
  
              <div className="mt-10">
                <div>
                  <div className="space-y-6">
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                        公司ID (如適用)
                      </label>
                      <div className="mt-2">
                        <input
                            id="organizationID"
                            name="organizationID"
                            type="text"
                            required
                            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            value={organizationID}
                            onChange={(e) => setOrganizationID(e.target.value)}
                        />
                      </div>
                    </div>
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                        顯示名稱*
                      </label>
                      <div className="mt-2">
                        <input
                            id="displayname"
                            name="displayname"
                            type="text"
                            required
                            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            value={displayname}
                            onChange={(e) => setDisplayname(e.target.value)}
                        />
                      </div>
                      <p
                        className="text-xs mt-1 text-gray-500"
                      >*必填欄</p>
                    </div>

                    <div>
                      <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                        電郵*
                      </label>
                      <div className="mt-2">
                        <input
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <p
                        className="text-xs mt-1 text-gray-500"
                      >*必填欄</p>
                    </div>
  
                    <div>
                      <div
                        className="flex justify-between items-center"
                      >
                        <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                          密碼*
                        </label>
                        <button
                          onClick={ViewPassword}
                          className=""
                        >
                          {passwordVisible ? 
                            <EyeIcon className="h-5 w-5" />
                          : 
                            <EyeSlashIcon className="h-5 w-5" />
                          }
                        </button>
                      </div>
                      <div className="mt-2">
                        <input
                            id="password"
                            name="password"
                            type="password"
                            ref={pwField}
                            autoComplete="current-password"
                            required
                            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                      <p
                        className="text-xs mt-1 text-gray-500"
                      >*必填欄</p>
                      <p
                        id="error-desc"
                        className="text-red-500 text-sm"
                      >

                      </p>
                    </div>
                    {/* checkbox for remember password */}
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <input
                          id="remember-me"
                          name="remember-me"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          checked={rememberPassword}
                          onChange={(e) => setRememberPassword(e.target.checked)}
                        />
                        <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-700">
                          記住密碼
                        </label>
                      </div>
                    </div>
  
                    {/* <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <input
                          id="remember-me"
                          name="remember-me"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                        <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-700">
                          Remember me
                        </label>
                      </div>
  
                      <div className="text-sm leading-6">
                        <a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">
                          Forgot password?
                        </a>
                      </div>
                    </div> */}
  
                    <div>
                      <button
                        type="button"
                        className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={handleSignup}
                        ref={submitBtnRef}
                        id="signup-button"
                      >
                        註冊
                      </button>
                    </div>
                    <button
                      type="button"
                      className="flex w-full justify-center rounded-md bg-gray-100 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-600 shadow-sm hover:bg-gray-300 duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={() => setOpenScanner(true)}
                    >
                      掃描QR Code
                    </button>

                      <div>
                      <button
                        type="button"
                        className="flex w-full justify-center rounded-md bg-gray-100 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-600 shadow-sm hover:bg-gray-300 duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={() => window.location.href = "/authentication"}
                        id="signup-button"
                      >
                        返回登入
                      </button>
                    </div>
                  </div>
                </div>
  
                {/* <div className="mt-10">
                  <div className="relative">
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                      <div className="w-full border-t border-gray-200" />
                    </div>
                    <div className="relative flex justify-center text-sm font-medium leading-6">
                      <span className="bg-white px-6 text-gray-900">Or continue with</span>
                    </div>
                  </div>
  
                  <div className="mt-6 grid grid-cols-2 gap-4">
                    <a
                      href="#"
                      className="flex w-full items-center justify-center gap-3 rounded-md bg-[#1D9BF0] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#1D9BF0]"
                    >
                      <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                      </svg>
                      <span className="text-sm font-semibold leading-6">Twitter</span>
                    </a>
  
                    <a
                      href="#"
                      className="flex w-full items-center justify-center gap-3 rounded-md bg-[#24292F] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#24292F]"
                    >
                      <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                        <path
                          fillRule="evenodd"
                          d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="text-sm font-semibold leading-6">GitHub</span>
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="relative hidden w-0 flex-1 lg:block">
            <img
              className="absolute inset-0 h-full w-full object-cover"
              src="assets/login-background.png"
              alt=""
            />
          </div>
        </div>
        {openScanner && 
          <QRCodeScanner 
            openScanner={openScanner} 
            setOpenScanner={setOpenScanner}
            setEmail={setEmail}
            setPassword={setPassword}
            setDisplayname={setDisplayname}
            setOrganizationID={setOrganizationID}
          />
        }
        
      </>
    )
  }

function QRCodeScanner({openScanner, setOpenScanner, setEmail, setPassword, setDisplayname, setOrganizationID}) {
  const handleResult = (text, result) => {
      let url = text;
      // remove everything before the ?
      url = url.split("?")[1];
      let urlParams = new URLSearchParams(url);
      let email = urlParams.get("email");
      let name = urlParams.get("name");
      let pw = urlParams.get("pw");
      let organizationid = urlParams.get("organizationid");

      // if each one of them is not none, set the value 
      if (email) {
        setEmail(email);
      }

      if (name) {
        setDisplayname(name);
      }

      if (pw) {
        setPassword(pw);
      }
      if (organizationid) {
        setOrganizationID(organizationid);
      }

      setOpenScanner(false);

  }

  const customVideoStyle = {
    height: "259px",
    paddingTop: "65%"
  }

  const scannerRef = useRef(null);

  return(
    <div
      className="fixed top-0 left-0 w-full h-[100svh] bg-black bg-opacity-50 z-50"
    >
      <button
        onClick={() => setOpenScanner(false)}
        className="absolute w-[20%] top-4 left-[40%] bg-indigo-600 p-2 px-5 rounded-full z-50 text-white"
      >
        關閉掃描器
      </button>
      <Scanner 
        ref={scannerRef}
        onResult={handleResult}
        onError={(err) => console.error(err)}
        styles={customVideoStyle}
        enabled={openScanner}
      />
    </div>
  )
}

// create user account 
function CreateUserAccount(submitData, submitBtnRef) {
  fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/users", {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
          "Authorization": sessionStorage.getItem("idToken")
      },
      body: JSON.stringify(submitData)
  })
    .then(response => {
      if (response.ok) {
        return response.json()
      } else {
        if (response.json().hasOwnProperty('message')) {
          if (response.json()['message'] == 'Email already exist') {
            alert("用戶已存在, 會自動導向登入頁面")
            window.location.href = "/authentication"
          }
        } 
        throw new Error('Something went wrong');
      }
      
    })
    .then(data => {
        // set locatlStorage for organizationID and type
        console.log(data);
        localStorage.setItem("type", data["type"]);
        localStorage.setItem("userid", data["userid"]);
        console.log(data["organizationID"])
        localStorage.setItem("organizationId", data["organizationID"]);
        sessionStorage.setItem("organizationId", data["organizationID"]);

        // check if users have a requested page in sessionStorage
        RedirectUserBasedOnRequestedPage();
    })
    .catch((error) => {
        console.log(error);
        alert("發生錯誤，請再試一次");

        // reset button
        submitBtnRef.current.innerHTML = "註冊";
        submitBtnRef.current.disabled = false;
    });
}

// password format validation 
function validatePassword() {
  const password = document.getElementById('password').value;
  const result = document.getElementById('error-desc');

  const minLength = 12;
  const uppercaseRegex = /[A-Z]/;
  const lowercaseRegex = /[a-z]/;
  const digitRegex = /[0-9]/;
  const specialCharRegex = /[!@#$%^&*()\-_=+\[\]{}|;:'",.<>?/`~]/;

  let errors = [];

  if (password.length < minLength) {
      errors.push(`密碼至少要有 ${minLength} 個字符長。`);
  }
  if (!uppercaseRegex.test(password)) {
      errors.push('密碼必須包含至少一個大寫字母。');
  }
  if (!lowercaseRegex.test(password)) {
      errors.push('密碼必須包含至少一個小寫字母。');
  }
  if (!digitRegex.test(password)) {
      errors.push('密碼必須包含至少一個數字。');
  }

  if (errors.length > 0) {
      result.innerHTML = '密碼格式錯誤:<br>' + errors.join('<br>');
      return false
  } else {
      return true
  }
}
