import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

export default function SwitchViewButton({view, setView}) {
    const viewOptions = [
        { name: '項目', value: 'item' },
        { name: '日', value: 'day' },
        { name: '週', value: 'week' },
        { name: '月', value: 'month' },
        { name: '年', value: 'year' },
    ]
    const selectedView = viewOptions.find((option) => option.value === view)

    return (
        <Menu as="div" className="relative">
            <MenuButton
            type="button"
            className="flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
            {selectedView.name}視圖
            <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
            </MenuButton>

            <MenuItems
            transition
            className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
            <div className="py-1">
                <MenuItem>
                <a
                    onClick={() => setView('item')}
                    className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                >
                    項目
                </a>
                </MenuItem>
                {/* <MenuItem>
                <a
                    onClick={() => setView('day')}
                    className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                >
                    日
                </a>
                </MenuItem> */}
                <MenuItem>
                <a
                    onClick={() => setView('week')}
                    className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                >
                    週
                </a>
                </MenuItem>
                <MenuItem>
                <a
                    onClick={() => setView('month')}
                    className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                >
                    月
                </a>
                </MenuItem>
                {/* <MenuItem>
                <a
                    onClick={() => setView('year')}
                    className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                >
                    年
                </a>
                </MenuItem> */}
            </div>
        </MenuItems>
    </Menu>
    )
}