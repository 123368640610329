import { useState, useEffect, useMemo } from "react";
import { 
    MagnifyingGlassIcon,
    ArrowDownTrayIcon
} from '@heroicons/react/20/solid'
import GlobalVars from "../../../../Config";

// components
import NewFileDialog from "../NewFileDialog";

export default function FileList() {
    const [files, setFiles] = useState([]);

    const [query, setQuery] = useState("");

    // Memoize filtered results to prevent unnecessary recalculations
    const filteredFiles = useMemo(() => {
        
        const searchStr = query.toLowerCase().trim();
        
        // If no search query, return all files
        if (!searchStr) return files;

        return files.filter(file => {
            // Add null checks and default values
            const fileName = file.fileName?.toLowerCase() || '';
            const fileType = file.fileType?.toLowerCase() || '';
            const uploadBy = file.uploadBy?.toLowerCase() || '';
    
            return fileName.includes(searchStr) ||
                   fileType.includes(searchStr) ||
                   uploadBy.includes(searchStr);
        });
    }, [files, query]); // Only recalculate when files or query changes


    // retrieve current file list
    useEffect(() => {
        let projectId = window.location.pathname.split('/')[2]
        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/project/files?projectID=" + projectId
        fetch(url, {
            headers: {
                'Authorization': `${sessionStorage.getItem('idToken')}`
            }
        })
        .then(response => response.json())
        .then(data => {
            // process file converting to KB, MB, GB by removing the unit
            data.forEach(file => {
                // Convert file size to most appropriate unit
                const size = parseFloat(file.fileSize);
                if (size >= 1024 * 1024) { // Size in GB
                    file.fileSize = (size / (1024 * 1024)).toFixed(2) + ' MB';
                } else if (size >= 1024) { // Size in MB
                    file.fileSize = (size / 1024).toFixed(2) + ' KB';
                } else { // Size in KB
                    file.fileSize = size.toFixed(2) + ' B';
                }

                if (file.fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                    file.fileType = "Excel"
                }
            })

            setFiles(data)
        })
        .catch(error => console.error('Error fetching files:', error));
    }, [])

    const [openNewFileDialog, setOpenNewFileDialog] = useState(false)
    const [downloadText, setDownloadText] = useState('Download')
    const handleDownload = (file) => {
        setDownloadText('Downloading...')
        // get signed url
        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/project/signed-url?fileID=" + file._id + "&organizationID=" + sessionStorage.getItem('organizationId')
        fetch(url, {
            headers: {
                'Authorization': `${sessionStorage.getItem('idToken')}`
            }
        })
        .then(response => response.json())
        .then(data => {
            window.open(data.url, '_blank')
        })
        .catch(error => console.error('Error fetching signed url:', error))
        .finally(() => setDownloadText('Download'))
    }

    return (
        <div className="">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                <h1 className="text-base font-semibold text-gray-900">檔案列表</h1>
                <p className="mt-2 text-sm text-gray-700">
                    此項目中的所有檔案列表。
                </p>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                <button
                    type="button"
                    className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => setOpenNewFileDialog(true)}
                >
                    新增檔案
                </button>
                </div>
            </div>
            <div className="mt-4">
                <SearchBar 
                    query={query}
                    setQuery={setQuery}
                />
            </div>
            <div className=" mt-4 ">
                <table className="min-w-full divide-y divide-gray-300">
                <thead>
                    <tr>
                    <th scope="col" className="w-[40%] py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                        檔案名稱
                    </th>
                    <th
                        scope="col"
                        className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                    >
                        類型
                    </th>
                    <th
                        scope="col"
                        className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                    >
                        大小
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        上傳日期
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        上傳者
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                        <span className="sr-only">下載</span>
                    </th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                    {filteredFiles.map((file) => (
                    <tr key={file.fileName}>
                        <td className="max-w-0 overflow-hidden whitespace-nowrap text-ellipsis py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                            {file.fileName}
                            {/* <dl assName="font-normal lg:hidden">
                                <dt className="sr-only">File Type</dt>
                                <dd className="mt-1 truncate text-gray-700">{file.fileType}</dd>
                                <dt className="sr-only sm:hidden">File Size</dt>
                                <dd className="mt-1 truncate text-gray-500 sm:hidden">{file.fileSize}</dd>
                            </dl>cl */}
                        </td>
                        <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">{file.fileType}</td>
                        <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{file.fileSize}</td>
                        <td className="px-3 py-4 text-sm text-gray-500">{file.createdAt}</td>
                        <td className="px-3 py-4 text-sm text-gray-500">{file.assignedTo}</td>
                        <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                        <button 
                            onClick={() => handleDownload(file)}
                            className="text-indigo-600 hover:text-indigo-900 flex items-center justify-end"
                        >
                            <ArrowDownTrayIcon className="size-5 mr-2" />{downloadText}
                            <span className="sr-only">, {file.fileName}</span>
                        </button>
                        </td>
                    </tr>
                    ))}
                </tbody>
                </table>
            </div>
            <NewFileDialog 
                open={openNewFileDialog}
                setOpen={setOpenNewFileDialog}
            />
        </div>
    )
}


function SearchBar({ query, setQuery }) {
  return (
    <div
        className="max-w-[400px]"
    >
      <div className="relative rounded-md shadow-sm">
        <input
          id="account-number"
          name="account-number"
          type="text"
          placeholder="搜尋檔案"
          className="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <MagnifyingGlassIcon aria-hidden="true" className="size-5 text-gray-400" />
        </div>
      </div>
    </div>
  )
}


