import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import GlobalVars from '../../../../../../Config'
import { XMarkIcon } from '@heroicons/react/24/outline'
import SelfDisappearNotification from '../../../../../../Components/SelfDisappearNotification'
import Select from 'react-select'

import RemarkDrawer from '../../../RemarkDrawer'

export default function ItemEditDialog({ 
    open, 
    setOpen, 
    item,
    memberList,
    onUpdate 
}) {

    const [title, setTitle] = useState(item?.itemTitle || '')
    const [remarks, setRemarks] = useState('')
    const [selectedMembers, setSelectedMembers] = useState(item?.assignedTo || [])
    const [attachments, setAttachments] = useState([])
    const [selectedFiles, setSelectedFiles] = useState([])
    const [showNotification, setShowNotification] = useState(false)
    const [notificationMessage, setNotificationMessage] = useState('保存成功')
    const [startDate, setStartDate] = useState(item?.startDate || '')
    const [deadline, setDeadline] = useState(item?.deadline || '')
    const [forecastedStartDate, setForecastedStartDate] = useState(item?.forecastedStartDate || '')
    const [forecastedDeadline, setForecastedDeadline] = useState(item?.forecastedDeadline || '')
    const [openRemarkDrawer, setOpenRemarkDrawer] = useState(false)
    useEffect(() => {
        if (item && open) {
            
            setTitle(item.itemTitle || '')
            setSelectedMembers(item.assignedTo || [])
            setRemarks(item.remarks || '')
            setAttachments(item.attachments || [])
            setStartDate(item.startDate || '')
            setDeadline(item.deadline || '')
            setForecastedStartDate(item.forecastedStartDate || item.startDate)
            setForecastedDeadline(item.forecastedDeadline || item.deadline)

            // clear selected files
            setSelectedFiles([])
        
        }
    }, [item, open])

    // Add this new function to get signed URLs
    const getSignedUrls = async () => {
        try {
            let projectId = window.location.pathname.split('/')[2]

            const response = await fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/project/signed-url`, {
                method: 'POST',
                headers: {
                    'Authorization': `${sessionStorage.getItem('idToken')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    fileNameList: selectedFiles.map(file => file.name),
                    fileTypeList: selectedFiles.map(file => file.type),
                    itemID: item.id,
                    organizationID: sessionStorage.getItem('organizationId'),
                    projectID: projectId
                })
            });

            if (!response.ok) {
                throw new Error('Failed to get signed URLs');
            }

            const data = await response.json();
            return data.urls;
        } catch (error) {
            console.error('Error getting signed URLs:', error);
            throw error;
        }
    };

    // Add function to upload file using signed URL
    const uploadFileWithSignedUrl = async (file, signedUrl) => {
        try {
            const response = await fetch(signedUrl, {
                method: 'PUT',
                body: file,
                headers: {
                    'Content-Type': file.type
                }
            });

            if (!response.ok) {
                throw new Error('Failed to upload file');
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            throw error;
        }
    };

    // Update the handleSave function
    const handleSave = async () => {
        try {
            // First update the item details
            let body = {
                itemID: item.id,
                itemTitle: title,
                startDate: startDate,
                deadline: deadline,
                forecastedStartDate: forecastedStartDate,
                forecastedDeadline: forecastedDeadline,
                remarks: remarks,
                assignedTo: selectedMembers.map(member => ({
                    displayName: member.displayName,
                    _id: member._id
                })),
                attachments: [...attachments, ...selectedFiles.map(file => file.name)]
            }

            // Update item details
            const response = await fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/board/items`, {
                method: 'PUT',
                headers: {
                    'Authorization': `${sessionStorage.getItem('idToken')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });

            if (!response.ok) {
                throw new Error('Failed to update item details');
            }

            // If there are selected files, handle the file uploads
            if (selectedFiles.length > 0) {
                let projectId = window.location.pathname.split('/')[2]


                // First record the files in the backend
                await fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/project/files`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `${sessionStorage.getItem('idToken')}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        fileNameList: selectedFiles.map(file => file.name),
                        itemID: item.id,
                        assignedTo: localStorage.getItem('userid'),
                        fileTypeList: selectedFiles.map(file => file.type),
                        projectID: projectId,
                    })
                });

                // Get signed URLs for all selected files
                const signedUrls = await getSignedUrls();

                // Upload all files in parallel
                await Promise.all(
                    selectedFiles.map((file, index) => 
                        uploadFileWithSignedUrl(file, signedUrls[index])
                    )
                );
            }

            // Update the UI
            onUpdate({
                ...item,
                itemTitle: title,
                assignedTo: selectedMembers,
                attachments: attachments
            });

            setOpen(false);
            setShowNotification(true)
        } catch (error) {
            console.error('Error in handleSave:', error);
            alert('保存失敗');
        }
    };

    
    const handleFileUpload = (event) => {
        const files = Array.from(event.target.files)
        setSelectedFiles(prev => [...prev, ...files])
    }
    
    // Add this function to handle file removal before upload
    const removeSelectedFile = (fileName) => {
        setSelectedFiles(prev => prev.filter(file => file.name !== fileName))
    }

    const handleDeleteFile = async (fileName) => {
        // Prompt user to confirm deletion
        if (!window.confirm('確定要刪除附件嗎?\n' + fileName)) {
            return;
        }

        try {
            const response = await fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/project/files`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `${sessionStorage.getItem('idToken')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    fileName: fileName,
                    itemID: item.id,
                })
            });

            if (response.ok) {
                // Remove the file from attachments array
                setAttachments(prev => prev.filter(f => f !== fileName));
            } else {
                throw new Error('Error deleting file');
            }
        } catch (error) {
            console.error('Error deleting file:', error);
            alert('刪除檔案失敗');
        }
    };

    // Add this new function after the existing functions
    const handleDownloadFile = async (fileName) => {
        try {
            const projectId = window.location.pathname.split('/')[2];
            
            // Get signed URL for download
            const response = await fetch(
                `${GlobalVars.BACKEND_DOMAIN}/api/v1/project/signed-url?` + 
                new URLSearchParams({
                    itemID: item.id,
                    fileName: fileName,
                    projectID: projectId,
                    organizationID: sessionStorage.getItem('organizationId')
                }), {
                method: 'GET',
                headers: {
                    'Authorization': `${sessionStorage.getItem('idToken')}`,
                }
            });

            if (!response.ok) {
                throw new Error('Failed to get download URL');
            }

            const data = await response.json();
            
            // Open the URL in a new tab
            window.open(data.url, '_blank');

        } catch (error) {
            console.error('Error downloading file:', error);
            alert('下載檔案失敗');
        }
    };

    // Add this new function after handleDownloadFile
    const handleDelete = async () => {
        // Prompt user to confirm deletion with item title
        if (!window.confirm(`確定要刪除「${item.itemTitle}」嗎?`)) {
            return;
        }

        try {
            const response = await fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/board/items`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `${sessionStorage.getItem('idToken')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    itemID: item.id
                })
            });

            if (!response.ok) {
                throw new Error('Failed to delete item');
            }

            setOpen(false);
            setNotificationMessage('刪除成功');
            onUpdate(null);
            setShowNotification(true);
        } catch (error) {
            console.error('Error deleting item:', error);
            alert('刪除失敗');
        }
    };

    // Convert memberList to react-select format
    const memberOptions = memberList.map(member => ({
        value: member._id,
        label: member.displayName,
        ...member // preserve other member data
    }))

    // Convert selectedMembers to react-select format
    const selectedOptions = selectedMembers.map(member => ({
        value: member._id,
        label: member.displayName,
        ...member
    }))

    const customStyles = {
        control: (provided) => ({
            ...provided,
            borderColor: '#D1D5DB',
            '&:hover': {
                borderColor: '#3B82F6'
            }
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#EFF6FF',
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: '#2563EB',
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: '#2563EB',
            '&:hover': {
                backgroundColor: '#DBEAFE',
                color: '#1E40AF',
            },
        }),
    }

    // Add these handler functions after the existing state declarations
    const handleStartDateChange = (e) => {
        const newStartDate = e.target.value;
        // If deadline exists, ensure startDate isn't later than deadline
        if (deadline && newStartDate > deadline) {
            alert('開始日期不能晚於截止日期');
            return;
        }
        setStartDate(newStartDate);
    };

    const handleDeadlineChange = (e) => {
        const newDeadline = e.target.value;
        // If startDate exists, ensure deadline isn't earlier than startDate
        if (startDate && newDeadline < startDate) {
            alert('截止日期不能早於開始日期');
            return;
        }
        setDeadline(newDeadline);
    };

    const handleForecastedStartDateChange = (e) => {
        const newForecastedStartDate = e.target.value;
        if (forecastedDeadline && newForecastedStartDate > forecastedDeadline) {
            alert('預測開始日期不能晚於預測截止日期');
            return;
        }
        setForecastedStartDate(newForecastedStartDate);
    };

    const handleForecastedDeadlineChange = (e) => {
        const newForecastedDeadline = e.target.value;
        if (forecastedStartDate && newForecastedDeadline < forecastedStartDate) {
            alert('預測截止日期不能早於預測開始日期');
            return;
        }
        setForecastedDeadline(newForecastedDeadline);
    };

    return (
        <>
            <SelfDisappearNotification
                body={notificationMessage}
                show={showNotification}
                setShow={setShowNotification}
            />
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 w-[80vw]">
                                    <div className="absolute right-0 top-0 pr-4 pt-4">
                                        <button
                                            type="button"
                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                                            onClick={() => setOpen(false)}
                                        >
                                            <XMarkIcon className="h-6 w-6" />
                                        </button>
                                    </div>
                                    <div className="space-y-4">
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">標題</label>
                                            <input
                                                type="text"
                                                value={title}
                                                onChange={(e) => setTitle(e.target.value)}
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                            />
                                        </div>

                                        <div className="space-y-4">
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700 mb-2">計劃日期</label>
                                                <div className="grid grid-cols-2 gap-4">
                                                    <div>
                                                        <label className="block text-sm font-medium text-gray-700">開始日期</label>
                                                        <input
                                                            type="date"
                                                            value={startDate}
                                                            disabled
                                                            className="mt-1 block w-full rounded-md border-gray-300 bg-gray-100 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm cursor-not-allowed"
                                                        />
                                                    </div>
                                                    <div>
                                                        <label className="block text-sm font-medium text-gray-700">截止日期</label>
                                                        <input
                                                            type="date"
                                                            value={deadline}
                                                            disabled
                                                            className="mt-1 block w-full rounded-md border-gray-300 bg-gray-100 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm cursor-not-allowed"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <label className="block text-sm font-medium text-gray-700 mb-2">預測日期</label>
                                                <div className="grid grid-cols-2 gap-4">
                                                    <div>
                                                        <label className="block text-sm font-medium text-gray-700">預測開始日期</label>
                                                        <input
                                                            type="date"
                                                            value={forecastedStartDate}
                                                            onChange={handleForecastedStartDateChange}
                                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                                            max={forecastedDeadline || undefined}
                                                        />
                                                    </div>
                                                    <div>
                                                        <label className="block text-sm font-medium text-gray-700">預測截止日期</label>
                                                        <input
                                                            type="date"
                                                            value={forecastedDeadline}
                                                            onChange={handleForecastedDeadlineChange}
                                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                                            min={forecastedStartDate || undefined}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">備註</label>
                                            <button
                                                onClick={() => {
                                                    setOpenRemarkDrawer(true)
                                                    setOpen(false)
                                                }}
                                                className="mt-1 block w-full rounded-md border border-gray-300 bg-indigo-600 px-3 py-2 text-center text-sm text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 shadow-sm transition-colors duration-200"
                                            >
                                                點擊添加備註
                                            </button>
                                        </div>

                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">分配給</label>
                                            <Select
                                                isMulti
                                                value={selectedOptions}
                                                onChange={(selected) => {
                                                    // Convert back to original member format
                                                    const selectedMembersFull = (selected || []).map(option => ({
                                                        _id: option.value,
                                                        displayName: option.label,
                                                        ...option
                                                    }))
                                                    setSelectedMembers(selectedMembersFull)
                                                }}
                                                options={memberOptions}
                                                className="mt-1"
                                                placeholder="選擇成員..."
                                                noOptionsMessage={() => "沒有符合的成員"}
                                                classNamePrefix="react-select"
                                                styles={customStyles}
                                            />
                                        </div>

                                        <div>
                                            <label className="block text-sm font-medium text-gray-700">附件</label>
                                            <input
                                                type="file"
                                                onChange={handleFileUpload}
                                                className="hidden"
                                                id="file-upload"
                                                multiple
                                            />
                                            <label
                                                htmlFor="file-upload"
                                                className="cursor-pointer mt-1 inline-block px-4 py-2 text-sm font-semibold text-indigo-700 bg-indigo-50 rounded-md hover:bg-indigo-100"
                                            >
                                                上傳檔案
                                            </label>
                                            <div className="mt-2 space-y-2">
                                                {/* Pending uploads section */}
                                                {selectedFiles.length > 0 && (
                                                    <div className="mb-4">
                                                        <h4 className="text-sm font-medium text-gray-500 mb-2">準備上傳</h4>
                                                        {selectedFiles.map((file, index) => (
                                                            <div key={index} className="flex items-center justify-between bg-gray-50 p-2 rounded">
                                                                <span className="text-sm text-gray-500">{file.name}</span>
                                                                <button
                                                                    onClick={() => removeSelectedFile(file.name)}
                                                                    className="text-red-500 hover:text-red-700"
                                                                >
                                                                    ×
                                                                </button>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                                
                                                {/* Uploaded files section */}
                                                {attachments.length > 0 && (
                                                    <div>
                                                        <h4 className="text-sm font-medium text-gray-500 mb-2">已上傳檔案</h4>
                                                        {attachments.map((fileName, index) => (
                                                            <div key={index} className="flex items-center justify-between bg-white p-2 rounded border border-gray-200">
                                                                <div className="flex items-center space-x-2">
                                                                    <svg className="w-4 h-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
                                                                    </svg>
                                                                    <span className="text-sm text-gray-600">{fileName}</span>
                                                                </div>
                                                                <div className="flex items-center space-x-2">
                                                                    <button
                                                                        onClick={() => handleDownloadFile(fileName)}
                                                                        className="text-gray-400 hover:text-blue-500 transition-colors"
                                                                        title="下載檔案"
                                                                    >
                                                                        <svg className="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                                                        </svg>
                                                                    </button>
                                                                    <button
                                                                        onClick={() => handleDeleteFile(fileName)}
                                                                        className="text-gray-400 hover:text-red-500 transition-colors"
                                                                        title="刪除檔案"
                                                                    >
                                                                        <XMarkIcon className="h-5 w-5" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                                
                                                {/* Empty state */}
                                                {attachments.length === 0 && selectedFiles.length === 0 && (
                                                    <div className="text-sm text-gray-500 italic">
                                                        尚未上傳任何檔案
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="mt-5 sm:mt-6 flex gap-4">
                                            <button
                                                type="button"
                                                className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                                onClick={handleDelete}
                                            >
                                                刪除
                                            </button>
                                            <button
                                                type="button"
                                                className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                onClick={handleSave}
                                            >
                                                儲存
                                            </button>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            <RemarkDrawer
                open={openRemarkDrawer}
                setOpen={setOpenRemarkDrawer}
                itemID={item?.id}
            />
        </>
    )
}