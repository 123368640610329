import { useState, useEffect } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { PlusIcon, XMarkIcon, ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import GlobalVars from '../../../../../../Config';
import { useParams } from 'react-router-dom';
// import { RemoveNodeMarkStep } from '@tiptap/pm/transform';

export default function FileDialog({
    open,
    setOpen,
    activeBoardID,
    fetchItems
}) {
    const { id } = useParams();

    // state
    const [fileNames, setFileNames] = useState([]);
    // const [fileTypes, setFileTypes] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    // const [existingFileTypes, setExistingFileTypes] = useState([]);

    // retrieve the remark and attachments
    useEffect(() => {
        if (!open || !activeBoardID) return

        fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/board/items?itemID=${activeBoardID}&organizationID=${sessionStorage.getItem('organizationId')}`, {
            headers: {
                'Authorization': `${sessionStorage.getItem('idToken')}`,
            }
        })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                setSelectedFiles([])
                setFileNames([])
                // setExistingFileTypes([])
                throw new Error('Error fetching remark and attachments')
            }
        })
        .then(data => {
            setFileNames(data.attachments)
            // setExistingFileTypes(data.attachments.map(() => 'application/octet-stream'))
            setSelectedFiles([])
        })
        .catch(error => console.error('Error fetching remark and attachments:', error))
    }, [activeBoardID, open])


    const getSignedUrl = async (itemID, organizationId) => {
        try {
            // Only get URLs for new files being uploaded
            const response = await fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/project/signed-url`, {
                headers: {
                    'Authorization': `${sessionStorage.getItem('idToken')}`,
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify({
                    fileNameList: selectedFiles.map(file => file.name), // Only new files
                    fileTypeList: selectedFiles.map(file => file.type), // Only new files' types
                    itemID: itemID,
                    organizationID: organizationId,
                    projectID: id
                })
            });

            if (!response.ok) {
                throw new Error('Error getting signed url');
            }

            const data = await response.json();
            return data.urls;
        } catch (error) {
            console.error('Error getting signed URL:', error);
            throw error;
        }
    }

    const handleFileUpload = async (signedUrl, file) => {
        await fetch(signedUrl, {
            method: 'PUT',
            body: file,
            headers: {
                'Content-Type': file.type
            }
        })
        .then(response => {
            if (response.ok) {
                fetchItems()  // refresh the items
                return
            } else {
                throw new Error('Error uploading file')
            }
        })
    }

    const handleFileRecordInput = async (fileNames) => {
        const response = await fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/project/files`, {
            method: 'POST',
            body: JSON.stringify({
                fileNameList: fileNames,
                itemID: activeBoardID,
                
            }),
            headers: {
                'Authorization': `${sessionStorage.getItem('idToken')}`,
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Error uploading file')
            }
        })
    }

    // handle add new project
    const [isLoading, setIsLoading] = useState(false);
    const handleEditRemark = async () => {
        setIsLoading(true);

        const body = {
            attachments: fileNames,
            itemID: activeBoardID
        }
        
        try {
            const response = await fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/board/items`, {
                method: 'PUT',
                headers: {
                    'Authorization': `${sessionStorage.getItem('idToken')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });

            if (!response.ok) {
                alert('編輯失敗');
                throw new Error('編輯失敗');
            }

            if (selectedFiles.length > 0) {
                // First record all files
                await handleFileRecordInput(fileNames);
                
                // Get signed URLs for all files
                const signedUrls = await getSignedUrl(activeBoardID, sessionStorage.getItem('organizationId'));
                
                // Upload all files in parallel
                await Promise.all(
                    selectedFiles.map((file, index) => 
                        handleFileUpload(signedUrls[index], file)
                    )
                );
            }
            
            setOpen(false);
        } catch (error) {
            console.error('Error editing remark:', error);
        } finally {
            setIsLoading(false);
        }
    }

    // useEffect(() => {
    //     setFileTypes(selectedFiles.map(file => file.type));
    // }, [selectedFiles])

    const handleDownloadFile = (file) => {
        console.log(file)
        let url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/project/signed-url?projectID=${id}&itemID=${activeBoardID}&organizationID=${sessionStorage.getItem('organizationId')}&fileName=${file}`
        fetch(url, {
            headers: {
                'Authorization': `${sessionStorage.getItem('idToken')}`,
            }
        })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Error downloading file')
            }
        })
        .then(data => {
            window.open(data.url, '_blank');
        })
        .catch(error => console.error('Error downloading file:', error))
    }

    return (
        <Dialog open={open} onClose={setOpen} className="relative z-50">
        <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
                transition
                className="relative transform w-[80vw] rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 rounded-lg">
                    <div className="sm:flex sm:items-start mb-4">
                        <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:size-10">
                        <PlusIcon aria-hidden="true" className="size-6 text-indigo-600" />
                        </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <DialogTitle as="h3" className="text-base font-semibold text-gray-900">
                            編輯附件
                        </DialogTitle>
                        <div className="mt-2">
                            <p className="text-sm text-gray-500">
                                請上載附件。
                            </p>
                        </div>
                        </div>
                    </div>
                    <div>
                        <FormBody 
                            fileNames={fileNames}
                            setFileNames={setFileNames}
                            selectedFiles={selectedFiles}
                            setSelectedFiles={setSelectedFiles}
                            handleDownloadFile={handleDownloadFile}
                            activeBoardID={activeBoardID}
                        />
                    </div>
                </div>
                
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 rounded-b-lg">
                <button
                    type="button"
                    onClick={handleEditRemark}
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                >
                    {isLoading ? '儲存中...' : '儲存'}
                </button>
                <button
                    type="button"
                    data-autofocus
                    onClick={() => setOpen(false)}
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                    取消
                </button>
                </div>
            </DialogPanel>
            </div>
        </div>
        </Dialog>
    )
}

// include all the inputs and labels
function FormBody({
    fileNames,
    setFileNames,
    selectedFiles,
    setSelectedFiles,
    handleDownloadFile,
    activeBoardID
}) {
    
    const handleFileChange = (e) => {
        const filesArray = Array.from(e.target.files);
        setSelectedFiles(filesArray);
        setFileNames(prevFileNames => [...prevFileNames, ...filesArray.map(file => file.name)]);
    };

    const handleDeleteFile = (fileToDelete) => {
        setSelectedFiles(prevFiles => 
            prevFiles.filter(file => file.name !== fileToDelete.name)
        );

        setFileNames(prevFileNames => 
            prevFileNames.filter(fileName => fileName !== fileToDelete.name)
        );
    };

    const handleExistingFileDelete = (file) => {
        // prompt user to confirm
        if (!window.confirm('確定要刪除附件嗎?\n' + file)) {
            return
        }

        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/project/files"
        fetch(url, {
            method: 'DELETE',
            headers: {
                'Authorization': `${sessionStorage.getItem('idToken')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                fileName: file,
                itemID: activeBoardID,
            })
        })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Error deleting file')
            }
        })
        .then(data => {
            // Remove the file from fileNames array
            setFileNames(prevFiles => prevFiles.filter(f => f !== file))
        })
        .catch(error => console.error('Error deleting file:', error))
    }

    return (
        <div className="space-y-4">
            <div>
                <label htmlFor="attachments" className="block text-sm/6 font-medium text-gray-900">
                    附件
                </label>
                <div className="my-2">
                    <label
                        htmlFor="attachments"
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 cursor-pointer"
                    >
                        <PlusIcon className="h-5 w-5 mr-2" />
                        上傳檔案
                    </label>
                    <input
                        id="attachments"
                        name="attachments"
                        type="file"
                        multiple
                        onChange={handleFileChange}
                        className="hidden w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-indigo-50 file:text-indigo-600 hover:file:bg-indigo-100"
                    />
                </div>

                {/* Show newly selected files */}
                {selectedFiles.length > 0 && (
                    <div className="mt-4">
                        <span className="text-sm text-gray-500">新選擇的檔案</span>
                        <div className="space-y-2 mt-2">
                            {selectedFiles.map((file, index) => (
                                <div key={index} className="flex items-center justify-between py-2 px-3 bg-gray-50 rounded-md">
                                    <span className="text-sm text-gray-500">{file.name}</span>
                                    <button
                                        type="button"
                                        onClick={() => handleDeleteFile(file)}
                                        className="text-red-500 hover:text-red-700"
                                    >
                                        <XMarkIcon className="h-5 w-5" />
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                
                {/* Show existing files */}
                {fileNames && fileNames.filter(name => !selectedFiles.some(file => file.name === name)).length > 0 && (
                    <div className="mt-4">
                        <span className="text-sm text-gray-500">已上傳附件</span>
                        <div className="space-y-2 mt-2">
                            {fileNames
                                .filter(name => !selectedFiles.some(file => file.name === name))
                                .map((fileName, index) => (
                                    <div key={index} className="flex items-center justify-between py-2 px-3 bg-gray-50 rounded-md">
                                        <span className="text-sm text-gray-500">{fileName}</span>
                                        <div className="flex items-center">
                                            <button
                                                type="button"
                                                onClick={() => handleDownloadFile(fileName)}
                                                className="text-indigo-500 hover:text-indigo-700"
                                            >
                                                <ArrowDownTrayIcon className="h-5 w-5" />
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => handleExistingFileDelete(fileName)}
                                                className="text-red-500 hover:text-red-700 ml-2"
                                            >
                                                <XMarkIcon className="h-5 w-5" />
                                            </button>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}


