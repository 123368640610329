import { useState, useEffect } from 'react'
import { 
  ChevronDownIcon, 
  ChevronUpIcon
} from '@heroicons/react/24/outline'

import GlobalVars from '../../../../Config';
import { useParams } from 'react-router-dom';

import BoardSettingDropdown from '../BoardSettingDropdown'
import TableItemView from './SubComponents/TableItemView'
import StackedListItemView from './SubComponents/StackedListItemView'
import PrioritySelect from './SubComponents/PrioritySelect'
import StatusSelect from './SubComponents/StatusSelect'


export default function BoardsComp({ 
    boardName, 
    boardId, 
    boardOrder,
    numBoards,
    newBoardItemsOpen, 
    setNewBoardItemsOpen,
    setActiveBoardID,
    setRemarkDialogOpen,
    fetchBoards,
    searchedItem,
    memberList,
    activeBoardID
}) {
    const [items, setItems] = useState([]);

    const { id } = useParams();

    // fetch items
    const fetchItems = () => {
        fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/board/items?boardID=${boardId}&organizationID=${localStorage.getItem('organizationId')}`, {
            headers: {
                'Authorization': `${sessionStorage.getItem('idToken')}`
            }
        })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Failed to fetch items')
            }
        })
        .then(data => {
            if (!data.items) {
                console.error('No items found in response');
                setItems([]);
                return;
            }
            console.log(data.items)
            setItems(data.items);
        })
        .catch(error => console.error('Error fetching items:', error));
    }

    useEffect(() => {
        fetchItems();
    }, [boardId, newBoardItemsOpen])

    // handle delete item
    const handleDeleteItem = (itemId) => {
        // prompt for confirmation
        if (!window.confirm('確定要刪除這個項目嗎?')) {
            return;
        }

        // send request
        fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/board/items`, {
            headers: {
                'Authorization': `${sessionStorage.getItem('idToken')}`,
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
            body: JSON.stringify({
                itemID: itemId
            })
        })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Failed to delete item')
            }
        })
        .then(data => {
            setItems(items.filter(item => item._id !== itemId));
        })
        .catch(error => console.error('Error deleting item:', error));
    }

    // handle update item
    const handleUpdateItem = (itemId, itemKey, itemValue) => {
        console.log('update item', itemId, itemKey, itemValue)
        let url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/board/items`
        let method = 'PUT'
        let body = {
            itemID: itemId,
            [itemKey]: itemValue,
        }

        fetch(url, {
            headers: {
                'Authorization': `${sessionStorage.getItem('idToken')}`,
                'Content-Type': 'application/json'
            },
            method: method,
            body: JSON.stringify(body)
        })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Failed to update item')
            }
        })
        .then(data => {
            console.log('update item', data)
            // refetch items
            fetchItems();
        })
        .catch(error => console.error('Error updating item:', error));
    }

    // handle remove assignedTo
    const handleRemoveAssignedTo = (itemId, assignedTo) => {

        // prompt for confirmation
        if (!window.confirm(`確定要移除的負責人嗎?\n${assignedTo.displayName}`)) {
            return;
        }

        let url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/board/items`
        let method = 'DELETE'
        let body = {
            itemID: itemId,
            assignedTo: assignedTo['_id']
        }

        fetch(url, {
            headers: {
                'Authorization': `${sessionStorage.getItem('idToken')}`,
                'Content-Type': 'application/json'
            },
            method: method,
            body: JSON.stringify(body)
        })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Failed to remove assignedTo')
            }
        })
        .then(data => {
            console.log('remove assignedTo', data)
            setItems(items.map(item => 
                item._id === itemId 
                  ? { ...item, assignedTo: item.assignedTo.filter(user => user._id !== assignedTo._id) } 
                  : item
            ));
        })
        .catch(error => {
          console.error('Error removing assignedTo:', error)
          alert("移除失敗, 請稍後再試")
        });
    }

    const handleMoveUp = () => {
      // ignore if the board is the first one
      if (boardOrder === 0) {
        return;
      }
      
      // update order 
      let url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/board`
      let method = 'PUT'
      let body = {
        boardID: boardId,
        currOrder: boardOrder,
        newOrder: boardOrder - 1,
        direction: 'up',
        projectID: id
      }

      fetch(url, {
        headers: {
          'Authorization': `${sessionStorage.getItem('idToken')}`,
          'Content-Type': 'application/json'
        },
        method: method,
        body: JSON.stringify(body)
      })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error('Failed to move up')
        }
      })
      .then(data => {
        console.log('move up', data)
        fetchBoards();
      })
      .catch(error => console.error('Error moving up:', error));
    }

    const handleMoveDown = () => {
      // ignore if the board is the last one
      if (boardOrder === numBoards - 1) {
        return;
      }

      let url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/board`
      let method = 'PUT'
      let body = {
        boardID: boardId,
        currOrder: boardOrder,
        newOrder: boardOrder + 1,
        direction: 'down',
        projectID: id
      }

      fetch(url, {
        headers: {
          'Authorization': `${sessionStorage.getItem('idToken')}`,
          'Content-Type': 'application/json'
        },
        method: method,
        body: JSON.stringify(body)
      })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error('Failed to move down')
        }
      })
      .then(data => {
        console.log('move down', data)
        fetchBoards();
      })
      .catch(error => console.error('Error moving down:', error));
    }

    

    return (
      <div className="">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto group relative">
            <div className="flex items-center">
              <div className="hidden group-hover:flex mr-2 transition-all duration-200 ease-in-out opacity-0 group-hover:opacity-100">
                {boardOrder !== 0 && (
                  <button className="p-1 hover:bg-gray-100 rounded transition-colors duration-150" onClick={handleMoveUp}>
                    <ChevronUpIcon className="h-4 w-4 text-gray-500" aria-hidden="true" />
                  </button>
                )}
                {boardOrder !== numBoards - 1 && (
                  <button className="p-1 hover:bg-gray-100 rounded transition-colors duration-150" onClick={handleMoveDown}>
                    <ChevronDownIcon className="h-4 w-4 text-gray-500" aria-hidden="true" />
                  </button>
                )}
              </div>
              <h1 className="text-base font-semibold text-gray-900">{boardName}</h1>
            </div>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none gap-2 flex items-center">
            <button
              type="button"
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => {
                setNewBoardItemsOpen(true)
                setActiveBoardID(boardId)
              }}
            >
              新增
            </button>
            <BoardSettingDropdown boardId={boardId} boardName={boardName} />
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="">
            <div className="inline-block min-w-full py-2 align-middle">
                {items.length > 0 ? (
                    <>
                      <div className="block sm:hidden">
                          <StackedListItemView
                              items={items}
                              handleUpdateItem={handleUpdateItem}
                              handleDeleteItem={handleDeleteItem}
                              handleRemoveAssignedTo={handleRemoveAssignedTo}
                              setItems={setItems}
                              setRemarkDialogOpen={setRemarkDialogOpen}
                              setActiveBoardID={setActiveBoardID}
                              PrioritySelect={PrioritySelect}
                              StatusSelect={StatusSelect}
                              activeItemId={searchedItem}
                              memberList={memberList}
                          />
                      </div>
                      <div className="hidden sm:block">
                          <TableItemView 
                              items={items}
                              handleUpdateItem={handleUpdateItem}
                              handleDeleteItem={handleDeleteItem}
                              handleRemoveAssignedTo={handleRemoveAssignedTo}
                              setItems={setItems}
                              setRemarkDialogOpen={setRemarkDialogOpen}
                              setActiveBoardID={setActiveBoardID}
                              PrioritySelect={PrioritySelect}
                              StatusSelect={StatusSelect}
                              activeItemId={searchedItem}
                              memberList={memberList}
                              activeBoardID={activeBoardID}
                              fetchItems={fetchItems}
                          />
                      </div>
                    </>
                ) : (
                    <div className="text-center text-gray-500">暫無子項目</div>
                )}
            </div>
          </div>
        </div>
      </div>
    )
}





