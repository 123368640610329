import { Fragment, useState, useRef } from 'react'
import { CalendarIcon, EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { ArrowPathIcon } from '@heroicons/react/20/solid'
import {
  FunnelIcon,
  ChevronDownIcon,
} from '@heroicons/react/24/outline'
import { useEffect } from 'react'
import GlobalVars from '../../Config'
import Datepicker from 'react-tailwindcss-datepicker'
import "./JobList.css"
import { useTranslation } from 'react-i18next';

// Components
import TableView from './components/TableView'
import StackedList from './components/StackedList'
import FilterComponent from './components/FilterComponent'
import StatusFilter from './components/StatusFilter'
import LargeListFilterComponent from './components/LargeListFilterComponent'
import FilterContainerDropdown from './components/FilterContainerDropdown'

const statuses = {
  "新工作": 'text-white-600 bg-white-50 ring-white-500/10',
  "待分配": "text-white-600 bg-white-50 ring-white-500/10",
  "待確認": "text-pink-600 bg-pink-50 ring-pink-500/10",
  "未開始": "text-gray-600 bg-gray-50 ring-gray-500/10",
  "進行中": "text-green-600 bg-green-50 ring-green-500/10",
  "中斷": "text-red-600 bg-red-50 ring-red-500/10",
  "待續": "text-yellow-600 bg-yellow-50 ring-yellow-500/10",
  "待檢查": "text-blue-600 bg-blue-50 ring-blue-500/10",
  "待批准": "text-blue-600 bg-blue-50 ring-blue-500/10",
  "完成": "text-orange-600 bg-orange-50 ring-orange-500/10",
  "拒絕": "text-red-600 bg-red-50 ring-red-500/10",
  "取消": "text-red-600 bg-red-50 ring-red-500/10",
  "延期": "text-yellow-600 bg-yellow-50 ring-yellow-500/10",
  "回公司": "text-yellow-600 bg-yellow-50 ring-yellow-500/10",
  "緊急": "text-red-600 bg-red-50 ring-red-500/10",
  "維修中": "text-yellow-600 bg-yellow-50 ring-yellow-500/10",
  "需要額外部件": "",
  "需要專家幫助": "",
  "通知": "text-lime-600 bg-lime-50 ring-lime-500/10",
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function JobList({
  jobs, 
  statusList, 
  filterStatus, 
  setFilterStatus, 
  locList, 
  staffList, 
  selectedLoc, 
  setSelectedLoc, 
  selectedStaff, 
  setSelectedStaff, 
  dateFilter, 
  setDateFilter, 
  estimateDate, 
  setEstimateDate,
  typeList,
  selectedTaskType,
  setSelectedTaskType,
  taskList,
  selectedTask,
  setSelectedTask,
  handleProgressJob,
  isBatchChange,
  setBatchSelectedJobs,
  batchSelectedJobs,
  batchSelectedStatus,
  setBatchSelectedStatus,
  manualRefreshBtnText,
  selectedJobId,
  setSelectedJobId,
  numJobDisplay,
  setNumJobDisplay,
  setIsLoadingNewData,
  displaySecondaryScreen,
}) {
  const { t } = useTranslation();


  // check if jobs is a list and not empty
  if (Array.isArray(jobs) && jobs.length !== 0) {

    for (let i = 0; i < jobs.length; i++) {
      jobs[i].href = "/jobs/" + jobs[i]._id;  // add job href to job list (/jobs/:id)

      // check if it's the first job of the day (if yes, add firstJobOfDay: true)
      if (i === 0) {
        jobs[i].firstJobOfDay = true;
      } else {
        if (jobs[i].date.substring(0, 10) !== jobs[i-1].date.substring(0, 10)) {
          jobs[i].firstJobOfDay = true;
        } else {
          jobs[i].firstJobOfDay = false;
        }
      }
      // create button text based on status
      if (jobs[i].status['status'] == '新工作' || jobs[i].status['status'] == '待確認' || jobs[i].status['status'] == '已轉移') {
        if (jobs[i].assignedTo == sessionStorage.getItem('userid')) {
          // primary action change to 接單  
          jobs[i].progressJobBtnText = '接單';
        } else {
          jobs[i].progressJobBtnText = '收到工作';
        }

      } else if (jobs[i].status['status'] == '未開始') {
        // primary action change to 開始工作
        jobs[i].progressJobBtnText = '開始工作';

      } else if (jobs[i].status['status'] == '進行中') {
        if (jobs[i].meta.hasOwnProperty('requiredInspection') && jobs[i].meta.requiredInspection == true) {
          // primary action change to 待檢查
          jobs[i].progressJobBtnText = '待檢查';
        } else {
          // primary action change to 完成工作
          jobs[i].progressJobBtnText = '完成工作';
        }
      } else if (jobs[i].status['status'] == '待檢查') {
        // primary action change to 完成工作
        jobs[i].progressJobBtnText = '完成工作';
      } else if (jobs[i].status['status'] == '完成') {
        // primary action change to 重新開始
        jobs[i].progressJobBtnText = '重新開始';
      } else {
        jobs[i].progressJobBtnText = '繼續工作';
      }
    }
  }


  // display job status statistics
  const [statusCount, setStatusCount] = useState({});
  useEffect(() => {
    // check if job is a list and not empty
    if (Array.isArray(jobs) && jobs.length !== 0) {
      let dates = []
      
      for (let i = 0; i < jobs.length; i++) {
        if (!dates.includes(jobs[i].date.substring(0, 10))) {
          dates.push(jobs[i].date.substring(0, 10));
        }
      }
      
      // post request to get status count
      let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/jobs/status-statistic';
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': sessionStorage.getItem('idToken')
        },
        body: JSON.stringify({
          "date": dates,
          "organizationID": localStorage.getItem('organizationId'),
          "type": "createdAt",
        })
      })
        .then(response => response.json())
        .then(data => {
          setStatusCount(data['statistic']);
        })
        .catch(error => console.log(error));
    }
  }, [jobs])

  // estimatedInput focus state
  const [estimatedInputBlur, setEstimatedInputBlur] = useState(true);

  // get user display setting 
  const [dateSort, setDateSort] = useState("");
  const [dateSortText, setDateSortText] = useState(t("接單日期"));
  useEffect(() => {
    let url = GlobalVars.BACKEND_DOMAIN + '/api/v1/jobs/user-job-list-display-setting?userID=' + localStorage.getItem("userid");
    fetch(url, {
      headers: {
        "Authorization": sessionStorage.getItem("idToken")
      }
    })
      .then(response => response.json())
      .then(rawData => {
        let data = rawData['displaySetting'];
        
        // check if dateSort is in data
        if ('dateSort' in data) {
          setDateSort(data['dateSort']);

          if (data['dateSort'] === "meta.estimatedDate") {
            setDateSortText(t('預計工作日期'));
          } else if (data['dateSort'] === "latestStatus.changedAt") {
            setDateSortText(t('最新狀態日期'));
          } else {
            setDateSortText(t('接單日期'));
          }
        }
      })
      .catch(error => console.log(error))
  }, [])

  // handle filter display button 
  const filterDisplayRef = useRef(null);
  const filterDisplayBtnIconRef = useRef(null);
  const handleFilterDisplay = (e) => {
    e.preventDefault();

    if (filterDisplayRef.current) {
      // check if it's hidden. If yes, show it
      if (filterDisplayRef.current.classList.contains('hidden')) {
        filterDisplayRef.current.classList.remove('hidden');

        // change icon to up
        filterDisplayBtnIconRef.current.classList.add('transform', 'rotate-180');

      } else {
        filterDisplayRef.current.classList.add('hidden');

        // change icon to down
        filterDisplayBtnIconRef.current.classList.remove('transform', 'rotate-180');

      }
    }
  }

  // handle batch change
  const handleBatchChange = (e) => {
    let jobID = e.target.getAttribute('data-job-id');
    let isSelected = e.target.checked;

    if (isSelected) {
      // check if the selected status is the same as the first selected status. If not, warn user
      if (batchSelectedStatus === "" || batchSelectedJobs.length === 0) {
        setBatchSelectedStatus(e.target.getAttribute('data-status'));
      } else {
        if (batchSelectedStatus !== e.target.getAttribute('data-status')) {
          alert("請選擇相同的工作狀態");
          e.target.checked = false;
          return;
        }
      }

      setBatchSelectedJobs(prevState => [...prevState, jobID]);
    } else {
      setBatchSelectedJobs(prevState => prevState.filter(item => item !== jobID));
    }

  }

  // job priority
  const invPriorityMapping = { 0: '無', 1: '低', 2: '中', 3: '高' };

  // handle show all filter which display hidden filter to main display container
  const [showAllFilter, setShowAllFilter] = useState(false);
  useEffect(() => {
    // set initial showAllFilter state by checking local storage
    if (localStorage.getItem('showAllFilter') === 'true') {
      setShowAllFilter(true);
    }
  }, [])

  // set local storage when showAllFilter state changes
  useEffect(() => {
    localStorage.setItem('showAllFilter', showAllFilter);
  }, [showAllFilter])

  // loading indicator
  const loadingIndicatorRef = useRef(null);
  const [isIndicatorInView, setIsIndicatorInView] = useState(false);
  useEffect(() => {
      const observer = new IntersectionObserver(
          ([entry]) => {
              const isVisible = entry.isIntersecting;
              // console.log('IntersectionObserver entry:', entry); // Debugging log
              setTimeout(() => setIsIndicatorInView(isVisible), 100); // 100ms debounce
          },
          {
              root: null,
              rootMargin: '0px',
              threshold: 0.1,
          }
      );

      const element = loadingIndicatorRef.current;
      if (element) {
          // console.log('Observing element:', element); // Debugging log
          observer.observe(element);
      } 

      return () => {
          if (element) observer.unobserve(element);
      };
  }, [loadingIndicatorRef.current]);

  // add to numJobDisplay when indicator is in view
  useEffect(() => {
    if (isIndicatorInView && (localStorage.getItem("jobListPreference_secondaryScreen") === "true" || localStorage.getItem("fixedChatInterface") === "true")) {
      // console.log("Indicator in view, adding 10 jobs");
      setIsLoadingNewData(true);
      setNumJobDisplay(prevNumJobDisplay => prevNumJobDisplay + 20);
    }
  }, [isIndicatorInView]);

  // job view type
  const [jobViewType, setJobViewType] = useState("stacked");
  useEffect(() => {  // set job view type when component is mounted
    setJobViewType(localStorage.getItem("jobListPreference_viewMode") || "stacked");
  }, [])

  return (
    <div
      className=""
    >
      <div id="header-bar">

        <div className="flex flex-wrap">
            <div
              className="flex items-center justify-between w-full"
            >
              <button 
                type="button"
                onClick = {handleFilterDisplay}
                className='sm:hidden w-full whitespace-nowrap inline-flex items-center justify-between rounded-md bg-white mt-1 px-3 h-[36px] text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 mr-2'
              > 
                <span 
                  className='flex items-center gap-x-1'
                >
                  <FunnelIcon className="h-5 w-5 mr-1" aria-hidden="true" />{t('篩選')}
                </span>
                <span
                  ref={filterDisplayBtnIconRef}

                >
                  <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                </span>
              </button>
              <button 
                  type="button"
                  onClick={() => {
                    setSelectedLoc({
                      "name": t("所有地點")
                    });
                    setSelectedStaff({
                      "name": t("所有員工")
                  });
                    setDateFilter(null);
                    setEstimateDate("");
                    setSelectedTaskType({"name": t("所有類型")});
                    setSelectedTask({"name": t("所有要求")});
                    setFilterStatus({"status": t("所有工作"), "color": "white"})
                  }}
                  className="whitespace-nowrap items-center justify-center rounded-md bg-white mt-1 px-3 h-[36px] text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:hidden inline-flex " 
                >
                  {t('重設')}
                </button>
            </div>
          <div
            className="hidden sm:flex flex-wrap w-full"
            ref={filterDisplayRef}
          >
            <StatusFilter 
              statusList={statusList}  
              filterStatus={filterStatus}
              setFilterStatus={setFilterStatus}
            />
            {/* Location Filter */}
            <LargeListFilterComponent 
              data={locList}
              filter={selectedLoc}
              setFilter={setSelectedLoc}
            />
            {/* Staff Filter */}
            <FilterComponent
              data={staffList}
              filter={selectedStaff}
              setFilter={setSelectedStaff}
            />
            <div
              className="sm:hidden"
            >
              {/* Type Filter */}
              <FilterComponent
                  data={typeList}
                  filter={selectedTaskType}
                  setFilter={setSelectedTaskType}
                />
                {/* service item filter */}
                <FilterComponent
                  data={taskList}
                  filter={selectedTask}
                  setFilter={setSelectedTask}
                />
                {/* Created Date Filter */}
                <div className='relative mt-1 sm:mr-2' id="datefilter-wrapper">
                  <Datepicker
                    showShortcuts={true}
                    value={dateFilter}
                    onChange={(date) => setDateFilter(date)}
                    placeholder={t('選擇接單日期')}
                    inputClassName="dark:ignore border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm"
                    configs={{
                        shortcuts: {
                          today: "今天",
                          yesterday: "昨日", 
                          past: (period) => `過去 ${period} 天`,
                          currentMonth: "本月",
                          pastMonth: "上月",
                        }
                      }} 
                  />
                </div>
                {/* Estimate Date Filter */}
                <div className='relative mt-1 sm:mr-2'>
                  <div className="relative rounded-md shadow-sm">
                    <input 
                      type='text'
                      placeholder={t('預計工作日期')}
                      id="estimateDate"
                      onFocus={(e) => {
                        e.target.type = 'date'
                        setEstimatedInputBlur(false)
                      }}
                      onBlur={(e) => {
                        e.target.type = 'text'
                        setEstimatedInputBlur(true)
                      }}
                      value={estimateDate}
                      onChange={(e) => setEstimateDate(e.target.value)}
                      className="text-[12px] h-[36px] shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 rounded-md min-w-[145px]"
                    />
                    {estimatedInputBlur && (
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </div>
                    )}
                  </div>
                </div>

            </div>
            
            {/* Task Type Filter */}
            <div className=" mr-1 hidden sm:flex sm:flex-wrap sm:items-center">
              {!showAllFilter ? (
                <div
                  className="mt-1"
                >
                  <FilterContainerDropdown 
                    typeList={typeList}
                  selectedTaskType={selectedTaskType}
                  setSelectedTaskType={setSelectedTaskType}
                  taskList={taskList}
                  selectedTask={selectedTask}
                  setSelectedTask={setSelectedTask}
                  dateFilter={dateFilter}
                  setDateFilter={setDateFilter}
                  estimateDate={estimateDate}
                  setEstimateDate={setEstimateDate}
                  estimatedInputBlur={estimatedInputBlur} 
                  setEstimatedInputBlur={setEstimatedInputBlur}
                    setShowAllFilter={setShowAllFilter}
                  />
                </div>
              ) : (
                <div
                  className="flex items-center flex-wrap"
                >
                  <>
                    <FilterComponent
                      data={typeList}
                      filter={selectedTaskType}
                      setFilter={setSelectedTaskType}
                    />
                    {/* service item filter */}
                    <FilterComponent
                      data={taskList}
                      filter={selectedTask}
                      setFilter={setSelectedTask}
                    />
                    {/* Created Date Filter */}
                    <div className='relative mt-1 mr-1' id="datefilter-wrapper">
                      <Datepicker
                        showShortcuts={true}
                        value={dateFilter}
                        onChange={(date) => setDateFilter(date)}
                        placeholder={t('選擇接單日期')}
                        inputClassName="dark:ignore border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm"
                        configs={{
                            shortcuts: {
                              today: "今天",
                              yesterday: "昨日", 
                              past: (period) => `過去 ${period} 天`,
                              currentMonth: "本月",
                              pastMonth: "上月",
                            }
                          }} 
                      />
                    </div>
                    {/* Estimate Date Filter */}
                    <div className='relative mt-1 mr-1'>
                      <div className="relative rounded-md shadow-sm">
                        <input 
                          type='text'
                          placeholder={t('預計工作日期')}
                          id="estimateDate"
                          onFocus={(e) => {
                            e.target.type = 'date'
                            setEstimatedInputBlur(false)
                          }}
                          onBlur={(e) => {
                            e.target.type = 'text'
                            setEstimatedInputBlur(true)
                          }}
                          value={estimateDate}
                          onChange={(e) => setEstimateDate(e.target.value)}
                          className="text-[12px] h-[36px] shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 rounded-md min-w-[145px]"
                        />
                        {estimatedInputBlur && (
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                  <button
                    onClick={() => setShowAllFilter(false)}
                    className="text-sm text-gray-500 hover:text-gray-700 cursor-pointer flex items-center gap-x-1 h-[36px] mx-1 mt-1 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 rounded-md bg-white px-3"
                  > 
                    <EllipsisVerticalIcon className="h-5 w-5" />
                  </button>
                </div>
              )}
            </div>
            {/* a filter reset button  */}
            <button 
              type="button"
              onClick={() => {
                setSelectedLoc({
                  "name": t("所有地點")
                });
                setSelectedStaff({
                  "name": t("所有員工")
              });
                setDateFilter(null);
                setEstimateDate("");
                setSelectedTaskType({"name": t("所有類型")});
                setSelectedTask({"name": t("所有要求")});
                setFilterStatus({"status": t("所有工作"), "color": "white"})
              }}
              className="whitespace-nowrap items-center justify-center rounded-md bg-white mt-1 px-3 h-[36px] text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 hidden sm:inline-flex"
            >
              {t('重設篩選')}
            </button>
          </div>
        </div>
      </div>
      <ul 
        role="list" 
        className="divide-y divide-gray-100 mt-4"
      >
        {manualRefreshBtnText === "刷新中..." && jobs.length === 0 ? (
          <div className="flex items-center justify-center">
            {t('存取數據中')}...
            <ArrowPathIcon className="h-5 w-5 animate-spin" aria-hidden="true" />
          </div>
        ) : (
            (!isBatchChange ? 
                (!jobs || jobs.length === 0) ? (
                  <>{t('沒有符合條件的工作, 請重設篩選.')}</>
                ) : (
                  jobViewType === "stacked" ? (
                    <StackedList
                      jobs={jobs}
                      statusCount={statusCount}
                      selectedJobId={selectedJobId}
                      displaySecondaryScreen={displaySecondaryScreen}
                      setSelectedJobId={setSelectedJobId}
                      dateSortText={dateSortText}
                      invPriorityMapping={invPriorityMapping}
                      statuses={statuses}
                      handleProgressJob={handleProgressJob}
                  />
                  ):(
                    <TableView
                      jobList={jobs}
                      displaySecondaryScreen={displaySecondaryScreen}
                      setSelectedJobId={setSelectedJobId}
                      statusCount={statusCount}
                    />
                  )
                )
               : (
              jobs.map((job) => (
                <>
                  <li key={job._id} className="flex items-center gap-x-6 py-4 px-1 " data-jobid={job._id}>
                    {/* checkbox selector */}
                    <div className="flex items-center gap-x-3">
                      <input 
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        type="checkbox" name="jobSelector" data-job-id={job._id} data-status={job.status['status']} onChange={handleBatchChange}
                      />
                      {/* <label htmlFor={job._id} className="text-sm font-semibold leading-6 text-gray-900">{job.locationName} | {job.typeName}</label> */}
                    </div>

                    {/* job info */}
                    <div className="min-w-0">
                      <div className="flex items-start gap-x-3 ">
                        <a className="text-sm font-semibold leading-6 text-gray-900 skip-trans" >{t(job.locationName)} | {t(job.typeName)}</a>
                      </div>
                      <div className="mt-1 sm:flex sm:items-center sm:gap-x-2 text-xs leading-5 text-gray-500 ">
                        <p className="overflow-auto whitespace-nowrap">{dateSortText}: {job.date}</p>
                        <p className="overflow-auto whitespace-nowrap">{t('施工同事')}: {job.assignedTo}</p>
                      </div>
                    </div>

                    {/* job status */}
                    <div className="flex flex-none items-center gap-x-4">
                      <p
                        className={classNames(
                          statuses[job.status['status']],
                          'rounded-md whitespace-nowrap mt-0.5 px-2.5 py-1.5 text-sm font-semibold ring-1 ring-inset'
                        )}
                      >
                        {t(job.status['status'])}
                      </p>
                    </div>
                  </li>
                </>
                ))
              )
            )
        )}
      </ul>
      {/* Loading indicator when retrieving more data */}
      {jobs.length > 0 && (
        <div 
            className="flex items-center justify-center py-4 text-sm text-gray-500"
            ref={loadingIndicatorRef}
          >
            <ArrowPathIcon className="h-5 w-5 animate-spin mr-2" aria-hidden="true" />
            {t('載入更多工作')}...
          </div>
        )}
      {/* <Export 
        exportDialog={exportDialog} 
        setExportDialog={setExportDialog}  
      /> */}
    </div>
  )
}

