import { useState, useEffect, useCallback } from 'react';
import { PlusIcon, DocumentIcon } from '@heroicons/react/24/outline';
import GlobalVars from '../../../../Config';
import Select from 'react-select';
import debounce from 'lodash/debounce';
import {MultiSelect} from 'react-multi-select-component';

// helper functions
import { GetTodayDateStr } from '../../Helper/GetTodayDateStr';

const serviceType = [
    { "label": "CABD 公共天線系統", "value": "CABD" },
    { "label": "CCTV 閉路電視", "value": "CCTV" },
    { "label": "Data Switch 數據交換機", "value": "Data Switch" },
    { "label": "Door Lock 門鎖", "value": "Door Lock" },
    { "label": "E-Casual 電子便捷", "value": "E-Casual" },
    { "label": "Email 電子郵件", "value": "Email" },
    { "label": "Firewall 防火牆", "value": "Firewall" },
    { "label": "Front Office/Property Management System (PMS) 酒店前台管理系統", "value": "Front Office System/Property Management System (PMS)" },
    { "label": "Internet Gateway 網絡閘道", "value": "Internet Gateway" },
    { "label": "Kiosk 自助服務機", "value": "Kiosk" },
    { "label": "Network 網絡", "value": "Network" },
    { "label": "NSS Server 通知系統", "value": "NSS Server" },
    { "label": "POS 餐飲系統", "value": "POS" },
    { "label": "PC 個人電腦", "value": "PC" },
    { "label": "PABX 電話系統", "value": "PABX" },
    { "label": "Telephone Set 電話機", "value": "Telephone Set" },
    { "label": "TV 電視", "value": "TV" },
    { "label": "UPS 不間斷電源", "value": "UPS" },
    { "label": "Vending Machine 自助售貨機", "value": "Vending Machine" },
    { "label": "Voice Logger 語音記錄器", "value": "Voice Logger" },
    { "label": "Website 網站", "value": "Website" },
    { "label": "WiFi/AP 無線網絡", "value": "WiFi/AP" },
    { "label": "Voice Mail System 語音信箱系統", "value": "i-Message Voice Mail System" },
    { "label": "iPanel/iSignage 電子廣告板", "value": "i-Panel" },
    { "label": "Call Accounting System 計費系統", "value": "i-interface Call Accounting System" },
    { "label": "Other Products & Services 其他產品及服務", "value": "Other Products & Services" }
  ]

export default function IssueForm({
    remarks, 
    setRemarks, 
    requestId, 
    setRequestId, 
    selectedLocation, 
    setSelectedLocation,
    files,
    setFiles,
    locationList,
    userList,
    selectedNotifyUser,
    setSelectedNotifyUser,
    currentFiles,
    setManualNotifyUser,
    issueDetail,
    setIssueDetail,
    statusUpdate,
    setIssueUpdateDesc,
    setNewJobForm,
    setJobDetail,
    editId,
    editItemInfo
  }) {
      
      // verify requestId 
      const [correctRequestId, setCorrectRequestId] = useState(false);
      useEffect(() => {
          if (requestId !== "") {
              // create a fetch request with a given id
              let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/jobs?jobID=" + requestId;
              fetch(url,{
                  headers: {
                      "Authorization": sessionStorage.getItem("idToken")
                  }
              })
              .then(response => {
                  if (response.status === 200) {
                      setCorrectRequestId(true);
                  } else {
  
                  }
              }).catch(error => {
                  console.log(error);
                  setCorrectRequestId(false);
              });
          }
      }, [requestId]);
  
      // handle file upload 
      // const [files, setFiles] = useState([]);
      const [fileRecognized, setFileRecognized] = useState(false);
      const handleFileUpload = (e) => {
        if (e.target.files.length > 0) {
          console.log(e.target.files);
          setFiles(e.target.files);
        }
      }
  
      // handle element drag over
      const [dragOver, setDragOver] = useState(false);
      const handleDragOver = (e) => {
        e.preventDefault();
        setDragOver(true);
          
      }
  
      // handle element drag leave
      const handleDragLeave = (e) => {
        e.preventDefault();
        setDragOver(false);
      }
  
  
      // handle file drop
      const handleFileDrop = (e) => {
        e.preventDefault();
        setDragOver(false);
  
        // get file list
        const files = e.dataTransfer.files;
        if (files.length > 0) {
          console.log(files);
          setFiles(files);
        }
  
      }
  
      // set file received if files more than 0
      useEffect(() => {
        if (files.length > 0) {
          setFileRecognized(true);
        } else {
          setFileRecognized(false);
        }
      }, [files]);
  
      //remove file from file list
      const removeFile = (index) => {
        let newFiles = [...files];
        newFiles.splice(index, 1);
        setFiles(newFiles);
      }
  
      const [staffName, setStaffName] = useState("");
      useEffect(() => {
        // return if userList not valid 
        if (!userList || !issueDetail) {
          return;
        }
  
        // use userList and issueDetail.staffID to find staff name
        if (issueDetail.hasOwnProperty("staffID")) {
          for (let i = 0; i < userList.length; i++) {
            if (userList[i].value === issueDetail.staffID) {
              setStaffName(userList[i].label);
              break;
            }
          }
        }
      }, [issueDetail, userList]);
  
      // state for issue description rows
      const [issueDescRows, setIssueDescRows] = useState(3);
  
      // state for status update rows
      const [statusUpdateRows, setStatusUpdateRows] = useState(3);
  
      return (
          <>
              <div className="space-y-6 pb-5 pt-6">
                  <div>
                      <label
                      htmlFor='status-update'
                      className="block text-sm font-medium leading-6 text-gray-900"
                      >
                      事件狀態更新
                      </label>
                      <p
                        className="text-sm text-gray-500"
                      >
                        供工作人員用於解釋事件的狀態更新，例如事件處理進度、事件處理結果等
                      </p>
                      <div className="mt-2">
                        <textarea
                          type="text"
                          name='status-update'
                          value={statusUpdate}
                          onChange={(e) => setIssueUpdateDesc(e.target.value)}
                          rows={statusUpdateRows}
                          onFocus={() => setStatusUpdateRows(8)}
                          onBlur={() => setStatusUpdateRows(3)}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 duration-300 transition-height"
                        >
                        </textarea>
                      </div>
                      <IssueStatusUpdateSuggestion 
                        setIssueUpdateDesc={setIssueUpdateDesc}
                        remarks={remarks}
                        productType={issueDetail.productType}
                        locationID={selectedLocation ? selectedLocation.value : ""}
                      />
                  </div>
                  <hr></hr>
                  {/* location list */}
                  <div>
                      <label
                      htmlFor='location'
                      className="block text-sm font-medium leading-6 text-gray-900"
                      >
                      地點
                      </label>
                      <div className="mt-2">
                          {editId && !selectedLocation && (
                            <span
                              className="text-sm text-gray-500 bg-gray-100 w-full p-1 rounded-md"
                            ><span className="font-semibold mr-1">{issueDetail['locationID']}</span>(未能配對現有地點, 顧客手動輸入地點)</span>
                          )}
                          <Select
                            options={locationList}
                            value={selectedLocation}
                            onChange={setSelectedLocation}
                            className="mt-2"
                          />
                      </div>
                  </div>
                  {/* 產品類別 */}
                  <div>
                      <label
                      htmlFor='issue-desc'
                      className="block text-sm font-medium leading-6 text-gray-900"
                      >
                      產品類別
                      </label>
                      <div className="mt-2">
                          {/* <input
                              type="text"
                              name='issue-desc'
                              value={issueDetail.productType}
                              onChange={(e) => setIssueDetail({...issueDetail, productType: e.target.value})}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              disabled
                          /> */}
                          {/* use serviceType to create a dropdown menu */}
                          <Select
                            key={issueDetail.productType}
                            options={serviceType}
                            defaultValue={serviceType.find(option => option.value === issueDetail.productType)}
                            onChange={(option) => setIssueDetail({...issueDetail, productType: option.value})}
                          />
                      </div>
                  </div>
                  <div>
                      <label
                      htmlFor='issue-desc'
                      className="block text-sm font-medium leading-6 text-gray-900"
                      >
                      事件描述
                      </label>
                      <div className="mt-2">
                          <textarea
                              type="text"
                              name='issue-desc'
                              value={remarks}
                              rows={issueDescRows}
                              onFocus={() => setIssueDescRows(8)}
                              onBlur={() => setIssueDescRows(3)}
                              onChange={(e) => setRemarks(e.target.value)}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                      </div>
                  </div>
                  
                  {/* a date and time input indicating Required visit time (指定工作時間) */}
                  <div>
                      <label
                      htmlFor='visit-time'
                      className="block text-sm font-medium leading-6 text-gray-900"
                      >
                      指定工作時間
                      </label>
                      <div className="mt-2">
                          <input
                              type="datetime-local"
                              name='visit-time'
                              value={(issueDetail.meta && issueDetail.meta.visitTime) ? issueDetail.meta.visitTime : ""}
                              onChange={(e) => setIssueDetail({...issueDetail, meta: {...issueDetail.meta, visitTime: e.target.value}})}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                      </div>
                  </div>
                  <div
                      className="mt-1"
                  >   
                  <div
                      className="flex justify-between"
                  >
                      <label
                        htmlFor='request-id'
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        相關工作單號
                      </label>
                      {editId &&
                        <button
                          className="text-sm font-semibold  text-indigo-600 hover:text-indigo-500 flex items-center cursor-pointer rounded duration-300"
                          onClick={() => {
  
                            let jobData = {
                              "issueID": editId,
                              "locationName": selectedLocation ? selectedLocation.label : "",
                              "assignedTo": localStorage.getItem("userid"),
                              "meta": {
                                "clientRemark": remarks + " (工單編號: " + editItemInfo.humanReadableId + ")",
                                "estimatedDate": GetTodayDateStr(),
                              }
                            }
  
                            if (selectedLocation) {
                              jobData["locationIDs"] = [selectedLocation.value];
                            }
  
                            setJobDetail(jobData);
  
                            setNewJobForm(true);
                          }}
                        >
                          <PlusIcon className="h-5 w-5 inline-block mr-1" />
                          生成工作
                        </button>
                      }
                  </div>
                      <div className="mt-2">
                          <input
                              type="text"
                              name='request-id'
                              value={requestId}
                              onChange={(e) => setRequestId(e.target.value)}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                          {/* support text */}
                          {requestId !== "" && 
                            <>
                              {correctRequestId ? (
                                <div
                                  className="flex items-center justify-between w-full cursor-pointer"
                                >
                                  <p className="mt-2 text-sm text-green-500">工作單號正確</p>
                                  <a
                                    href={"/jobs/" + requestId}
                                    target="_blank"
                                    className="mt-2 text-sm font-semibold text-indigo-600 hover:text-indigo-500"
                                  >查看工作</a>
                                </div>
                              ):( 
                                <p className="mt-2 text-sm text-red-500">工作單號錯誤</p>
                              )}
                            </>
                          }
                      </div>
                  </div>
                  {/* supporting documents */}
                  <div className="col-span-full">
                    <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                      補充檔案 (如適用)
                    </label>
                    <div 
                      className={`mt-2 flex justify-center rounded-lg border border-dashed ${dragOver ? 'border-indigo-800' :'border-[#cbcbcbde]'} px-6 py-10 duration-300`}
                      onDragOver={handleDragOver}
                      onDrop={handleFileDrop}
                      onDragLeave={handleDragLeave}
                    >
                      <div className="text-center flex flex-col items-center">
                        <DocumentIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                        <div className="mt-4 flex text-sm leading-6 text-gray-600">
                          <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                          >
                            <span>上傳檔案</span>
                            <input 
                              id="file-upload" 
                              name="file-upload" 
                              type="file" 
                              className="sr-only" 
                              multiple 
                              onChange={handleFileUpload}
                            />
                          </label>
                          <p className="pl-1">或拖放</p>
                        </div>
                        <p className="text-xs leading-5 text-gray-600">PNG, JPG, PDF, DOCX, XLSX, CSV up to 10MB</p>
                      </div>
                    </div>
                    {/* list out selected file by file name */}
                    {fileRecognized && 
                      <div className="mt-2">
                        <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                          {Array.from(files).map((file, index) => (
                            <li key={index} className="pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5">
                              <div className="w-0 flex-1 flex items-center">
                                <DocumentIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                                <span className="ml-2 flex-1 w-0 truncate">{file.name}</span>
                              </div>
                              <div className="ml-4 flex-shrink-0">
                                <a 
                                  className="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer"
                                  onClick={() => removeFile(index)}
                                >
                                  移除
                                </a>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    }
                  </div>
                  {/* show current files  */}
                  {currentFiles.length > 0 && 
                    <div className="col-span-full">
                      <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                        已上傳檔案
                      </label>
                      <div className="mt-2">
                        <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                          {currentFiles.map((file, index) => (
                            <li key={index} className="pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5">
                              <div className="w-0 flex-1 flex items-center">
                                <DocumentIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                                <span className="ml-2 flex-1 w-0 truncate">{file.fileName}</span>
                              </div>
                              <div className="ml-4 flex-shrink-0">
                                <a 
                                  className="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer"
                                  onClick={() => window.open(file.url, "_blank")}
                                >
                                  下載
                                </a>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  }
                  <hr></hr>
                  <h2
                    className="text-lg font-semibold leading-6 text-gray-900"
                  >
                    聯絡資料
                  </h2>
                  <h3
                    className="text-sm font-semibold  text-gray-900"
                  >
                    查詢工作的客戶資料
                  </h3>
                  <div>
                      <label
                      htmlFor='customer-name'
                      className="block text-sm font-medium leading-6 text-gray-900"
                      >
                      客戶姓名
                      </label>
                      <div className="mt-2">
                          <input
                              type="text"
                              name='customer-name'
                              value={(issueDetail.meta && issueDetail.meta.name) ? issueDetail.meta.name : ""}
                              onChange={(e) => setIssueDetail({...issueDetail, meta: {...issueDetail.meta, name: e.target.value}})}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                      </div>
                      <label
                      htmlFor='customer-phone'
                      className="mt-3 block text-sm font-medium leading-6 text-gray-900"
                      >
                      客戶電話
                      </label>
                      <div className="mt-2">
                          <input
                              type="text"
                              name='customer-phone'
                              value={issueDetail.customerPhoneForEnquiry ? issueDetail.customerPhoneForEnquiry : ""}
                              onChange={(e) => setIssueDetail({...issueDetail, customerPhoneForEnquiry: e.target.value})}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                      </div>
                  </div>
                  {/* a selector for client preferred contact method (sms or phone) */}
                  <h3
                    className="text-sm font-semibold text-gray-900"
                  >工單完成時的通知</h3>
                  <div
                    className='mt-2'
                  >
                      <label
                      htmlFor='contact-method'
                      className="block text-sm font-medium leading-6 text-gray-900"
                      >
                      聯絡方式
                      </label>
                      <div className="mt-2">
                          <select
                              name='contact-method'
                              value={(issueDetail.contactInfo && issueDetail.contactInfo.medium) ? issueDetail.contactInfo.medium : ""}
                              onChange={(e) => setIssueDetail({...issueDetail, contactInfo: {...issueDetail.contactInfo, medium: e.target.value}})}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          >
                            <option value="sms">短訊</option>
                            <option value="phone">電話</option>
                            <option value="whatsapp">WhatsApp</option>
                          </select>
                      </div>
                      {issueDetail.contactInfo && issueDetail.contactInfo.medium === "phone" &&
                        <p
                          className="text-sm text-gray-500 mt-1"
                        >
                          若選擇電話為聯絡方式，系統將會自動使用客戶提供的聯絡電話進行通知. 同事無需手動通知撥打電話. 
                        </p>
                      }
                  </div>
                  {/* a text input display message body */}
                  <div>
                      <label
                      htmlFor='message-body'
                      className="block text-sm font-medium leading-6 text-gray-900"
                      >
                      訊息內容
                      </label>
                      <div className="mt-2">
                          <textarea
                              type="text"
                              name='message-body'
                              value={(issueDetail.contactInfo && issueDetail.contactInfo.content) ? issueDetail.contactInfo.content : ""}
                              onChange={(e) => setIssueDetail({...issueDetail, contactInfo: {...issueDetail.contactInfo, content: e.target.value}})}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                      </div>
                  </div>
                  {/* a list of number input listing client's phone number */}
                  <div>
                      <label
                        htmlFor='contact-method'
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                      聯絡電話
                      </label>
                      {(issueDetail.contactInfo && issueDetail.contactInfo.contactList) ? (
                        issueDetail.contactInfo.contactList.map((contact, index) => (
                          <div key={index} className="mt-2">
                            <input
                                type="text"
                                name='contact-method'
                                value={contact}
                                onChange={(e) => {
                                  let newContactList = [...issueDetail.contactInfo.contactList];
                                  newContactList[index] = e.target.value;
                                  setIssueDetail({...issueDetail, contactInfo: {...issueDetail.contactInfo, contactList: newContactList}});
                                }}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        ))
                      ) : (
                        null
                      )}
                      {/* button to add more number or delete last number */}
                      <div className="mt-2">
                        <button 
                          className="text-sm font-semibold text-indigo-600 hover:text-indigo-500 mr-2"
                          onClick={() => {
                            console.log(issueDetail.contactInfo.contactList)
                            let newContactList = [...issueDetail.contactInfo.contactList];
                            newContactList.push("");
                            setIssueDetail({...issueDetail, contactInfo: {...issueDetail.contactInfo, contactList: newContactList}});
                          }}
                        >
                          新增聯絡電話
                        </button>
                        <button 
                          className="text-sm font-semibold text-red-600 hover:text-red-500"
                          onClick={() => {
                            let newContactList = [...issueDetail.contactInfo.contactList];
                            newContactList.pop();
                            setIssueDetail({...issueDetail, contactInfo: {...issueDetail.contactInfo, contactList: newContactList}});
                          }}
                        >
                          刪除最後一個
                        </button>
                      </div>
                  </div>
                        
                  <hr></hr>
                  <h2
                    className="text-lg font-semibold leading-6 text-gray-900"
                  >
                    內部聯絡
                  </h2>
                  {/* responsible person */}
                  <div>
                      <label
                      htmlFor='responsible-person'
                      className="block text-sm font-medium leading-6 text-gray-900"
                      >
                      負責同事
                      </label>
                      <div className="mt-2">
                          <input
                              type="text"
                              name='responsible-person'
                              value={staffName}
                              disabled
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                      </div>
                  </div>
                  {/* notify person */}
                  <div>
                      <label
                      htmlFor='notify-person'
                      className="block text-sm font-medium leading-6 text-gray-900"
                      >
                      通知同事
                      </label>
                      <div className="mt-2">
                          <MultiSelect
                            options={userList}
                            value={selectedNotifyUser}
                            onChange={setSelectedNotifyUser}
                            labelledBy="Select"
                            className="w-full"
                            hasSelectAll={false}
                          />
                      </div>
                  </div>
                  {/* manual input of phone nubmers */}
                  {/* <div>
                      <label
                      htmlFor='manual-notify-person'
                      className="block text-sm font-medium leading-6 text-gray-900"
                      >
                      手動輸入通知人
                      </label>
                      <div className="mt-2">
                          <textarea
                              type="text"
                              name='manual-notify-person'
                              value={manualInputList}
                              onChange={(e) => setManualInputList(e.target.value)}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                      </div>
                  </div> */}
              </div>
          </>
      )
}


// a list of chips to display suggested replies for ths issue status update
function IssueStatusUpdateSuggestion({setIssueUpdateDesc, remarks, productType, locationID}) {
    const [suggestions, setSuggestions] = useState([]);
    // const [loading, setLoading] = useState(false);
    
    // Create a memoized version of the fetch function
    const fetchSuggestions = useCallback(
      debounce(async (remarks, productType, locationID) => {
        if (!remarks || !productType || !locationID) {
          return;
        }
  
        // setLoading(true);
  
        try {
          const url = GlobalVars.ASSISTANT_DOMAIN + "/api/v1/job/issue-status-update-assistant";
          const body = {
            clientDescription: remarks,
            productType,
            locationID,
            userID: localStorage.getItem("userid")
          };
  
          const response = await fetch(url, {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
              "Content-Type": "application/json"
            }
          });
  
          if (response.status === 200) {
            const data = await response.json();
            setSuggestions(data);
          } else {
            console.log("Error: " + response.status);
          }
        } catch (error) {
          console.log(error);
        } finally {
        //   setLoading(false);
        }
      }, 500),  // 500ms debounce
      []  // This function never needs to be re-created
    );
  
    // Use a single effect with a combined dependency
    useEffect(() => {
      fetchSuggestions(remarks, productType, locationID);
    }, [fetchSuggestions, remarks, productType, locationID]);
  
  
  
    return (
      <>
        
          <div className="mt-2">
            <h3 className="text-sm font-semibold leading-6 text-gray-900">建議回覆:</h3>
            {suggestions.length > 0 ? (
            <div className="mt-1 space-y-2">
              {suggestions.map((suggestion) => (
                <button
                  type="button"
                  className="inline-flex items-center px-2.5 py-1.5 rounded-full text-xs font-semibold text-gray-900 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 duration-300 text-left"
                  onClick={() => setIssueUpdateDesc(suggestion)}
                >
                  {suggestion}
                </button>
              ))}
            </div>
            ) : (
              <div className="mt-1 space-y-2">
                <p className="text-sm text-gray-500 animate-pulse">
                  正在生成建議回覆...
                </p>
              </div>
            )}
          </div>
        
      </>
    )
  }