import { Fragment } from 'react'
import { Menu, Transition, MenuItems, MenuItem, MenuButton } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { useTranslation } from 'react-i18next'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

// create job options button 
export default function CreateJobOptionBtn({setNewFormOpen, setMultiFormOpen}) {
    const { t } = useTranslation();

    return (
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <MenuButton className="ml-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            {t('新增工作')}
            <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
          </MenuButton>
        </div>
  
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <MenuItems className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <MenuItem>
                {({ active }) => (
                  <a
                    onClick={() => setNewFormOpen(true)}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm cursor-pointer relative'
                    )}
                  >
                    <span
                        className="block pr-14 text-nowrap"
                    >{t('單一工作')}</span>
                    <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                        <kbd className="inline-flex items-center rounded border border-gray-200 px-1 font-sans text-xs text-gray-400 w-5 justify-center">
                            c
                        </kbd>
                        <kbd className="ml-1 inline-flex items-center rounded border border-gray-200 px-1 font-sans text-xs text-gray-400 w-5 justify-center">
                            j
                        </kbd>
                    </div>
                  </a>
                )}
              </MenuItem>
              <MenuItem>
                {({ active }) => (
                  <a
                    onClick={() => setMultiFormOpen(true)}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm cursor-pointer relative'
                    )}
                  >
                    <span
                        className="block pr-14 text-nowrap"
                    >{t('多個工作')}</span>
                    <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                        <kbd className="w-5 inline-flex items-center rounded border border-gray-200 px-1 font-sans text-xs text-gray-400 justify-center">
                            c
                        </kbd>
                        <kbd className="ml-1 inline-flex items-center rounded border border-gray-200 px-1 font-sans text-xs text-gray-400 w-5 justify-center">
                            m
                        </kbd>
                    </div>
                  </a>
                )}
              </MenuItem>
              {/* <Menu.Item>
                {({ active }) => (
                  <a
                    href="#"
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm'
                    )}
                  >
                    License
                  </a>
                )}
              </Menu.Item> */}
            </div>
          </MenuItems>
        </Transition>
      </Menu>
    )
}
