import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import GlobalVars from '../../../../Config'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'


export default function BoardSettingDropdown({boardId, boardName}) {
    const [renameBoardDialogOpen, setRenameBoardDialogOpen] = useState(false)

    const handleDeleteBoard = () => {
        // prompt user to confirm
        const confirm = window.confirm(`確定要刪除 ${boardName} 這個項目版嗎？`);
        if (!confirm) {
            return;
        }

        let url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/board`;
        fetch(url, {
            method: 'DELETE',
            headers: {
                'Authorization': `${sessionStorage.getItem('idToken')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                boardID: boardId
            })
        })
        .then(response => {
            if (response.ok) {
                window.location.reload();
            } else {
                throw new Error('Failed to delete board');
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }

    const handleOpenRenameBoardDialog = () => {
        setRenameBoardDialogOpen(true)
    }

    return (
        <>
            <Menu as="div" className="relative inline-block text-left">
            <div>
                <MenuButton className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                <span className="sr-only">Open options</span>
                <EllipsisVerticalIcon aria-hidden="true" className="size-5" />
                </MenuButton>
            </div>

            <MenuItems
                transition
                className="absolute sm:right-0 z-50 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
                <div className="py-1">
                <MenuItem>
                    <button
                    onClick={handleDeleteBoard}
                    className="cursor-pointer text-red-400 w-full text-left block px-4 py-2 text-sm data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                    >
                    刪除
                    </button>
                </MenuItem>
                <MenuItem>
                    <button
                    onClick={handleOpenRenameBoardDialog}
                    className="cursor-pointer transition duration-300 w-full text-left block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                    >
                    修改名稱
                    </button>
                </MenuItem>
                </div>
                </MenuItems>
            </Menu>
            <RenameBoardDialog 
                open={renameBoardDialogOpen} 
                setOpen={setRenameBoardDialogOpen} 
                boardId={boardId} 
                boardName={boardName} 
            />
        </>
    )
}


function RenameBoardDialog({ open, setOpen, boardId, boardName }) {
    const [newBoardName, setNewBoardName] = useState(boardName)

    const handleSubmit = (e) => {
        e.preventDefault()

        fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/board`, {
            method: 'PUT',
            headers: {
                'Authorization': `${sessionStorage.getItem('idToken')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                boardID: boardId,
                boardTitle: newBoardName
            })
        })
        .then(response => {
            if (response.ok) {
                setOpen(false)
                window.location.reload()
            } else {
                throw new Error('Failed to rename board')
            }
        })
        .catch(error => {
            console.error('Error:', error)
            alert('修改失敗，請稍後再試')
        })
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-50 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <form onSubmit={handleSubmit}>
                                    <div>
                                        <div className="mt-3 text-center sm:mt-5">
                                            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                                修改名稱
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <input
                                                    type="text"
                                                    value={newBoardName}
                                                    onChange={(e) => setNewBoardName(e.target.value)}
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    placeholder="輸入新名稱"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                        <button
                                            type="submit"
                                            className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                                        >
                                            確認
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                            onClick={() => setOpen(false)}
                                        >
                                            取消
                                        </button>
                                    </div>
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

