import { PencilIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline'
import MembersSelect from '../../../MemberSelect'
import { useRef, useEffect } from 'react'

export default function StackedListItemView({
    items,
    handleUpdateItem,
    handleDeleteItem,
    handleRemoveAssignedTo,
    setItems,
    setRemarkDialogOpen,
    setActiveBoardID,
    PrioritySelect,
    StatusSelect,
    memberList,
    activeItemId
}) {

    const itemRefs = useRef(new Map());

    // Scroll to item when activeItemId changes
    useEffect(() => {
        if (activeItemId && itemRefs.current.has(activeItemId)) {
            const element = itemRefs.current.get(activeItemId);
            element?.scrollIntoView({ 
                behavior: 'smooth',
                block: 'center' // Centers the item in the viewport
            });
        }
    }, [activeItemId]);

    return (
        <ul role="list" className="divide-y divide-gray-100">
            {items.map((item, itemIdx) => (
                <li key={item._id} className={`flex flex-col gap-y-4 py-5 px-4 sm:px-6 ${activeItemId === item._id ? 'bg-[#C7D2FE]' : ''}`} ref={el => {
                    // Store ref in the Map
                    if (el) {
                        itemRefs.current.set(item._id, el);
                    } else {
                        itemRefs.current.delete(item._id);
                    }
                }}>
                    <div className="flex items-center justify-between gap-x-4">
                        <input 
                            type="text"
                            className="pl-0 min-w-0 text-sm font-semibold leading-6 text-gray-900 flex-1 border-1 border-white hover:border-gray-300 rounded-md duration-300 transition-all px-2 py-1"
                            value={item.itemTitle}
                            onChange={(e) => {
                                setItems(prevItems => {
                                    const newItems = [...prevItems];
                                    newItems[itemIdx].itemTitle = e.target.value;
                                    return newItems;
                                });
                                handleUpdateItem(item._id, 'itemTitle', e.target.value)
                            }}
                        />
                        <button 
                            className="text-indigo-600 hover:text-indigo-900 cursor-pointer hover:bg-gray-100 rounded-md p-1 duration-300"
                            onClick={() => handleDeleteItem(item._id)}
                        >
                            <TrashIcon className="w-5 h-5" />
                        </button>
                    </div>

                    <div className="flex gap-4">
                        <div className="flex items-center gap-x-2">
                            <span className="text-sm text-gray-500">優先:</span>
                            <div className={`px-3 py-1 rounded-full text-white text-sm ${
                                item.priority === 'high' ? 'bg-red-500' :
                                item.priority === 'medium' ? 'bg-yellow-500' :
                                item.priority === 'low' ? 'bg-green-500' : 'bg-gray-500'
                            }`}>
                                <PrioritySelect itemIdx={itemIdx} itemID={item._id} priority={item.priority} setItems={setItems} />
                            </div>
                        </div>

                        <div className="flex items-center gap-x-2">
                            <span className="text-sm text-gray-500">狀態:</span>
                            <div className={`px-3 py-1 rounded-full text-white text-sm ${
                                item.status === 'pending' ? 'bg-red-500' :
                                item.status === 'inProgress' ? 'bg-yellow-500' :
                                item.status === 'completed' ? 'bg-green-500' : 'bg-gray-500'
                            }`}>
                                <StatusSelect itemIdx={itemIdx} itemID={item._id} status={item.status} setItems={setItems} />
                            </div>
                        </div>
                    </div>

                    <div className="flex items-start gap-x-3">
                        <span className="text-sm text-gray-500 whitespace-nowrap mt-1">負責人:</span>
                        <div className="flex items-center flex-1">
                            <div className="flex flex-wrap gap-2">
                            {item?.assignedTo?.map((user, index) => (
                                <button
                                    key={index}
                                    className="group relative inline-flex items-center justify-center px-3 h-7 rounded-full bg-gray-100 hover:bg-gray-200 text-gray-600 text-sm font-medium transition-colors duration-200"
                                    title={user.displayName}
                                    onClick={() => handleRemoveAssignedTo(item._id, user)}
                                >
                                    <span className="group-hover:opacity-0 transition-opacity duration-200 whitespace-nowrap max-w-[50px] overflow-x-hidden text-ellipsis">
                                        {user?.displayName}
                                    </span>
                                    <XMarkIcon className="absolute w-4 h-4 text-gray-600 opacity-0 group-hover:opacity-100 transition-opacity duration-200" />
                                </button>
                            ))}
                            </div>
                            <div
                                className="ml-1"
                            >
                                <MembersSelect 
                                    outputs={item}
                                    setOutputs={(newAssignedTo) => {
                                        if (item?.assignedTo && item.assignedTo.some(user => user._id === newAssignedTo._id)) {
                                            alert('已指派此人員')
                                            return
                                        }
                                        
                                        const newAssignedArray = Array.isArray(newAssignedTo) 
                                            ? newAssignedTo 
                                            : newAssignedTo['assignedTo'] || [newAssignedTo];

                                        setItems(prevItems => 
                                            prevItems.map(prevItem => 
                                                prevItem._id === item._id 
                                                    ? { 
                                                        ...prevItem, 
                                                        assignedTo: [...(prevItem.assignedTo || []), ...newAssignedArray] 
                                                    }
                                                    : prevItem
                                            )
                                        );

                                        handleUpdateItem(item._id, 'assignedTo', newAssignedArray)
                                    }}
                                    memberList={memberList}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center justify-between text-sm text-gray-500">
                        <div className="flex items-center gap-x-2">
                            <span>由</span>
                            <div 
                                className="relative group cursor-pointer hover:bg-gray-100 rounded px-2 py-1"
                                onClick={(e) => {
                                    const input = e.currentTarget.querySelector('input');
                                    input.showPicker();
                                }}
                            >
                                <div>{item.startDate || '未設定'}</div>
                                <input
                                    type="date"
                                    value={item.startDate || ''}
                                    max={item.deadline || ''}
                                    onChange={(e) => {
                                        if (!item.deadline || e.target.value <= item.deadline) {
                                            handleUpdateItem(item._id, 'startDate', e.target.value)
                                        }
                                    }}
                                    className="absolute opacity-0 cursor-pointer w-0 h-0"
                                />
                            </div>
                            <span>至</span>
                            <div 
                                className="relative group cursor-pointer hover:bg-gray-100 rounded px-2 py-1"
                                onClick={(e) => {
                                    const input = e.currentTarget.querySelector('input');
                                    input.showPicker();
                                }}
                            >
                                <div>{item.deadline || '未設定'}</div>
                                <input
                                    type="date"
                                    value={item.deadline || ''}
                                    min={item.startDate || ''}
                                    onChange={(e) => {
                                        if (!item.startDate || e.target.value >= item.startDate) {
                                            handleUpdateItem(item._id, 'deadline', e.target.value)
                                        }
                                    }}
                                    className="absolute opacity-0 cursor-pointer w-0 h-0"
                                />
                            </div>
                        </div>
                        <button onClick={() => {
                            setRemarkDialogOpen(true)
                            setActiveBoardID(item._id)
                        }}>
                            <PencilIcon className="w-4 h-4" />
                        </button>
                    </div>
                </li>
            ))}
        </ul>
    )
}
