import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

export default function TableDialog({ isOpen, setIsOpen, tableNode }) {
    // Helper function to extract text from nodes
    const extractText = (node) => {
        if (!node) return '';
        if (node.type === 'text') return node.value;
        if (node.children) return node.children.map(child => extractText(child)).join('');
        return '';
    };

    const renderTableContent = () => {
        if (!tableNode) return null;

        const thead = tableNode.children.find(child => child.tagName === 'thead');
        const tbody = tableNode.children.find(child => child.tagName === 'tbody');

        return (
            <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-600">
                {thead && (
                    <thead>
                        {thead.children
                            .filter(child => child.tagName === 'tr')
                            .map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {row.children
                                        .filter(cell => cell.tagName === 'th')
                                        .map((cell, cellIndex) => (
                                            <th
                                                key={cellIndex}
                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-100"
                                            >
                                                {extractText(cell)}
                                            </th>
                                        ))}
                                </tr>
                            ))}
                    </thead>
                )}
                {tbody && (
                    <tbody className="divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-gray-800">
                        {tbody.children
                            .filter(child => child.tagName === 'tr')
                            .map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {row.children
                                        .filter(cell => cell.tagName === 'td')
                                        .map((cell, cellIndex) => (
                                            <td
                                                key={cellIndex}
                                                className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-300"
                                            >
                                                {extractText(cell)}
                                            </td>
                                        ))}
                                </tr>
                            ))}
                    </tbody>
                )}
            </table>
        );
    };

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={setIsOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 dark:bg-gray-900 bg-opacity-75 dark:bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                                <div className="absolute right-0 top-0 pr-4 pt-4">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white dark:bg-gray-800 text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
                                        onClick={() => setIsOpen(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className="mt-3 sm:mt-0 sm:p-4">
                                    <div className="overflow-x-auto">
                                        {renderTableContent()}
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}