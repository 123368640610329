import { useState, useEffect } from 'react';
import GlobalVars from '../../../../Config';

export default function JobSheetSetting({
    isSaving,
    setIsSaving
}) {
    const [jobTypes, setJobTypes] = useState([]);
    
    const fetchJobTypes = () => {
        // query job types from backend
        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/submodules/job-sheet-setting?organizationID=" + localStorage.getItem("organizationId") + "&lang=tc";
        fetch(url,{
            headers: {
                'Authorization': sessionStorage.getItem("idToken")
            }
        })
            .then(response => response.json())
            .then(data => {
                setJobTypes(data)
            })
            .catch(error => console.error('Error fetching job types:', error));
    }

    // query job types from backend
    useEffect(() => {
        fetchJobTypes();
    }, []);

    // handle job type selection
    const [statusText, setStatusText] = useState('準備儲存');
    const handleJobTypeSelection = (jobType, selected) => {
        setStatusText('儲存中...');

        let method = selected ? 'DELETE' : 'POST';

        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/submodules/job-sheet-setting";
        fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': sessionStorage.getItem("idToken")
            },
            body: JSON.stringify({
                typeID: jobType._id,
                organizationID: localStorage.getItem("organizationId"),
            })
        })
        .then(response => response.json())
        .then(data => {
            setStatusText("成功儲存");
            // update job types list by modifying the selected job type and set selected to true
            setJobTypes(prevJobTypes => prevJobTypes.map(prevJobType => prevJobType._id === jobType._id ? {...prevJobType, selected: !selected} : prevJobType));
        })
        .catch(error => {
            setStatusText("儲存失敗");
            console.error('Error fetching job sheet setting:', error);
        });
    }

    const [query, setQuery] = useState('');
    const filteredJobTypes = jobTypes.filter(jobType => 
        jobType?.langVar?.tc?.toLowerCase().includes(query.toLowerCase()) ||
        jobType?.name?.toLowerCase().includes(query.toLowerCase())
    );

    useEffect(() => {
        if (isSaving) {
            setIsSaving(false);
        }
    }, [isSaving]);

    return (
        <div>
            <div className="flex items-center justify-between mb-4">
                <input
                    type="text"
                    value={query}
                    onChange={e => setQuery(e.target.value)}
                    placeholder="搜尋工作類型"
                    className="w-full p-2 border border-gray-300 rounded-md text-sm"
                />
                <span
                    className="text-sm font-medium text-gray-900 ml-4 whitespace-nowrap"
                >{statusText}</span>
            </div>
            {filteredJobTypes.map(jobType => (
                <div key={jobType._id} className="flex justify-between items-center p-4 border-b">
                    <span className="text-sm font-medium text-gray-900 max-w-[75%] overflow-hidden whitespace-nowrap text-ellipsis">{jobType?.langVar?.tc} {jobType.name}</span>
                    <button
                        onClick={() => handleJobTypeSelection(jobType, jobType.selected)}
                        className={`px-4 py-2 text-sm font-medium rounded-md ${
                            jobType.selected 
                            ? 'bg-gray-100 text-gray-500 hover:bg-gray-200'
                            : 'bg-indigo-600 text-white hover:bg-indigo-500'
                        }`}
                    >
                        {jobType.selected ? '取消選擇' : '選擇'}
                    </button>
                </div>
            ))}
        </div>
    )
}