import MembersSelect from '../../../MemberSelect'
import { 
    PencilIcon, 
    TrashIcon, 
    XMarkIcon,
    PaperClipIcon
} from '@heroicons/react/24/outline'
import { useRef, useEffect, useState } from 'react'
import FileDialog from '../FileDialog'
import RemarkDrawer from '../../../RemarkDrawer'
export default function TableItemView({
    items,
    handleUpdateItem,
    handleDeleteItem,
    handleRemoveAssignedTo,
    setItems,
    setActiveBoardID,
    PrioritySelect,
    StatusSelect,
    activeItemId,
    memberList,
    activeBoardID,
    fetchItems
}) {
    const [fileDialogOpen, setFileDialogOpen] = useState(false);
    const [remarkDrawerOpen, setRemarkDrawerOpen] = useState(false);
    // Create a Map to store refs for each item
    const itemRefs = useRef(new Map());

    // Scroll to item when activeItemId changes
    useEffect(() => {
        if (activeItemId && itemRefs.current.has(activeItemId)) {
            const element = itemRefs.current.get(activeItemId);
            element?.scrollIntoView({ 
                behavior: 'smooth',
                block: 'center' // Centers the item in the viewport
            });
        }
    }, [activeItemId]);

    return (
        <>
        <table className="min-w-full border-separate border-spacing-0">
            <thead>
                <tr>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-white/75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                    >
                      工作標題
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white/75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell text-center w-[5%]"
                    >
                      優先
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white/75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell text-center w-[10%]"
                    >
                      狀態
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white/75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell text-center"
                    >
                      負責人
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-white/75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter text-center w-[10%]"
                    >
                      開始日期
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-white/75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter text-center w-[10%]"
                    >
                      目標完成日期
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-white/75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter text-center w-[5%]"
                    >
                      備註
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-white/75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
            <tbody>
                {items.map((item, itemIdx) => (
                <tr key={item._id} ref={el => {
                    // Store ref in the Map
                    if (el) {
                        itemRefs.current.set(item._id, el);
                    } else {
                        itemRefs.current.delete(item._id);
                    }
                }}  className={`${activeItemId === item._id ? 'bg-[#C7D2FE]' : ''}`}>
                    <td
                    className={classNames(
                        itemIdx !== items.length - 1 ? 'border-b border-gray-200' : '',
                        'cursor-text whitespace-nowrap py-1 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8',
                    )}
                    >
                    <textarea 
                        className={`sm:text-[14px] leading-loose w-full rounded-md duration-300 transition-all px-0 outline-none border-none ${activeItemId === item._id ? 'bg-[#C7D2FE]' : ''} focus:bg-white resize-none overflow-hidden`}
                        value={item.itemTitle}
                        rows={1}
                        onFocus={(e) => {
                            e.target.rows = 3;
                        }}
                        onBlur={(e) => {
                            e.target.rows = 1;
                        }}
                        onChange={(e) => {
                            setItems(prevItems => {
                                const newItems = [...prevItems];
                                newItems[itemIdx].itemTitle = e.target.value;
                                return newItems;
                            });
                            handleUpdateItem(item._id, 'itemTitle', e.target.value)
                        }}
                    />
                    </td>
                    <td
                    className={classNames(
                        itemIdx !== items.length - 1 ? 'border-b border-gray-200' : '',
                        'hidden whitespace-nowrap px-3 py-1 sm:text-[14px] text-white sm:table-cell text-center ' + 
                        (item.priority === 'high' ? 'bg-red-500' :
                        item.priority === 'medium' ? 'bg-yellow-500' :
                        item.priority === 'low' ? 'bg-green-500' : 'bg-gray-500'),
                    )}
                    >
                    <PrioritySelect itemIdx={itemIdx} itemID={item._id} priority={item.priority} setItems={setItems} />
                    </td>
                    <td
                    className={classNames(
                        itemIdx !== items.length - 1 ? 'border-b border-gray-200' : '',
                        'hidden whitespace-nowrap px-3 py-1 sm:text-[14px] text-white sm:table-cell text-center ' + 
                        (item.status === 'pending' ? 'bg-red-500' :
                        item.status === 'inProgress' ? 'bg-yellow-500' :
                        item.status === 'completed' ? 'bg-green-500' : 'bg-gray-500'),
                    )}
                    >
                    <StatusSelect itemIdx={itemIdx} itemID={item._id} status={item.status} setItems={setItems} />
                    </td>
                    <td
                    className={classNames(
                        itemIdx !== items.length - 1 ? 'border-b border-gray-200' : '',
                        'hidden whitespace-nowrap px-3 py-1 text-sm text-gray-500 lg:table-cell text-center w-[200px]',
                    )}
                    >
                    <div
                        className="flex items-center space-x-1 flex-wrap justify-center"
                    >
                    {item?.assignedTo?.map((user, index) => (
                        
                        <button
                            key={index}
                            className="sm:text-[14px] group relative inline-flex items-center justify-center w-6 h-6 rounded-full bg-gray-200 hover:bg-gray-300 text-gray-600 text-sm font-medium -ml-1 first:ml-0"
                            title={user.displayName}
                            onClick={() => handleRemoveAssignedTo(item._id, user)}
                        >
                            {/* Initial content - display the first letter of the user's name */}
                            <span className="group-hover:opacity-0 transition-opacity duration-200">
                                {user?.displayName?.charAt(0).toUpperCase()}
                            </span>
                        
                            {/* XMark icon - appears on hover */}  
                            <XMarkIcon
                            className="absolute w-4 h-4 text-gray-600 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                            />
                            {/* Tooltip */}
                            <div className="absolute z-20 invisible group-hover:visible bg-gray-900 text-white text-xs rounded-md px-2 py-1 -top-8 left-1/2 -translate-x-1/2 whitespace-nowrap">
                            {user.displayName}
                            </div>
                        </button>
                        
                    ))}
                    <MembersSelect 
                        outputs={item}
                        setOutputs={(newAssignedTo) => {
                        // 1. check against existing assignedTo

                        if (item?.assignedTo && item.assignedTo.some(user => user._id === newAssignedTo._id)) {
                            alert('已指派此人員')
                            return
                        }
                        
                        // 2. Handle different input format 
                        const newAssignedArray = Array.isArray(newAssignedTo) 
                            ? newAssignedTo 
                            : newAssignedTo['assignedTo'] || [newAssignedTo]; // Fallback to single user if not an array

                        // 3. Update the items state
                        setItems(prevItems => 
                            prevItems.map(prevItem => 
                            prevItem._id === item._id 
                                ? { 
                                    ...prevItem, 
                                    assignedTo: [
                                    ...(prevItem.assignedTo || []), // Initialize as empty array if undefined
                                    ...newAssignedArray
                                    ] 
                                }
                                : prevItem
                            )
                        );

                        handleUpdateItem(item._id, 'assignedTo', newAssignedArray)
                        }}
                        memberList={memberList}
                    />
                    </div>
                    </td>
                    <td
                    className={classNames(
                        itemIdx !== items.length - 1 ? 'border-b border-gray-200' : '',
                        'whitespace-nowrap px-3 py-1 text-sm text-gray-500 text-center sm:text-[14px]',
                    )}
                    >
                        <div 
                            className="relative group cursor-pointer hover:bg-gray-100 rounded px-2 py-1"
                            onClick={(e) => {
                                const input = e.currentTarget.querySelector('input');
                                input.showPicker();
                            }}
                        >
                            <div>
                                {item.startDate || '未設定'}
                            </div>
                            <input
                                type="date"
                                value={item.startDate || ''}
                                max={item.deadline || ''}
                                onChange={(e) => {
                                    if (!item.deadline || e.target.value <= item.deadline) {
                                        handleUpdateItem(item._id, 'startDate', e.target.value)
                                    }
                                }}
                                className="absolute opacity-0 cursor-pointer w-0 h-0"
                            />
                        </div>
                    </td>
                    <td
                    className={classNames(
                        itemIdx !== items.length - 1 ? 'border-b border-gray-200' : '',
                        'whitespace-nowrap px-3 py-1 text-sm text-gray-500 text-center sm:text-[14px]',
                    )}
                    >
                        <div 
                            className="relative group cursor-pointer hover:bg-gray-100 rounded px-2 py-1"
                            onClick={(e) => {
                                const input = e.currentTarget.querySelector('input');
                                input.showPicker();
                            }}
                        >
                            <div>
                                {item.deadline || '未設定'}
                            </div>
                            <input
                                type="date"
                                value={item.deadline || ''}
                                min={item.startDate || ''} 
                                onChange={(e) => {
                                    if (!item.startDate || e.target.value >= item.startDate) {
                                        handleUpdateItem(item._id, 'deadline', e.target.value)
                                    }
                                }}
                                className="absolute opacity-0 cursor-pointer w-0 h-0"
                            />
                        </div>
                    </td>
                    <td
                    className={classNames(
                        itemIdx !== items.length - 1 ? 'border-b border-gray-200' : '',
                        'whitespace-nowrap px-3 py-1 text-sm text-gray-500 text-center lg:table-cell',
                    )}
                    >
                    <div
                        className="flex justify-center items-center gap-x-2"
                    >
                        <button
                            className="relative text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded p-1 transition-colors duration-200"
                            onClick={() => {
                                setRemarkDrawerOpen(true)
                                setActiveBoardID(item._id)
                            }}
                        >
                            <PencilIcon className="w-4 h-4" />
                            {item.remarks && (
                                <span className="absolute top-0 right-0 block h-2 w-2 rounded-full ring-2 ring-white bg-red-500"></span>
                            )}
                        </button>
                        <button 
                            className="relative text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded p-1 transition-colors duration-200"
                            onClick={() => {
                                setFileDialogOpen(true)
                                setActiveBoardID(item._id)
                            }}
                        >
                            <PaperClipIcon className="w-4 h-4" />
                            {item.attachments && item.attachments.length > 0 && (
                                <span className="absolute top-0 right-0 block h-2 w-2 rounded-full ring-2 ring-white bg-red-500"></span>
                            )}
                        </button>
                    </div>
                    </td>
                    <td
                    className={classNames(
                        itemIdx !== items.length - 1 ? 'border-b border-gray-200' : '',
                        'relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-8 lg:pr-8',
                    )}
                    >
                    <button className="text-indigo-600 hover:text-indigo-900 cursor-pointer hover:bg-gray-100 rounded-md p-1 duration-300" onClick={() => handleDeleteItem(item._id)}>
                        <TrashIcon className="w-4 h-4" />
                    </button>
                    </td>
                </tr>
                ))}
            </tbody>
        </table>
        <FileDialog
            open={fileDialogOpen}
            setOpen={setFileDialogOpen}
            activeBoardID={activeBoardID}
            fetchItems={fetchItems}
        />
        <RemarkDrawer
            open={remarkDrawerOpen}
            setOpen={setRemarkDrawerOpen}
            itemID={activeBoardID}
        />
        </>
    )
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
