import ApplicationShell from '../../Components/ApplicationShell/ApplicationShell';
import { useParams, useNavigate } from 'react-router-dom';
import { Fragment, useState, useEffect } from 'react';
// import icons from heroicons
import { 
    PencilIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    CheckIcon,
    XMarkIcon,
    ChevronUpDownIcon
} from '@heroicons/react/24/outline';
import GlobalVars from '../../Config';
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Label } from '@headlessui/react'

// components
import NewBoardItems from './Components/NewBoardItems';
import BoardsComp from './Components/BoardsComp';
import FileList from './Components/FileList';
import Note from './Components/Note';
import NewBoard from './Components/NewBoard';
import RemarkDialog from './Components/RemarkDialog';
import ProjectOtherSettingDropdown from './Components/ProjectOtherSettingDropdown';
import ProjectCalendar from './Components/ProjectCalendar';

export default function ProjectManagementBoard() {
    // document title
    document.title = "項目管理";

    const navigate = useNavigate();

    // get current board id from url (e.g. /project-management/123)
    const { id } = useParams();

    // if id is not 24 characters, redirect to 404 page
    if (id.length !== 24) {
        console.log('id is not 24 characters, redirect to 404 page');
        navigate('/404');
    }

    // get project details
    useEffect(() => {
        fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/project?projectID=${id}&organizationID=${localStorage.getItem('organizationId')}`, {
            headers: {
                'Authorization': `${sessionStorage.getItem('idToken')}`
            }
        })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                console.log(response.json())
                throw new Error('Failed to fetch project details')
            }
        })
        .then(data => {
            setPageTitle(data.projectTitle)
        })
        .catch(error => console.error('Error fetching project details:', error));
    }, [id])
    
    // const [boards, setBoards] = useState([{
    //     "boardName": "未開始",
    // }]);

    
    const [currentTab, setCurrentTab] = useState("項目版");
    const [newBoardItemsOpen, setNewBoardItemsOpen] = useState(false);
    const [pageTitle, setPageTitle] = useState("Lee Kam Kee");
    const [pageDescription, setPageDescription] = useState("A list of all the files in this project.");
    const [newBoardOpen, setNewBoardOpen] = useState(false);
    const [remarkDialogOpen, setRemarkDialogOpen] = useState(false);
    const [activeBoardID, setActiveBoardID] = useState('');

    // get current tab by url query params
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const currentTab = searchParams.get('tab') || "項目版";
        setCurrentTab(currentTab);
    }, [])

    const [boards, setBoards] = useState([]);
    const fetchBoards = () => {
      let id = window.location.pathname.split('/')[2];
        fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/board?projectID=${id}&organizationID=${localStorage.getItem('organizationId')}`, {
            headers: {
                'Authorization': `${sessionStorage.getItem('idToken')}`,
            }
        })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Failed to fetch boards')
            }
        })
        .then(data => {
            setBoards(data.map(board => ({
                boardName: board.boardTitle,
                boardId: board._id, 
                boardOrder: board.order
            })));
        })
        .catch(error => console.error('Error fetching boards:', error));
    }


    

    return (
        <ApplicationShell>
            <PageHeader 
                title={pageTitle}
                id={id}
            />
            <PageTabs 
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                navigate={navigate}
                id={id}
            />
            <div className="mt-4">
                {currentTab === "項目版" && 
                    <BoardList 
                        setNewBoardOpen={setNewBoardOpen}
                        newBoardItemsOpen={newBoardItemsOpen}
                        setNewBoardItemsOpen={setNewBoardItemsOpen}
                        setRemarkDialogOpen={setRemarkDialogOpen}
                        activeBoardID={activeBoardID}
                        setActiveBoardID={setActiveBoardID}
                        fetchBoards={fetchBoards}
                        boards={boards}
                    />}
                {currentTab === "檔案" && <FileList />}
                {currentTab === "備註" && <Note />}
            </div>
            
            <NewBoard 
                open={newBoardOpen}
                setOpen={setNewBoardOpen}
                fetchBoards={fetchBoards}
            />
            <RemarkDialog 
                open={remarkDialogOpen}
                setOpen={setRemarkDialogOpen}
                activeBoardID={activeBoardID}
            />
        </ApplicationShell>
    )
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function PageHeader({ title, id}) {
    const [isEditing, setIsEditing] = useState(false);
    const [updatedTitle, setUpdatedTitle] = useState(title);

    useEffect(() => {
        setUpdatedTitle(title);
    }, [title])

    const handleEditProject = () => {
        setIsEditing(true);
    }

    const handleTitleChange = (e) => {
        setUpdatedTitle(e.target.value);
    }

    const handleSaveProject = () => {
        setIsEditing(false);
        // create fetch request to update project title
        fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/project?projectID=${id}&organizationID=${localStorage.getItem('organizationId')}`, {
            headers: {
                'Authorization': `${sessionStorage.getItem('idToken')}`,
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify({
                projectTitle: updatedTitle,
                projectID: id
            })
        })
        .then(response => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Failed to update project title')
            }
        })
        .then(data => {
            
        })
        .catch(error => console.error('Error updating project title:', error));
    }

    const handleCancelEditProject = () => {
        setIsEditing(false);
        setUpdatedTitle(title);

    }

    return (
      <div classNames="">
        <div>
          <nav aria-label="Back" className="sm:hidden">
            <a href="/project-management" className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
              <ChevronLeftIcon aria-hidden="true" className="-ml-1 mr-1 size-5 shrink-0 text-gray-400" />
              返回
            </a>
          </nav>
          <nav aria-label="Breadcrumb" className="hidden sm:flex">
            <ol role="list" className="flex items-center space-x-4">
              <li>
                <div className="flex">
                  <a href="/project-management" className="text-sm font-medium text-gray-500 hover:text-gray-700">
                    項目
                  </a>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <ChevronRightIcon aria-hidden="true" className="size-5 shrink-0 text-gray-400" />
                  <a href={`/project-management/${id}`} className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 skip-trans">
                    {updatedTitle}
                  </a>
                </div>
              </li>
            </ol>
          </nav>
        </div>
        <div className="mt-2 md:flex md:items-center md:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl/7 font-bold text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight skip-trans">
              {isEditing ? (
                <input 
                    type="text" 
                    value={updatedTitle} 
                    onChange={handleTitleChange} 
                    className="border-1 border-gray-300 rounded-md p-1 text-2xl/7 font-bold text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight"
                />
              ) : (
                updatedTitle
              )}
              {!isEditing ? (
                <button className="ml-2" onClick={handleEditProject}>
                    <PencilIcon className="size-5" />
                </button>
              ):(
                <>
                    <button className="ml-2" onClick={handleSaveProject}>
                        <CheckIcon className="size-5" />
                    </button>
                    <button className="ml-2" onClick={handleCancelEditProject}>
                        <XMarkIcon className="size-5" />
                    </button>
                </>
              )}
            </h2>
          </div>
          <div className="mt-4 flex shrink-0 md:ml-4 md:mt-0">
            {/* <button
              type="button"
              className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Edit
            </button> */}
            {/* <button
              type="button"
              className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => setNewBoardOpen(true)}
            >
              新增項目版
            </button> */}
          </div>
        </div>
      </div>
    )
}

function PageTabs({ currentTab, setCurrentTab, navigate, id }) {
    const [tabs, setTabs] = useState([
        { name: '項目版', href: '#', current: true },
        { name: '檔案', href: '#', current: false },
        { name: '備註', href: '#', current: false },
    ]);

    const handleTabChange = (e) => {
        console.log('e', e)
        setCurrentTab(e);
        setTabs(tabs.map((tab) => ({
            ...tab,
            current: tab.name === e,
        })));

        // update url query params
        navigate(`/project-management/${id}?tab=${e}`);
    }

    useEffect(() => {
        setTabs(tabs.map((tab) => ({
            ...tab,
            current: tab.name === currentTab,
        })));
    }, [currentTab])

    return (
      <div
        className="mt-4"
      >
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            defaultValue={tabs.find((tab) => tab.current).name}
            onChange={(e) => handleTabChange(e.target.value)}
            value={tabs.find((tab) => tab.current).name}
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          >
            {tabs.map((tab) => (
              <option key={tab.name} value={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav aria-label="Tabs" className="-mb-px flex space-x-8">
              {tabs.map((tab) => (
                <button
                  key={tab.name}
                  aria-current={tab.current ? 'page' : undefined}
                  className={classNames(
                    tab.current
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium cursor-pointer',
                  )}
                  onClick={() => handleTabChange(tab.name)}
                >
                  {tab.name}
                </button>
              ))}
            </nav>
          </div>
        </div>
      </div>
    )
}

function BoardList({ 
    newBoardItemsOpen, 
    setNewBoardItemsOpen,
    setRemarkDialogOpen,
    activeBoardID,
    setActiveBoardID,
    fetchBoards,
    boards,
    setNewBoardOpen
}) {
    // initial fetch boards
    useEffect(() => {
        fetchBoards();
    }, [])

    const [query, setQuery] = useState('')
    const [searchedItem, setSearchedItem] = useState(null)

    const [memberList, setMemberList] = useState([])
    useEffect(() => {
      // fetch member list
      fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/users?organizationID=${localStorage.getItem('organizationId')}`, {
          headers: {
              'Authorization': `${sessionStorage.getItem('idToken')}`
          }
      })
      .then(response => {
          if (response.ok) {
              return response.json()
          } else {
              throw new Error('Failed to fetch members')
          }
      })
      .then(data => {
          setMemberList(data['users'].map(user => ({
              displayName: user.displayName,
              _id: user._id
          })))
      })
      .catch(error => console.error('Error fetching members:', error));
    }, [])

    const [BoardViewMode, setBoardViewMode] = useState('list')
    useEffect(() => {
      const storedViewMode = localStorage.getItem('boardViewMode');
      if (storedViewMode) {
        setBoardViewMode(storedViewMode);
      }
    }, [])

    return (
        <>  
            <div
              className="flex flex-col sm:flex-row justify-between gap-4 sm:gap-0 mb-4"
            >
                <SearchBar 
                    query={query}
                    setQuery={setQuery}
                    setSearchedItem={setSearchedItem}
                />
                <div
                  className="flex items-center justify-end"
                >
                  <button
                    type="button"
                    className="mr-2 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => setNewBoardOpen(true)}
                  >
                    新增項目版
                  </button>
                  <ProjectOtherSettingDropdown 
                    boardViewMode={BoardViewMode}
                    setBoardViewMode={setBoardViewMode}
                  />
                </div>
            </div>
            {BoardViewMode === 'list' ? (
              <ul role="list" className="space-y-3">
                {boards.map((board) => (
                    <li key={board.boardId} className="bg-white px-4 py-4 shadow sm:rounded-md sm:px-6">
                        <BoardsComp 
                            boardName={board.boardName} 
                            boardId={board.boardId} 
                            boardOrder={board.boardOrder}
                            numBoards={boards.length}
                            newBoardItemsOpen={newBoardItemsOpen}
                            setNewBoardItemsOpen={setNewBoardItemsOpen}
                            setActiveBoardID={setActiveBoardID}
                            setRemarkDialogOpen={setRemarkDialogOpen}
                            fetchBoards={fetchBoards}
                            searchedItem={searchedItem}
                            memberList={memberList}
                            activeBoardID={activeBoardID}
                        />
                    </li>
                ))}
              </ul>
            ) : (
              <div className="grid grid-cols-1 gap-6">
                {boards.map((board) => (
                  <div key={board.boardId} className="bg-white px-4 py-4 shadow sm:rounded-md sm:px-6">
                    <div className="flex justify-between items-center mb-2">
                      <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">{board.boardName}</h3>
                      <button
                        type="button"
                        className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={() => {
                          setNewBoardItemsOpen(true)
                          setActiveBoardID(board.boardId)
                        }}
                      >
                        新增工作
                      </button>
                    </div>
                    <ProjectCalendar  
                      board={board}
                      boardId={board.boardId}
                    />
                  </div>
                ))}
              </div>
            )}
            <NewBoardItems 
                open={newBoardItemsOpen}
                setOpen={setNewBoardItemsOpen}
                boardID={activeBoardID}
            />
        </>
    )
}

function SearchBar({ query, setQuery, setSearchedItem }) {
    const [items, setItems] = useState([])
    const [localQuery, setLocalQuery] = useState('')
    const { id } = useParams();

    useEffect(() => {
      let url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/board/items?projectID=${id}&organizationID=${localStorage.getItem('organizationId')}`
      fetch(url, {
        headers: {
          'Authorization': `${sessionStorage.getItem('idToken')}`
        }
      })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error('Failed to fetch items')
        }
      })
      .then(data => {
        setItems(data.items)
      })
      .catch(error => console.error('Error fetching items:', error));
    }, [])

    const filteredItems = 
      localQuery === ''
        ? items
        : items.filter((item) => {
            return item.itemTitle.toLowerCase().includes(localQuery.toLowerCase())
          })

    return (
        <div className="relative mt-2 mb-4 sm:min-w-96">
          <Combobox
            as="div"
            value={query}
            onChange={(item) => {
              setLocalQuery('')
              setQuery(item?.itemTitle || '')
              setSearchedItem(item?._id || '')
            }}
          >
            <div className="relative">
              <ComboboxInput
                className="block w-full rounded-md bg-white py-1.5 pl-3 pr-12 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                onChange={(event) => setLocalQuery(event.target.value)}
                onBlur={() => setLocalQuery('')}
                onFocus={(e) => e.target.select()}
                displayValue={(value) => value || '搜尋工作'}
                placeholder="搜尋工作"
              />
              <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                <ChevronUpDownIcon className="size-5 text-gray-400" aria-hidden="true" />
              </ComboboxButton>

              {filteredItems.length > 0 && (
                <ComboboxOptions className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                  <ComboboxOption
                    key="empty"
                    value=""
                    className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white data-[focus]:outline-none"
                  >
                    <span className="block truncate group-data-[selected]:font-semibold">搜尋工作</span>
                  </ComboboxOption>

                  {filteredItems.map((item) => (
                    <ComboboxOption
                      key={item._id}
                      value={item}
                      className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white data-[focus]:outline-none"
                    >
                      <span className="block truncate group-data-[selected]:font-semibold">
                        {item.itemTitle}
                      </span>
                      <span className="absolute inset-y-0 right-0 hidden items-center pr-4 text-indigo-600 group-data-[selected]:flex group-data-[focus]:text-white">
                        <CheckIcon className="size-5" aria-hidden="true" />
                      </span>
                    </ComboboxOption>
                  ))}
                </ComboboxOptions>
              )}
            </div>
          </Combobox>
        </div>
    )
}