import { useState } from 'react'
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/react'
import { ChevronUpDownIcon, CheckIcon } from '@heroicons/react/20/solid'
import { Transition } from '@headlessui/react'
import { Fragment } from 'react'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function StatusFilter({statusList, filterStatus, setFilterStatus}) {
    const [selected, setSelected] = useState(statusList[0])
  
    const updateFilterStatus = (status) => {
      // set filter status
      setFilterStatus(status);
  
      // set item in localStorage
      localStorage.setItem("filterStatus", JSON.stringify(status));
  
    }
  
    return (
      <Listbox value={filterStatus} onChange={updateFilterStatus}>
        {({ open }) => (
          <>
            {/* <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">過濾工作狀態</Listbox.Label> */}
            <div className="relative mt-1 sm:mr-2">
              <ListboxButton className="text-[12px] relative w-full cursor-default rounded-md bg-white h-[36px] pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <span className="flex items-center">
                  <span
                    aria-label={filterStatus.status}
                    className={classNames(
                      filterStatus.color ? `bg-${filterStatus.color}-400 ring-${filterStatus.color}-500/10` : 'bg-white ring-white-500/10',
                      'inline-block h-2 w-2 flex-shrink-0 rounded-full ring-1 ring-inset'
                    )}
                  />
                  <span className="ml-3 block truncate text-[12px]">{filterStatus.status}</span>
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </ListboxButton>
  
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <ListboxOptions className="absolute z-10 mt-1 max-h-60 w-full min-w-[200px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ">
                  {statusList.map((item) => (
                    <ListboxOption
                      key={item.status}
                      className={({ active }) =>
                        classNames(
                          active ? 'text-white bg-indigo-600' : 'text-gray-900',
                          'relative cursor-default select-none py-2 pl-3 pr-9'
                        )
                      }
                      value={item}
                    >
                      {({ filterStatus, active }) => (
                        <>
                          <div className="flex items-center">
                            <span
                              className={classNames(
                                item.color ? `bg-${item.color}-400 ring-${item.color}-500/10` : 'bg-white ring-white-500/10',
                                'inline-block h-2 w-2 flex-shrink-0 rounded-full ring-1 ring-inset'
                              )}
                              aria-hidden="true"
                            />
                            <span
                              className={classNames(filterStatus ? 'font-semibold' : 'font-normal', 'ml-3 block truncate text-sm')}
                            >
                              {item.status}
                              <span className="sr-only"> is {}</span>
                            </span>
                          </div>
  
                          {filterStatus ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-indigo-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </ListboxOption>
                  ))}
                </ListboxOptions>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    )
  } 