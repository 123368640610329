import { useState, useEffect } from "react";
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from "@headlessui/react";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import GlobalVars from "../../../../../../Config";

export default function EmailDialog({
    open,
    setOpen,
    submitDetails,
    isSaving,
}) {
    const [emailContent, setEmailContent] = useState(`Dear [Client's Name],

We are pleased to inform you that the job assigned to our team has been successfully completed.

Job Details:

Job ID: [Job ID]
Job Description: [Short description of the job]
Date Completed: [Completion Date]
Attached to this email is the job sheet for your records. If you have any questions or require further assistance, please do not hesitate to contact us.

Thank you for trusting us with your work. We look forward to serving you again in the future!

Best regards,

[Your Name]
[Your Company Name]`);
    const [emailTarget, setEmailTarget] = useState('');

    const handleSendEmail = () => {
        // prompt user to confirm sending email
        if (!window.confirm('確定要發送電郵嗎？\n收件者為: ' + emailTarget)) {
            return;
        }

        
        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/submodules/send-job-sheet-email";
        let body = {
            email: emailTarget,
            content: emailContent,
            jobID: submitDetails.jobID,
        }
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Authorization': sessionStorage.getItem('idToken'),
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            if (!res.ok) {
                throw new Error('Failed to send email');
            }
            return res.json();
        })
        .then(data => {
            console.log(data);
            alert('電郵已發送');
            setOpen(false);
        })
        .catch(err => {
            console.log(err);
            alert('電郵發送失敗');
        });
        
    }


    useEffect(() => {
        setEmailContent(`Dear ${submitDetails.contactName},

We are pleased to inform you that the job assigned to our team has been successfully completed.


Job Details:

Job ID: ${submitDetails.jobID}
Customer Request: ${submitDetails.customerRequest}
Completed Work: ${submitDetails.completedTaskRemarks}

Attached to this email is the job sheet for your records. If you have any questions or require further assistance, please do not hesitate to contact us.

Thank you for trusting us with your work. We look forward to serving you again in the future!

Best regards,
Golden News Enterprise Limited`);

        
        // set email target
        setEmailTarget(submitDetails.contactEmail);

    }, [submitDetails, open]);

  

    const downloadJobSheet = () => {
        console.log('download job sheet');
        // check if jobID in submitDetails
        if (!submitDetails.jobID) {
            console.log('jobID not found');
            return;
        }

        // download the job sheet
        const url = GlobalVars.BACKEND_DOMAIN + "/api/v1/submodules/job-sheet-file?jobID=" + submitDetails.jobID;

        fetch(url, {
            headers: {
                'Authorization': sessionStorage.getItem('idToken')
            }
        })
        .then(res => res.json())
        .then(data => {
            window.open(data.url, '_blank');
        })
        .catch(err => {
            console.log(err);
        });
    }


    return (
      <Dialog open={open} onClose={setOpen} className="relative z-50">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />
  
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div className="sm:flex sm:items-start w-full">
                <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:size-10">
                  <EnvelopeIcon aria-hidden="true" className="size-6 text-indigo-600" />
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                  <DialogTitle as="h3" className="text-base font-semibold text-gray-900">
                    發送電郵
                  </DialogTitle>
                  <div className="mt-2 space-y-4">
                    <p className="text-sm text-gray-500">
                      請確認以下電郵內容
                    </p>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                            收件人電郵
                        </label>
                        <input
                            type="email" 
                            className="w-full p-3 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 text-[16px] sm:text-sm"
                            placeholder="請輸入收件人電郵..."
                            value={emailTarget}
                            onChange={(e) => setEmailTarget(e.target.value)}
                        />
                    </div>
                    <textarea
                        className="w-full h-48 p-3 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 text-[16px] sm:text-sm"
                        placeholder="請輸入電郵內容..."
                        value={emailContent}
                        onChange={(e) => setEmailContent(e.target.value)}
                    />
                    <button
                      type="button"
                      onClick={downloadJobSheet}
                      className={`mt-3 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ${
                        isSaving ? 'bg-gray-300 text-gray-500' : 'bg-white text-gray-900 hover:bg-gray-50'
                      }`}
                      disabled={isSaving}
                    >
                      {isSaving ? '儲存中...' : '查看工單檔案'}
                    </button>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={handleSendEmail}
                  className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                >
                  發送
                </button>
                <button
                  type="button"
                  data-autofocus
                  onClick={() => setOpen(false)}
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  取消
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    )
  }
