import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const fallbackLng = ["zh-TW"];
const storageKey = 'i18nextLng';

// Get stored language or default to zh-TW
const storedLanguage = localStorage.getItem(storageKey) || 'zh-TW';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng,
    lng: storedLanguage, // Use stored language
    detection: {
      checkWhitelist: true,
      order: ['localStorage', 'cookie', 'navigator'],
      caches: ['localStorage', 'cookie'],
      lookupLocalStorage: storageKey,
    },
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;