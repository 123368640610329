import { useState, useEffect, useRef, useContext } from "react";
import JobList from "../../Components/JobList";
import "./FullJobList.css";
import ApplicationShell from "../../Components/ApplicationShell";
import GlobalVars from "../../Config";
import { throttle } from 'lodash'; // You can use lodash for throttling

import MultiJobForm from "../../Components/MultiJobForm/MultiJobForm";
import { Fragment } from 'react'
import { Transition, Dialog } from '@headlessui/react'
import MinimalIconDropDown from "../../Components/MinimalIconDropDown/MinimalIconDropDown";
import { XMarkIcon, ChevronDownIcon, ArrowPathIcon, ExclamationTriangleIcon} from '@heroicons/react/20/solid'
import SelfDisappearNotification from "../../Components/SelfDisappearNotification";
import { UserContext } from '../../Context';
import { useTranslation } from 'react-i18next';


// import components
// import QuickJobSlideOver from "../../Components/QuickJobSlideOver";
import NewJobForm from "../../Components/NewJobForm";
import ExportJobListDialog from "../../Components/ExportJobListDialog";
import JobDetailSecondaryScreen from "./Components/JobDetailSecondaryScreen";
import CreateJobOptionBtn from "./Components/CreateJobOptionBtn";
import JobListOptions from "./Components/JobListOptions";



// add keyboard shortcut for opening new job form
function KeyboardShortcut({
    newFormOpen,
    setNewFormOpen,
    newMultiFormOpen,
    setMultiFormOpen,
}) {
    const [lastKey, setLastKey] = useState(null);

    const handleKeyDown = (e) => { 
            
        if (lastKey === "c" && e.key === "j") {
            if (!newFormOpen) {
                setNewFormOpen(true);
                setLastKey(null); // Reset the last key
            }
            
        } else if (lastKey === "c" && e.key === "m") {
            if (!newMultiFormOpen) {
                setMultiFormOpen(true);
                setLastKey(null); // Reset the last key
            }
        } else {
            
            setLastKey(e.key); // Update the last key
        }
    }

    useEffect(() => {
        const keyDownListener = (e) => handleKeyDown(e);
        window.addEventListener('keydown', keyDownListener);

        return () => {
            window.removeEventListener('keydown', keyDownListener);
        };
    }, [lastKey]);  

    return null;

}
 
// FullJobList component
function FullJobListComp({
    userid=null,
    locList,
    setLocList,
    selectedLoc,
    setSelectedLoc,
    staffList,
    setStaffList,
    selectedStaff,
    setSelectedStaff,
    typeList,
    setTypeList,
    selectedTaskType,
    setSelectedTaskType,
    taskList,
    setTaskList,
    selectedTask,
    setSelectedTask,
    dateFilter,
    setDateFilter,
    estimateDate,
    setEstimateDate,
    filterStatus,
    setFilterStatus,
    numJobDisplay,
    setNumJobDisplay,
}) {
    const { t } = useTranslation();
    // check current selected

    // update title to 工作列表 and get filter status from localStorage
    document.title = t("工作列表");

    // list of status with their status color 
    const statusList = [
        {"status": t("所有工作"), "color": "white"},
        { "status": t("回公司"), "color": "yellow" },
        { "status": t("新工作"), "color": "white" },
        { "status": t("待分配"), "color": "pink"},
        { "status": t("待確認"), "color": "pink" },
        { "status": t("未開始"), "color": "gray" },
        { "status": t("進行中"), "color": "green" },
        { "status": t("完成"), "color": "orange" },
        { "status": t("拒絕"), "color": "red" },
        { "status": t("取消"), "color": "red" },
        { "status": t("中斷"), "color": "red" },
        { "status": t("待續"), "color": "yellow" },
        { "status": t("待檢查"), "color": "blue" },
        { "status": t("待批准"), "color": "blue" },
        { "status": t("延期"), "color": "yellow" },
        { "status": t("緊急"), "color": "red" },
        { "status": t("維修中"), "color": "yellow" },
        { "status": t("需要額外部件"), "color": "white" },
        { "status": t("需要專家幫助"), "color": "white" },
        { "status": t("通知"), "color": "white"},
    ]

    // get filter status from localStorage
    var currStatus = JSON.parse(localStorage.getItem("filterStatus"));
    // if undefined, set to "所有工作"
    if (currStatus === null) {
        // setFilterStatus(statusList[0]); 
        currStatus = statusList[0];

        // set item in localStorage
        localStorage.setItem("filterStatus", JSON.stringify(statusList[0]));
        
    }

    // set states
    const [jobs, setJobs] = useState([]);
    const [newFormOpen, setNewFormOpen] = useState(false);
    const [newMultiFormOpen, setMultiFormOpen] = useState(false);

    // handle manual refresh
    const [manualRefreshBtnText, setManualRefreshBtnText] = useState("刷新列表");
    const [enableManualRefresh, setEnableManualRefresh] = useState(true);
    const handleManualRefresh = () => {
        // change button text to "刷新中..."
        setManualRefreshBtnText(t("刷新中..."));
        // disable button
        setEnableManualRefresh(false);

        setNumJobDisplay(20);

    };
    
    // fetch list data from backend
    useEffect(() => {
        // fetch location list
        fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/locations?organizationID=" + localStorage.getItem("organizationId"), {
            headers: {
              "Authorization": sessionStorage.getItem("idToken")
            }
          })
            .then((res) => res.json())
            .then((rawData) => {
                let data = rawData['locations']
                let selectedLang = localStorage.getItem("language");
                
                data.unshift({
                    "name": t("所有地點"),
                    "color": "white"
                });

                // go through each item in location. For each item, check if langVar.tc exist. If exist, set name to langVar.tc
                data.forEach((item) => {
                    if (item.hasOwnProperty("langVar")) {
                        
                        if (selectedLang === "en") {
                            // Keep original name for English
                        } else if (item["langVar"].hasOwnProperty("tc") && item["langVar"]["tc"] != "") {
                            item["name"] = item["name"] + " - " + item["langVar"]["tc"];
                        }
                    }
                });
                setLocList(data);
            })
            .catch((err) => {
                console.log(err);
            });
        
        // fetch staff list
        fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/users?organizationID=" + localStorage.getItem("organizationId"), {
            headers: {
              "Authorization": sessionStorage.getItem("idToken")
            }
          })
        .then((res) => res.json())
        .then((rawData) => {
            let data = rawData['users']
            let selectedLang = localStorage.getItem("language");

            // filter out status not equal to "active"
            // data = data.filter((item) => {
            //     return item["status"] === "active";
            // });

            // convert displayName to name
            data.forEach((item) => {
                item["name"] = item["displayName"];
                delete item["displayName"];
            });

            // add "所有員工" to the list
            data.unshift({
                "name": t("所有員工"),
                "color": "white"
            });

            // check if userid is selected
            if (userid != null) {
                // find the user with the same id
                for (let i = 0; i < data.length; i++) {
                    if (data[i]["_id"] === userid) {
                        setSelectedStaff(data[i]);
                        break;
                    }
                }
            }

            // remove duplicates based on user id 
            let uniqueData = [];
            let uniqueIds = [];
            for (let i = 0; i < data.length; i++) {
                if (!uniqueIds.includes(data[i]["_id"])) {
                    uniqueData.push(data[i]);
                    uniqueIds.push(data[i]["_id"]);
                }
            }

            setStaffList(uniqueData);
        })
        .catch((err) => {
            console.log(err);
        });

        // fetch main type list
        fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/maintypes?organizationID=" + localStorage.getItem("organizationId")+ "&lang=tc", {
            headers: {
              "Authorization": sessionStorage.getItem("idToken")
            }
          })
            .then((res) => res.json())
            .then((rawData) => {
                let data = rawData['types'];
                data.unshift({"name": "所有類型"});

                // add langVar.tc if langVar exists and if langVar.tc exists
                data.forEach((item) => {
                    if (item.hasOwnProperty("langVar")) {
                        if (item["langVar"].hasOwnProperty("tc") && item["langVar"]["tc"] != "") {
                            item["name"] = item["name"] + " - " + item["langVar"]["tc"];
                        }
                    }
                });

                setTypeList(data);
            })
            .catch((err) => {
                console.log(err);
            });

        // fetch task list
        fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/jobtypes?organizationID=" + localStorage.getItem("organizationId")+ "&lang=tc", {
            headers: {
              "Authorization": sessionStorage.getItem("idToken")
            }
          })
            .then((res) => res.json())
            .then((rawData) => {
                let data = rawData['jobTypes'];

                // add item code to name, e.g. <item code> - <item name>
                data.forEach((item) => {
                    item["name"] = "(" + item["itemCode"].toString() + ") " + item["langVar"]["en"] + " - " + item["name"];
                });

                // sort by name
                data.sort((a, b) => {
                    return a["name"].localeCompare(b["name"]);
                });

                data.unshift({"name": t("所有要求")});
                setTaskList(data);
            })
            .catch((err) => {
                console.log(err);
            }
        );
    

    }, []);
            
    
    // fetch data from backen
    useEffect(() => {

        // change button text to "刷新中..."
        setManualRefreshBtnText(t("刷新中..."));
        // disable button
        setEnableManualRefresh(false);
        
        RetrieveData(
            jobs,
            filterStatus, 
            setJobs, 
            setEnableManualRefresh, 
            setManualRefreshBtnText,
            selectedLoc,
            selectedStaff,
            selectedTask,
            dateFilter, 
            estimateDate,
            selectedTaskType,
            typeList,
            taskList,
            numJobDisplay,
            setIsLoadingNewData,
            locList=locList,
            t
        );

        StoreExistingPagePreference(
            filterStatus,
            selectedLoc,
            selectedStaff,
            selectedTask,
            dateFilter,
            estimateDate,
            selectedTaskType,
            numJobDisplay
        )        

    }, [filterStatus, selectedLoc, selectedStaff, dateFilter, estimateDate, selectedTaskType, selectedTask, locList]);

    // set automatic refresh every fixed seconds
    useEffect(() => {
        const interval = setInterval(() => {

            // change button text to "刷新中..."
            setManualRefreshBtnText(t("刷新中..."));
            // disable button
            setEnableManualRefresh(false);
            
            RetrieveData(
                jobs,
                filterStatus, 
                setJobs, 
                setEnableManualRefresh, 
                setManualRefreshBtnText,
                selectedLoc,
                selectedStaff,
                selectedTask,
                dateFilter, 
                estimateDate,
                selectedTaskType,
                typeList,
                taskList,
                numJobDisplay,
                setIsLoadingNewData,
                locList=locList,
                t
            );
            // console.log("Automatic refresh every 5 seconds")

        }, 5000);

        return () => clearInterval(interval);
    }, [filterStatus, selectedLoc, selectedStaff, dateFilter, estimateDate, selectedTaskType, selectedTask, locList, numJobDisplay]);

    // set states for list setting
    const [listSettingOpen, setListSettingOpen] = useState(false);

    const handleOpenListSetting = () => {
        setListSettingOpen(true);
    };

    // batch changes of jobs 
    const progressJobHeader = useRef(null);
    const progressJobFooter = useRef(null);
    const [isBatchChange, setIsBatchChange] = useState(false);
    const [batchSelectedJobs, setBatchSelectedJobs] = useState([]);
    const [batchSelectedStatus, setBatchSelectedStatus] = useState("");
    const handleBatchChange = () => {
        
        // set isBatchChange to true
        setIsBatchChange(true);

        // show progressJobHeader and progressJobFooter
        progressJobHeader.current.classList.remove("hidden");
        progressJobFooter.current.classList.remove("hidden");

        progressJobHeader.current.classList.add("flex");
        progressJobFooter.current.classList.add("flex");
    }

    // handle export dialog 
    const [exportDialog, setExportDialog] = useState(false);
    const handleExportDialog = () => {
        setExportDialog(true);
    }

    // menu list for dropdown menu
    const [menuList, setMenuList] = useState([
        {"name": t('修改工作'), "action": handleBatchChange},
        {"name": t('週期性工作'), "href": "/recurring-job"},
        {"name": t('列表設定'), "action": handleOpenListSetting},
        {"name": t('匯出'), "action": handleExportDialog},
    ]);

    // detect if users have scrolled to the bottom of the page
    const [isLoadingNewData, setIsLoadingNewData] = useState(false);


    // detect if users have scrolled to the bottom of the page
    const handleScroll = throttle(() => {
        if (isLoadingNewData) return;

        if (sessionStorage.getItem("openChatInterface") === "true") return;

        const d = document.documentElement;
        const offset = d.scrollTop + window.innerHeight;
        const height = d.offsetHeight;

        if (offset >= height * 0.90) {
            setIsLoadingNewData(true);
            setNumJobDisplay(prev => prev + 20);
        }
    }, 200); // Adjust the throttle delay as needed

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isLoadingNewData]);

    useEffect(() => {
        if (!isLoadingNewData) return;

        RetrieveData(
            jobs,
            filterStatus, 
            setJobs, 
            setEnableManualRefresh, 
            setManualRefreshBtnText,
            selectedLoc,
            selectedStaff,
            selectedTask,
            dateFilter, 
            estimateDate,
            selectedTaskType,
            typeList,
            taskList,
            numJobDisplay,
            setIsLoadingNewData,
            locList,
            t
        );
    }, [numJobDisplay]);

    // handle progress job button press for each job 
    const handleProgressJob = (job) => {
   
        // get job details
        var userid = localStorage.getItem('userid');
        var assignedTo = job.assignedTo;
        var status = job.status.status;
    
        // check if users have the right to update job status
        if (status != '待確認' && userid != assignedTo && localStorage.getItem('userType') != "admin") {
          alert("只有負責人員或是管理者才能更新工作狀態");
          return;
        }
    
        // prompt confirmation
        if (status != '待確認' || (status == '待確認' && userid == assignedTo)) {
          var r = window.confirm("確定要更新工作狀態嗎？");  
        } else {
          var r = window.confirm("此工作尚未分配。 您確認接受這份工作嗎？")
        }
        if (!r) {
          return;
        }
    
        // based on status update the job status
        var newStatus = '';
    
        if (status == '新工作' ) {
          newStatus = '待確認';
        } else if (status == '待確認' || status == '已轉移') {
          newStatus = '未開始';
        } else if (status == '未開始') {
          newStatus = '進行中';
        } else if (status == '進行中') {
          if (job.meta.hasOwnProperty('requiredInspection') && job.meta.requiredInspection == true) {
            newStatus = '待檢查';
          } else {
            newStatus = '完成';
          }
        } else if (status == '待檢查') {
          newStatus = '完成';
        } else {
          newStatus = '進行中';
        }
    
        // fetch request to Progress job (PUT)
        var url = GlobalVars.BACKEND_DOMAIN + "/api/v1/jobs/progress";
        var data = {
          jobID: job._id,
          status: newStatus,
          assignedTo: userid,
          userID: localStorage.getItem('userid')
        };
        
        fetch(url, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
        })
          .then(response => response.json())
          .then(data => {
            // console.log('Success:', data);
            // setJob(data['job']);
            setShowSelfDisappearNotification(true);
            setSelfDisappearNotificationTitle("工作狀態更新成功");
            setSelfDisappearNotificationText("");
            
            // retrieve data from backend
            RetrieveData(
                jobs,
                filterStatus, 
                setJobs, 
                setEnableManualRefresh, 
                setManualRefreshBtnText,
                selectedLoc,
                selectedStaff,
                selectedTask,
                dateFilter, 
                estimateDate,
                selectedTaskType,
                typeList,
                taskList,
                numJobDisplay,
                locList,
                t
            );
          })
      }

    // manage disappering notification
    const [showSelfDisappearNotification, setShowSelfDisappearNotification] = useState(false);
    const [selfDisappearNotificiationTitle, setSelfDisappearNotificationTitle] = useState("");
    const [selfDisappearNotificationText, setSelfDisappearNotificationText] = useState("");
    const [selfDisappearNotificationUrl, setSelfDisappearNotificationUrl] = useState("");

    // get global user context
    const { globalUserRole } = useContext(UserContext);

    // secondary screen 
    const [selectedJobId, setSelectedJobId] = useState(null);
    const [displaySecondaryScreen, setDisplaySecondaryScreen] = useState(false);
    // setDisplaySecondaryScreen to true when localStorage.getItem("jobListPreference_secondaryScreen") is true
    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === "jobListPreference_secondaryScreen") {
                console.log(event.newValue);
                setDisplaySecondaryScreen(event.newValue === "true");
            }
        };
    
        window.addEventListener('storage', handleStorageChange);
    
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    return (
        <>  
            <ApplicationShell 
                secondaryScreen={<JobDetailSecondaryScreen selectedJobId={selectedJobId} />}
                displaySecondaryScreen={displaySecondaryScreen}
                setDisplaySecondaryScreen={setDisplaySecondaryScreen}
            >
                <div className="md:flex md:items-center md:justify-between mb-5" id="page-heading">
                    <div className="min-w-0 flex-1">
                        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                            {t("工作列表")}
                        </h2>
                    </div>
                    <div className="mt-4 flex md:ml-4 md:mt-0 items-center">
                        <button
                        type="button"
                        className="dynamic-comp inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200 duration-300"
                        onClick={handleManualRefresh}
                        disabled={!enableManualRefresh}
                        >
                        {manualRefreshBtnText}
                        {manualRefreshBtnText == t('刷新中...') && <ArrowPathIcon className="-mr-1 ml-2 h-5 w-5 text-gray-400 animate-spin" aria-hidden="true" /> }
                        </button>
                        {globalUserRole !== "viewer" &&
                            <CreateJobOptionBtn setNewFormOpen={setNewFormOpen} setMultiFormOpen={setMultiFormOpen} />
                        }
                        <div
                            className="ml-3 flex items-center"
                        >
                            <MinimalIconDropDown menuList={menuList} />
                        </div>
                    </div>
                </div>
                <JobList 
                    jobs={jobs} 
                    statusList={statusList} 
                    filterStatus={filterStatus}
                    setFilterStatus={setFilterStatus} 
                    locList={locList}
                    selectedLoc={selectedLoc}
                    setSelectedLoc={setSelectedLoc}
                    staffList={staffList}
                    selectedStaff={selectedStaff}
                    setSelectedStaff={setSelectedStaff}
                    dateFilter={dateFilter}
                    setDateFilter={setDateFilter}
                    estimateDate={estimateDate}
                    setEstimateDate={setEstimateDate}
                    typeList={typeList}
                    selectedTaskType={selectedTaskType}
                    setSelectedTaskType={setSelectedTaskType}
                    taskList={taskList}
                    selectedTask={selectedTask}
                    setSelectedTask={setSelectedTask}
                    handleProgressJob={handleProgressJob}
                    isBatchChange={isBatchChange}
                    setBatchSelectedJobs={setBatchSelectedJobs}
                    batchSelectedJobs={batchSelectedJobs}
                    batchSelectedStatus={batchSelectedStatus}
                    setBatchSelectedStatus={setBatchSelectedStatus}
                    manualRefreshBtnText={manualRefreshBtnText}
                    selectedJobId={selectedJobId}
                    setSelectedJobId={setSelectedJobId}
                    numJobDisplay={numJobDisplay}
                    setNumJobDisplay={setNumJobDisplay}
                    setIsLoadingNewData={setIsLoadingNewData}
                    displaySecondaryScreen={displaySecondaryScreen}
                />
                <BatchJobChangeHeader progressJobHeader={progressJobHeader} progressJobFooter={progressJobFooter} setIsBatchChange={setIsBatchChange} />
                <BatchJobChangeFooter progressJobFooter={progressJobFooter} batchSelectedJobs={batchSelectedJobs} batchSelectedStatus={batchSelectedStatus} />
                <NewJobForm 
                    state={newFormOpen} 
                    setEditState={setNewFormOpen} 
                    jobPrefill={{}}
                    setShowSelfDisappearNotification={setShowSelfDisappearNotification}
                    setSelfDisappearNotificationTitle={setSelfDisappearNotificationTitle}
                    setSelfDisappearNotificationText={setSelfDisappearNotificationText}
                    setSelfDisappearNotificationUrl={setSelfDisappearNotificationUrl}
                /> 
                <MultiJobForm state={newMultiFormOpen} setEditState={setMultiFormOpen} />
                <JobListOptions open={listSettingOpen} setOpen={setListSettingOpen} />
                <ExportJobListDialog exportDialog={exportDialog} setExportDialog={setExportDialog} />
            </ApplicationShell>
            <SelfDisappearNotification
                show={showSelfDisappearNotification}
                setShow={setShowSelfDisappearNotification}
                title={selfDisappearNotificiationTitle}
                body={selfDisappearNotificationText}
                url={selfDisappearNotificationUrl}
            />
            {/* <RefreshAlert show={showIdleAlert} setShow={setShowIdleAlert} alertText={idleAlertText} handleCancelRefresh={handleCancelRefresh} /> */}
            <KeyboardShortcut
                newFormOpen={newFormOpen}
                setNewFormOpen={setNewFormOpen}
                newMultiFormOpen={newMultiFormOpen}
                setMultiFormOpen={setMultiFormOpen}
            />

        </>
        
    );
}

// refresh alert component 
function RefreshAlert({show, setShow, alertText, handleCancelRefresh}) {
    
    return (
        <>
            {/* Global notification live region, render this permanently at the end of the document */}
            <div
            aria-live="assertive"
            className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50"
            >
                <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
                    {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                    <Transition
                    show={show}
                    as={Fragment}
                    enter="transform ease-out duration-300 transition"
                    enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                    enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    >
                    <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="p-4">
                        <div className="flex items-center">
                            <div className="flex w-0 flex-1 justify-between">
                            <p className="w-0 flex-1 text-sm font-medium text-gray-900">{alertText}</p>
                            <button
                                type="button"
                                className="ml-3 flex-shrink-0 rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                onClick={handleCancelRefresh}
                            >
                                取消刷新
                            </button>
                            </div>
                            <div className="ml-4 flex flex-shrink-0">
                            <button
                                type="button"
                                className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                onClick={() => {
                                setShow(false)
                                }}
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                            </div>
                        </div>
                        </div>
                    </div>
                    </Transition>
                </div>
            </div>
        </>
    )
}

// Retrieve data from backend
function RetrieveData(
    jobs,
    filterStatus, 
    setJobs, 
    setEnableManualRefresh, 
    setManualRefreshBtnText,
    selectedLoc,
    selectedStaff,
    selectedTask,
    dateFilter,
    estimateDate,
    selectedTaskType,
    typeList,
    taskList,
    numJobDisplay,
    setIsLoadingNewData=null,
    locList,
    t
    ) {

        // return if locList length is one
        if (!locList || locList.length == 1) {
            return;
        }
        
        // check for search argument from url 
        var urlParams = new URLSearchParams(window.location.search);
        var search = urlParams.get("search");

        // check filter status
        let organizationID = localStorage.getItem("organizationId");
        var orgUrl = GlobalVars.BACKEND_DOMAIN + "/api/v1/jobs?organizationID=" + organizationID + "&userID=" + localStorage.getItem("userid");
        var url = GlobalVars.BACKEND_DOMAIN + "/api/v1/jobs?organizationID=" + organizationID + "&userID=" + localStorage.getItem("userid");

        // if search argument exists, add to url
        if (search != null) {
            url += "&search=" + search;
        }

        if (t(filterStatus["status"]) != t("所有工作")) {
            url += "&status=" + filterStatus["status"];
        }
        
        if (selectedLoc != undefined && t(selectedLoc["name"]) != t("所有地點")) {
            // retrieve location id by iterating through locList and compare the name 
            let locID = null;
            for (let i = 0; i < locList.length; i++) {
                if (locList[i]["name"] === t(selectedLoc["name"])) {
                    locID = locList[i]["_id"];
                    url += "&location=" + locID;
                    break;
                }
            }
            
            // url += "&location=" + selectedLoc["name"];
        }

        if (selectedStaff != undefined && selectedStaff["name"] != t("所有員工")) {
            url += "&staff=" + encodeURIComponent(selectedStaff["name"]);
        }

        if (selectedTask != undefined && selectedTask["name"] != t("所有要求") && selectedTask["_id"] != undefined) {
            // finding the matching task id
            let taskID = selectedTask["_id"];
            url += "&jobTypeID=" + taskID;
        }

        // add numJobDisplay if not undefined
        if (numJobDisplay != undefined) {
            url += "&numJobDisplay=" + numJobDisplay;
        }

        // add start date and end date 
        if (typeof dateFilter !== 'undefined' && dateFilter != null) {
            if (dateFilter["startDate"] != null) {
                url += "&startDate=" + dateFilter["startDate"];
            }
            if (dateFilter["endDate"] != null) {
                url += "&endDate=" + dateFilter["endDate"];
            }
        }
    
        // add estimate date
        if (estimateDate != null && estimateDate != undefined && estimateDate != "") {
            url += "&estimatedDate=" + estimateDate;
        }

        // add type list 
        if (selectedTaskType != undefined && selectedTaskType["name"] != t("所有類型") && selectedTaskType.hasOwnProperty("_id")) {
            // get task id by iterating through typeList
            let taskID = selectedTaskType["_id"];
            url += "&mainTypeID=" + taskID;
        }

        // get language
        let language = localStorage.getItem("i18nextLng");
        if (language != "zh-TW") {
            url += "&language=" + language;
        }

        // Fetch function with retry logic
        const fetchWithRetry = async (cloudFunctionUrl, originalUrl) => {
            try {
                // Try cloud function first
                const cloudResponse = await fetch(cloudFunctionUrl, {
                    headers: {
                        "Authorization": sessionStorage.getItem("idToken")
                    }  // No auth header for cloud function
                });
                
                if (!cloudResponse.ok) {
                    throw new Error('Cloud function failed');
                }
                
                // console.log("Using cloud function");
                return await cloudResponse.json();
                
            } catch (error) {
                // If cloud function fails, try original URL
                // console.log("Cloud function failed, falling back to backend");
                const backendResponse = await fetch(originalUrl, {
                    headers: {
                        "Authorization": sessionStorage.getItem("idToken")
                    }
                });
                
                if (!backendResponse.ok) {
                    throw new Error('Both endpoints failed');
                }
                
                return await backendResponse.json();
            }
        };

        // replace with cloud function url 
        let cloudFunctionUrl = GlobalVars.CLOUD_FUNCTION_JOBLIST_URL + 
            "?organizationID=" + organizationID + 
            "&userID=" + localStorage.getItem("userid") + 
            "&numJobDisplay=" + numJobDisplay;
        if (language != "zh-TW") {
            cloudFunctionUrl += "&language=" + language;
        }
       
        
        if (url === orgUrl + "&numJobDisplay=" + numJobDisplay + "&language=" + language) {  // use cloud function for generic job search
            // console.log("Using cloud function");
            // Use fetchWithRetry when we want to try cloud function
            fetchWithRetry(cloudFunctionUrl, orgUrl)
                .then(data => {
                    // check if there is no new data
                    if (data["requests"].length === jobs.length) {
                        console.log("no new data");
                    }

                    setJobs(data["requests"]);
                    setManualRefreshBtnText(t("刷新列表"));
                    setEnableManualRefresh(true);

                    // Set isLoadingNewData to false if we got fewer results than requested
                    // This indicates we've reached the end of available data
                    if (data["requests"].length < numJobDisplay) {
                        setIsLoadingNewData(false);
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                    console.log(t("無法取得資料，請稍後再試"));
                    setManualRefreshBtnText(t("刷新列表"));
                    setEnableManualRefresh(true);
                    setIsLoadingNewData(false);
                });
        } else {
            // Use original URL directly
            fetch(url, {
                headers: {
                    "Authorization": sessionStorage.getItem("idToken")
                }
            })
                .then(response => {
                    if (!response.ok) throw new Error("Failed to fetch data");
                    return response.json();
                })
                .then(data => {
                    setJobs(data["requests"]);
                    setManualRefreshBtnText(t("刷新列表"));
                    setEnableManualRefresh(true);
                    setIsLoadingNewData(false);
                })
                .catch(error => {
                    console.error('Error:', error);
                    // alert("無法取得資料，請稍後再試");
                    setJobs([]);
                    setManualRefreshBtnText(t("刷新列表"));
                    setEnableManualRefresh(true);
                    setIsLoadingNewData(false);
                });
        }
}



// batch job change header actin bar
function BatchJobChangeHeader({progressJobHeader, progressJobFooter, setIsBatchChange}) {
    // handle completion of batch job change
    const handleCompleteBatchChange = () => {
        setIsBatchChange(false);
        
        progressJobHeader.current.classList.remove("flex");
        progressJobFooter.current.classList.remove("flex");

        progressJobHeader.current.classList.add("hidden");
        progressJobFooter.current.classList.add("hidden");
    }   

    return (
        <div
            ref={progressJobHeader}
            className="hidden fixed top-0 left-0 w-full bg-gray-50 shadow-md p-4 justify-start items-center z-50 border-b-2 border-gray-300"
        >
            <button
                onClick={handleCompleteBatchChange}
                className='text-indigo-700 font-bold'
            >完成</button>
        </div>
    )
}

// batch job change footer action bar
function BatchJobChangeFooter({progressJobFooter, batchSelectedJobs, batchSelectedStatus}) {

    const submitBtn = useRef(null);
    const [btnText, setBtnText] = useState("確認工作");
    const [btnNumJobs, setBtnNumJobs] = useState("");
    const handleBatchJobChange = () => {
        // return if no job is selected
        if (batchSelectedJobs.length === 0) {
            console.log("no job is selected");
            return;
        }

        // prompt users for confirmation
        var r = window.confirm("確定要更新工作狀態嗎？");
        if (!r) {
            return;
        }

        // based on status update the job status
        var newStatus = '';

        setBtnText("更新中...");
        submitBtn.current.disabled = true;
    
        if (batchSelectedStatus == '新工作' ) {
          newStatus = '待確認';
        } else if (batchSelectedStatus == '待確認' || batchSelectedStatus == '已轉移') {
          newStatus = '未開始';
        } else if (batchSelectedStatus == '未開始') {
          newStatus = '進行中';
        } else if (batchSelectedStatus == '進行中') {
          newStatus = '完成';
        } else if (batchSelectedStatus == '待檢查') {
          newStatus = '完成';
        } else {
          newStatus = '進行中';
        }

        let data = {
            "jobIDs": batchSelectedJobs,
            "status": newStatus,
            "userID": localStorage.getItem("userid"),
            "assignedTo": localStorage.getItem("userid")
        }

        // PUT request to update job status
        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/jobs/progress";
        fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": sessionStorage.getItem("idToken")
            },
            body: JSON.stringify(data),
        }).then(response => response.json())
        .then(data => {
            console.log('Success:', data);
            alert("工作狀態已更新");

            // refresh the page
            window.location.reload();
        }).catch((error) => {
            console.error('Error:', error);
            alert("請求失敗");

            // reset button text and enable button
            setBtnText("確認工作");
            submitBtn.current.disabled = false;
        });

    }

    // update button text based on number of jobs
    useEffect(() => {
        if (batchSelectedJobs.length === 0) {
            setBtnNumJobs("");
            return;
        }
        let text = "(" + batchSelectedJobs.length + ")";
        setBtnNumJobs(text);
    }, [batchSelectedJobs]);

    // update button text based on selected status
    useEffect(() => {
        if (batchSelectedJobs.length == 0) {
            setBtnText("更新工作狀態");
            return;
        }

        if (batchSelectedStatus == '新工作' ) {
            setBtnText("確認工作");
        } else if (batchSelectedStatus == '待確認' || batchSelectedStatus == '已轉移') {
            setBtnText("確認工作");
        } else if (batchSelectedStatus == '未開始') {
            setBtnText("開始工作");
        } else if (batchSelectedStatus == '進行中') {
            setBtnText("完成");
        } else if (batchSelectedStatus == '待檢查') {
            setBtnText("完成");
        } else if (batchSelectedStatus == '完成') {
            setBtnText("重新開始工作");
        } else {
            setBtnText("更新工作狀態");
        }
    }, [batchSelectedStatus, batchSelectedJobs]);

    return (
        <div
            ref={progressJobFooter}
            className="hidden fixed bottom-0 left-0 w-full pb-10 bg-gray-50 shadow-md p-4 justify-start items-center z-50 border-t-2 border-gray-300"
        >
            <button
                ref={submitBtn}
                onClick={handleBatchJobChange} 
                className={` ${batchSelectedJobs.length === 0 ? 'text-gray-500' : 'text-indigo-600'}`}
            >{btnText} {btnNumJobs}</button>
        </div>
    )
}




export default function FullJobList({
    userid=null
}) {
    const { t } = useTranslation();

    // Helper function to find key by value
    function findKeyByValue(t, value) {
        // Get the current translation resources
        const resources = t.store.data;
        const currentLang = t.language;
        console.log(value);
        
        // Search through the translations
        for (const [key, translation] of Object.entries(resources[currentLang].translation)) {
            if (translation === value) {
                return key;
            }
        }
        
        // Return the original value if no key is found
        return value;
    }

    // Utility function to retrieve and parse data from sessionStorage
    function getSessionStorageItem(key, defaultValue) {
        const item = sessionStorage.getItem(key);
        try {
            if (!item) return defaultValue;
            
            const parsedItem = JSON.parse(item);
            
            // Handle translated values in the stored object
            if (parsedItem && typeof parsedItem === 'object') {
                // If the object has a name property that needs translation
                if (parsedItem.name) {
                    const originalKey = findKeyByValue(t, parsedItem.name);
                    if (originalKey !== parsedItem.name) {
                        parsedItem.name = t(originalKey);
                    }
                }
                // If the object has a status property that needs translation 
                if (parsedItem.status) {
                    const originalKey = findKeyByValue(t, parsedItem.status);
                    if (originalKey !== parsedItem.status) {
                        parsedItem.status = t(originalKey);
                    }
                }
                return parsedItem;
            }
            
            return parsedItem;
        } catch (e) {
            return defaultValue;
        }
    }

    // Initial state values
    const initialSelectedLoc = getSessionStorageItem("jobListPreference_selectedLoc", {"name": t("所有地點"), "color": "white"});
    const initialSelectedStaff = getSessionStorageItem("jobListPreference_selectedStaff", {"name": t("所有員工"), "color": "white"});
    const initialSelectedTaskType = getSessionStorageItem("jobListPreference_selectedTaskType", {"name": t("所有類型")});
    const initialSelectedTask = getSessionStorageItem("jobListPreference_selectedTask", {"name": t("所有要求")});
    const initialDateFilter = getSessionStorageItem("jobListPreference_dateFilter", {"startDate": null, "endDate": null});
    const initialEstimateDate = getSessionStorageItem("jobListPreference_estimateDate", null);
    const initialFilterStatus = getSessionStorageItem("jobListPreference_filterStatus", {"status": t("所有工作")});


    const [locList, setLocList] = useState([{
        "name": t("所有地點"),
        "color": "white"
    }]);
    const [selectedLoc, setSelectedLoc] = useState(initialSelectedLoc);
    const [staffList, setStaffList] = useState([{
        "name": t("所有員工"),
        "color": "white"
    }]);
    const [selectedStaff, setSelectedStaff] = useState(initialSelectedStaff);
    const [typeList, setTypeList] = useState([{"name": t("所有類型")}])
    const [selectedTaskType, setSelectedTaskType] = useState(initialSelectedTaskType);
    const [taskList, setTaskList] = useState([{"name": t("所有要求")}])
    const [selectedTask, setSelectedTask] = useState(initialSelectedTask);
    const [dateFilter, setDateFilter] = useState(initialDateFilter);
    const [estimateDate, setEstimateDate] = useState(initialEstimateDate);
    const [filterStatus, setFilterStatus] = useState(initialFilterStatus);
    const [numJobDisplay, setNumJobDisplay] = useState(20);

    return (
        <div
            className=""
        >
            <FullJobListComp 
                userid={userid}
                locList={locList}
                setLocList={setLocList}
                selectedLoc={selectedLoc}
                setSelectedLoc={setSelectedLoc}
                staffList={staffList}
                setStaffList={setStaffList}
                selectedStaff={selectedStaff}
                setSelectedStaff={setSelectedStaff}
                typeList={typeList}
                setTypeList={setTypeList}
                selectedTaskType={selectedTaskType}
                setSelectedTaskType={setSelectedTaskType}
                taskList={taskList}
                setTaskList={setTaskList}
                selectedTask={selectedTask}
                setSelectedTask={setSelectedTask}
                dateFilter={dateFilter}
                setDateFilter={setDateFilter}
                estimateDate={estimateDate}
                setEstimateDate={setEstimateDate}
                filterStatus={filterStatus}
                setFilterStatus={setFilterStatus}
                numJobDisplay={numJobDisplay}
                setNumJobDisplay={setNumJobDisplay}
            />
            
        </div>
    )
}

// Store existing scroll position, filters and number of jobs displayed to sessionStorage
function StoreExistingPagePreference(
    filterStatus,
    selectedLoc,
    selectedStaff,
    selectedTask,
    dateFilter,
    estimateDate,
    selectedTaskType,
    numJobDisplay
) {
    
    // console.log("Storing existing page preference")
    sessionStorage.setItem("jobListPreference_filterStatus", JSON.stringify(filterStatus));
    sessionStorage.setItem("jobListPreference_selectedLoc", JSON.stringify(selectedLoc));
    sessionStorage.setItem("jobListPreference_selectedStaff", JSON.stringify(selectedStaff));
    sessionStorage.setItem("jobListPreference_selectedTask", JSON.stringify(selectedTask));
    sessionStorage.setItem("jobListPreference_dateFilter", JSON.stringify(dateFilter));
    sessionStorage.setItem("jobListPreference_estimateDate", JSON.stringify(estimateDate));
    sessionStorage.setItem("jobListPreference_selectedTaskType", JSON.stringify(selectedTaskType));
    sessionStorage.setItem("jobListPreference_numJobDisplay", numJobDisplay);
    sessionStorage.setItem("jobListPreference_scrollY", window.scrollY);
}


