import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { useTranslation } from 'react-i18next'

export default function LangSwitcher() {
    const { t, i18n } = useTranslation();
    
    const handleLangChange = (langCode) => {
        i18n.changeLanguage(langCode);
        localStorage.setItem('i18nextLng', langCode);
        // reload page to ensure all translations are updated
        window.location.reload();
    }

    const langMap = {
        "zh-TW": "繁體中文",
        "zh-CN": "简体中文",
        "en-US": "English"
    }

    return (
        <Menu as="div" className="relative inline-block text-left lang-switcher" >
            <div>
                <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50">
                    {langMap[i18n.language] || langMap["zh-TW"]}
                    <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400" />
                </MenuButton>
            </div>

            <MenuItems
                transition
                className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
                <div className="py-1">
                    <MenuItem>
                        <button
                            onClick={() => handleLangChange("zh-TW")}
                            className="block w-full px-4 py-2 text-left text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                        >
                            繁體中文
                        </button>
                    </MenuItem>
                    <MenuItem>
                        <button
                            onClick={() => handleLangChange("zh-CN")}
                            className="block w-full px-4 py-2 text-left text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                        >
                            简体中文
                        </button>
                    </MenuItem>
                    <MenuItem>
                        <button
                            onClick={() => handleLangChange("en-US")}
                            className="block w-full px-4 py-2 text-left text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                        >
                            English
                        </button>
                    </MenuItem>
                </div>
            </MenuItems>
        </Menu>
    )
}
