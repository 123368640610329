import { useState, useRef, useEffect, useMemo } from 'react'
import { PaperClipIcon } from '@heroicons/react/24/outline'
import GlobalVars from '../../../../../../Config'

export default function NewRemark({ itemID, onRemarkAdded }) {
    const [remarks, setRemarks] = useState('')
    const [attachments, setAttachments] = useState([])
    const [submitting, setSubmitting] = useState(false)
    const fileInputRef = useRef(null)
    const [users, setUsers] = useState([])
    const [showMentionList, setShowMentionList] = useState(false)
    const [mentionFilter, setMentionFilter] = useState('')
    const [cursorPosition, setCursorPosition] = useState(null)
    const textAreaRef = useRef(null)
    const [selectedIndex, setSelectedIndex] = useState(0)

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files)
        if (files.length === 0) return

        // Store the file objects directly
        setAttachments(prev => [...prev, ...files.map(file => ({
            file,
            name: file.name,
            size: file.size
        }))])
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!remarks.trim()) return

        setSubmitting(true)
        try {
            const projectID = window.location.pathname.split('/').pop()
            const attachmentKeys = []
            const failedUploads = []

            // Upload files first if there are any
            for (const attachment of attachments) {
                try {
                    console.log(`Starting upload for file: ${attachment.name}`)
                    // Get presigned URL for upload
                    const presignResponse = await fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/project/signed-url`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': sessionStorage.getItem('idToken')
                        },
                        body: JSON.stringify({
                            fileNameList: [attachment.name],
                            fileTypeList: [attachment.file.type],
                            organizationID: localStorage.getItem('organizationId'),
                            projectID: projectID,
                            itemID: itemID
                        })
                    })

                    if (!presignResponse.ok) {
                        throw new Error(`Failed to get presigned URL for ${attachment.name}`)
                    }

                    const { urls, key } = await presignResponse.json()

                    // Upload the file to S3
                    const uploadResponse = await fetch(urls[0], {
                        method: 'PUT',
                        body: attachment.file,
                        headers: {
                            'Content-Type': attachment.file.type
                        }
                    })

                    if (uploadResponse.ok) {
                        console.log(`Successfully uploaded file: ${attachment.name}`)
                        attachmentKeys.push(key)
                    } else {
                        throw new Error(`Failed to upload file: ${attachment.name}`)
                    }
                } catch (error) {
                    console.error(`Error uploading file ${attachment.name}:`, error)
                    failedUploads.push(attachment.name)
                }
            }

            // If there were any failed uploads, update the attachments state to remove them
            if (failedUploads.length > 0) {
                setAttachments(prev => prev.filter(att => !failedUploads.includes(att.name)))
                // Optionally show an error message to the user
                alert(`Failed to upload: ${failedUploads.join(', ')}`)
                setSubmitting(false)
                return // Don't proceed with remark submission if there were failed uploads
            }

            // Submit the remark with the uploaded file keys
            const url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/board/item-remarks`
            
            const payload = {
                itemID: itemID,
                remarks: remarks,
                attachments: attachmentKeys,
                user: localStorage.getItem('userid')
            }

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            })

            if (response.ok) {
                setRemarks('')
                setAttachments([])
                if (onRemarkAdded) {
                    onRemarkAdded()
                }
                // Reset the file input
                if (fileInputRef.current) {
                    fileInputRef.current.value = ''
                }
            } else {
                const errorData = await response.json()
                console.error('Error response:', errorData)
                throw new Error('Failed to submit remark')
            }
        } catch (error) {
            console.error('Error adding remark:', error)
            alert('Failed to submit remark. Please try again.')
            // Also reset the file input on error
            if (fileInputRef.current) {
                fileInputRef.current.value = ''
            }
        } finally {
            setSubmitting(false)
        }
    }

    // Fetch users when component mounts
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await fetch(
                    `${GlobalVars.BACKEND_DOMAIN}/api/v1/users?organizationID=${localStorage.getItem('organizationId')}`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                )
                if (response.ok) {
                    const data = await response.json()
                    // Ensure we're setting an array of users
                    setUsers(Array.isArray(data) ? data : data.users || [])
                }
            } catch (error) {
                console.error('Error fetching users:', error)
                setUsers([]) // Set empty array on error
            }
        }
        fetchUsers()
    }, [])

    // Filter users based on mention input
    const filteredUsers = useMemo(() => {
        if (!Array.isArray(users)) return []
        if (!mentionFilter) return users
        return users.filter(user => 
            user?.displayName?.toLowerCase().includes(mentionFilter.toLowerCase())
        )
    }, [users, mentionFilter])

    // Reset selected index when filtered users change
    useEffect(() => {
        setSelectedIndex(0)
    }, [filteredUsers])

    const handleTextAreaChange = (e) => {
        const value = e.target.value
        setRemarks(value)

        // Get cursor position
        const cursorPos = e.target.selectionStart
        const textBeforeCursor = value.slice(0, cursorPos)
        const lastAtSymbol = textBeforeCursor.lastIndexOf('@')

        if (lastAtSymbol !== -1) {
            const textAfterAt = textBeforeCursor.slice(lastAtSymbol + 1)
            const hasSpaceAfterAt = textAfterAt.includes(' ')

            if (!hasSpaceAfterAt) {
                setMentionFilter(textAfterAt)
                setShowMentionList(true)
                setCursorPosition({
                    top: e.target.offsetTop + 20, // Adjust these values based on your layout
                    left: e.target.offsetLeft + 10
                })
                return
            }
        }

        setShowMentionList(false)
    }

    const handleMentionSelect = (user) => {
        const cursorPos = textAreaRef.current.selectionStart
        const textBeforeCursor = remarks.slice(0, cursorPos)
        const lastAtSymbol = textBeforeCursor.lastIndexOf('@')
        const textAfterCursor = remarks.slice(cursorPos)

        const newText = 
            remarks.slice(0, lastAtSymbol) + 
            `@${user.displayName} ` + 
            textAfterCursor

        setRemarks(newText)
        setShowMentionList(false)
        textAreaRef.current.focus()
    }

    const handleKeyDown = (e) => {
        if (!showMentionList || filteredUsers.length === 0) return

        switch (e.key) {
            case 'ArrowDown':
                e.preventDefault()
                setSelectedIndex(prev => 
                    prev < filteredUsers.length - 1 ? prev + 1 : prev
                )
                break
            case 'ArrowUp':
                e.preventDefault()
                setSelectedIndex(prev => 
                    prev > 0 ? prev - 1 : prev
                )
                break
            case 'Enter':
                if (showMentionList) {
                    e.preventDefault()
                    handleMentionSelect(filteredUsers[selectedIndex])
                }
                break
            case 'Escape':
                setShowMentionList(false)
                break
        }
    }

    // Add a function to remove individual attachments
    const removeAttachment = (fileName) => {
        setAttachments(prev => prev.filter(att => att.name !== fileName))
        // Reset file input if all files are removed
        if (fileInputRef.current && attachments.length <= 1) {
            fileInputRef.current.value = ''
        }
    }

    return (
        <form onSubmit={handleSubmit} className="space-y-4 relative">
            <div>
                <textarea
                    ref={textAreaRef}
                    value={remarks}
                    onChange={handleTextAreaChange}
                    onKeyDown={handleKeyDown}
                    placeholder="輸入備註..."
                    className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    rows={4}
                />
                {showMentionList && filteredUsers.length > 0 && (
                    <div 
                        className="absolute z-10 w-64 max-h-48 overflow-y-auto bg-white border border-gray-200 rounded-md shadow-lg"
                        style={{
                            top: cursorPosition?.top || '100%',
                            left: cursorPosition?.left || 0
                        }}
                    >
                        {filteredUsers.map((user, index) => (
                            <div
                                key={user.id}
                                className={`px-4 py-2 cursor-pointer ${
                                    index === selectedIndex 
                                        ? 'bg-indigo-50 text-indigo-600' 
                                        : 'hover:bg-gray-100'
                                }`}
                                onClick={() => handleMentionSelect(user)}
                            >
                                {user.displayName}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className="flex justify-between items-center">
                <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    className="hidden"
                    multiple
                />
                <div className="flex items-center gap-4">
                    <button
                        type="button"
                        onClick={() => fileInputRef.current?.click()}
                        className="inline-flex items-center text-gray-700 hover:text-gray-900"
                    >
                        <PaperClipIcon className="h-5 w-5 mr-1" />
                        <span>附加檔案</span>
                    </button>
                    {attachments.length > 0 && (
                        <div className="text-sm text-gray-500">
                            已選擇 {attachments.length} 個檔案
                        </div>
                    )}
                </div>
                <button
                    type="submit"
                    disabled={submitting || !remarks.trim()}
                    className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50"
                >
                    {submitting ? '提交中...' : '新增備註'}
                </button>
            </div>
            {attachments.length > 0 && (
                <div className="flex flex-wrap gap-2 mt-2">
                    {attachments.map((file, idx) => (
                        <div
                            key={idx}
                            className="inline-flex items-center gap-1 text-xs text-gray-600 bg-gray-100 px-2 py-1 rounded-md"
                        >
                            <PaperClipIcon className="h-3 w-3" />
                            {file.name}
                            <button
                                type="button"
                                onClick={() => removeAttachment(file.name)}
                                className="ml-1 text-gray-500 hover:text-gray-700"
                            >
                                ×
                            </button>
                        </div>
                    ))}
                </div>
            )}
        </form>
    )
}