import { useTranslation } from "react-i18next";

export default function Payment({ currentPlan }) {
    const { t } = useTranslation();
    
    return (
        <div className="px-4 py-5 sm:p-6">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
                {t("付款方案")}
            </h3>
            <div className="mt-2">
                <p className="text-sm text-gray-500">
                    {t("目前方案")}: {currentPlan}
                </p>
                <button 
                    className="mt-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                >
                    {t("升級")}
                </button>
            </div>
            {/* ... rest of the component ... */}
        </div>
    );
}