import { useEffect, useState, useRef } from "react";
import ApplicationShell from "../../Components/ApplicationShell";
import PageHeading from "../../Components/PageHeading/PageHeading";
import GlobalVars from "../../Config";
import { 
  ChevronDownIcon,
  EllipsisVerticalIcon,
  ArrowPathIcon
} from '@heroicons/react/20/solid';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';

import SelfDisappearNotification from '../../Components/SelfDisappearNotification';
import NewDocDropdown from './Components/NewDocDropdown';


export default function KnowledgeBase() {
    // set page title 
    document.title = "知識庫";

    const [showNotification, setShowNotification] = useState(false);

    return (
        <>
          <ApplicationShell>
              <PageHeading title="知識庫">
                  <div className="ml-3">
                      <NewDocDropdown />
                  </div>
              </PageHeading>
              <DocumentList setShowNotification={setShowNotification} />
          </ApplicationShell>
          <SelfDisappearNotification 
              show={showNotification}
              setShow={setShowNotification}
              title="成功更新知識庫!"
              body="文檔已經更新到AI知識庫裡."
          />
        </>
    );
}

// show list of documents
function DocumentList({setShowNotification}) {
    // query all documents
    const [docList, setDocList] = useState([]);
    useEffect(() => {
        fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/knowledge-base/get-available-doc?organizationID=" + localStorage.getItem("organizationId"))
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                setDocList(data['documents']);
            })
    }, []);

    // query for user name with user id
    useEffect(() => {
      // check if the document list is empty
      if (docList.length === 0) {
          return;
      }

      // get all user ids
      let userIds = docList.map((item) => item.userID);

      // return if there are no user ids
      if (userIds.length === 0) {
          return;
      }

    }, [docList]);

    // handle document deletion
    const handleDeleteDocument = (e) => {
        // prompt confirmation 
        if (!window.confirm("確定要刪除此文章嗎？")) {
            return;
        }

        let docId = e.target.getAttribute("data-docId");
        fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/knowledge-base/documents/manage", {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                documentID: docId,
                organizationID: localStorage.getItem("organizationId")
            })
        })
            .then((res) => res.json())
            .then((data) => {
                // remove the deleted document from the list
                let newDocList = docList.filter((item) => item._id !== docId);
                
                setDocList(newDocList);

                let url = GlobalVars.ASSISTANT_DOMAIN + "/api/v1/knowledge-base/delete-document";
                let submitData = {
                  docId: [docId],
                  organizationID: localStorage.getItem("organizationId")
                };

                fetch(url, {
                  method: "DELETE",
                  headers: {
                    "Content-Type": "application/json"
                  },
                  body: JSON.stringify(submitData)
                })
                  .then((res) => {
                    if (res.status === 200) {
                      alert("成功刪除文檔並從知識庫移除");
                    } else {
                      alert("從知識庫移除失敗");
                    }
                    
                  })
                  .catch((err) => {
                    alert("從知識庫移除失敗");
                    console.log(err)
                  })

            })
            .catch((err) => {
                console.log(err);
                alert("刪除失敗");
            })
    }


    return (
        <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 pb-[300px]">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    標題
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    內容
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    建立日期
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 whitespace-nowrap">
                    創建者
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {docList.map((item) => (
                  <tr key={item._id}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                      <a
                        href={"/knowledge-base/create-new-document?docId=" + item._id}
                        className=" hover:text-indigo-600 duration-300"
                      >
                        {item.title}
                      </a>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.content}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.createdAt}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.userName != "" ? item.userName : "加載中..."}</td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0 flex items-center">
                      <a href={"/knowledge-base/create-new-document?docId=" + item._id} className="text-indigo-600 hover:text-indigo-900">
                        修改<span className="sr-only">, {item.name}</span>
                      </a>
                      <button 
                        data-docId={item._id} 
                        className="mx-2 text-indigo-600 hover:text-indigo-900"
                        onClick={handleDeleteDocument}
                    >
                        刪除
                      </button>
                      <OtherActions 
                        docId={item._id} 
                        inKnowledgeBase={item.inKnowledgeBase ? true:false} 
                        setShowNotification={setShowNotification}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
}

// further action dropdown
function OtherActions({docId, inKnowledgeBase, setShowNotification}) {
  const [isLoading, setIsLoading] = useState(false);
  // const handleAddingToKnowledgeBase = () => {
  //   setIsLoading(true);
  //   let url = GlobalVars.ASSISTANT_DOMAIN + "/api/v1/knowledge-base/add-document";
  //   let data = {
  //     docId: [docId],
  //     organizationID: localStorage.getItem("organizationId")
  //   };

  //   fetch(url, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json"
  //     },
  //     body: JSON.stringify(data)
  //   })
  //     .then((res) => {
  //       setIsLoading(false);
  //       if (res.status === 200) {
  //         alert("成功新增至知識庫");
  //       } else {
  //         alert("新增至知識庫失敗");
  //       }
        
  //     })
  //     .catch((err) => {
  //       alert("新增至知識庫失敗");
  //       console.log(err)
  //     })

  // }

  const handleRefreshKnowledgeBase = () => {
     let url = GlobalVars.ASSISTANT_DOMAIN + "/api/v1/knowledge-base/create-embedding";

    setIsLoading(true);
    let data = {
      docIDs: [docId],
      organizationID: localStorage.getItem("organizationId")
    };

    fetch(url, {
      method: "POST", 
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          setShowNotification(true);
        } else {
          setShowNotification(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setShowNotification(false);
        console.log(err);
      });
  }

  const handleRemoveFromKnowledgeBase = () => {
    setIsLoading(true);
    let url = GlobalVars.ASSISTANT_DOMAIN + "/api/v1/knowledge-base/delete-document";
    let data = {
      docId: [docId],
      organizationID: localStorage.getItem("organizationId")
    };

    fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          alert("成功從知識庫移除");
        } else {
          alert("從知識庫移除失敗");
        }
        
      })
      .catch((err) => {
        alert("從知識庫移除失敗");
        console.log(err)
      })

  }


  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <MenuButton className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
          <span className="sr-only">Open options</span>
          {!isLoading ? (
            <EllipsisVerticalIcon aria-hidden="true" className="h-5 w-5" />
          ) : (
            <ArrowPathIcon aria-hidden="true" className="h-5 w-5 animate-spin" />
          )}
        </MenuButton>
      </div>

      <MenuItems
        transition
        className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="py-1">
          {!inKnowledgeBase && (
          <MenuItem>
            <a
              
              onClick={handleRefreshKnowledgeBase}
              className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
            >
              新增至知識庫
            </a>
          </MenuItem>
          )}
          {inKnowledgeBase && (
            <>
              <MenuItem>
                <a
                  onClick={handleRefreshKnowledgeBase}
                  className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                >
                  刷新知識庫
                </a>
              </MenuItem>
              <MenuItem>
                <a
                  onClick={handleRemoveFromKnowledgeBase}
                  className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                >
                  從知識庫移除
                </a>
              </MenuItem>
            </>
          )}
        </div>
        {/* <div className="py-1">
          <MenuItem>
            <a
              href="#"
              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
            >
              Archive
            </a>
          </MenuItem>
          <MenuItem>
            <a
              href="#"
              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
            >
              Move
            </a>
          </MenuItem>
        </div>
        <div className="py-1">
          <MenuItem>
            <a
              href="#"
              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
            >
              Share
            </a>
          </MenuItem>
          <MenuItem>
            <a
              href="#"
              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
            >
              Add to favorites
            </a>
          </MenuItem>
        </div>
        <div className="py-1">
          <MenuItem>
            <a
              href="#"
              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
            >
              Delete
            </a>
          </MenuItem>
        </div> */}
      </MenuItems>
    </Menu>
  )
}
