import { ChevronDownIcon } from '@heroicons/react/16/solid'
import { useEffect } from 'react';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function PageTab({ tabs, setTabs }) {
    const handleTabClick = (tab) => {
        setTabs(tabs.map((t) => ({ ...t, current: t.name === tab })));
    }
    return (
        <div>
            <label htmlFor="tab-select" className="block sm:hidden text-sm font-medium text-gray-700 mb-2">
                選擇頁面
            </label>
        <div className="grid grid-cols-1 sm:hidden">
            
            {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
            <select
                value={tabs.find((tab) => tab.current).name}
                aria-label="Select a tab"
                className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-2 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600"
                onChange={(e) => {
                    handleTabClick(e.target.value)
                }}
            >
                {tabs.map((tab) => (
                    <option key={tab.name} value={tab.name}>{tab.name}</option>
                ))}
            </select>
            <ChevronDownIcon
                aria-hidden="true"
                className="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end fill-gray-500"
            />
        </div>
        <div className="hidden sm:block">
            <div className="border-b border-gray-200">
            <nav aria-label="Tabs" className="-mb-px flex space-x-8">
                {tabs.map((tab) => (
                <button
                    key={tab.name}
                    aria-current={tab.current ? 'page' : undefined}
                    onClick={() => handleTabClick(tab.name)}
                    className={classNames(
                    tab.current
                        ? 'border-indigo-500 text-indigo-600'
                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium',
                    )}
                >
                    {tab.name}
                </button>
                ))}
            </nav>
            </div>
        </div>
        </div>
    )
}
