import { useState, useEffect, useRef, Fragment } from 'react';
import { 
    PaperAirplaneIcon, 
    PlusIcon, 
    DocumentIcon,
    PhotoIcon,
    VideoCameraIcon,
    ArrowPathIcon, 
    CheckCircleIcon,
    XMarkIcon
} from '@heroicons/react/24/solid';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import GlobalVars from '../../Config';
import TableDialog from './components/TableDialog';
import TextareaAutosize from 'react-textarea-autosize';

const tools = {
    "KnowledgeBaseSearch": {
        "endPoint": "/api/v1/tools/knowledge-base/search",
        "method": "POST"
    }
}

const formatToolCall = (toolName, parameters) => {
    const toolInfo = tools[toolName];
    if (!toolInfo) return null;
    
    return `🔧 Using tool: ${toolName}\n\`\`\`json\n${JSON.stringify(parameters, null, 2)}\n\`\`\``;
};

export default function AIChat() {
    const messageContainerRef = useRef(null);
    const inputRef = useRef(null);
    const fileInputRef = useRef(null);
    const [messages, setMessages] = useState([
        // Initial welcome message
        { role: 'assistant', content: '你好！今日有什麼需要我幫忙的嗎？' }
    ]);
    const [inputMessage, setInputMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [toolCall, setToolCall] = useState(null);
    const [isTableDialogOpen, setIsTableDialogOpen] = useState(false);
    const [selectedTable, setSelectedTable] = useState('');
    const [isDragging, setIsDragging] = useState(false);
    const [dragCounter, setDragCounter] = useState(0);

    // Helper function to extract text from nodes
    const extractText = (node) => {
        if (!node) return '';
        if (node.type === 'text') return node.value;
        if (node.children) return node.children.map(child => extractText(child)).join('');
        return '';
    };

    // Move TableComponent here so it has access to the state setters
    const TableComponent = ({ node, ...props }) => {
        return (
            <div className="space-y-2">
                <table {...props} className="min-w-full divide-y divide-gray-300 dark:divide-gray-600" />
                <div className="flex justify-end">
                    <button
                        onClick={() => {
                            setSelectedTable(node);
                            setIsTableDialogOpen(true);
                        }}
                        className="inline-flex items-center gap-1 text-xs text-indigo-600 dark:text-indigo-400 hover:text-indigo-500 dark:hover:text-indigo-300"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                            <path d="M10 12.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z" />
                            <path fillRule="evenodd" d="M.664 10.59a1.651 1.651 0 010-1.186A10.004 10.004 0 0110 3c4.257 0 7.893 2.66 9.336 6.41.147.381.146.804 0 1.186A10.004 10.004 0 0110 17c-4.257 0-7.893-2.66-9.336-6.41zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
                        </svg>
                        查看完整表格
                    </button>
                </div>
            </div>
        );
    };

    useEffect(() => {
        if (messageContainerRef.current) {
            messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
        }
    }, [messages]);

    // Add this useEffect near your other useEffect hooks
    useEffect(() => {
        if (!isLoading && inputRef.current) {
            // Small timeout to ensure DOM is ready
            setTimeout(() => {
                inputRef.current?.focus();
            }, 100);
        }
    }, [isLoading]);

    const handleSubmitNonStream = async (e) => {
        e.preventDefault();
        if ((!inputMessage.trim() && !hasFile) || isLoading) return;

        setIsLoading(true);

        let newMessages;
        if (hasFile) {
            // Modify the last message to include both file and text
            newMessages = messages.map((msg, index) => {
                if (index === messages.length - 1) {
                    return {
                        ...msg,
                        parts: [
                            ...msg.parts,
                            {
                                text: inputMessage.trim()
                            }
                        ]
                    };
                }
                return msg;
            });
        } else {
            // Normal text message flow
            newMessages = [...messages, { role: 'USER', content: inputMessage.trim() }];
        }

        setMessages(newMessages);
        setInputMessage('');

        if (messageContainerRef.current) {
            messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
        }

        try {
            // Convert chat history to GCP format
            const formattedMessages = newMessages.map(msg => {
                if (msg.parts) {
                    return {
                        role: msg.role,
                        parts: msg.parts
                    };
                }
                return {
                    role: msg.role,
                    parts: [{
                        text: msg.content
                    }]
                };
            });

            const response = await fetch(`${GlobalVars.AGENT_DOMAIN}/api/v1/assistant`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${sessionStorage.getItem('idToken')}`
                },
                body: JSON.stringify({
                    "messages": formattedMessages,
                    "userID": localStorage.getItem('userid')
                })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            // setMessages(prev => [...prev, { role: 'MODEL', content: data.response }]);
            if (data.response.functionCall) {
                const toolMessage = formatToolCall(
                    data.response.functionCall.name, 
                    data.response.functionCall.args
                );
                
                setMessages(prev => [...prev, 
                    { role: 'assistant', content: toolMessage },
                    { role: 'assistant', content: '正在搜尋資料...' }
                ]);
                
                // Make the tool API call
                const toolResponse = await fetch(
                    tools[data.response.functionCall.name].method === 'GET'
                        ? `${GlobalVars.AGENT_DOMAIN}${tools[data.response.functionCall.name].endPoint}?${new URLSearchParams({...data.response.functionCall.args, organizationID: localStorage.getItem('organizationID')})}`
                        : `${GlobalVars.AGENT_DOMAIN}${tools[data.response.functionCall.name].endPoint}`,
                    {
                        method: tools[data.response.functionCall.name].method,
                        ...(tools[data.response.functionCall.name].method === 'POST' && {
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                ...data.response.functionCall.args,
                                organizationID: localStorage.getItem('organizationId')
                            })
                        })
                    }
                );
                const toolData = await toolResponse.json();

                // Get updated messages including the tool call
                const updatedMessages = [
                    ...formattedMessages,
                    {
                        role: "MODEL",
                        parts: [{
                            functionCall: {
                                name: data.response.functionCall.name,
                                args: data.response.functionCall.args
                            }
                        }]
                    },
                    {
                        role: "USER", 
                        parts: [{
                            functionResponse: {
                                name: data.response.functionCall.name,
                                response: {
                                    name: data.response.functionCall.name,
                                    content: toolData
                                }
                            }
                        }]
                    }
                ];

                // Make second call to Gemini with complete history
                const secondResponse = await fetch(`${GlobalVars.AGENT_DOMAIN}/api/v1/assistant`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `${sessionStorage.getItem('idToken')}`
                    },
                    body: JSON.stringify({
                        messages: updatedMessages
                    })
                });

                const secondData = await secondResponse.json();
                setMessages(prev => [...prev, 
                    { role: 'MODEL', content: secondData.response.text }
                ]);
            } else {
                console.log(data.response);
                if (typeof data.response === 'string') {
                    setMessages(prev => [...prev, { role: 'MODEL', content: data.response }]);
                } else if (!data.response.text) {
                    setMessages(prev => [...prev, { role: 'MODEL', content: '對不起，我目前無法回應，請稍後再試。' }]);
                } else {
                    setMessages(prev => [...prev, { role: 'MODEL', content: data.response.text }]);
                }
            }

            // scroll to the bottom
            if (messageContainerRef.current) {
                messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
            }

        } catch (error) {
            console.error('Error:', error);
            setMessages(prev => [...prev, { role: 'MODEL', content: '對不起, 我遇到問題了, 請稍後再試.' }]);
        } finally {
            setIsLoading(false);

            if (hasFile) {
                setHasFile(false);
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!inputMessage.trim() || isLoading) return;

        setIsLoading(true);
        const newMessages = [...messages, { role: 'USER', content: inputMessage.trim() }];
        setMessages(newMessages);
        setInputMessage('');

        if (messageContainerRef.current) {
            messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
        }

        try {
            const response = await fetch(`${GlobalVars.AGENT_DOMAIN}/api/v1/assistant`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'text/event-stream',
                },
                body: JSON.stringify({
                    user_messages: newMessages
                })
            });

            setMessages(prev => [...prev, { role: 'MODEL', content: '' }]);

            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            
            let buffer = '';
            let accumulatedContent = '';

            while (true) {
                const { done, value } = await reader.read();
                
                if (done) {
                    // Process any remaining data in the buffer
                    if (buffer) {
                        const content = processSSEMessage(buffer);
                        if (content) {
                            accumulatedContent += content;
                            updateUIWithContent(accumulatedContent, setMessages);
                        }
                    }
                    
                    // Use callback form of setState to ensure focus happens after state update
                    setIsLoading(false);
                    break;
                }

                // Decode the chunk and add to buffer
                const chunk = decoder.decode(value, { stream: true });
                buffer += chunk;

                // Find complete SSE messages
                let newlineIndex;
                while ((newlineIndex = buffer.indexOf('\n\n')) !== -1) {
                    const message = buffer.slice(0, newlineIndex);
                    buffer = buffer.slice(newlineIndex + 2);

                    const content = processSSEMessage(message);
                    if (content) {
                        accumulatedContent += content;
                        updateUIWithContent(accumulatedContent, setMessages);
                    }
                }
            }
        } catch (error) {
            console.error('Error:', error);
            setIsLoading(false);
            inputRef.current?.focus();
        }
    };

    // Helper function to process SSE messages
    const processSSEMessage = (message) => {
        const lines = message.split('\n');
        let content = '';
        
        for (const line of lines) {
            if (line.startsWith('data:')) {
                // Remove 'data:' prefix and preserve the rest exactly as is
                content += line.slice(5);
            } else if (line.trim() !== '') {
                // If it's not empty and doesn't start with 'data:', 
                // it's probably continuation of previous line
                content += '\n' + line;
            }
        }
        
        return content;
    };

    // Helper function to update UI with new content
    const updateUIWithContent = (content, setMessages) => {
        setMessages(prev => {
            const updatedMessages = [...prev];
            const lastMessage = updatedMessages[updatedMessages.length - 1];
            if (lastMessage && lastMessage.role === 'MODEL') {
                lastMessage.content = content;
            }
            return updatedMessages;
        });
    };

    const handleNewChat = () => {
        setMessages([
            { role: 'MODEL', content: '你好！今日有什麼需要我幫忙的嗎？' }
        ]);
        setInputMessage('');
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };

    const [uploadingFile, setUploadingFile] = useState(false);
    const [hasFile, setHasFile] = useState(false);
    const validFileTypes = [
        'application/pdf',
        'audio/mpeg',
        'audio/mp3',
        'audio/wav',
        'image/png',
        'image/jpeg',
        'image/webp',
        'text/plain',
        'video/mov',
        'video/mpeg',
        'video/mp4',
        'video/mpg',
        'video/avi',
        'video/wmv',
        'video/mpegps',
        'video/flv'
    ];

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setUploadingFile(true);

            // check if the file type is valid
            if (!validFileTypes.includes(file.type)) {
                console.error('Invalid file type');
                setUploadingFile(false);
                return;
            }
            
            try {
                // Get signed URL from backend
                const response = await fetch(`${GlobalVars.AGENT_DOMAIN}/api/v1/file/signed-url`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': sessionStorage.getItem('idToken')
                    },
                    body: JSON.stringify({
                        fileName: file.name,
                        fileType: file.type
                    })
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const resp = await response.json();
                
                // Upload file to signed URL
                const uploadResponse = await fetch(resp.url, {
                    method: 'PUT',
                    body: file,
                    headers: {
                        'Content-Type': file.type
                    }
                });

                if (!uploadResponse.ok) {
                    throw new Error(`HTTP error! status: ${uploadResponse.status}`);
                } else {
                    console.log("File uploaded successfully");
                }


                // Add message with file info
                setMessages(prev => [...prev, {
                    role: 'USER',
                    parts: [
                        {
                            fileData: {
                                fileUri: resp.fileName,
                                mimeType: file.type
                            }
                        }
                    ]
                }]);

                setHasFile(true);

                // set inputRef focus
                inputRef.current?.focus();

            } catch (error) {
                console.error('Error uploading file:', error);
                
            } finally {
                // reset file input
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
                setUploadingFile(false);
            }
            
        }
    };

    const GetFileContent = async (fileUri) => {
        const response = await fetch(`${GlobalVars.AGENT_DOMAIN}/api/v1/file/signed-url?fileID=${fileUri}`, {
            method: 'GET',
            headers: {
                'Authorization': sessionStorage.getItem('idToken')
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        
        // open the url in a new tab
        window.open(data.url, '_blank');
    }

    const handleRemoveFile = () => {
        // Clear file selection
        setSelectedFile(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }

        // Reset file upload state
        setHasFile(false);

        // Remove the last message if it contains file data
        setMessages(prev => {
            const lastMessage = prev[prev.length - 1];
            if (lastMessage?.parts?.some(part => part.fileData)) {
                return prev.slice(0, -1);
            }
            return prev;
        });

        // Set focus back to input
        inputRef.current?.focus();
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        
        setDragCounter(prev => {
            const newCount = prev + 1;
            if (newCount === 1) {
                setIsDragging(true);
            }
            return newCount;
        });
        
        if (e.dataTransfer) {
            e.dataTransfer.dropEffect = 'copy';
        }
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        
        setDragCounter(prev => {
            const newCount = prev - 1;
            if (newCount === 0) {
                setIsDragging(false);
            }
            return newCount;
        });
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer) {
            e.dataTransfer.dropEffect = 'copy';
        }
    };

    const handleDrop = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        setDragCounter(0); // Reset counter on drop
        
        const file = e.dataTransfer.files[0];
        if (!file) return;

        if (!validFileTypes.includes(file.type)) {
            console.error('Invalid file type');
            return;
        }

        setUploadingFile(true);
        
        try {
            // Get signed URL from backend
            const response = await fetch(`${GlobalVars.AGENT_DOMAIN}/api/v1/file/signed-url`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': sessionStorage.getItem('idToken')
                },
                body: JSON.stringify({
                    fileName: file.name,
                    fileType: file.type
                })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const resp = await response.json();
            
            // Upload file to signed URL
            const uploadResponse = await fetch(resp.url, {
                method: 'PUT',
                body: file,
                headers: {
                    'Content-Type': file.type
                }
            });

            if (!uploadResponse.ok) {
                throw new Error(`HTTP error! status: ${uploadResponse.status}`);
            }

            // Add message with file info
            setMessages(prev => [...prev, {
                role: 'USER',
                parts: [
                    {
                        fileData: {
                            fileUri: resp.fileName,
                            mimeType: file.type
                        }
                    }
                ]
            }]);

            setHasFile(true);
            inputRef.current?.focus();

        } catch (error) {
            console.error('Error uploading file:', error);
        } finally {
            setUploadingFile(false);
        }
    };

    return (
        <div className="flex flex-col h-full">
            <div className="flex-none flex justify-between items-center">
                <h2 className="text-lg font-semibold text-gray-900">AI 助手</h2>
                <button
                    onClick={handleNewChat}
                    className="flex items-center gap-2 rounded-md bg-indigo-600 px-3 py-1.5 text-sm text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 duration-300"
                >
                    <PlusIcon className="h-4 w-4" />
                    新對話
                </button>
            </div>

            {/* Chat messages */}
            <div 
                ref={messageContainerRef}
                className={`flex-1 overflow-y-auto mt-4 space-y-4 scroll-smooth relative ${
                    isDragging ? 'bg-indigo-50 dark:bg-indigo-900/20' : ''
                }`}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onDragStart={(e) => e.preventDefault()}
            >
                {isDragging && (
                    <div className="absolute inset-0 flex items-center justify-center bg-indigo-50/90 dark:bg-indigo-900/90 border-2 border-dashed border-indigo-500 rounded-lg z-10 pointer-events-none">
                        <div className="text-center">
                            <DocumentIcon className="h-12 w-12 mx-auto text-indigo-500" />
                            <p className="mt-2 text-sm text-indigo-600 dark:text-indigo-400">拖放檔案到這裡上傳</p>
                        </div>
                    </div>
                )}
                {messages.map((message, index) => (
                    <div
                        key={index}
                        className={`flex ${message.role === 'USER' ? 'justify-end' : 'justify-start'}`}
                    >
                        <div
                            className={`max-w-[80%] rounded-lg px-4 py-2 ${
                                message.role === 'USER'
                                    ? 'bg-indigo-600 text-white text-sm'
                                    : 'bg-gray-200 text-gray-900 dark:bg-gray-700 dark:text-white'
                            }`}
                        >
                            {message.role === 'USER' ? (
                                message.content ? (
                                    message.content
                                ) : (
                                    message.parts.map((part, partIndex) => (
                                        part.fileData ? (
                                            <div className="flex items-center gap-2">
                                                <div 
                                                    onClick={() => GetFileContent(part.fileData.fileUri)}
                                                    className="cursor-pointer p-2 hover:bg-indigo-700 rounded-lg transition-colors flex items-center justify-center"
                                                >
                                                    {part.fileData.mimeType.startsWith('image') ? (
                                                        <PhotoIcon className="h-8 w-8" />
                                                    ) : part.fileData.mimeType.startsWith('video') ? (
                                                        <VideoCameraIcon className="h-8 w-8" />
                                                    ) : (
                                                        <DocumentIcon className="h-8 w-8" />
                                                    )}
                                                </div>
                                                <button
                                                    onClick={() => handleRemoveFile()}
                                                    className="p-1 hover:bg-indigo-700 rounded-full transition-colors"
                                                >
                                                    <XMarkIcon className="h-4 w-4" />
                                                </button>
                                            </div>
                                        ) : (
                                            <div 
                                                key={partIndex}
                                                className="text-sm overflow-hidden whitespace-nowrap overflow-ellipsis"
                                            >
                                                {part.text}
                                            </div>
                                        )
                                    ))
                                )
                            ) : (
                                <div>

                                    <ReactMarkdown 
                                        remarkPlugins={[remarkGfm]}
                                        className="prose prose-sm max-w-none dark:prose-invert overflow-x-auto"
                                        components={{
                                            pre: ({node, ...props}) => (
                                                <div className="overflow-auto my-2 bg-gray-800 text-white p-2 rounded-lg text-sm">
                                                    <pre {...props} />
                                                </div>
                                            ),
                                            code: ({node, inline, ...props}) => (
                                                inline ? 
                                                <code className="bg-gray-100 dark:bg-gray-800 px-1 rounded" {...props} /> :
                                                <code className="text-sm text-gray-900 bg-gray-100 dark:text-gray-100 dark:bg-gray-900 px-1 rounded whitespace-pre" {...props} />
                                            ),
                                            table: TableComponent
                                        }}
                                    >
                                        {message.content}
                                    </ReactMarkdown>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>

            {/* Input form */}
            <form onSubmit={handleSubmitNonStream} className="flex-none mt-4">
                {/* {selectedFile && (
                    <div className="flex items-center gap-2 mb-2">
                        <span className="inline-flex items-center gap-1 px-2 py-1 rounded-full text-xs bg-indigo-100 text-indigo-700">
                            <DocumentIcon className="h-3 w-3" />
                            {selectedFile.name}
                            <button
                                type="button"
                                onClick={handleRemoveFile}
                                className="ml-1 text-indigo-600 hover:text-indigo-800"
                            >
                                ×
                            </button>
                        </span>
                    </div>
                )} */}
                <div className="flex gap-x-2 items-end">
                    <TextareaAutosize
                        ref={inputRef}
                        value={inputMessage}
                        onChange={(e) => setInputMessage(e.target.value)}
                        placeholder={selectedFile ? "添加訊息或直接發送檔案..." : "輸入訊息..."}
                        disabled={isLoading}
                        maxRows={6}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault();
                                handleSubmitNonStream(e);
                            }
                        }}
                        className="block w-full rounded-md border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:opacity-50 disabled:cursor-not-allowed resize-none min-h-[38px]"
                    />
                    <label className="cursor-pointer flex-shrink-0 flex">
                        <input
                            ref={fileInputRef}
                            type="file"
                            onChange={handleFileUpload}
                            className="hidden"
                            disabled={isLoading}
                        />
                        <div className={`rounded-md p-2 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50 disabled:cursor-not-allowed h-[38px] w-[38px] flex items-center justify-center ${
                            selectedFile 
                                ? 'bg-indigo-700 hover:bg-indigo-600' 
                                : 'bg-indigo-600 hover:bg-indigo-500'
                        }`}>
                            {uploadingFile ? (
                                <ArrowPathIcon className="h-5 w-5 animate-spin" />
                            ) : hasFile ? (
                                <CheckCircleIcon className="h-5 w-5" />
                            ) : (
                                <DocumentIcon className="h-5 w-5" />
                            )}
                        </div>
                    </label>
                    <button
                        type="submit"
                        disabled={isLoading || (!inputMessage.trim() && !selectedFile)}
                        className="rounded-md bg-indigo-600 p-2 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50 disabled:cursor-not-allowed h-[38px] w-[38px] flex items-center justify-center"
                    >
                        {isLoading ? (
                            <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        ) : (
                            <PaperAirplaneIcon className="h-5 w-5" />
                        )}
                    </button>
                </div>
            </form>

            <TableDialog 
                isOpen={isTableDialogOpen}
                setIsOpen={setIsTableDialogOpen}
                tableNode={selectedTable}
            />
        </div>
    );
}