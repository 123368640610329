import { Popover, Transition } from '@headlessui/react'
import { Fragment, useState, useRef, useEffect } from 'react'
import { MultiSelect } from "react-multi-select-component"

const jobStatus = {
    "新工作": "New Job",
    "待確認": "Awaiting Confirmation",
    "未開始": "Not Started",
    "進行中": "In Progress",
    "中斷": "Interrupted",
    "待續": "Temporarily Suspended",
    "待檢查": "Awaiting Inspection",
    "待批准": "Awaiting Approval",
    "完成": "Completed",
    "拒絕": "Rejected",
    "取消": "Cancelled",
    "延期": "Postponed",
    "回公司": "Returned to Office",
    "緊急": "Emergency",
    "維修中": "Under Repair",
    "需要額外部件": "Additional Parts Required",
    "需要專家幫助": "Expert Assistance Required"
  };

export default function JobReminderDialog({
    staffChoices, 
    statusNotificationList, 
    setStatusNotificationList,
    t
}) {
    // const [statusNotificationList, setStatusNotificationList] = useState([{"name": "", "targets": []}]);

    // handle input change for status inputs
    const handleStatusInputChange = (index, e) => {
        console.log(e.target.value);
        const values = [...statusNotificationList];
        values[index][e.target.name] = e.target.value;
        setStatusNotificationList(values);
    };


    // handle input change when users interact with multiselect
    const handleMultiUserSelect = (ind, selectedOptions) => {
        const values = [...statusNotificationList];
        values[ind]['targets'] = selectedOptions;
        setStatusNotificationList(values);
    }

    // handle adding another set of reminder
    const handleAddAnotherReminder = (e) => {
    e.preventDefault();
    setStatusNotificationList([...statusNotificationList, {"name": "", "targets": []}]);
    };

    // handle removing last set of reminder
    const handleRemoveLastReminder = (e) => {
        e.preventDefault();
        setStatusNotificationList(statusNotificationList.slice(0, -1));
        
    };

    const popBtnRef = useRef();

    // handle job reminder button text
    const [jobReminderBtnText, setJobReminderBtnText] = useState(t('狀態通知'));
    useEffect(() => {
        // if there are at least one statusNotificationList with filled name and targets, change the text to '狀態通知 (已設定<數量>)'
        if (statusNotificationList.length > 0) {
            let filledStatusNotificationList = statusNotificationList.filter((status) => {
                return status.name !== "" && status.targets.length > 0;
            })
            if (filledStatusNotificationList.length > 0) {
                setJobReminderBtnText(t('狀態通知 (已設定') + filledStatusNotificationList.length + ')');
            } else {
                setJobReminderBtnText(t('狀態通知'));
            }
        }
    }, [statusNotificationList, t])


    return (
        <Popover className="relative" id="job-reminder-dialog">
            <Popover.Button ref={popBtnRef} className="inline-flex items-center gap-x-1 text-sm leading-6 text-gray-900 rounded ring-1 ring-inset ring-gray-300 hover:bg-gray-50 duration-300 px-3 py-1">
                <span>{jobReminderBtnText}</span>
                {/* <ChevronDownIcon className="h-5 w-5" aria-hidden="true" /> */}
            </Popover.Button>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
            >
                <Popover.Panel className="absolute right-1/2  z-10 mt-5 flex w-screen max-w-max px-4 ">
                <div className="w-screen max-w-md flex-auto overflow-visible rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                    <div className="p-4">
                        {/* content  */}
                        <div className="flex flex-col gap-y-2">
                            {/* status name */}
                            {statusNotificationList.map((status, index) => {
                                return (
                                    <div className="flex flex-col gap-y-1" key={index}>
                                        <label htmlFor="status-name" className="text-gray-700">狀態名稱</label>
                                        <select
                                            name="name"
                                            id="status-name"
                                            className="text-sm rounded border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                            value={status.name}
                                            onChange={(e) => handleStatusInputChange(index, e)}
                                        >
                                            <option value="">選擇狀態</option>
                                            {Object.keys(jobStatus).map((key) => {
                                                return (
                                                    <option value={jobStatus[key]} key={key}>{key}</option>
                                                )
                                            })}
                                        </select>
                                        <label htmlFor="status-name" className="text-gray-700">通知對象</label>
                                        <MultiSelect
                                            options={staffChoices}
                                            value={status.targets}
                                            onChange={(selectedOptions) => handleMultiUserSelect(index, selectedOptions)}
                                            labelledBy={"Select"}
                                            className='text-sm'
                                            overrideStrings={
                                                {
                                                    "selectSomeItems": "選擇通知對象",
                                                    "allItemsAreSelected": "所有通知對象已選擇",
                                                    "selectAll": "選擇所有通知對象",
                                                    "search": "搜尋",
                                                }
                                            }
                                        /> 
                                    </div>
                                )
                            })}
                            {/* add and remove buttons */}
                            <div className="flex gap-x-2">
                                <button
                                    type="button"
                                    className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                                    onClick={handleAddAnotherReminder}
                                >
                                    {t('新增')}
                                </button>
                                <button
                                    type="button"
                                    className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                                    onClick={handleRemoveLastReminder}
                                >
                                    {t('移除')}
                                </button>
                            </div>
                        </div>
                        {/* footer buttons for cancel and save */}
                        <div className="flex justify-end gap-x-2">
                            <button
                                type="button"
                                className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                                onClick={() => {
                                    setStatusNotificationList([{"name": "", "targets": []}]);
                                    // close popover
                                    popBtnRef.current?.click();
                                }}
                            >
                                {t('取消')}
                            </button>
                            
                            <button
                                type="button"
                                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                onClick={() => {
                                    // close popover
                                    popBtnRef.current?.click();
                                }}
                            >
                                {t('儲存')}
                            </button>
                        </div>
                    </div>
                </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    )
}