import { useState, useEffect } from 'react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import GlobalVars from '../../../../../../Config'

const statusOpt = [
    { name: '待處理', value: 'pending', color: 'bg-red-500' },
    { name: '進行中', value: 'inProgress', color: 'bg-yellow-500' },
    { name: '已完成', value: 'completed', color: 'bg-green-500' },
    { name: '停滯', value: 'stuck', color: 'bg-gray-500' },
]

export default function StatusSelect({ itemIdx, itemID, status, setItems }) {
  const handleStatusChange = (statusName) => {
      setItems(prevItems => {
          const newItems = [...prevItems];
          newItems[itemIdx].status = statusName;
          return newItems;
      });

      let url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/board/items`
      let method = 'PUT'
      let body = {
          itemID: itemID,
          status: statusName
      }

      fetch(url, {
          headers: {
              'Authorization': `${sessionStorage.getItem('idToken')}`,
              'Content-Type': 'application/json'
          },
          method: method,
          body: JSON.stringify(body)
      })
      .then(response => {
          if (response.ok) {
              return response.json()
          } else {
              throw new Error('Failed to update item')
          }
      })
      .then(data => {
          console.log('update item', data)
      })
      .catch(error => console.error('Error updating item:', error));
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-transparent px-3 text-sm font-semibold text-white">
          {statusOpt.find(opt => opt.value === status)?.name}
          <ChevronDownIcon aria-hidden="true" className="-mr-1 size-5 text-white" />
        </MenuButton>
      </div>
      <MenuItems
      transition
      className="absolute left-0 z-[100] mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="py-1">
          {statusOpt.map((statusItem) => (
          <MenuItem key={statusItem.value}>
              <button
              onClick={() => {
                if (status !== statusItem.value) {
                  handleStatusChange(statusItem.value)
                }
              }}
              className="block w-full px-4 py-2 text-left text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
              >
              {statusItem.name}
              </button>
          </MenuItem>
          ))}
        </div>
      </MenuItems>
    </Menu>
  )
}