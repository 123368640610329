import { useState, useEffect } from "react";
import {
    XMarkIcon
} from '@heroicons/react/24/outline';
import GlobalVars from "../../../../Config";
import { useTranslation } from "react-i18next";

// job remark setting
export default function JobRemarkSetting() {
    const { t } = useTranslation();
    const [defaultSuggestion, setDefaultSuggestion] = useState([]);
    const [newSuggestion, setNewSuggestion] = useState(null);

    // fetch default suggestion
    const fetchDefaultSuggestion = () => {
        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/jobs/remark-preset?organizationID=" + localStorage.getItem("organizationId");

        fetch(url, {
            method: "GET",
            headers: {
                "Authorization": sessionStorage.getItem("idToken")
            }
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error("Failed to fetch job remark suggestions");
            }
        })
        .then(data => {
            setDefaultSuggestion(data["remarks"]);
        })
        .catch(error => console.error(error));
    }

    // fetch default suggestion when component is mounted
    useEffect(() => {
        fetchDefaultSuggestion();
    }, []);


    const handleAddingDefaultSuggestion = (e) => {
        e.preventDefault();
        if (newSuggestion) {
            // ensure newSuggestion is not already in defaultSuggestion
            const trimmedSuggestion = newSuggestion.trim();
            if (defaultSuggestion.includes(trimmedSuggestion) || trimmedSuggestion === "") {
                return;
            }

            setDefaultSuggestion([...defaultSuggestion, trimmedSuggestion]);
            setNewSuggestion("");

            let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/jobs/remark-preset";
            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": sessionStorage.getItem("idToken")
                },
                body: JSON.stringify({
                    remark: trimmedSuggestion,
                    organizationID: localStorage.getItem("organizationId")
                })
            })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("Failed to add job remark suggestion");
                }
            })
            .then(data => {
                console.log(data);
            })
            .catch(error => console.error(error));

        }

    }

    const handleNewSuggestionInput = (e) => {
        
        // when user press enter, add new suggestion
        if (e.key === "Enter") {
            handleAddingDefaultSuggestion(e);
        }
    }

    const handleDeleteSuggestion = (index) => {
        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/jobs/remark-preset";
        fetch(url, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": sessionStorage.getItem("idToken")
            },
            body: JSON.stringify({
                remark: defaultSuggestion[index],
                organizationID: localStorage.getItem("organizationId")
            })
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error("Failed to delete job remark suggestion");
            }
        })
        .then(data => {
            console.log(data);

            const newDefaultSuggestion = defaultSuggestion.filter((_, i) => i !== index);
            setDefaultSuggestion(newDefaultSuggestion);
        })
    }

       return (
        <div className="px-4 py-5 sm:p-6">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
                {t("工作備註設定")}
            </h3>
            <div className="mt-2 max-w-xl text-sm text-gray-500">
                {t("設定工作備註的預設建議, 以方便工作人員快速選擇")}
            </div>
            <div className="mt-5">
                <label className="block text-sm font-medium text-gray-700">
                    {t("備註名稱")}
                </label>
                <div className="mt-1 flex">
                    <input
                        type="text"
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-sm border-gray-300 rounded-md"
                        placeholder={t("請輸入備註名稱")}
                        value={newSuggestion}
                        onChange={(e) => setNewSuggestion(e.target.value)}
                        onKeyDown={handleNewSuggestionInput}
                    />
                    <button
                        className="ml-3 text-nowrap inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={handleAddingDefaultSuggestion}
                    >
                        {t("新增")}
                    </button>
                </div>
            </div>
            <div>
                {defaultSuggestion && (
                    <div className="mt-5">
                        <ul className="flex space-x-2">
                            {defaultSuggestion.map((suggestion, index) => (
                                <li key={index} className="relative group cursor-pointer">
                                    <button
                                        className="flex items-center space-x-2 bg-gray-100 rounded-full px-3 py-1 hover:pr-8 hover:border-gray-300 border border-transparent duration-300 relative"
                                        onClick={() => handleDeleteSuggestion(index)}
                                    >
                                        <span className="text-sm">{suggestion}</span>
                                        <span
                                            className="absolute right-3 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                                        >
                                            <XMarkIcon className="h-4 w-4" />
                                        </span>
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    )
}