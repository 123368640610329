import { useState, useEffect } from 'react'
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { XMarkIcon, ArrowsPointingOutIcon } from '@heroicons/react/24/outline'
import AIChat from '../AIChat'

export default function AIChatContainer({
  open, 
  setOpen,
  fixedChatInterface,
  setFixedChatInterface
}) {

  return (
    <>
      {fixedChatInterface ? (
        <Container />
      ) : (
        <Drawer 
          open={open}
          setOpen={setOpen}
          setFixedChatInterface={setFixedChatInterface}
        />
      )}
    </>
  )
}

function Drawer({
  open,
  setOpen,
  setFixedChatInterface
})  {

    return (
      <Dialog open={open} onClose={setOpen} className="relative z-50">
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <DialogPanel
                transition
                className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
              >
                <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-end">
                      {/* <DialogTitle className="text-base font-semibold text-gray-900">Virty</DialogTitle> */}
                      <div className="ml-3 flex h-7 items-center gap-2">
                        <button
                          type="button"
                          onClick={() => {
                            setFixedChatInterface(true);
                            localStorage.setItem("fixedChatInterface", "true");
                            sessionStorage.setItem("displayChatInterface", "true");
                            // setOpen(false);
                          }}
                          className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Fix chat interface</span>
                          <ArrowsPointingOutIcon aria-hidden="true" className="size-6" />
                        </button>
                        <button
                          type="button"
                          onClick={() => setOpen(false)}
                          className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon aria-hidden="true" className="size-6" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="relative mt-6 flex-1 px-4 sm:px-6">
                    <AIChat 
                    />
                  </div>
                </div>
              </DialogPanel>
            </div>
          </div>
        </div>
      </Dialog>
    )
}

function Container() {
  return (
    <div className="flex flex-col h-full p-4">
      <AIChat />
    </div>
  )
}