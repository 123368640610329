import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClockIcon,
  EllipsisHorizontalIcon,
} from '@heroicons/react/20/solid'
import { 
    useState, 
    useEffect, 
    useRef, 
    useMemo 
} from 'react';
import { 
    subMonths,
    addMonths,
    startOfWeek,
    endOfWeek,
    startOfMonth, 
    endOfMonth, 
    startOfYear,
    endOfYear,
    eachMonthOfInterval,
    eachDayOfInterval, 
    format, 
    isToday, 
    isSameMonth,
    addDays,
    isSameDay,
    subDays,

} from 'date-fns';

import GlobalVars from '../../Config';

// components
import ApplicationShell from '../../Components/ApplicationShell/ApplicationShell';
import SwitchViewButton from './Components/SwitchViewButton';
import CalendarSlideOver from './Components/CalendarSlideOver';
import EventPopover from './Components/EventPopover';
import Filters from './Components/Filters';
import ItemView from './Components/ItemView';
export default function Calendar() {
    // document title
    document.title = '日曆';

    const [view, setView] = useState('item');
    const [openForm, setOpenForm] = useState(false);

    const [filters, setFilters] = useState({});

    // ref for calendar container
    const calendarContainerRef = useRef(null);

    return (
        <>
          <ApplicationShell fullHeight={true}>
              <div className={`flex flex-col h-[85vh]`}>
                  <div className="flex-shrink-0 md:flex md:items-center md:justify-between py-4">
                      <div className="min-w-0 flex-1">
                          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">日曆</h2>
                      </div>
                  </div>
                  <Filters filters={filters} setFilters={setFilters} /> 
                  <div className="flex-grow overflow-y-auto overflow-x-hidden" ref={calendarContainerRef}>
                      {view === 'item' && <ItemView 
                          view={view}
                          setView={setView}
                          setOpenForm={setOpenForm}
                          filters={filters}
                      />}
                      {view === 'year'  && <YearView 
                          view={view}
                          setView={setView}
                          setOpenForm={setOpenForm}
                      />}
                      {view === 'month' && <MonthView 
                          view={view}
                          setView={setView}
                          setOpenForm={setOpenForm}
                          filters={filters}
                          calendarContainerRef={calendarContainerRef}
                      />} 
                      {view === 'week' && <WeekView 
                          view={view}
                          setView={setView}
                          setOpenForm={setOpenForm}
                          filters={filters}
                          setFilters={setFilters}
                      />} 
                      {view === 'day' && <DayView 
                          view={view}
                          setView={setView}
                          setOpenForm={setOpenForm}
                          filters={filters}
                          setFilters={setFilters}
                      />}
                      
                  </div>
              </div>
          </ApplicationShell>
          <CalendarSlideOver title={"新增日程"} open={openForm} setOpen={setOpenForm} />
        </>
    )
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function MonthView({view, setView, setOpenForm, filters, calendarContainerRef}) {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [displayMonth, setDisplayMonth] = useState(new Date().getMonth());
    const [displayYear, setDisplayYear] = useState(new Date().getFullYear());
    const [days, setDays] = useState([]);

    useEffect(() => {

        const createDays = async () => {
            let days = generateMonthCalendar(currentDate);
            let events = await fetchCurrentEvents(days[0].date, days[days.length - 1].date);

            // reverse the events array
            events.reverse();
            
            // add events to days
            for (let i = 0; i < days.length; i++) {
              // loop through events and add to days
              for (let j = 0; j < events.length; j++) {
                if (events[j].date === days[i].date) {
                  days[i].events.push({
                    id: events[j]._id,
                    name: events[j].locationName,
                    time: events[j].allDay ? "全日" : events[j].startTime,
                    href: events[j].type === "job" ? `/jobs/${events[j]._id}` : "#",
                  });
                }
              }
            }
            setDays(days);
        }
        createDays();
    }, [currentDate, filters]);

    // fetch current events
    async function fetchCurrentEvents(startDate, endDate) {
        const events = await combineCalendarEvent({startDate: startDate, endDate: endDate, filters: filters})
        return events;
    }

    // generate month calendar
    function generateMonthCalendar(date) {
        const startDate = startOfWeek(startOfMonth(date), { weekStartsOn: 1 }); // Start from Monday
        const endDate = endOfWeek(endOfMonth(date), { weekStartsOn: 1 });
        
        return eachDayOfInterval({ start: startDate, end: endDate }).map(day => ({
            date: format(day, 'yyyy-MM-dd'),
            isCurrentMonth: isSameMonth(day, date),
            isToday: isToday(day),
            isSelected: false, // You can update this based on user selection
            events: [], // You would populate this from your events data
        }));
    }

    function handlePreviousMonth() {
        setCurrentDate(prevDate => subMonths(prevDate, 1));
        
        if (displayMonth === 0) {
            setDisplayMonth(11);
            setDisplayYear(prevYear => prevYear - 1);
        } else {
            setDisplayMonth(prevMonth => prevMonth - 1);
        }
    }

    function handleNextMonth() {
        setCurrentDate(prevDate => addMonths(prevDate, 1));
        if (displayMonth === 11) {
            setDisplayMonth(0);
            setDisplayYear(prevYear => prevYear + 1);
        } else {
            setDisplayMonth(prevMonth => prevMonth + 1);
        }
    }

    function handleToday() {
        setCurrentDate(new Date());
        setDisplayMonth(new Date().getMonth());
        setDisplayYear(new Date().getFullYear());
    }

    

    // handle day click
    const [selectedDay, setSelectedDay] = useState(null);
    const handleDayClick = (day) => {
        const selectedDayWithEvents = days.find(d => d.date === day.date);
        console.log(selectedDayWithEvents);
        setSelectedDay(selectedDayWithEvents);

        // scroll to the bottom of the calendar container
        // Wait for the next render cycle using setTimeout
        setTimeout(() => {
            calendarContainerRef.current?.scrollTo({
                top: calendarContainerRef.current.scrollHeight,
                behavior: 'smooth'
            });
        }, 0);
    }


    return (
        <div className="lg:flex lg:h-full lg:flex-col">
          <header className="flex items-center justify-between border-b border-gray-200 py-4 lg:flex-none">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              <time dateTime={`${displayYear}-${displayMonth + 1}`}>{displayMonth + 1}-{displayYear}</time>
            </h1>
            <div className="flex items-center">
              <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
                <button
                  type="button"
                  className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50"
                  onClick={handlePreviousMonth}
                >
                  <span className="sr-only">Previous month</span>
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                <button
                  type="button"
                  className="hidden border-y border-gray-300 px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block"
                  onClick={handleToday}
                >
                  今天
                </button>
                <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
                <button
                  type="button"
                  className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50"
                  onClick={handleNextMonth}
                >
                  <span className="sr-only">Next month</span>
                  <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
              <div className="hidden md:ml-4 md:flex md:items-center">
                <SwitchViewButton view={view} setView={setView} />
                <div className="ml-6 h-6 w-px bg-gray-300" />
                <button
                  type="button"
                  className="ml-6 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={() => setOpenForm(true)}
                >
                  新增日程
                </button>
              </div>
              <CalendarDropdown 
                setOpenForm={setOpenForm}
                setView={setView}
              />
            </div>
          </header>
          <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
            <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
              <div className="bg-white py-2">
                週一
              </div>
              <div className="bg-white py-2">
                週二
              </div>
              <div className="bg-white py-2">
                週三
              </div>
              <div className="bg-white py-2">
                週四
              </div>
              <div className="bg-white py-2">
                週五
              </div>
              <div className="bg-white py-2">
                週六
              </div>
              <div className="bg-white py-2">
                週七
              </div>
            </div>
            <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
              <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px">
                {days.map((day) => (
                  <div
                    key={day.date}
                    onClick={() => handleDayClick(day)}
                    className={classNames(  
                      day.isCurrentMonth ? 'bg-white' : 'bg-gray-50 text-gray-500',
                      'relative px-3 py-2 cursor-pointer hover:bg-gray-100 transition-colors duration-200',
                    )}
                  >
                    <time
                      dateTime={day.date}
                      className={
                        day.isToday
                          ? 'flex h-6 w-6 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white'
                          : undefined
                      }
                    >
                      {day.date.split('-').pop().replace(/^0/, '')}
                    </time>
                    {day.events.length > 0 && (
                      <ol className="mt-2">
                        {day.events.slice(0, 2).map((event) => (
                          <li key={event.id}>
                            <a href={event.href} className="group flex">
                              <p className="line-clamp-1 flex-auto truncate font-medium text-gray-900 group-hover:text-indigo-600">
                                {event.name}
                              </p>
                              <time
                                dateTime={event.datetime}
                                className="ml-3 hidden flex-none text-gray-500 group-hover:text-indigo-600 xl:block"
                              >
                                {event.time}
                              </time>
                            </a>
                          </li>
                        ))}
                        {day.events.length > 2 && <li className="text-gray-500">+ {day.events.length - 2} more</li>}
                      </ol>
                    )}
                  </div>
                ))}
              </div>
              <div className="isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden">
                {days.map((day) => (
                  <button
                    key={day.date}
                    onClick={() => handleDayClick(day)}
                    type="button"
                    className={classNames(
                      day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                      (day.isSelected || day.isToday) && 'font-semibold',
                      day.isSelected && 'text-white',
                      !day.isSelected && day.isToday && 'text-indigo-600',
                      !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900',
                      !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-500',
                      'flex h-14 flex-col px-3 py-2 hover:bg-gray-100 focus:z-10',
                    )}
                  >
                    <time
                      dateTime={day.date}
                      className={classNames(
                        day.isSelected && 'flex h-6 w-6 items-center justify-center rounded-full',
                        day.isSelected && day.isToday && 'bg-indigo-600',
                        day.isSelected && !day.isToday && 'bg-gray-900',
                        'ml-auto',
                      )}
                    >
                      {day.date.split('-').pop().replace(/^0/, '')}
                    </time>
                    <span className="sr-only">{day.events.length} events</span>
                    {day.events.length > 0 && (
                      <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                        {day.events.map((event) => (
                          <span key={event.id} className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-gray-400" />
                        ))}
                      </span>
                    )}
                  </button>
                ))}
              </div>
            </div>
          </div>
          {selectedDay?.events.length > 0 && (
            <div className="px-4 py-10 sm:px-6 ">
              <ol className="divide-y divide-gray-100 overflow-hidden rounded-lg bg-white text-sm shadow ring-1 ring-black ring-opacity-5">
                {selectedDay.events.map((event) => (
                  <li key={event.id} className="group flex p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50">
                    <div className="flex-auto">
                      <p className="font-semibold text-gray-900">{event.name}</p>
                      <time dateTime={event.datetime} className="mt-2 flex items-center text-gray-700">
                        <ClockIcon className="mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                        {event.time}
                      </time>
                    </div>
                    <a
                      href={event.href}
                      className="ml-6 flex-none self-center rounded-md bg-white px-3 py-2 font-semibold text-gray-900 opacity-0 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400 focus:opacity-100 group-hover:opacity-100"
                    >
                      修改<span className="sr-only">, {event.name}</span>
                    </a>
                  </li>
                ))}
              </ol>
            </div>
          )}
        </div>
      )
}

function WeekView({view, setView, setOpenForm, filters, setFilters}) {
    const container = useRef(null)
    const containerNav = useRef(null)
    const containerOffset = useRef(null)
    const [currentTime, setCurrentTime] = useState(new Date());
    const [scrollTop, setScrollTop] = useState(0)
    const [selectedDay, setSelectedDay] = useState({
      date: new Date(),
      dayName: format(new Date(), 'EEE'),
      dayNumber: format(new Date(), 'd'),
      isToday: true,
      isSelected: true,
    })
    const [displayMonth, setDisplayMonth] = useState(new Date().getMonth())
    const [displayYear, setDisplayYear] = useState(new Date().getFullYear())

    const [currentDate, setCurrentDate] = useState(new Date());

    const [days, setDays] = useState([])
    useEffect(() => {
        setDays(generateWeekDays(currentDate));
    }, [currentDate]);

    // create days of the week
    function generateWeekDays(date) {
        const start = startOfWeek(date, { weekStartsOn: 1 }); // Start from Monday
        return Array.from({ length: 7 }, (_, i) => {
        const day = addDays(start, i);
        return {
            date: day,
            dayName: format(day, 'EEE'),
            dayNumber: format(day, 'd'),
            isToday: isSameDay(day, new Date()),
        };
        });
    }

    const handleNextWeek = () => {
        setCurrentDate(prevDate => {
            const newDate = addDays(prevDate, 7);
            setDisplayMonth(newDate.getMonth());
            setDisplayYear(newDate.getFullYear());
            return newDate;
        });

    }

    const handlePreviousWeek = () => {
        setCurrentDate(prevDate => {
            const newDate = subDays(prevDate, 7);
            setDisplayMonth(newDate.getMonth());
            setDisplayYear(newDate.getFullYear());
            return newDate;
        });
    }

    const handleToday = () => {
        setCurrentDate(new Date());
        setDisplayMonth(new Date().getMonth());
        setDisplayYear(new Date().getFullYear());
    }
    
    useEffect(() => {
        // Set the container scroll position based on the current time.
        const currentMinute = currentTime.getHours() * 60 + currentTime.getMinutes()
        const newScrollTop = ((container.current.scrollHeight - containerNav.current.offsetHeight - containerOffset.current.offsetHeight - 50) *
        currentMinute) / 1440
        
        container.current.scrollTop = newScrollTop
        setScrollTop(newScrollTop)

        // Update current time every minute
        const timer = setInterval(() => {
        setCurrentTime(new Date())
        }, 60000)

        // Add scroll event listener
        const handleScroll = () => {
            setScrollTop(container.current.scrollTop )
        }
        container.current.addEventListener('scroll', handleScroll)

        return () => {
        clearInterval(timer)
        if (container.current) {
            container.current.removeEventListener('scroll', handleScroll)
        }
        }
    }, [])

  
    // Calculate the top position for the current time indicator
    const indicatorTop = useMemo(() => {
    const currentMinute = currentTime.getHours() * 60 + currentTime.getMinutes()
    const adjustedMinute = Math.max(currentMinute + 27, 0) // Subtract 30 minutes, but don't go below 0
    const totalHeight = container.current ? container.current.scrollHeight - containerNav.current.offsetHeight - containerOffset.current.offsetHeight : 0
    return `calc(1.75rem + ${(totalHeight * adjustedMinute) / 1440}px - ${scrollTop}px)`
    }, [currentTime, scrollTop])

    // map chinese text to english text
    const dayNameMap = {
        'Mon': '週一',
        'Tue': '週二',
        'Wed': '週三',
        'Thu': '週四',
        'Fri': '週五',
        'Sat': '週六',
        'Sun': '週日',
    }

    // handle day click
    const handleDayClick = (day) => {
        // go through days and update the selected day
        setDays(days.map(d => ({
            ...d,
            isSelected: d.date === day.date
        })))
        setSelectedDay(day);

    }

    // retrieve current events
    const [currentEvents, setCurrentEvents] = useState([])
    useEffect(() => {
        const fetchEvents = async () => {
            // get the start and end date of the week
            const startDate = startOfWeek(currentDate, { weekStartsOn: 1 });
            const endDate = addDays(startDate, 6); // This will be the end of the week (Sunday)
  
            // convert to string YYYY-MM-DD
            const startDateStr = format(startDate, 'yyyy-MM-dd');
            const endDateStr = format(endDate, 'yyyy-MM-dd');
    
            // use combineCalendarEvent to get current events
            const events = await combineCalendarEvent({startDate: startDateStr, endDate: endDateStr, filters: filters})

            setCurrentEvents(Array.isArray(events) ? events : [])
        }
    
        fetchEvents()
    }, [currentDate, filters])

    useEffect(() => {
      console.log(selectedDay);
    }, [selectedDay]);

    return (
        <div className="flex h-full flex-col overflow-y-auto overflow-x-hidden sm:overflow-x-auto">
          <header className="flex flex-col items-center justify-between border-b border-gray-200 py-4 gap-4">
              <div className="flex items-center justify-between w-full">
                <h1 className="text-base font-semibold leading-6 text-gray-900">
                <time dateTime={`${displayYear}-${displayMonth + 1}`}>{displayMonth + 1} - {displayYear}</time>
                </h1>
                <div className="flex items-center">
                <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
                    <button
                    type="button"
                    className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50"
                    onClick={handlePreviousWeek}
                    >
                    <span className="sr-only">Previous week</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                    <button
                    type="button"
                    className="hidden border-y border-gray-300 px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block"
                    onClick={handleToday}
                    >
                    今天
                    </button>
                    <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
                    <button
                    type="button"
                    className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50"
                    onClick={handleNextWeek}
                    >
                    <span className="sr-only">Next week</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                </div>
                <div className="hidden md:ml-4 md:flex md:items-center">
                    <SwitchViewButton view={view} setView={setView} />
                    <div className="ml-6 h-6 w-px bg-gray-300" />
                    <button
                    type="button"
                    className="ml-6 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 dynamic-comp"
                    onClick={() => {
                      setOpenForm(true);
                    }}
                    >
                    新增日程
                    </button>
                </div>
                <CalendarDropdown 
                  setOpenForm={setOpenForm}
                  setView={setView}
                />
                </div>
              </div>
              
          </header>
          <div ref={container} className="isolate flex flex-auto flex-col overflow-auto bg-white">
              <div style={{ width: '165%' }} className="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full">
              <div
                  ref={containerNav}
                  className="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black ring-opacity-5 sm:pr-8"
              >
                <div>
                  <div className="grid grid-cols-7 text-sm leading-6 text-gray-500 sm:hidden">
                  {days.map((day, ind) => (
                      <button 
                          type="button" 
                          className="flex flex-col items-center pb-3 pt-2"
                          onClick={() => handleDayClick(day)}
                      >
                          {dayNameMap[day.dayName]} <span className={`mt-1 flex h-8 w-8 items-center justify-center font-semibold text-gray-900 rounded-full ${day.isToday ? 'bg-indigo-600 text-white' : ''} ${day.isSelected ? 'bg-gray-600 text-white' : ''} `}>{day.dayNumber}</span>
                      </button>
                  ))}

                  </div>

                  <div className="-mr-px hidden grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-6 text-gray-500 sm:grid">
                    <div className="col-end-1 w-14" />
                    {days.map((day, ind) => (
                        <div className={`flex items-center justify-center py-3 ${day.dayName === "Sat" || day.dayName === "Sun" ? "bg-gray-100" : ""}`}>
                            <span>
                            {dayNameMap[day.dayName]}<span className={`ml-1 items-center justify-center font-semibold text-gray-900 rounded-full p-2 ${day.isToday ? 'bg-indigo-600 text-white' : ''} `}>{day.dayNumber}</span>
                            </span>
                        </div>
                    ))}
                        
                  </div>
                </div>
                <div>
                  <div className="grid grid-cols-7 text-sm text-gray-500 flex items-center">
                    <div className="col-end-1 w-14">
                      <span>全日</span>
                    </div>
                    {days.map((day, ind) => (
                      <div className={`h-full hidden md:flex flex-col items-center justify-start ${day.dayName === "Sat" || day.dayName === "Sun" ? "bg-gray-100" : ""}`}>
                        {/* go through each event and list out allDay events which event.allDay is true */}
                        {currentEvents.filter(event => event.allDay && (event.dayOfWeek === ind + 1 || (event.dayOfWeek === 0 && ind === 6))).map((event, ind) => (
                          <span
                            className='w-full'
                          >
                            <EventPopover 
                              event={event} 
                              needHeight={true}
                            />
                          </span>
                        ))}
                      </div>
                    ))}
                    {/* show mobile view by filtering selectedDay */}
                    {currentEvents.filter(event => event.allDay && event.date.split('-')[2] === selectedDay.dayNumber).map((event, ind) => (
                      <div className="md:hidden flex flex-col items-center justify-start col-span-full">
                        <EventPopover 
                          event={event} 
                          needHeight={true}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="flex flex-auto">
                  <div className="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100" />
                  <div className="grid flex-auto grid-cols-1 grid-rows-1">
                      {/* Horizontal lines */}
                      <div
                          className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-200"
                          style={{ gridTemplateRows: 'repeat(48, minmax(3.5rem, 1fr))' }}
                      >
                          <div ref={containerOffset} className="row-end-1 h-7"></div>
                          <div>
                          <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                              12AM
                          </div>
                          </div>
                          <div />
                          <div>
                          <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                              1AM
                          </div>
                          </div>
                          <div />
                          <div>
                          <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                              2AM
                          </div>
                          </div>
                          <div />
                          <div>
                          <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                              3AM
                          </div>
                          </div>
                          <div />
                          <div>
                          <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                              4AM
                          </div>
                          </div>
                          <div />
                          <div>
                          <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                              5AM
                          </div>
                          </div>
                          <div />
                          <div>
                          <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                              6AM
                          </div>
                          </div>
                          <div />
                          <div>
                          <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                              7AM
                          </div>
                          </div>
                          <div />
                          <div>
                          <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                              8AM
                          </div>
                          </div>
                          <div />
                          <div>
                          <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                              9AM
                          </div>
                          </div>
                          <div />
                          <div>
                          <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                              10AM
                          </div>
                          </div>
                          <div />
                          <div>
                          <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                              11AM
                          </div>
                          </div>
                          <div />
                          <div>
                          <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                              12PM
                          </div>
                          </div>
                          <div />
                          <div>
                          <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                              1PM
                          </div>
                          </div>
                          <div />
                          <div>
                          <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                              2PM
                          </div>
                          </div>
                          <div />
                          <div>
                          <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                              3PM
                          </div>
                          </div>
                          <div />
                          <div>
                          <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                              4PM
                          </div>
                          </div>
                          <div />
                          <div>
                          <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                              5PM
                          </div>
                          </div>
                          <div />
                          <div>
                          <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                              6PM
                          </div>
                          </div>
                          <div />
                          <div>
                          <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                              7PM
                          </div>
                          </div>
                          <div />
                          <div>
                          <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                              8PM
                          </div>
                          </div>
                          <div />
                          <div>
                          <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                              9PM
                          </div>
                          </div>
                          <div />
                          <div>
                          <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                              10PM
                          </div>
                          </div>
                          <div />
                          <div>
                          <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                              11PM
                          </div>
                          </div>
                          <div />
                      </div>

                      {/* Vertical lines */}
                      <div className="col-start-1 col-end-2 row-start-1 hidden grid-cols-7 grid-rows-1 divide-x divide-gray-200 sm:grid sm:grid-cols-7">
                          <div className="col-start-1 row-span-full" />
                          <div className="col-start-2 row-span-full" />
                          <div className="col-start-3 row-span-full" />
                          <div className="col-start-4 row-span-full" />
                          <div className="col-start-5 row-span-full" />
                          <div className="col-start-6 row-span-full bg-gray-50" />
                          <div className="col-start-7 row-span-full bg-gray-50" />
                          <div className="col-start-8 row-span-full w-8" />
                      </div>
                      {/* Current time indicator */}
                      <div 
                          className="col-start-1 col-end-2 row-start-1 pointer-events-none"
                          aria-hidden="true"
                      >
                          <div
                              className="sticky right-0 z-20"
                              style={{
                                  top: `calc(${indicatorTop} - 5px)`
                              }}
                          >
                              <div className="w-3 h-3 rounded-full bg-red-400 mr-1"></div>
                          </div>
                          <div
                              className="sticky left-0 right-0 z-20 h-px bg-red-400"
                              style={{
                                  top: indicatorTop,
                              }}
                          />
                      </div>

                      {/* Events */}
                      <ol
                          className="col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-8"
                          style={{ gridTemplateRows: '1.75rem repeat(288, minmax(0, 1fr)) auto' }}
                      >
                          {currentEvents
                          .filter(event => {
                            if (window.innerWidth < 640) { // sm breakpoint
                              // Mobile: show only events for selected day
                              return !event.allDay && event.date === format(selectedDay.date, 'yyyy-MM-dd');
                            } else {
                              // Desktop: show all events
                              return !event.allDay;
                            }
                          })
                          .map((event, ind) => (
                            <li key={ind} 
                                className={`relative mt-px flex ${window.innerWidth < 640 ? 'col-span-full' : ''}`} 
                                style={{ 
                                  gridRow: event.startRow, 
                                  gridColumnStart: window.innerWidth < 640 ? 1 : event.colNum
                                }}>
                              <EventPopover event={event} />
                            </li>
                          ))}
                      </ol>
                  </div>
              </div>
              </div>
          </div>
        </div>
    )
}

function calculateGridRow(startTime, durationMinutes) {
  const [hours, minutes] = startTime.split(':').map(Number);
  const startRow = (hours * 12) + (minutes / 5) + 2;
  const span = durationMinutes / 5 >= 4 ? Math.ceil(durationMinutes / 5) : 4
  return `${Math.floor(startRow)} / span ${span}`;
}

function DayView({view, setView, setOpenForm}) {
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1); // January is 0 in JavaScript Date
    const [selectedDay, setSelectedDay] = useState(new Date());

    // generate days array for the current month
    function generateDaysArray(year, month) {

        // Create a date object for the first day of the given month
        const firstDayOfMonth = new Date(year, month - 1, 1);
        
        // Get the start of the week containing the first day of the month
        const startDate = startOfWeek(startOfMonth(firstDayOfMonth), { weekStartsOn: 1 });
        
        // Get the end of the week containing the last day of the month
        const endDate = endOfWeek(endOfMonth(firstDayOfMonth), { weekStartsOn: 1 });

        console.log(selectedDay.toISOString());
    
        // Generate an array of all days between start and end date
        const days = eachDayOfInterval({ start: startDate, end: endDate }).map(day => ({
            date: format(day, 'yyyy-MM-dd'),
            isCurrentMonth: isSameMonth(day, firstDayOfMonth),
            isToday: isToday(day),
            isSelected: format(day, 'yyyy-MM-dd') === format(selectedDay, 'yyyy-MM-dd')
        }));
    
        return days;
    }


    // generate days array for the current month
    const [days, setDays] = useState(generateDaysArray(currentYear, currentMonth));

    const container = useRef(null)
    const containerNav = useRef(null)
    const containerOffset = useRef(null)
  
    const [currentTime, setCurrentTime] = useState(new Date());
    const [scrollTop, setScrollTop] = useState(0)

    useEffect(() => {
      // Set the container scroll position based on the current time.
      const currentMinute = currentTime.getHours() * 60 + currentTime.getMinutes()
      const newScrollTop = ((container.current.scrollHeight - containerNav.current.offsetHeight - containerOffset.current.offsetHeight - 50) *
      currentMinute) / 1440
      
      container.current.scrollTop = newScrollTop
      setScrollTop(newScrollTop)

      // Update current time every minute
      const timer = setInterval(() => {
        setCurrentTime(new Date())
      }, 60000)

      // Add scroll event listener
      const handleScroll = () => {
        setScrollTop(container.current.scrollTop)
      }
      container.current.addEventListener('scroll', handleScroll)

      return () => {
        clearInterval(timer)
        if (container.current) {
          container.current.removeEventListener('scroll', handleScroll)
        }
      }
    }, [])
      
    // Calculate the top position for the current time indicator
    const indicatorTop = useMemo(() => {
      const currentMinute = currentTime.getHours() * 60 + currentTime.getMinutes()
      const adjustedMinute = Math.max(currentMinute + 27, 0) // Subtract 30 minutes, but don't go below 0
      const totalHeight = container.current ? container.current.scrollHeight - containerNav.current.offsetHeight - containerOffset.current.offsetHeight : 0
      return `calc(1.75rem + ${(totalHeight * adjustedMinute) / 1440}px - ${scrollTop}px - 50px)`
    }, [currentTime, scrollTop])

    // handle next month click
    const handleNextMonthClick = () => {
        

        setCurrentMonth(prevMonth => {
            const nextMonth = prevMonth + 1;
            if (nextMonth > 12) {
                setDays(generateDaysArray(currentYear + 1, 1));
                setCurrentYear(prevYear => prevYear + 1);
                
                return 1;
            }

            // update days
            setDays(generateDaysArray(currentYear, nextMonth));
            
            return nextMonth;
        });

    }

    // handle previous month click
    const handlePreviousMonthClick = () => {

        setCurrentMonth(prevMonth => {
            const prevMonthValue = prevMonth - 1;
            if (prevMonthValue < 1) {
                setCurrentYear(prevYear => prevYear - 1);
                setDays(generateDaysArray(currentYear - 1, 12));
                return 12;
            }

            // update days
            setDays(generateDaysArray(currentYear, prevMonthValue));

            return prevMonthValue;
        });

    }

    // handle next day click
    const handleNextDayClick = () => {
        setSelectedDay(prevDay => {
            const nextDay = new Date(prevDay);
            nextDay.setDate(prevDay.getDate() + 1);
            
            // update isSelected
            setDays(days.map(day => ({
                ...day,
                isSelected: day.date === format(nextDay, 'yyyy-MM-dd')
            })));

            return nextDay;
        });
    }

    // handle previous day click
    const handlePreviousDayClick = () => {
        setSelectedDay(prevDay => {
            const previousDay = new Date(prevDay);
            previousDay.setDate(prevDay.getDate() - 1);

            // update isSelected
            setDays(days.map(day => ({
                ...day,
                isSelected: day.date === format(previousDay, 'yyyy-MM-dd')
            })));

            return previousDay;
        });
    }

    const handleSpecificDayClick = (day) => {
        const selectedDay = new Date(day);
        setSelectedDay(selectedDay);

        // update isSelected
        setDays(days.map(day => ({
            ...day,
            isSelected: day.date === format(selectedDay, 'yyyy-MM-dd')
        })));
    }

    // handle today click
    const handleTodayClick = () => {
        setSelectedDay(new Date());
    }

    const chineseNameDayOfWeekMap = {
        1: '一',
        2: '二',
        3: '三',
        4: '四',
        5: '五',
        6: '六',
        0: '日',
    }
  
    
    return (
      <div className="flex h-[100%] flex-col">
        <header className="flex flex-none items-center justify-between border-b border-gray-200 py-4">
          <div>
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              <time dateTime="2022-01-22" className="sm:hidden">
                {format(selectedDay, 'M-d, yyyy')}
              </time>
              <time dateTime="2022-01-22" className="hidden sm:inline">
                {format(selectedDay, 'M-d, yyyy')}
              </time>
            </h1>
            <p className="mt-1 text-sm text-gray-500">週{chineseNameDayOfWeekMap[selectedDay.getDay()]}</p>
          </div>
          <div className="flex items-center">
            <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
              <button
                type="button"
                className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50"
                onClick={handlePreviousDayClick}
              >
                <span className="sr-only">Previous day</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              <button
                type="button"
                className="hidden border-y border-gray-300 px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block"
                onClick={handleTodayClick}
              >
                今天
              </button>
              <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
              <button
                type="button"
                className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50"
                onClick={handleNextDayClick}
              >
                <span className="sr-only">Next day</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden md:ml-4 md:flex md:items-center">
              <SwitchViewButton view={view} setView={setView} />
              <div className="ml-6 h-6 w-px bg-gray-300" />
              <button
                type="button"
                className="ml-6 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                新增日程
              </button>
            </div>
            <CalendarDropdown 
              view={view}
              setView={setView}
              setOpenForm={setOpenForm}
            />
          </div>
        </header>
        <div className="isolate flex flex-auto overflow-hidden bg-white">
          <div ref={container} className="flex flex-auto flex-col overflow-auto">
            <div
              ref={containerNav}
              className="sticky top-0 z-10 grid flex-none grid-cols-7 bg-white text-xs text-gray-500 shadow ring-1 ring-black ring-opacity-5 md:hidden"
            >
              <button type="button" className="flex flex-col items-center pb-1.5 pt-3">
                <span>W</span>
                {/* Default: "text-gray-900", Selected: "bg-gray-900 text-white", Today (Not Selected): "text-indigo-600", Today (Selected): "bg-indigo-600 text-white" */}
                <span className="mt-3 flex h-8 w-8 items-center justify-center rounded-full text-base font-semibold text-gray-900">
                  19
                </span>
              </button>
              <button type="button" className="flex flex-col items-center pb-1.5 pt-3">
                <span>T</span>
                <span className="mt-3 flex h-8 w-8 items-center justify-center rounded-full text-base font-semibold text-indigo-600">
                  20
                </span>
              </button>
              <button type="button" className="flex flex-col items-center pb-1.5 pt-3">
                <span>F</span>
                <span className="mt-3 flex h-8 w-8 items-center justify-center rounded-full text-base font-semibold text-gray-900">
                  21
                </span>
              </button>
              <button type="button" className="flex flex-col items-center pb-1.5 pt-3">
                <span>S</span>
                <span className="mt-3 flex h-8 w-8 items-center justify-center rounded-full bg-gray-900 text-base font-semibold text-white">
                  22
                </span>
              </button>
              <button type="button" className="flex flex-col items-center pb-1.5 pt-3">
                <span>S</span>
                <span className="mt-3 flex h-8 w-8 items-center justify-center rounded-full text-base font-semibold text-gray-900">
                  23
                </span>
              </button>
              <button type="button" className="flex flex-col items-center pb-1.5 pt-3">
                <span>M</span>
                <span className="mt-3 flex h-8 w-8 items-center justify-center rounded-full text-base font-semibold text-gray-900">
                  24
                </span>
              </button>
              <button type="button" className="flex flex-col items-center pb-1.5 pt-3">
                <span>T</span>
                <span className="mt-3 flex h-8 w-8 items-center justify-center rounded-full text-base font-semibold text-gray-900">
                  25
                </span>
              </button>
            </div>
            <div className="flex w-full flex-auto">
              <div className="w-14 flex-none bg-white ring-1 ring-gray-100" />
              <div className="grid flex-auto grid-cols-1 grid-rows-1">
                {/* Horizontal lines */}
                <div
                  className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
                  style={{ gridTemplateRows: 'repeat(48, minmax(3.5rem, 1fr))' }}
                >
                  <div ref={containerOffset} className="row-end-1 h-7"></div>
                  <div>
                    <div className="sticky left-0 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                      12AM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                      1AM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                      2AM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                      3AM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                      4AM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                      5AM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                      6AM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                      7AM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                      8AM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                      9AM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                      10AM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                      11AM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                      12PM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                      1PM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                      2PM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                      3PM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                      4PM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                      5PM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                      6PM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                      7PM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                      8PM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                      9PM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                      10PM
                    </div>
                  </div>
                  <div />
                  <div>
                    <div className="sticky left-0 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                      11PM
                    </div>
                  </div>
                  <div />
                </div>

                {/* Current time indicator */}
                <div 
                  className="col-start-1 col-end-2 row-start-1 pointer-events-none"
                  aria-hidden="true"
                >
                  <div
                    className="sticky left-0 right-0 z-20 h-px bg-red-400"
                    style={{ top: indicatorTop }}
                  />
                </div>

                {/* Events */}
                <ol
                  className="col-start-1 col-end-2 row-start-1 grid grid-cols-1"
                  style={{ gridTemplateRows: '1.75rem repeat(288, minmax(0, 1fr)) auto' }}
                >
                  <li className="relative mt-px flex" style={{ gridRow: '74 / span 12' }}>
                    <a
                      href="#"
                      className="group absolute inset-1 flex flex-col overflow-y-auto rounded-lg bg-blue-50 p-2 text-xs leading-5 hover:bg-blue-100"
                    >
                      <p className="order-1 font-semibold text-blue-700">Breakfast</p>
                      <p className="text-blue-500 group-hover:text-blue-700">
                        <time dateTime="2022-01-22T06:00">6:00 AM</time>
                      </p>
                    </a>
                  </li>
                  <li className="relative mt-px flex" style={{ gridRow: '92 / span 30' }}>
                    <a
                      href="#"
                      className="group absolute inset-1 flex flex-col overflow-y-auto rounded-lg bg-pink-50 p-2 text-xs leading-5 hover:bg-pink-100"
                    >
                      <p className="order-1 font-semibold text-pink-700">Flight to Paris</p>
                      <p className="order-1 text-pink-500 group-hover:text-pink-700">
                        John F. Kennedy International Airport
                      </p>
                      <p className="text-pink-500 group-hover:text-pink-700">
                        <time dateTime="2022-01-22T07:30">7:30 AM</time>
                      </p>
                    </a>
                  </li>
                  <li className="relative mt-px flex" style={{ gridRow: '134 / span 18' }}>
                    <a
                      href="#"
                      className="group absolute inset-1 flex flex-col overflow-y-auto rounded-lg bg-indigo-50 p-2 text-xs leading-5 hover:bg-indigo-100"
                    >
                      <p className="order-1 font-semibold text-indigo-700">Sightseeing</p>
                      <p className="order-1 text-indigo-500 group-hover:text-indigo-700">Eiffel Tower</p>
                      <p className="text-indigo-500 group-hover:text-indigo-700">
                        <time dateTime="2022-01-22T11:00">11:00 AM</time>
                      </p>
                    </a>
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="hidden w-1/2 max-w-md flex-none border-l border-gray-100 px-8 py-10 md:block">
            <div className="flex items-center text-center text-gray-900">
              <button
                type="button"
                className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                onClick={handlePreviousMonthClick}
              >
                <span className="sr-only">Previous month</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              <div className="flex-auto text-sm font-semibold">{`${currentMonth}-${currentYear}`}</div>
              <button
                type="button"
                className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                onClick={handleNextMonthClick}
              >
                <span className="sr-only">Next month</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
              <div>M</div>
              <div>T</div>
              <div>W</div>
              <div>T</div>
              <div>F</div>
              <div>S</div>
              <div>S</div>
            </div>
            <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
              {days.map((day, dayIdx) => (
                <button
                  key={day.date}
                  type="button"
                  className={classNames(
                    'py-1.5 hover:bg-gray-100 focus:z-10',
                    day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                    (day.isSelected || day.isToday) && 'font-semibold',
                    day.isSelected && 'text-white',
                    !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900',
                    !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-400',
                    day.isToday && !day.isSelected && 'text-indigo-600',
                    dayIdx === 0 && 'rounded-tl-lg',
                    dayIdx === 6 && 'rounded-tr-lg',
                    dayIdx === days.length - 7 && 'rounded-bl-lg',
                    dayIdx === days.length - 1 && 'rounded-br-lg',
                  )}
                  onClick={() => handleSpecificDayClick(day.date)}
                >
                  <time
                    dateTime={day.date}
                    className={classNames(
                      'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                      day.isSelected && day.isToday && 'bg-indigo-600',
                      day.isSelected && !day.isToday && 'bg-gray-900',
                    )}
                  >
                    {day.date.split('-').pop().replace(/^0/, '')}
                  </time>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
}

function YearView({view, setView, setOpenForm}) {
    // const months = [
    //     {
    //       name: 'January',
    //       days: [
    //         { date: '2021-12-27' },
    //         { date: '2021-12-28' },
    //         { date: '2021-12-29' },
    //         { date: '2021-12-30' },
    //         { date: '2021-12-31' },
    //         { date: '2022-01-01', isCurrentMonth: true },
    //         { date: '2022-01-02', isCurrentMonth: true },
    //         { date: '2022-01-03', isCurrentMonth: true },
    //         { date: '2022-01-04', isCurrentMonth: true },
    //         { date: '2022-01-05', isCurrentMonth: true },
    //         { date: '2022-01-06', isCurrentMonth: true },
    //         { date: '2022-01-07', isCurrentMonth: true },
    //         { date: '2022-01-08', isCurrentMonth: true },
    //         { date: '2022-01-09', isCurrentMonth: true },
    //         { date: '2022-01-10', isCurrentMonth: true },
    //         { date: '2022-01-11', isCurrentMonth: true },
    //         { date: '2022-01-12', isCurrentMonth: true, isToday: true },
    //         { date: '2022-01-13', isCurrentMonth: true },
    //         { date: '2022-01-14', isCurrentMonth: true },
    //         { date: '2022-01-15', isCurrentMonth: true },
    //         { date: '2022-01-16', isCurrentMonth: true },
    //         { date: '2022-01-17', isCurrentMonth: true },
    //         { date: '2022-01-18', isCurrentMonth: true },
    //         { date: '2022-01-19', isCurrentMonth: true },
    //         { date: '2022-01-20', isCurrentMonth: true },
    //         { date: '2022-01-21', isCurrentMonth: true },
    //         { date: '2022-01-22', isCurrentMonth: true },
    //         { date: '2022-01-23', isCurrentMonth: true },
    //         { date: '2022-01-24', isCurrentMonth: true },
    //         { date: '2022-01-25', isCurrentMonth: true },
    //         { date: '2022-01-26', isCurrentMonth: true },
    //         { date: '2022-01-27', isCurrentMonth: true },
    //         { date: '2022-01-28', isCurrentMonth: true },
    //         { date: '2022-01-29', isCurrentMonth: true },
    //         { date: '2022-01-30', isCurrentMonth: true },
    //         { date: '2022-01-31', isCurrentMonth: true },
    //         { date: '2022-02-01' },
    //         { date: '2022-02-02' },
    //         { date: '2022-02-03' },
    //         { date: '2022-02-04' },
    //         { date: '2022-02-05' },
    //         { date: '2022-02-06' },
    //       ],
    //     },
    //     // More months...
    //   ]

    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const months = useMemo(() => generateYearCalendar(currentYear), [currentYear]);

    return (
      <div>
        <header className="flex items-center justify-between border-b border-gray-200 px-6 py-4">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            <time dateTime={currentYear}>{currentYear}</time>
          </h1>
          <div className="flex items-center">
            <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
              <button
                type="button"
                className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50"
                onClick={() => setCurrentYear(prevYear => prevYear - 1)}
              >
                <span className="sr-only">Previous year</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              <button
                type="button"
                className="hidden border-y border-gray-300 px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block"
                onClick={() => setCurrentYear(new Date().getFullYear())}
              >
                今天
              </button>
              <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
              <button
                type="button"
                className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50"
                onClick={() => setCurrentYear(prevYear => prevYear + 1)}
              >
                <span className="sr-only">Next year</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden md:ml-4 md:flex md:items-center">
              <SwitchViewButton view={view} setView={setView} />
              <div className="ml-6 h-6 w-px bg-gray-300" />
              <button
                type="button"
                className="ml-6 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                新增日程
              </button>
            </div>
            <Menu as="div" className="relative ml-6 md:hidden">
              <MenuButton className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500">
                <span className="sr-only">Open menu</span>
                <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
              </MenuButton>
  
              <MenuItems
                transition
                className="absolute right-0 z-10 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
              >
                <div className="py-1">
                  <MenuItem>
                    <a
                      href="#"
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                    >
                      Create event
                    </a>
                  </MenuItem>
                </div>
                <div className="py-1">
                  <MenuItem>
                    <a
                      href="#"
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                    >
                      Go to today
                    </a>
                  </MenuItem>
                </div>
                <div className="py-1">
                  <MenuItem>
                    <a
                      href="#"
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                    >
                      Day view
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <a
                      href="#"
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                    >
                      Week view
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <a
                      href="#"
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                    >
                      Month view
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <a
                      href="#"
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                    >
                      Year view
                    </a>
                  </MenuItem>
                </div>
              </MenuItems>
            </Menu>
          </div>
        </header>
        <div className="bg-white">
          <div className="mx-auto grid max-w-3xl grid-cols-1 gap-x-8 gap-y-16 px-4 py-16 sm:grid-cols-2 sm:px-6 xl:max-w-none xl:grid-cols-3 xl:px-8 2xl:grid-cols-4">
            {months.map((month) => (
              <section key={month.name} className="text-center">
                <h2 className="text-sm font-semibold text-gray-900">{month.name}</h2>
                <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
                  <div>M</div>
                  <div>T</div>
                  <div>W</div>
                  <div>T</div>
                  <div>F</div>
                  <div>S</div>
                  <div>S</div>
                </div>
                <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
                  {month.days.map((day, dayIdx) => (
                    <button
                      key={day.date}
                      type="button"
                      className={classNames(
                        day.isCurrentMonth ? 'bg-white text-gray-900' : 'bg-gray-50 text-gray-400',
                        dayIdx === 0 && 'rounded-tl-lg',
                        dayIdx === 6 && 'rounded-tr-lg',
                        dayIdx === month.days.length - 7 && 'rounded-bl-lg',
                        dayIdx === month.days.length - 1 && 'rounded-br-lg',
                        'py-1.5 hover:bg-gray-100 focus:z-10',
                      )}
                    >
                      <time
                        dateTime={day.date}
                        className={classNames(
                          day.isToday && 'bg-indigo-600 font-semibold text-white',
                          'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                        )}
                      >
                        {day.date.split('-').pop().replace(/^0/, '')}
                      </time>
                    </button>
                  ))}
                </div>
              </section>
            ))}
          </div>
        </div>
      </div>
    )
  }


function CalendarDropdown({setOpenForm, setView}) {
    return (
        <Menu as="div" className="relative ml-6 md:hidden">
            <MenuButton className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500">
              <span className="sr-only">Open menu</span>
              <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
            </MenuButton>

            <MenuItems
              transition
              className="absolute right-0 z-10 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <div className="py-1">
                <MenuItem>
                  <a
                    onClick={() => setOpenForm(true)}
                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                  >
                    新增事件
                  </a>
                </MenuItem>
              </div>
              <div className="py-1">
                
                <MenuItem>
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                  >
                    今天
                  </a>
                </MenuItem>
              </div>
              <div className="py-1">
                <span
                    className="block px-4 py-2 text-[11px] text-gray-500"
                >更換日曆介面</span>
                <MenuItem>
                  <a
                    onClick={() => setView("item")}
                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                  >
                    項目
                  </a>
                </MenuItem>
                {/* <MenuItem>
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                  >
                    日
                  </a>
                </MenuItem> */}
                <MenuItem>
                  <a
                    onClick={() => setView("week")}
                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                  >
                    週
                  </a>
                </MenuItem>
                <MenuItem>
                  <a
                    onClick={() => setView("month")}
                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                  >
                    月
                  </a>
                </MenuItem>
                {/* <MenuItem>
                  <a
                    href="#"
                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                  >
                    年
                  </a>
                </MenuItem> */}
              </div>
            </MenuItems>
          </Menu>
    )
}


// generate year calendar
function generateYearCalendar(year) {
    const startDate = startOfYear(new Date(year, 0, 1));
    const endDate = endOfYear(startDate);
    
    return eachMonthOfInterval({ start: startDate, end: endDate }).map(monthDate => {
      const monthStart = startOfMonth(monthDate);
      const monthEnd = endOfMonth(monthDate);
      const calendarStart = new Date(monthStart);
      calendarStart.setDate(calendarStart.getDate() - calendarStart.getDay());
      const calendarEnd = new Date(monthEnd);
      calendarEnd.setDate(calendarEnd.getDate() + (6 - calendarEnd.getDay()));
  
      const days = eachDayOfInterval({ start: calendarStart, end: calendarEnd }).map(day => ({
        date: format(day, 'yyyy-MM-dd'),
        isCurrentMonth: isSameMonth(day, monthDate),
        isToday: isToday(day)
      }));
  
      return {
        name: format(monthDate, 'MMMM'),
        days: days
      };
    });
}


// Modify getJobEvent to return a Promise
function getJobEvent({startDate, endDate, filters=null, userID=null}) {
  let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/calendar/job-event"
  let params = {
      startDate: startDate,
      endDate: endDate,
      organizationID: localStorage.getItem("organizationId")
  }

  if (filters) {
    params.filters = filters
  }

  return fetch(url, {
      method: "POST",
      headers: {
          'Authorization': sessionStorage.getItem('idToken'),
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
  })
  .then(response => {
      if (response.ok) {
          return response.json()
      } else {
          throw new Error("Failed to fetch job event")
      }
  })
  .then(data => {
    let exportEvents = []
    // calculate grid row for each event
    for (let event of data) {
      event.type = "job";

      // skip if allDay is true
      if (event.allDay) {
        // check if meta.estimatedDate exists
        if (event.meta && event.meta.estimatedDate) {
          event.dayOfWeek = new Date(event.meta.estimatedDate).getDay();
        }

        continue;
      }
      // get start date and end date
      let eventStartDate = event.startDateTime.split('T')[0]
      let eventEndDate = event.endDateTime.split('T')[0]
      // if date the same, add to exportEvents
      if (eventStartDate === eventEndDate) {
        event.date = eventStartDate

        event.startRow = calculateGridRow(event.startTime, event.duration);

        // calculate col num from the day of the week using startDateTime
        event.colNum = new Date(event.date).getDay();

        // if equal to 0, set to 7
        if (event.colNum === 0) {
          event.colNum = 7
        }

        exportEvents.push(event)
      } else {
        // iterate through each date between startDate and endDate
        for (let date = new Date(event.startDateTime); date < addDays(new Date(event.endDateTime), 1); date.setDate(date.getDate() + 1)) {
          const currentDate = format(date, 'yyyy-MM-dd');
          
          // skip if date not within startDate and endDate
          if (currentDate < startDate || currentDate > endDate) {
            continue;
          }
        
          const newEvent = { ...event };

          newEvent.date = currentDate;
          
          if (format(new Date(event.startDateTime), 'yyyy-MM-dd') === currentDate) {
            // First day of event
            newEvent.startRow = calculateGridRow(event.startTime, 
              (new Date(`${currentDate}T23:59:59`) - new Date(event.startDateTime)) / 1000 / 60);
          } else if (format(new Date(event.endDateTime), 'yyyy-MM-dd') === currentDate) {
            // Last day of event
            newEvent.startRow = calculateGridRow("00:00", 
              (new Date(event.endDateTime) - new Date(`${currentDate}T00:00:00`)) / 1000 / 60);
          } else {
            // Middle days of multi-day event
            newEvent.allDay = true;
            newEvent.dayOfWeek = date.getDay() || 7; // Convert Sunday (0) to 7
          }
        
          newEvent.colNum = date.getDay() || 7; // Convert Sunday (0) to 7
          exportEvents.push(newEvent);
        }
      }
    }

    return exportEvents
  })
  .catch(error => {
      console.log(error)
      return [] // Return an empty array in case of error
  })
}

// get leave event
function getLeaveEvent({startDate, endDate, filters=null, userID=null}) {
    const leaveMap = {
      "sick_leave": "病假",
      "annual_leave": "年假",
      "maternity_leave": "產假",
      "no_pay_leave": "無薪假"
    }


    let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/leaves" + "?organizationID=" + localStorage.getItem("organizationId")
    return fetch(url, {
      method: "GET",
      headers: {
        'Authorization': sessionStorage.getItem('idToken'),
      }
    })
    .then(response => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error("Failed to fetch leave event")
      }
    })
    .then(data => {
      let exportLeaves = []

      // iterate through each leave
      for (let leave of data['leaves']) {
        if (leave.endDate >= startDate && leave.startDate <= endDate) {
          // set allDay to true
          leave.allDay = true;
          leave.locationName = leaveMap[leave.reason] + " | " + leave.name;

          // go from start date to end date
          const startDateObj = new Date(leave.startDate + 'T00:00:00');
          const endDateObj = new Date(leave.endDate + 'T00:00:00');
          const viewStartDate = new Date(startDate + 'T00:00:00');
          const viewEndDate = new Date(endDate + 'T00:00:00');

          for (let date = startDateObj; date <= endDateObj; date.setDate(date.getDate() + 1)) {
            // Only add dates that fall within the view range
            if (date >= viewStartDate && date <= viewEndDate) {
              const newLeave = { ...leave };
              newLeave.date = format(date, 'yyyy-MM-dd');
              newLeave.dayOfWeek = date.getDay();
              if (newLeave.dayOfWeek === 0) {
                newLeave.dayOfWeek = 7;
              }
              exportLeaves.push(newLeave);
            }
          }
        }
      }

      return exportLeaves;
    })
    .catch(error => {
      console.log(error)
      return []
    })
}

// Modify combineCalendarEvent to be async
async function combineCalendarEvent({startDate, endDate, filters=null, userID=null}) {
  var calendarEvent = []

  try {
      const jobEvents = await getJobEvent({startDate, endDate, filters, userID})
      calendarEvent.push(...jobEvents)
      const leaveEvents = await getLeaveEvent({startDate, endDate, filters, userID})
      calendarEvent.push(...leaveEvents)
      
      // Add other event types here as needed
      // const otherEvents = await getOtherEvents({startDate, endDate})
      // calendarEvent.push(...otherEvents)
  } catch (error) {
      console.error("Error combining calendar events:", error)
  }

  return calendarEvent
}

