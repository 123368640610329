import { Menu } from '@headlessui/react'
import { ChevronDownIcon, CalendarIcon, EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { useState, useRef, useEffect } from 'react'
import { MenuButton, MenuItems } from '@headlessui/react'
import FilterComponent from '../FilterComponent'
import Datepicker from 'react-tailwindcss-datepicker'
import { useTranslation } from 'react-i18next';

export default function FilterContainerDropdown({
  typeList,
  selectedTaskType,
  setSelectedTaskType,
  taskList,
  selectedTask,
  setSelectedTask,
  dateFilter,
  setDateFilter,
  estimateDate,
  setEstimateDate,
  estimatedInputBlur,
  setEstimatedInputBlur,
  setShowAllFilter,
}) {
  const { t } = useTranslation();

    // check if the menu is overflowing. set left-0 to be right-0 if it is
    const [menuLeft, setMenuLeft] = useState('left-0');
    const menuRef = useRef(null);
    useEffect(() => {
      if (menuRef.current) {
        if (menuLeft == 'left-0' && menuRef.current.getBoundingClientRect().right > window.innerWidth) {
          setMenuLeft('right-0');
        } else if (menuLeft == 'right-0' && menuRef.current.getBoundingClientRect().left < 0) {
          setMenuLeft('left-0');
        }
      }
    }, [menuRef.current]);


    return (
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            {t('其他篩選')}
            <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400" />
          </MenuButton>
        </div>

        <MenuItems
          transition
          className={`absolute ${menuLeft} z-10 mt-2 w-[50vw] max-w-[400px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in`}
          ref={menuRef}
        >
          <div className="p-4">
            <div
              className="flex justify-between items-center mb-2"
            >
              <h3 className="text-sm font-semibold">{t('篩選')}</h3>
              <button
                onClick={() => setShowAllFilter(true)}
                className="text-sm text-gray-500 hover:text-gray-700 cursor-pointer flex items-center gap-x-1"
              > 
                <EllipsisVerticalIcon className="h-5 w-5" />
                {t('顯示全部篩選')}
              </button>
            </div>
            {/* Type Filter */}
            <FilterComponent
                data={typeList}
                filter={selectedTaskType}
                setFilter={setSelectedTaskType}
              />
              {/* service item filter */}
              <FilterComponent
                data={taskList}
                filter={selectedTask}
                setFilter={setSelectedTask}
              />
              {/* Created Date Filter */}
              <div className='relative mt-1 mr-1' id="datefilter-wrapper">
                <Datepicker
                  showShortcuts={true}
                  value={dateFilter}
                  onChange={(date) => setDateFilter(date)}
                  placeholder={t('選擇接單日期')}
                  inputClassName="dark:ignore border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm"
                  configs={{
                      shortcuts: {
                        today: t('今天'),
                        yesterday: t('昨日'),
                        past: (period) => `${t('過去')} ${period} ${t('天')}`,
                        currentMonth: t('本月'),
                        pastMonth: t('上月'),
                      }
                    }} 
                />
              </div>
              {/* Estimate Date Filter */}
              <div className='relative mt-1 mr-1'>
                <div className="relative rounded-md shadow-sm">
                  <input 
                    type='text'
                    placeholder={t('預計工作日期')}
                    id="estimateDate"
                    onFocus={(e) => {
                      e.target.type = 'date'
                      setEstimatedInputBlur(false)
                    }}
                    onBlur={(e) => {
                      e.target.type = 'text'
                      setEstimatedInputBlur(true)
                    }}
                    value={estimateDate}
                    onChange={(e) => setEstimateDate(e.target.value)}
                    className="text-[12px] h-[36px] shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full border-gray-300 rounded-md min-w-[145px]"
                  />
                  {estimatedInputBlur && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                  )}
                </div>
              </div>
          </div>
        </MenuItems>
      </Menu>
    )
}
 