
import '../../styles.scss'
import { 
    useEditor, 
    EditorContent, 
    FloatingMenu, 
    BubbleMenu 
} from '@tiptap/react'
import { useParams } from 'react-router-dom';

import StarterKit from '@tiptap/starter-kit'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Heading from '@tiptap/extension-heading'
import BulletList from '@tiptap/extension-bullet-list'
import OrderedList from '@tiptap/extension-ordered-list'
import ListItem from '@tiptap/extension-list-item'
import Highlight from '@tiptap/extension-highlight'
import Underline from '@tiptap/extension-underline'

import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'

import debounce from 'lodash/debounce'

import GlobalVars from '../../../../../../Config';

import { useState, useEffect, useCallback, useRef } from 'react'

// import heroicon
import {
    ListBulletIcon,
    ArrowPathIcon,
    TableCellsIcon,
    XMarkIcon,
    TrashIcon
} from '@heroicons/react/24/outline'
import NumberedListIcon from '../NumberedListIcon'

// define your extension array
const extensions = [
    StarterKit,
    Document,
    // Paragraph,
    // Text,
    // BulletList,
    // ListItem,
    // OrderedList,
    // Heading.configure({
    //     levels: [1, 2, 3]
    // }),
    Table,
    TableCell,
    TableHeader,
    TableRow,
    Underline,
    Highlight
]


const Tiptap = ({initialContent}) => {
    const { id } = useParams();

    const [isEditing, setIsEditing] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    // save the content to the database
    const [savingText, setSavingText] = useState('')
    const editorRef = useRef(null);

    const [cursorPositionCharCount, setCursorPositionCharCount] = useState(0);
    const [displayPromptBar, setDisplayPromptBar] = useState(false);
    const [cursorPositionCoord, setCursorPositionCoord] = useState({top: 0, left: 0});
    const [highlightedText, setHighlightedText] = useState('');
    const [highlightedTextCharCount, setHighlightedTextCharCount] = useState(null);

    // Wrap handleContentChange in useCallback
    const handleContentChange = useCallback(async (content) => {
        const body = {
            "projectID": id,
            "note": content
        };

        let url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/project`;

        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${sessionStorage.getItem('idToken')}`
                },
                body: JSON.stringify(body)
            });

            if (!response.ok) {
                setSavingText('Failed to save');
                throw new Error('Error saving note');
            } else {
                setSavingText(`Last saved ${new Date().toLocaleString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'}).replace(',', '')}`);
            }
        } catch (error) {
            console.error('Save failed:', error);
            throw error;
        }

        

    }, []); // Empty dependency array since none of these values change


    // Debounced save function
    const debouncedSave = useCallback(
        debounce(async (content) => {
            try {
                setIsSaving(true);
                setSavingText('Saving...');
                await handleContentChange(content);
                
            } catch (error) {
                console.error('Failed to save:', error);
                // Optionally show error toast
            } finally {
                setIsSaving(false);
            }
        }, 1000), // Wait 1 second after last change before saving
        [handleContentChange]
    );

    // Cleanup debounce on unmount
    useEffect(() => {
        return () => {
            debouncedSave.cancel();
        };
    }, [debouncedSave]);

    // initialize the editor
    const editor = useEditor({
        extensions,
        content: initialContent,
        onUpdate: ({editor}) => {
            setIsEditing(true);
            const content = editor.getHTML()
            debouncedSave(content)
        }
    });

    // Set initial content on first mount
    useEffect(() => {
        if (editor && initialContent && !isEditing) {
            const projectID = id;
            if (projectID.length != 24) {
                return;
            }

            let url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/project?projectID=${projectID}&organizationID=${sessionStorage.getItem('organizationId')}`;
            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `${sessionStorage.getItem('idToken')}`
                }
            })
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error('Error fetching project')
                }
            })
            .then(data => {
                if (data.note) {
                    editor.commands.setContent(data.note);
                }
            })
            .catch(error => {
                console.error('Error fetching project:', error);
            })
        }
    }, [editor]); // Only run when editor is initialized

    // handle control group floading
    const controlGroupRef = useRef(null); // Reference for the control group
    const placeholderRef = useRef(null); // Reference for the placeholder to prevent layout shift
    const [isFloating, setIsFloating] = useState(false); // State to track floating status
    const [placeholderHeight, setPlaceholderHeight] = useState(0); // To store the height of the control group

    useEffect(() => {
        const handleScroll = () => {
            if (controlGroupRef.current && placeholderRef.current) {
                const placeholderTop = placeholderRef.current.getBoundingClientRect().top;

                // Check if the placeholder has scrolled out of view
                if (placeholderTop <= 0) {
                setIsFloating(true);
                } else {
                setIsFloating(false);
                }
            }
        };

        const calculatePlaceholderHeight = () => {
            if (controlGroupRef.current) {
                // Set the placeholder height to match the control group's height
                setPlaceholderHeight(controlGroupRef.current.offsetHeight);
            }
        };

        // Attach scroll and resize listeners
        window.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", calculatePlaceholderHeight);

        // Calculate the height initially
        calculatePlaceholderHeight();

        // Cleanup listeners on unmount
        return () => {
        window.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", calculatePlaceholderHeight);
        };
    }, []);

    // Track cursor position as character count 
    useEffect(() => {
        if (editor) {
            const updateCursorPosition = () => {
                const { from, to } = editor.state.selection;
                setCursorPositionCharCount({
                    from: from,
                    to: to
                });
                const pos = editor.view.coordsAtPos(from);
                setCursorPositionCoord(pos);
            };

            // Update position when selection changes
            editor.on('selectionUpdate', updateCursorPosition);

            return () => {
                editor.off('selectionUpdate', updateCursorPosition);
            };
        }
    }, [editor]);

    // Update highlighted text when cursor position changes
    useEffect(() => {
        if (cursorPositionCharCount && displayPromptBar) {
            const {from, to} = cursorPositionCharCount;
            
            // Get highlighted text
            const highlightedText = editor.state.doc.textBetween(from, to, ' ');
            setHighlightedText(highlightedText);

            // Add new highlight
            editor.commands.setHighlight({
                from: from,
                to: to,
                color: 'indigo'
            });

            // Update highlighted text position
            setHighlightedTextCharCount({
                from: from,
                to: to
            });
        }
    }, [cursorPositionCharCount, displayPromptBar]);

    // Add cleanup when prompt bar is dismissed
    useEffect(() => {
        if (!displayPromptBar && highlightedTextCharCount) {
            // set selection to the highlighted text
            editor.commands.setTextSelection({
                from: highlightedTextCharCount.from,
                to: highlightedTextCharCount.to
            });

            // unset the highlight
            editor.commands.unsetHighlight();
            setHighlightedTextCharCount(null);
        }
    }, [displayPromptBar]);

    // listener for ctrl+k to show the prompt bar
    useEffect(() => {
        const handleKeyDown = (event) => {
            if ((event.ctrlKey || event.metaKey) && event.key === 'k') {
                event.preventDefault(); // Prevent the default action
                setDisplayPromptBar(true); // Show the prompt bar
            } else if (event.key === 'Escape' && displayPromptBar) {
                setDisplayPromptBar(false); // Hide the prompt bar
            }
        };

        // Attach keydown listener to the editor
        if (editorRef.current) {
            editorRef.current.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            if (editorRef.current) {
                editorRef.current.removeEventListener('keydown', handleKeyDown);
            }
        }
    }, [editorRef]); // Only run when editor is initialized

    return (
        <div
        >
            {editor && <BubbleMenu className="bubble-menu" tippyOptions={{ duration: 100 }} editor={editor}>
                <div className="flex flex-col p-1">
                    <div className="flex items-center gap-2">
                        <button
                            onClick={() => editor.chain().focus().toggleBold().run()}
                            className={`font-bold ${editor.isActive('bold') ? 'is-active text-white' : ''}`}
                        >
                            Bold
                        </button>
                        <button
                            onClick={() => editor.chain().focus().toggleItalic().run()}
                            className={`italic ${editor.isActive('italic') ? 'is-active text-white' : ''}`}
                        >
                            Italic
                        </button>
                        <button
                            onClick={() => editor.chain().focus().toggleStrike().run()}
                            className={`line-through ${editor.isActive('strike') ? 'is-active text-white' : ''}`}
                        >
                            Strike
                        </button>
                        <button
                            onClick={() => editor.chain().focus().toggleUnderline().run()}
                            className={`underline ${editor.isActive('underline') ? 'is-active text-white' : ''}`}
                        >
                            Underline
                        </button>
                        <button
                            onClick={() => editor.chain().focus().unsetHighlight().run()}
                            className={editor.isActive('highlight') ? 'is-active text-white' : 'text-gray-500' }
                        >
                            <span className="material-symbols-outlined">
                                format_ink_highlighter
                            </span>
                        </button>

                    </div>
                    {editor.isActive('table') && (
                        <div className="flex flex-col space-y-1">
                            <hr className="my-2" />
                            <span className="text-gray-500">Table Setting</span>
                            <div className="flex flex-col justify-start space-y-1">
                                <button
                                    onClick={() => editor.chain().focus().deleteColumn().run()}
                                    className="text-gray-600 whitespace-nowrap flex items-center"
                                >
                                    <span className="material-symbols-outlined ">
                                        delete
                                    </span>
                                    Delete Column
                                </button>
                                <button
                                    onClick={() => editor.chain().focus().deleteRow().run()}
                                    className="text-gray-600 whitespace-nowrap flex items-center"
                                >
                                    <span className="material-symbols-outlined ">
                                        delete
                                    </span>
                                    Delete Row
                                </button>
                                <button
                                    onClick={() => editor.chain().focus().deleteTable().run()}
                                    className="text-gray-600 whitespace-nowrap flex items-center"
                                >
                                    <span className="material-symbols-outlined ">
                                        delete
                                    </span>
                                    Delete Table
                                </button>
                                <hr className="my-2" />
                                <button
                                    onClick={() => editor.chain().focus().addColumnAfter().run()}
                                    className="text-gray-800 whitespace-nowrap flex items-center"
                                >
                                    <span className="material-symbols-outlined ">
                                        add_column_left
                                    </span>
                                    Add Column To Left
                                </button>
                                <button
                                    onClick={() => editor.chain().focus().addColumnBefore().run()}
                                    className="text-gray-800 whitespace-nowrap flex items-center"
                                >
                                    <span className="material-symbols-outlined ">
                                        add_column_right
                                    </span>
                                    Add Column To Right
                                </button>
                                <hr className="my-2" />
                                <button
                                    onClick={() => editor.chain().focus().addRowAfter().run()}
                                    className="text-gray-800 whitespace-nowrap flex items-center"
                                >
                                    <span className="material-symbols-outlined ">
                                        add_row_below
                                    </span>
                                    Add Row To Below
                                </button>
                                <button
                                    onClick={() => editor.chain().focus().addRowBefore().run()}
                                    className="text-gray-800 whitespace-nowrap flex items-center"
                                >
                                    <span className="material-symbols-outlined ">
                                        add_row_above
                                    </span>
                                    Add Row To Above
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </BubbleMenu>}

            {editor && <FloatingMenu key="floating-menu" className="floating-menu" tippyOptions={{ duration: 100 }} editor={editor}>
                <button
                    onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                    className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
                >
                    H1
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                    className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
                >
                    H2
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleBulletList().run()}
                    className={editor.isActive('bulletList') ? 'is-active' : ''}
                >
                    <ListBulletIcon className="w-4 h-4" />
                </button>
                <button
                    onClick={() => editor.chain().focus().setParagraph().run()}
                    className={editor.isActive('paragraph') ? 'is-active' : ''}
                >
                    P
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleOrderedList().run()}
                    className={editor.isActive('orderedList') ? 'is-active' : ''}
                >
                    <NumberedListIcon />
                </button>
                <button
                    onClick={() => editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()
                    }
                >
                    <TableCellsIcon className="w-4 h-4" />
                </button>
            </FloatingMenu>}
            {/* Placeholder to prevent layout shifts */}
            <div ref={placeholderRef} style={{ height: isFloating ? placeholderHeight : 0 }}></div>
            {/* <div
                className={`transition-all duration-300 flex items-center justify-between mb-5 ${
                    isFloating
                      ? "fixed top-0 left-0 w-full bg-white shadow-md z-50"
                      : "relative"
                  }`}
                style={{ backdropFilter: 'blur(8px)', backgroundColor: 'rgba(255, 255, 255, 0.95)' }}
                ref={controlGroupRef}
            >
                <ControlGroup editor={editor} />
                <p className="text-sm text-gray-500 flex items-center gap-1">
                    {savingText}
                    {savingText === 'Saving' && <ArrowPathIcon className="w-4 h-4 animate-spin" />}
                </p>
            </div> */}
            <div
                className="flex items-center justify-between mb-5"
            >
                <ControlGroup editor={editor} />
                <LastSavedText savingText={savingText} />
            </div>
            <EditorContent editor={editor} ref={editorRef}/>
            <PromptBar 
                displayPromptBar={displayPromptBar} 
                setDisplayPromptBar={setDisplayPromptBar} 
                cursorPositionCharCount={cursorPositionCharCount} 
                cursorPositionCoord={cursorPositionCoord} 
                highlightedText={highlightedText}
                editor={editor}
            />
        </div>
    )
}

export default Tiptap;

function ControlGroup({editor}) {
    return (
        <div className="control-group">
            <div className="button-group flex items-center gap-2">
                <button
                    onClick={() => editor.chain().focus().toggleBulletList().run()}
                    className={editor.isActive('bulletList') ? 'is-active' : ''}
                >
                    <ListBulletIcon className="w-4 h-4" />
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleOrderedList().run()}
                    className={editor.isActive('orderedList') ? 'is-active' : ''}
                >
                    <NumberedListIcon />
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                    className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
                >
                    H1
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                    className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
                >
                    H2
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
                    className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
                >
                    H3
                </button>
                <button
                    onClick={() => editor.chain().focus().setParagraph().run()}
                    className={editor.isActive('paragraph') ? 'is-active' : ''}
                >
                    P
                </button>
            </div>
      </div>
    )
}

function LastSavedText({savingText}) {
    return (
        <p className="text-sm text-gray-500 flex items-center gap-1">
            {savingText}
            {savingText === 'Saving' && <ArrowPathIcon className="w-4 h-4 animate-spin" />}
        </p>
    )
}

function PromptBar({
    displayPromptBar, 
    setDisplayPromptBar, 
    cursorPositionCharCount,
    cursorPositionCoord,
    highlightedText,
    editor
}) {
    const [prompt, setPrompt] = useState('');
    const promptBarRef = useRef(null);
    const promptInputRef = useRef(null);
    const [assistantResponse, setAssistantResponse] = useState(null);

    const [submitBtnText, setSubmitBtnText] = useState('Submit');

    useEffect(() => {
        // focus the prompt bar
        if (promptInputRef.current) {
            promptInputRef.current.focus();
            // clear the prompt
            setPrompt('');
            setSubmitBtnText('Submit');
            setAssistantResponse(null);
        }
    }, [displayPromptBar]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            // Check if the click is outside the prompt bar
            if (promptBarRef.current && !promptBarRef.current.contains(event.target)) {
                setDisplayPromptBar(false); // Dismiss the prompt bar
            }
        };
    
        // Attach the event listener
        document.addEventListener('mousedown', handleClickOutside);
    
        // Cleanup the event listener on unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }

    }


    const handleSubmit = () => {
        setSubmitBtnText('Submitting...');

        let body = {
            prompt: prompt,
            content: editor.getText(),
            inputPosition: cursorPositionCharCount.from,
            selectedText: highlightedText,
            userID: sessionStorage.getItem('userid')
        }

        let url = `${GlobalVars.ASSISTANT_DOMAIN}/api/v1/writing/general-writing-assistant`;
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Error submitting prompt');
            }
        })
        .then(data => {
            setAssistantResponse(data.response);
            setSubmitBtnText('Submit');
        })
        .catch(error => {
            console.error('Error submitting prompt:', error);
            setAssistantResponse('Error in generating a response');
            setSubmitBtnText('Resubmit');
        })
    }

    const [promptBarPos, setPromptBarPos] = useState({
        top: cursorPositionCoord.top + 30,
        // left: 20
    });

    useEffect(() => {
        let promptBarHeight = 56;
        if (promptBarRef.current) {
            promptBarHeight = promptBarRef.current.clientHeight;
        }

        // check if the prompt bar is off the screen
        if (cursorPositionCoord.top + 30 + promptBarHeight > window.innerHeight) {
            setPromptBarPos({
                top: cursorPositionCoord.top - promptBarHeight - 30,
                // left: 20
            });
        } else {
            setPromptBarPos({
                top: cursorPositionCoord.top + 30,
                // left: 20
            });
        }
    }, [cursorPositionCoord, assistantResponse]);

    return (
        <>
            {displayPromptBar && (
                <div 
                    className={`fixed bg-white shadow-lg rounded-lg p-4 flex justify-between flex-col w-[500px] max-h-[550px] overflow-y-auto left-[20px] lg:left-[300px]`}
                    style={promptBarPos}
                    ref={promptBarRef}
                >
                    <button
                        className="absolute top-1 right-1 text-gray-400 hover:text-gray-600"
                        onClick={() => setDisplayPromptBar(false)}
                    >
                        <XMarkIcon className="h-4 w-4" />
                    </button>
                    <input
                        type="text"
                        placeholder="Enter your prompt..."
                        className="outline-none p-0 border-0 focus:ring-0 focus:border-0 focus:outline-none w-full text-sm"
                        onKeyDown={handleKeyDown}
                        value={prompt}
                        onChange={(e) => setPrompt(e.target.value)}
                        ref={promptInputRef}
                    />
                    {prompt && (
                        <button
                            className="mt-2 bg-indigo-600 text-white py-1 px-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 text-xs flex items-center"
                            onClick={handleSubmit}
                        >
                            {submitBtnText}
                            {submitBtnText === 'Submitting...' && <ArrowPathIcon className="w-4 h-4 ml-1 animate-spin" />}
                        </button>
                    )}
                    {assistantResponse && (
                        <div className="mt-4">
                            <div className="bg-gray-50 rounded-md p-3 text-sm text-gray-700 max-h-[400px] overflow-y-auto">                           
                                {!assistantResponse.includes('Error in generating a response') && (
                                    <>
                                        <DisplayPromptResponse assistantResponse={assistantResponse} />
                                        
                                        <button
                                            className="mt-2 bg-green-600 text-white py-1 px-2 rounded-md hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 text-xs"
                                            onClick={() => {
                                                // extract the suggested text from the assistant response wrapped with ```
                                                let match = assistantResponse.match(/```([\s\S]*?)```/);
                                                if (!match) {
                                                    console.error('No code block found in response');
                                                    return;
                                                }
                                                let suggestedText = match[1].trim();

                                                console.log(suggestedText);
                                                
                                                // replace the selected text with the suggested text
                                                editor.commands.insertContentAt({
                                                    from: cursorPositionCharCount.from,
                                                    to: cursorPositionCharCount.to
                                                }, suggestedText);
                                                setDisplayPromptBar(false);
                                            }}
                                        >
                                            Apply Changes
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    )
}


function DisplayPromptResponse({assistantResponse}) {
    // Split the response into parts: before code, code, and after code
    // Split the response into parts: before code, code, and after code
    const parseResponse = (response) => {
        // Check if there's any code block
        const codeMatch = response.match(/([\s\S]*?)```([\s\S]*?)```([\s\S]*)/);

        if (!codeMatch) {
            // If no code block found, treat entire response as explanation
            return {
                beforeText: response.trim(),
                suggestedText: null,
                afterText: null
            };
        }

        return {
            // If parts are empty strings, convert to null
            beforeText: codeMatch[1].trim() || null,
            suggestedText: codeMatch[2].replace(/^\s+|\s+$/g, '') || null,
            afterText: codeMatch[3].trim() || null
        };
    };

    const { beforeText, suggestedText, afterText } = parseResponse(assistantResponse);

    return (
        <div className="mt-4 space-y-4">
            {/* Display explanation before code */}
            {beforeText && (
                <div className="bg-gray-50 rounded-lg">
                    {/* <div className="text-xs text-gray-500 font-medium mb-2">Assistant's Explanation:</div> */}
                    <div className="text-sm text-gray-700 prose prose-sm max-w-none">
                        {beforeText}
                    </div>
                </div>
            )}
            
            {/* Display suggested text if it exists */}
            {suggestedText && (
                <div className="bg-indigo-50 border border-indigo-200 rounded-lg p-4">
                    <div className="text-xs text-indigo-600 font-medium mb-2">Proposed Changes:</div>
                    <div className="bg-white rounded-md p-3 overflow-x-auto">
                        <div className="text-sm text-gray-800 font-mono whitespace-pre-wrap" dangerouslySetInnerHTML={{ __html: suggestedText }}>
                        </div>
                    </div>
                </div>
            )}

            {/* Display explanation after code */}
            {afterText && (
                <div className="bg-gray-50 rounded-lg">
                    {/* <div className="text-xs text-gray-500 font-medium mb-2">Additional Notes:</div> */}
                    <div className="text-sm text-gray-700 prose prose-sm max-w-none">
                        {afterText}
                    </div>
                </div>
            )}
        </div>
    )
}