import { useState, useEffect } from 'react'
import Select, { components } from 'react-select'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'

// large list filter component using react-select and return the selected value as object
export default function LargeListFilterComponent({data, filter, setFilter}) {
    const [selectedOption, setSelectedOption] = useState(filter);
    const [options, setOptions] = useState();
  
    useEffect(() => {
      let dataOptions = data.map(item => {
        return {
          value: item,
          label: item.name,
        }
      })
  
      setOptions(dataOptions);
    }, [data])
  
    // update selectedOption when filter changes
    useEffect(() => {
      // return if options is null
      if (options === null || options === undefined) {
        return;
      }
  
      // return if filter is null
      if (filter === null || filter === undefined) {
        setSelectedOption(options[0]);
        return;
      }
  
      // compare filter with options
      let found = options.find(item => item.label === filter.name);
  
      if (found) {
        setSelectedOption(found);
      } else {
        setSelectedOption(null);
      }
  
    }, [filter, options])
  
    const handleChange = (selectedOption) => {
      setSelectedOption(selectedOption);
      setFilter(selectedOption['value']);
  
    }
  
    const DropdownIndicator = (props) => {
      return (
        <components.DropdownIndicator {...props}>
          <ChevronUpDownIcon label="ChevruonUpDown" className='h-5 w-5' />
        </components.DropdownIndicator>
      );
    };
  
    const customStyles = {
      control: (base) => ({
        ...base,
        minHeight: 'initial',
        borderRadius: '0.375rem',
        borderColor: '#d1d5db',
      }),
      valueContainer: (base) => ({
        ...base,
        height: '34px', // Adjust height here
        padding: '0 8px',
      }),
      clearIndicator: (base) => ({
        ...base,
        padding: '4px', // Adjust padding here
      }),
      dropdownIndicator: (base) => ({
        ...base,
        padding: '4px', // Adjust padding here
      }),
      option: (base, state) => ({
        ...base,
        backgroundColor: state.isSelected ? '#4f46e5': 'white',
        color: state.isSelected ? 'white': 'black',
      }),
    };
  
    return (
      <Select
        value={selectedOption}
        onChange={handleChange}
        options={options}
        components={{ DropdownIndicator }}
        styles={customStyles}
        className="text-xs mt-1 sm:mr-2 min-w-[180px] skip-trans shadow-sm"
      />
    ) 
  }