import { useState, useRef, useEffect } from 'react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function MoreDropdown({
    item,
    isMobile,
    setIsEdit,
    setEditId,
    setOpen,
    setEditItemInfo,
    handleIssueResolve,
    handleJobCreation,
    handleIssueCancel,
    handleIssueReopen,
}) {
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef(null)

    // Close dropdown when clicking outside
    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => document.removeEventListener('mousedown', handleClickOutside)
    }, [])

    return (
        <div className="relative flex-none" ref={dropdownRef}>
            <button
                className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900"
                onClick={() => setIsOpen(!isOpen)}
            >
                <span className="sr-only">Open options</span>
                <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
            </button>

            {isOpen && (
                <div className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    {isMobile && (
                        <button
                            onClick={() => {
                                setIsEdit(true)
                                setEditId(item._id)
                                setOpen(true)
                                setEditItemInfo(item)
                                setIsOpen(false)
                            }}
                            className="block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 cursor-pointer"
                        >
                            查看<span className="sr-only">, {item.name}</span>
                        </button>
                    )}
                    
                    {item.status !== "Resolved" && (
                        <button
                            onClick={(e) => {
                                handleIssueResolve(e, item)
                                setIsOpen(false)
                            }}
                            data-id={item._id}
                            className="block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 cursor-pointer"
                        >
                            {item.status === "Pending" ? "接受工單" : "完成工單"}
                            <span className="sr-only">, {item.name}</span>
                        </button>
                    )}

                    <button
                        onClick={(e) => {
                            handleIssueCancel(e)
                            setIsOpen(false)
                        }}
                        data-id={item._id}
                        className="block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 cursor-pointer"
                    >
                        取消<span className="sr-only">, {item.name}</span>
                    </button>

                    {item.status !== "Pending" && (
                        <button
                            onClick={(e) => {
                                handleIssueReopen(e)
                                setIsOpen(false)
                            }}
                            data-id={item._id}
                            className="block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 cursor-pointer"
                        >
                            重開<span className="sr-only">, {item.name}</span>
                        </button>
                    )}

                    <button
                        onClick={(e) => {
                            handleJobCreation(e, item)
                            setIsOpen(false)
                        }}
                        className="block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900 hover:bg-gray-50 cursor-pointer"
                    >
                        生成工作<span className="sr-only">, {item.name}</span>
                    </button>
                </div>
            )}
        </div>
    )
}