// import libraries
// import { MultiSelect } from "react-multi-select-component";
import PreviewSelectedOptions from "../PreviewSelectedOption";
import { useEffect, useState, useRef } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
export default function JobLocationComponent({
    locationList,
    locations,
    setLocations,    
}) {
    const { t } = useTranslation();

    // custom value renderer for multi select
    // const customValueRenderer = (selected, _options) => {
    //     if (selected.length === 0) {
    //     return "";
    //     } else if (selected.length === 1) {
    //     if (selected[0].label.length > 30) {
    //         return selected[0].label.slice(0, 30) + "...";
    //     } 
    //     return selected[0].label
    //     }
    //     return "已選擇 " + selected.length + " 項";
    // }

    const [searchResult, setSearchResult] = useState([]);
    const searchResultRef = useRef(searchResult);
    const filterOptions = (option, inputValue) => {
        return option.label.toLowerCase().includes(inputValue.toLowerCase());
    };

    // Keep searchResultRef in sync with the latest searchResult state
    // useEffect(() => {
    //     searchResultRef.current = searchResult;
    // }, [searchResult]);

    // select all options from search result when users type in enter 
    // useEffect(() => {
    //     // add event listener for key press for #location_input_selector
    //     const location_input_selector = document.getElementById('location_input_selector');
    //     if (location_input_selector) {
    //         const handleKeyDown = (e) => {
    //             if (e.key === 'Enter') {
    //                 e.preventDefault();

    //                 const currentSearchResult = searchResultRef.current;
    
    //                 if (currentSearchResult.length === 0) {
    //                     console.log("No search result");
    //                     return;
    //                 }
    
    //                 let newLocations = locations;
    //                 console.log(currentSearchResult)
    //                 currentSearchResult.forEach((option) => {
    //                     if (!newLocations.find((location) => location.value === option.value)) {
    //                         newLocations.push(option);
    //                     }
    //                 });

    //                 setLocations(newLocations);
    //             }
    //         };


    //         location_input_selector.addEventListener('keydown', handleKeyDown);
    
    //         return () => {
    //             location_input_selector.removeEventListener('keydown', handleKeyDown);
    //         };
    //     }
    // }, []);
    
    return (
        <div>
            <label htmlFor="location_input" className="block text-sm font-medium leading-6 text-gray-900">
                {t("客戶/地點")}
            </label>
            <p
            className='text-xs text-red-500'
            >{t("必填欄")}</p>
            <div className="mt-2" id="location_input_selector">
                <Select
                    closeMenuOnSelect={false}
                    options={locationList}
                    isMulti
                    className="text-sm cursor-pointer"
                    onChange={(value) => {
                        setLocations(value);
                    }}
                    placeholder={t("選擇地點")}
                    filterOption={filterOptions}
                    value={locations}
                />
                {/* info tag that show how many seleceted */}
                <p className="text-xs text-gray-500 mt-1 flex items-center">
                <div
                    className='ml-1'
                >
                    <PreviewSelectedOptions options={locations} />
                </div>
                </p>
            </div>
            <p className="mt-2 text-sm text-red-500 hidden" >
                {t("不能為空")}
            </p>
        </div>
    );
}