import { Fragment } from 'react'
import SwitchViewButton from '../SwitchViewButton';
import {useState, useEffect, useRef} from 'react';
import { 
    ArrowPathIcon, 
    ChevronRightIcon, 
    ChevronLeftIcon ,
    ChevronUpIcon,
    MapPinIcon,
    UserIcon
} from '@heroicons/react/24/outline';
import GlobalVars from '../../../../Config';


const ReasonsMap = {
    "sick_leave": "病假",
    "annual_leave": "年假",
    "maternity_leave": "產假",
    "no_pay_leave": "無薪假",
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const processEvent = (events, dateRange) => {
    // Initialize eventsByDate with all dates in range
    const eventsByDate = {};
    const start = new Date(dateRange.startDate);
    const end = new Date(dateRange.endDate);
    
    // Add an entry for each date in range
    for (let date = start; date <= end; date.setDate(date.getDate() + 1)) {
        const dateStr = date.toISOString().split('T')[0];
        eventsByDate[dateStr] = {
            name: dateStr,
            event: []
        };
    }

    // Add events to their respective dates
    events.forEach(event => {
        if (!event) {
            return;
        }
        
        const date = event.startDateTime?.split('T')[0];
        if (!date || !eventsByDate[date]) {
            return;
        }

        if (!eventsByDate[date].event) {
            eventsByDate[date].event = [];
        }
        
        eventsByDate[date].event.push({
            id: event._id,
            type: event.typeName,
            startTime: event.startTime,
            startDateTime: event.startDateTime,
            endTime: event.endTime,
            endDateTime: event.endDateTime,
            locationName: event.locationName,
            assignedToName: event.assignedToName
        });
    });

    // Convert to array format
    return Object.values(eventsByDate);
}

const getCalendarItems = async (calendarItems, setCalendarItems, dateRange, filters) => {
    
    fetch(GlobalVars.BACKEND_DOMAIN + '/api/v1/calendar/job-event', {
        method: 'POST',
        headers: {
            'Authorization': sessionStorage.getItem("idToken"),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
            organizationID: localStorage.getItem("organizationId")
        })
    })
    .then(response => {
        if (response.ok) {
            return response.json()
        } else {
            console.log(response.json())
            throw new Error("Failed to fetch calendar items") 
        }
    })
    .then(data => {
        let processedEvents = processEvent(data, dateRange);
        getLeaveItems(processedEvents, setCalendarItems, dateRange, filters)
    })
    .catch(error => {
        console.error("Failed to fetch calendar items:", error)
        setCalendarItems([])
    })
}

const getLeaveItems = async (processedEvents, setCalendarItems, dateRange, filters) => {
    fetch(GlobalVars.BACKEND_DOMAIN + '/api/v1/leaves?organizationID=' + localStorage.getItem("organizationId") + "&startTime=" + dateRange.startDate, {
        method: 'GET',
        headers: {
            'Authorization': sessionStorage.getItem("idToken"),
        },
    })
    .then(response => {
        if (response.ok) {
            return response.json()
        } else {
            console.log(response.json())
            throw new Error("Failed to fetch leave items") 
        }
    })
    .then(rawData => {
        let data = rawData.leaves;
        
        // Process and combine calendar items with leave data
        // Add leaves array to each date in processedEvents
        let combinedItems = processedEvents.map(event => ({
            ...event,
            leaves: []
        }));

        // For each leave record in data
        for (let leave of data) {
            // Skip if leave dates don't overlap with date range
            if (leave.endDate < dateRange.startDate || leave.startDate > dateRange.endDate) {
                continue;
            }

            // For each date in processedEvents
            combinedItems.forEach(dateEntry => {
                // Check if this date falls within the leave period
                if (dateEntry.name >= leave.startDate && dateEntry.name <= leave.endDate) {
                    dateEntry.leaves.push({
                        ...leave,
                        currentDate: dateEntry.name
                    });
                }
            });
        }

        // Sort combined items by date
        combinedItems.sort((a, b) => a.name.localeCompare(b.name));

        setCalendarItems(combinedItems);
        
    })
    .catch(error => {
        console.error("Failed to fetch leave items:", error)
    })
}

export default function ItemView({view, setView, setOpenForm, filters}  ) {
    const [calendarItems, setCalendarItems] = useState([])
    const [leaveItems, setLeaveItems] = useState([])
    const [dateRange, setDateRange] = useState({
        "startDate": new Date().toISOString().split('T')[0],
        "endDate": new Date().toISOString().split('T')[0]
    })
    useEffect(() => {
        // return if view is not item
        if (view !== 'item') {
            return
        }
        getCalendarItems(calendarItems, setCalendarItems, dateRange, filters)
    }, [dateRange, filters, view])

    // handle last day
    const handleLastDay = () => {
        let newDate = new Date(dateRange.startDate);
        newDate.setDate(newDate.getDate() - 1);
        setDateRange(prevRange => ({
            ...prevRange,
            startDate: newDate.toISOString().split('T')[0],
        }))
    }

    // handle next day
    const handleNextDay = () => {
        let newDate = new Date(dateRange.endDate);
        newDate.setDate(newDate.getDate() + 1);
        setDateRange(prevRange => ({
            ...prevRange,
            endDate: newDate.toISOString().split('T')[0],
        }))
    }

    // loading icon
    const [isIndicatorInView, setIsIndicatorInView] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const loadingIconRef = useRef(null);
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                const isVisible = entry.isIntersecting;
                // console.log('IntersectionObserver entry:', entry); // Debugging log
                setTimeout(() => setIsIndicatorInView(isVisible), 100); // 100ms debounce
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.1,
            }
        );
  
        const element = loadingIconRef.current;
        if (element) {
            // console.log('Observing element:', element); // Debugging log
            observer.observe(element);
        } 
  
        return () => {
            if (element) observer.unobserve(element);
        };
    }, [loadingIconRef.current]);

    useEffect(() => {
        if (isIndicatorInView) {
            handleNextDay();
        }
    }, [isIndicatorInView])

    useEffect(() => {
        console.log("filters", filters)
    }, [filters])

    return (
        <div className="mt-6">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                <h1 className="text-base font-semibold text-gray-900">日曆項目</h1>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none flex items-center gap-x-2">
                    <SwitchViewButton view={view} setView={setView} setOpenForm={setOpenForm} />
                    <div className="mx-2 sm:mx-6 h-6 w-px bg-gray-300" />
                    <button
                        type="button"
                        className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={() => setOpenForm(true)}
                    >
                        新增日程
                    </button>
                </div>
            </div>
            <div className="mt-8 flow-root">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <button className="ml-4 sm:ml-0 text-gray-500 hover:text-gray-900 duration-300 transition-all cursor-pointer mb-4 flex items-center" onClick={handleLastDay}>
                            <ChevronUpIcon className="w-4 h-4 mr-1" />
                            上一日
                        </button>
                        <table className="min-w-full">
                            <colgroup>
                                <col className="w-3/4" /> {/* first column takes 75% */}
                                <col className="w-1/4" /> {/* second column takes 25% */}
                            </colgroup>
                            <tbody className="bg-white">

                                {calendarItems.map((calendarItem) => (
                                    <Fragment key={calendarItem.name}>
                                        <tr className="border-t border-gray-200 bg-gray-200">
                                            <td
                                                // scope="colgroup"
                                                // colSpan={5}
                                                className=" py-2 pl-4 sm:pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                                            >
                                                <span>
                                                    {calendarItem.name}
                                                </span>
                                            </td>
                                            <td className="text-sm text-gray-500 text-right">
                                                <div
                                                    className="flex flex-col sm:flex-row items-center justify-end"
                                                >
                                                    <span className="mx-2 text-sm text-gray-500 whitespace-nowrap">
                                                        工作數量: {calendarItem.event.length}
                                                    </span>
                                                    <span className="mx-2 text-sm text-gray-500 whitespace-nowrap">
                                                        請假數量: {calendarItem.leaves?.length || 0}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                        {(!calendarItem.leaves?.length && !calendarItem.event?.length) && (
                                            <tr className="border-t border-gray-200">
                                                <td colSpan={2} className="whitespace-nowrap py-4 text-sm text-gray-500 text-center">
                                                    沒有找到相關記錄
                                                </td>
                                            </tr>
                                        )}
                                        {(!filters?.type?.length || filters?.type?.includes('leaves')) && (
                                            calendarItem.leaves?.map((leave, leaveIdx) => (
                                                <tr
                                                    key={leave._id}
                                                    className={classNames(leaveIdx === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t hover:bg-gray-100 duration-300 transition-all cursor-pointer')}
                                                >
                                                    <td className="whitespace-nowrap py-2 sm:py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3 ">
                                                        <div className="flex items-center justify-start">
                                                            {ReasonsMap[leave.reason]}
                                                            <UserIcon className="w-4 h-4 mx-1" />
                                                            <span className="text-gray-500">{leave.name}</span>
                                                        </div>
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 text-sm text-gray-500 flex flex-col items-end h-full">
                                                        <span className="flex-1 flex items-end">{leave.startTime}</span>
                                                        <span className="flex-1 flex items-end">{leave.endTime}</span>
                                                    </td>
                                                </tr>
                                            ))
                                        )}
                                        {(!filters?.type?.length || filters?.type?.includes('job')) && (
                                            calendarItem.event.map((event, eventIdx) => (
                                                <tr
                                                    key={event._id}
                                                    className={classNames(eventIdx === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t hover:bg-gray-100 duration-300 transition-all cursor-pointer')}
                                                >
                                                    <td className="py-2 sm:py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                                                        <div className="flex flex-col sm:flex-row items-start sm:items-center sm:justify-start">
                                                            {event.type}
                                                            <span className="sm:ml-2 text-gray-500 flex items-center whitespace-nowrap">
                                                                <MapPinIcon className="w-4 h-4 mr-1" />
                                                                {event.locationName}
                                                            </span>
                                                            <span className="sm:ml-2 text-gray-500 flex items-center">
                                                                <UserIcon className="w-4 h-4 mr-1" />
                                                                {event.assignedToName}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td className="text-sm text-gray-500">
                                                        <div className="flex flex-col items-end pr-4">
                                                            <span className="">{event.startTime}</span>
                                                            <span className="">{event.endTime}</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        )}
                                    </Fragment>
                                ))}
                                <tr>
                                    <td colSpan={2} className="text-center py-4 flex items-center justify-center" ref={loadingIconRef}>
                                        <span className="mr-2 flex items-center">
                                            <ArrowPathIcon className="w-4 h-4 animate-spin mr-1" />
                                            載入中
                                        </span>
                                        <button
                                            onClick={handleNextDay}
                                            className="inline-flex items-center rounded-md bg-white px-3  py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                        >
                                            下一天
                                            <ChevronRightIcon className="ml-2 h-5 w-5" aria-hidden="true" />
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
