import SignatureCanvas from 'react-signature-canvas';
import { useState, useEffect, useRef } from "react";
import { 
    PencilIcon
} from "@heroicons/react/24/outline";
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from "@headlessui/react";

export default function SignatureDialog({
    open,
    setOpen,
    submitDetails,
    setSubmitDetails
}) {
    const padRef = useRef(null);

    const clear = () => padRef.current.clear();

    const handleConfirm = () => {
        setOpen(false);
        setSubmitDetails({
            ...submitDetails,
            signature: padRef.current.toDataURL(),
            customerSignedAt: new Date().toISOString()
        });
    }

    // Reset and load signature when dialog opens
    useEffect(() => {
        if (!open) {
            // clear
            if (padRef.current) {
                padRef.current.clear();
            }
            return;
        }

        // Wait for next tick to ensure canvas is ready
        const timer = setTimeout(() => {
            if (submitDetails.signature && padRef.current) {
                try {
                    padRef.current.fromDataURL(submitDetails.signature);
                } catch (error) {
                    console.error('Error loading signature:', error);
                }
            }
        }, 100);

        return () => clearTimeout(timer);
    }, [open, submitDetails.signature]);

    return (
        <Dialog
        as="div"
        className="relative z-50"
        onClose={setOpen}
        open={open}
        >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start w-full">
                <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:size-10">
                    <PencilIcon aria-hidden="true" className="size-6 text-indigo-600" />
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                    <DialogTitle as="h3" className="text-base font-semibold text-gray-900">
                    簽名確認
                    </DialogTitle>
                    <div className="mt-2 space-y-4">
                        <p className="text-sm text-gray-500">
                            請在下方簽名
                        </p>
                        <div className="border border-gray-300 rounded-md">
                            <SignatureCanvas
                            ref={padRef}
                            penColor='black'
                            canvasProps={{
                                className: 'w-full h-48'
                            }}
                            />
                        </div>
                        <button
                            type="button"
                            onClick={clear}
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        >
                            清除簽名
                        </button>
                    </div>
                </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex flex-row-reverse">
                <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                    onClick={handleConfirm}
                >
                    確認
                </button>
                <button
                    type="button"
                    data-autofocus
                    onClick={() => setOpen(false)}
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                    取消
                </button>
                </div>
            </DialogPanel>
            </div>
        </div>
        </Dialog>
    )

}