import React from 'react';
import Select from 'react-select';
import { MultiSelect } from "react-multi-select-component";
import JobReminderDialog from '../JobReminderDialog';
import { useTranslation } from 'react-i18next';

export default function FilterContainer({
    locChoices,
    selectedLoc,
    setSelectedLoc,
    jobTypeChoices,
    selectedJobType,
    setSelectedJobType,
    staffChoices,
    selectedStaff,
    setSelectedStaff,
    selectedRequiredInspection,
    setSelectedRequiredInspection,
    selectedEstimatedDate,
    setSelectedEstimatedDate,
    jobReminderList,
    setJobReminderList,
}) {
    const { t } = useTranslation();

    // Custom filter function for job types
    const customFilter = (option, inputValue) => {
        return option.label.toLowerCase().includes(inputValue.toLowerCase());
    };

    return (
        <div id="filter-container" className="md:grid md:grid-cols-6 gap-2.5 mb-3 flex flex-wrap">
            <MultiSelect
                options={locChoices}
                value={selectedLoc || []}
                onChange={setSelectedLoc}
                labelledBy={"Select"}
                className='text-sm'
                overrideStrings={{
                    "selectSomeItems": t('選擇地點'),
                    "allItemsAreSelected": t('所有地點已選擇'),
                    "selectAll": t('選擇所有地點'),
                    "search": t('搜尋'),
                }}
            />    
            <Select
                options={jobTypeChoices}
                placeholder={t('選擇工作類別')}
                value={selectedJobType}
                onChange={setSelectedJobType}
                className='text-sm text-nowrap h-[40px]'
                filterOption={customFilter}
            />  
            <MultiSelect
                options={staffChoices}
                value={selectedStaff || []}
                onChange={setSelectedStaff}
                labelledBy={"Select"}
                className='text-sm h-[36px]'
                placeholder={t('選擇施工人員')}
                overrideStrings={{
                    "selectSomeItems": t('選擇施工人員'),
                    "allItemsAreSelected": t('所有施工人員已選擇'),
                    "selectAll": t('選擇所有施工人員'),
                    "search": t('搜尋'),
                }}
            />                    
            <div className="flex items-center justify-center col-span-1">
                <label 
                    htmlFor="required-inspection"
                    className="flex items-center cursor-pointer text-sm mr-2"
                >{t('需要檢查 (全選)')}</label>
                <input 
                    type="checkbox"
                    id="required-inspection"
                    name="required-inspection"
                    checked={selectedRequiredInspection}
                    className='w-4 h-4'
                    onChange={(e) => {
                        setSelectedRequiredInspection(e.target.checked);
                    }}
                />
            </div>
            <div className="relative">
                <label
                    htmlFor="name"
                    className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                >
                    {t('預計工作日期')}
                </label>
                <input
                    type="date"
                    name="estimatedDate"
                    id="estimatedDate"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={selectedEstimatedDate}
                    onChange={(e) => {
                        setSelectedEstimatedDate(e.target.value);
                    }}
                />
            </div>
            <JobReminderDialog 
                staffChoices={staffChoices} 
                statusNotificationList={jobReminderList} 
                setStatusNotificationList={setJobReminderList} 
                t={t}
            />
        </div>
    )
}