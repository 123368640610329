

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

// page tabs
export default function PageTabs({tabs, setTabs}) {
    
    const handleTabSelection = (e) => {
      // check if the target is a select element or not
      let selectedOption = ""
      if (e.target.tagName !== 'SELECT') {
        selectedOption = e.target.innerHTML;
      } else {
        selectedOption = e.target.value;
      }
  
      // update the current tab
      let newTabs = tabs.map((tab) => {
          if (tab.name === selectedOption) {
              return {
                  name: tab.name,
                  href: tab.href,
                  current: true,
              }
          } else {
              return {
                  name: tab.name,
                  href: tab.href,
                  current: false,
              }
          }
      });
  
      setTabs(newTabs);
    }
  
    return (
        <div className="mb-10">
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
            <select
              id="tabs"
              name="tabs"
              className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              defaultValue={tabs.find((tab) => tab.current).name}
              onChange={handleTabSelection}
            >
              {tabs.map((tab) => (
                <option key={tab.name}>{tab.name}</option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <div className="border-b border-gray-200">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {tabs.map((tab) => (
                  <button
                    key={tab.name}
                    onClick={handleTabSelection}
                    className={classNames(
                      tab.current
                        ? 'border-indigo-500 text-indigo-600'
                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                      'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                    )}
                    aria-current={tab.current ? 'page' : undefined}
                  >
                    {tab.name}
                  </button>
                ))}
              </nav>
            </div>
          </div>
        </div>
      )
  }
  