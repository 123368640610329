import { useState } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { 
    DocumentArrowUpIcon,
    DocumentIcon,
    XMarkIcon
} from '@heroicons/react/24/outline'

import GlobalVars from '../../../../Config'

export default function NewFileDialog({open, setOpen}) {

  const [fileTypeList, setFileTypeList] = useState([])
  const [fileList, setFileList] = useState([])
  const [fileNameList, setFileNameList] = useState([])


  const getSignedUrl = async (projectId, organizationId) => {
    // get signed url from backend
    let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/project/signed-url"
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({ 
            fileTypeList: fileTypeList,
            fileList: fileList,
            fileNameList: fileNameList,
            projectID: projectId,
            organizationID: organizationId
      }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${sessionStorage.getItem('idToken')}`
      }
    })

    if (!response.ok) {
      throw new Error('Failed to get signed url')
    }

    return response.json()
  }

  const uploadFile = async (signedUrl, file, fileName) => {
    const response = await fetch(signedUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type
      }
    })

    if (!response.ok) {
      throw new Error(`未能上傳 ${fileName}`)
    }
    return response.ok
  }

  const handleFileListUpdate = async (projectId, organizationId) => {
    // post request to uplaod 
    let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/project/files"
    let body = {
        "projectID": projectId,
        "organizationID": organizationId,
        "fileNameList": fileNameList,
        "fileTypeList": fileTypeList,
        "assignedTo": localStorage.getItem('userid')
    }

    // get file size from fileList and create a fileSizeList
    let fileSizeList = fileList.map(file => file.size)
    body['fileSizeList'] = fileSizeList

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${sessionStorage.getItem('idToken')}`
      }
    })

    if (!response.ok) {
      throw new Error('Failed to update file list')
    } else {
        alert('檔案列表更新成功, 現在為你刷新頁面')
        window.location.reload()
    }

  }

  const handleUpload = async () => {
    // get project id from url
    const projectId = window.location.pathname.split('/').pop()
    
    // get organization ID from session storage
    const organizationId = sessionStorage.getItem('organizationId')

    try {
        // get signed url
        const signedUrls = await getSignedUrl(projectId, organizationId)
        const uploadPromises = signedUrls['urls'].map((signedUrl, index) => {
            const file = fileList[index];
            const fileName = fileNameList[index];

            return uploadFile(signedUrl, file, fileName)
                .then(result => {
                    console.log(`Successfully uploaded ${fileName}`)
                    return result;
                })
                .catch(error => {
                    console.error(`Error uploading file ${fileName}:`, error)
                    throw error;
                });
        });

        const results = await Promise.allSettled(uploadPromises);

        // Check if any uploads failed
        const failedUploads = results.filter(r => r.status === 'rejected');
        if (failedUploads.length > 0) {
            throw new Error(`${failedUploads.length} files failed to upload`);
        }

        // All uploads successful
        await handleFileListUpdate(projectId, organizationId);

    } catch (error) {
      console.error('Error uploading file:', error)
    }
  }

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setFileList([...fileList, file]);

    // update fileNameList
    setFileNameList([...fileNameList, file.name]);

    // update fileTypeList
    setFileTypeList([...fileTypeList, file.type]);
  }

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-50">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div>
              <div className="mx-auto flex size-12 items-center justify-center rounded-full bg-indigo-100">
                <DocumentArrowUpIcon aria-hidden="true" className="size-6 text-indigo-600" />
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <DialogTitle as="h3" className="text-base font-semibold text-gray-900">
                  上傳檔案
                </DialogTitle>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    從你的電腦選擇一個檔案上傳到這個專案。
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-6">
              <label className="block text-sm font-medium text-gray-700">檔案</label>
              <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                <div className="text-center">
                  <div className="mt-4 flex text-sm leading-6 text-gray-600">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                    >
                      <span>選擇一個檔案</span>
                      <input 
                        id="file-upload" 
                        name="file-upload" 
                        type="file" 
                        className="sr-only"
                        onChange={handleFileSelect}
                        multiple
                      />
                    </label>
                    <p className="pl-1">或拖放</p>
                  </div>
                  {/* <p className="text-xs leading-5 text-gray-600">PNG, JPG, PDF</p> */}
                </div>
              </div>
            </div>
            {fileList && fileList.map((file, index) => (
              <div key={index} className="mt-4 flex items-center justify-between rounded-md border border-gray-200 p-3">
                <div className="flex items-center">
                  <DocumentIcon className="size-5 text-gray-400" aria-hidden="true" />
                  <span className="ml-2 text-sm text-gray-900">{file.name}</span>
                </div>
                <button
                  type="button"
                  onClick={() => {
                    const newFileList = [...fileList];
                    newFileList.splice(index, 1);
                    setFileList(newFileList);
                  }}
                  className="ml-4 rounded-md p-1 text-gray-400 hover:bg-gray-100 hover:text-gray-500"
                >
                  <span className="sr-only">Remove file</span>
                  <XMarkIcon className="size-5" aria-hidden="true" />
                </button>
              </div>
            ))}
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <button
                type="button"
                onClick={handleUpload}
                className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
              >
                上傳
              </button>
              <button
                type="button"
                data-autofocus
                onClick={() => setOpen(false)}
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
              >
                取消
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  )
}
