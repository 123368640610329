import { Fragment, useState, useEffect, useRef, useCallback, useContext } from 'react'
import { Dialog, Transition, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { 
  XMarkIcon, 
  DocumentIcon, 
  TrashIcon, 
  ArrowPathIcon,
  InformationCircleIcon,
  EllipsisHorizontalIcon,
} from '@heroicons/react/24/outline'
import './JobEditForm.css';
import SearchableMenu from '../SearchableMenu';
import GlobalVars from '../../Config';
import { MultiSelect } from 'react-multi-select-component';
import React from 'react';
import Select from 'react-select';
import debounce from 'lodash/debounce';
import { UserContext } from '../../Context';

// components
import AssignedToAISuggestion from './SubComponents/AssignedToAISuggestion';
import SelectedStaffCurrStatus from './SubComponents/SelectedStaffCurrStatus';
import JobLocationComponent from './SubComponents/JobLocationComponent';
import JobTypeComponent from './SubComponents/JobTypeComponent';
import MainTypeAssistant from './SubComponents/MainTypeAssistant';

// language
import { useTranslation } from 'react-i18next';

// status
const jobStatus = {
  "新工作": "New Job",
  "待分配": "Awaiting Assignment",
  "待確認": "Awaiting Confirmation",
  "未開始": "Not Started",
  "進行中": "In Progress",
  "中斷": "Interrupted",
  "待續": "Temporarily Suspended",
  "待檢查": "Awaiting Inspection",
  "待批准": "Awaiting Approval",
  "完成": "Completed",
  "拒絕": "Rejected",
  "取消": "Cancelled",
  "延期": "Postponed",
  "回公司": "Returned to Office",
  "緊急": "Emergency",
  "維修中": "Under Repair",
  "需要額外部件": "Additional Parts Required",
  "需要專家幫助": "Expert Assistance Required"
};

export default function NewJobForm({
  state, 
  setEditState, 
  jobPrefill={},
  setShowSelfDisappearNotification = null,
  setSelfDisappearNotificationTitle = () => {},
  setSelfDisappearNotificationText = () => {},
  setSelfDisappearNotificationUrl = () => {},
}) {
  const { t, i18n } = useTranslation();

  // get user plan
  const { globalUserPlan } = useContext(UserContext);

  // set useEffect to change the state of open
  useEffect(() => {
    setEditState(state);
  }, [state]);

  const [formTitle, setFormTitle] = useState(t("新增工作"));

  // get today's date in YYYY-MM-DD format
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth()+1;
  var yyyy = today.getFullYear();
  if (dd < 10) {  // add 0 in front of day and month if they are less than 10
      dd = '0' + dd;
  }
  if (mm < 10) {
      mm = '0' + mm;
  }
  
  // set up states and default values
  const [location, setLocation] = useState(Object.keys(jobPrefill).length === 0 ? "" : jobPrefill["locationName"] || "");
  const [locations, setLocations] = useState(Object.keys(jobPrefill).length === 0 ? [] : jobPrefill["locationIDs"] || []);  // list of selected locations
  const [staff, setStaff] = useState(Object.keys(jobPrefill).length === 0 ? "" : jobPrefill["assignedTo"] || "");
  const [estimatedDate, setEstimatedDate] = useState(Object.keys(jobPrefill).length === 0 ? yyyy + "-" + mm + "-" + dd : jobPrefill["meta"]["estimatedDate"] || "");
  const [requestedDate, setRequestedDate] = useState(Object.keys(jobPrefill).length === 0 ? "" : jobPrefill["requestedTime"] || "");
  const [clientRemark, setClientRemark] = useState(Object.keys(jobPrefill).length === 0 ? "" : jobPrefill["meta"]["clientRemark"] || "");
  const [jobRemark, setJobRemark] = useState(Object.keys(jobPrefill).length === 0 ? "" : jobPrefill["meta"]["jobRemark"] || "");
  const [jobType, setJobType] = useState(Object.keys(jobPrefill).length === 0 ? "" : jobPrefill["typeID"] || "");
  const [jobTypes, setJobTypes] = useState(Object.keys(jobPrefill).length === 0 ? "" : jobPrefill["typeIDs"] || []);  // list of selected job types
  const [mainType, setMainType] = useState(Object.keys(jobPrefill).length === 0 ? "" : jobPrefill["meta"]["mainType"] || "");
  const [contactPerson, setContactPerson] = useState(Object.keys(jobPrefill).length === 0 ? "" : jobPrefill["meta"]["contactPerson"] || "");
  const [contactDetail, setContactDetail] = useState(Object.keys(jobPrefill).length === 0 ? "" : jobPrefill["meta"]["contactDetail"] || "");
  const [requiredInspection, setRequiredInspection] = useState(false);
  const [recurringSchedule, setRecurringSchedule] = useState({
    "frequency": "",
    "timeOfDay": "",
    "dayOfFreq": [],
  });
  const [statusNotificationList, setStatusNotificationList] = useState([{"name": "", "targets": []}]);
  const [sendPhoneNotification, setSendPhoneNotification] = useState(true);
  const [jobPriority, setJobPriority] = useState(0);
  const [issueID, setIssueID] = useState(Object.keys(jobPrefill).length === 0 ? "" : jobPrefill["issueID"] || "");
  const jobTypeSelect = useRef(null);
  const [alertOnly, setAlertOnly] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [isNotAssigningNow, setIsNotAssigningNow] = useState(false);
  const [isUsedAISuggestion, setIsUsedAISuggestion] = useState(false);

  // reset data 
  const resetData = () => {
    setLocation("");
    setStaff("");
    setEstimatedDate(yyyy + "-" + mm + "-" + dd);
    setRequestedDate("");
    setClientRemark("");
    setJobRemark("");
    setJobType("");
    setMainType("");
    setContactPerson("");
    setContactDetail("");
    setRequiredInspection(false);
    setRecurringSchedule({
      "frequency": "",
      "timeOfDay": "",
      "dayOfFreq": [],
    });
    setStatusNotificationList([{"name": "", "targets": []}]);
    setSendPhoneNotification(true);
    setJobPriority(0);
    setLocations([]);
    setJobTypes([]);
    setSelectedTemplate("");
    setIssueID("");
  };

  let organizationID = sessionStorage.getItem("organizationId");
  
  // get list of existing staff
  const [staffList, setStaffList] = useState([]);
  useEffect(() => {
    let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/users?organizationID=" + organizationID;
    
    // create a fetch request
    fetch(url,{
      headers: {
        'Authorization': sessionStorage.getItem('idToken')
      }
    })
      .then(response => response.json())
      .then(rawData => {
        let data = rawData["users"];

        // keep only active users
        data = data.filter((user) => user.status === "active");
      
        // set staff list
        setStaffList(data);
      })
      .catch(error => function() {
        // print status code
        console.log(error);
      })
  }, []);

  // get list of existing main types
  const [mainTypeList, setMainTypeList] = useState([]);
  useEffect(() => {
    let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/maintypes?lang=tc&organizationID=" + organizationID;

    // create a fetch request
    fetch(url,{
      headers: {
        'Authorization': sessionStorage.getItem('idToken')
      }
    })
      .then(response => response.json())
      .then(data => {
        
        // convert itemCode to string and update name (e.g. <itemCode>-<name>)
        for (var i = 0; i < data["types"].length; i++) {
          let itemCode = data["types"][i]["itemCode"];

          // zero pad the itemCode if it is less than 2 digits
          if (itemCode < 10) {
            itemCode = "0" + itemCode.toString();
          } else {
            itemCode = itemCode.toString();
          }

          data["types"][i]["name"] = itemCode + "-" + data["types"][i]["name"];

          // add langVar to name if it exists
          if (data["types"][i].hasOwnProperty("langVar")) {
            // check for tc in langVar
            if (data["types"][i]['langVar'].hasOwnProperty("tc")) {
              // add to name
              data["types"][i]["name"] = data["types"][i]["name"] + " (" + data["types"][i]['langVar']['tc'] + ")";
            }
          }
        }

        // sort the list by name
        data["types"].sort((a, b) => (a.name > b.name) ? 1 : -1);

        // set main type list
        setMainTypeList(data["types"]);
      })
      .catch(error => function() {
        // print status code
        console.log(error);
      })
  }, []);

  // get list of existing locations
  const [locationList, setLocationList] = useState([]);
  useEffect(() => {
    let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/locations?lang=tc&organizationID=" + organizationID + "&status=active";

    // create a fetch request
    fetch(url,{
      headers: {
        'Authorization': sessionStorage.getItem('idToken')
      }
    })
      .then(response => response.json())
      .then(data => {
        // if location list is empty, return
        if (data["locations"].length === 0) {
          return;
        }

        // // check if langVar exists, if not, set to empty object
        // for (var i = 0; i < data["locations"].length; i++) {
        //   if (data["locations"][i].hasOwnProperty("langVar")) {
        //     // check for tc in langVar
        //     if (data["locations"][i]['langVar'].hasOwnProperty("tc")) {
        //       // add to name
        //       data["locations"][i]["name"] = data["locations"][i]["name"] + " (" + data["locations"][i]['langVar']['tc'] + ")";
        //     }
        //   }
        // }

        // // there maybe a field name isActive, if the key exists and it's false, remove it from the list
        // data["locations"] = data["locations"].filter(item => item["isActive"] !== false);

        // setLocationList(data["locations"]);

        // Filter out items where isActive is false
        data["locations"] = data["locations"].filter(item => item["isActive"] !== false);

        // Iterate over locations to modify each item
        data["locations"] = data["locations"].map(item => {
          // Check if langVar exists, if so, append its 'tc' value to the name if available
          if (item.hasOwnProperty("langVar") && item["langVar"].hasOwnProperty("tc")) {
            item["name"] += " (" + item["langVar"]["tc"] + ")";
          }
          // Return the modified item in the specified format, changing 'value' to item["_id"]
          return {'label': item["name"], 'value': item["_id"]};
        });

        // Update the location list with the modified data
        setLocationList(data["locations"]);

      })
      .catch(error => function() {
        // print status code
        console.log(error);
      })
  }, []);


  // get list of existing job types
  const [jobTypeList, setJobTypeList] = useState([]);
  useEffect(() => {
    let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/jobtypes?lang=" + GlobalVars.language[i18n.language] + "&organizationID=" + organizationID + "&status=active";

    // create a fetch request
    fetch(url,{
      headers: {
        'Authorization': sessionStorage.getItem('idToken')
      }
    })
      .then(response => response.json())
      .then(data => {

        let jobTypeList = data["jobTypes"].map(jobType => {
          // Convert itemCode to string with zero padding if necessary
          let itemCodeStr = jobType["itemCode"] < 10 ? `0${jobType["itemCode"]}` : `${jobType["itemCode"]}`;
        
          // Update name format and append langVar 'en' if available
          let updatedName = `${itemCodeStr}-${jobType["name"]}`;
          if (jobType.hasOwnProperty("langVar") && jobType["langVar"].hasOwnProperty("en")) {
            updatedName += ` (${jobType["langVar"]["en"]})`;
          }
        
          // Return the jobType with the updated name and original _id as value
          return { label: updatedName, value: jobType["_id"], type: jobType["mainTypeName"] };
        });
        
        // Sort the list by label
        jobTypeList.sort((a, b) => (a.label > b.label) ? 1 : -1);
        
        // Update the job type list
        setJobTypeList(jobTypeList);


      })
      .catch(error => function() {
        // print status code
        console.log(error);
      })
  }, []);

  function SetButtonLoadingState(isLoading) {
    if (isLoading) {
      // disable button and show loading
      document.getElementById('submit-button').disabled = true;
      document.getElementById('submit-button').innerHTML = t("儲存中...");

      // disable dismiss button
      document.getElementById('dismiss-button').disabled = true;
    } else {
      // enable button and show save
      document.getElementById('submit-button').disabled = false;
      document.getElementById('submit-button').innerHTML = t("儲存");
      // enable dismiss button
      document.getElementById('dismiss-button').disabled = false;
    }
  }

  // Display success message
  function ShowSuccessMsg(jobId) {
    // check if setShowDisappearNotification is a function
    if (typeof setShowSelfDisappearNotification === 'function') {
      setShowSelfDisappearNotification(true);
      setSelfDisappearNotificationTitle(t("提交成功"));
      setSelfDisappearNotificationText(t("工作已成功提交"));
      console.log(jobId);
      setSelfDisappearNotificationUrl("/jobs/" + jobId);
    } else {
      // display success message
      alert(t("提交成功"));
    }
  }

  // manage form submission
  const submitForm = () => {
    SetButtonLoadingState(true);

    // pre fill estimatedDate if it's an empty string
    let submitEstimatedDate = !estimatedDate ? yyyy + "-" + mm + "-" + dd : estimatedDate;

    // get selected staff
    let selectedStaff = staff?.value ?? "";

    // check if users is not assigning now but didn't set isNotAssigningNow to true
    if (!isNotAssigningNow && selectedStaff === "") {
      if (!window.confirm("您尚未選擇「暫時不分配」或選擇施工人員。\n請問您確定不指派工作嗎?")) {
        // enable button and show loading
        SetButtonLoadingState(false);
        return;
      }
    }
    
    // get data from form
    var submitData = {
      // "locationID": location["_id"],  // extract id from location object
      "assignedTo": selectedStaff,
      "assignedBy": localStorage.getItem("userid"),
      "meta": {
        "clientRemark": clientRemark,
        "contactPerson": contactPerson,
        "contactDetail": contactDetail,
        "estimatedDate": submitEstimatedDate,
        "jobRemark": jobRemark,
        "requiredInspection": requiredInspection,
        // "recurringSchedule": recurringSchedule
      },
      "organizationID": organizationID,
      "requestedTime": requestedDate,
      "sendPhoneNotification": sendPhoneNotification,
      "jobPriority": jobPriority,
      "issueID": issueID,
      "alertOnly": alertOnly,
      "isUsedAISuggestion": isUsedAISuggestion,
    };

    // if assignedTo and assignedBy are the same, set sendPhoneNotification to false
    if (selectedStaff === localStorage.getItem("userid") && submitData["sendPhoneNotification"] === true) {
      submitData["sendPhoneNotification"] = false;
    }


    if (recurringSchedule.frequency !== "") {  
      let inputVerify = VerifyRecurringScheduleInput();
      if (inputVerify === 'shouldReturn') {
        return;
      }
      // modify recurrent schedule format if frequency is not empty
      let submitSchedule = recurringSchedule;
      if (recurringSchedule.dayOfFreq.length !== 0) {
        submitSchedule.dayOfFreq = recurringSchedule.dayOfFreq.map(day => day["value"]);
      }
      submitData["meta"]["recurringSchedule"] = submitSchedule;
    }

    // check if locations length is zero, if so, prompt users to select a location
    if (locations.length !== 0) {
      submitData["locationIDs"] = locations.map(location => location["value"]);

    } else {
      alert("請確保從清單中選擇地點");

      setTimeout(() => {
        document.getElementById("location_wrapper").style.border = "none";
      }, 3000);

      // enable button and show save
      document.getElementById('submit-button').disabled = false;
      document.getElementById('submit-button').innerHTML = '儲存';
      // enable dismiss button
      document.getElementById('dismiss-button').disabled = false;
      return;
    }

    // check if jobTypes length is zero, if so, prompt users to select a job type
    if (jobTypes.length !== 0) {
      // ensure jobType selection is one of the selection, else prompt users to select an option from the list
      // if (!jobTypeList.map(item => item["_id"]).includes(jobType["_id"])) {
      //   alert("請確保從清單中選擇工作類型");
      //   // enable button and show loading
      //   document.getElementById('submit-button').disabled = false;
      //   document.getElementById('submit-button').innerHTML = '儲存';
      //   // enable dismiss button
      //   document.getElementById('dismiss-button').disabled = false;
      //   return;
      // } 

      submitData["typeIDs"] = jobTypes.map(jobType => jobType["value"]);

    } else {
      alert("請確保從清單中選擇工作要求");
      
      document.getElementById("job_type_wrapper").scrollIntoView();
      document.getElementById("job_type_wrapper").style.border = "1px solid red";
      setTimeout(() => {
        document.getElementById("job_type_wrapper").style.border = "none";
      }, 3000);

      // enable button and show save
      document.getElementById('submit-button').disabled = false;
      document.getElementById('submit-button').innerHTML = t("儲存");
      // enable dismiss button
      document.getElementById('dismiss-button').disabled = false;
      return;
    }


    if (!window.confirm(t("確定要提交?"))) {
      // enable button and show loading
      document.getElementById('submit-button').disabled = false;
      document.getElementById('submit-button').innerHTML = t("儲存");

      // enable dismiss button
      document.getElementById('dismiss-button').disabled = false;
      return
    }

    // check if mainType is not undefined, then add to submitData
    if (mainType != undefined && mainType !== "") {
      submitData["meta"]["mainType"] = mainType["_id"];
    }
    

    if (submitData["requestedTime"] != null) {
      submitData["requestedTime"] = submitData["requestedTime"].replace(" ", "T");
    }

    // check if users selected any user for status notification 
    if (statusNotificationList.length !== 0) {
      // return if the the first status is empty
      if (statusNotificationList[0]["name"] != "") {
        submitData["meta"]["statusNotification"] = statusNotificationList;
      }
    }

    // check for job duplications
    let dupUrl = GlobalVars.BACKEND_DOMAIN + "/api/v1/jobs/check-duplication";
    let dupData = {
      "locationIDs": submitData["locationIDs"],
      "estimatedDate": submitEstimatedDate,
      "organizationID": organizationID,
      "assignedTo": selectedStaff,
      "typeIDs": submitData["typeIDs"],
    };
    
    fetch(dupUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('idToken')
      },
      body: JSON.stringify(dupData),
    })
      .then(response => {
        if (response.status === 200) {
          
          if (!window.confirm(t("同一個預計工作日期已經有相同的工作, 確定要提交?"))) {
            // enable button and show loading
            document.getElementById('submit-button').disabled = false;
            document.getElementById('submit-button').innerHTML = '儲存';
            return
          }

        } else {
          console.log(response.json())
        }

        // create a fetch request to post jobs 
        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/jobs";

        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': sessionStorage.getItem('idToken')
          },
          body: JSON.stringify(submitData),
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json()
          })
          .then(data => {      
            let jobId = data["jobId"];  
            console.log(jobId);

            // check for uploaded files
            if (uploadedFiles) {
              var url = GlobalVars.BACKEND_DOMAIN + "/api/v1/signed-url";
              var postData = {
                fileNames: Array.from(uploadedFiles).map(file => file.name),
                fileTypes: Array.from(uploadedFiles).map(file => file.type),
                organizationID: sessionStorage.getItem('organizationId'),
                jobID: jobId,
                multiFile: true
              };
              fetch(url, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': sessionStorage.getItem('idToken')
                },
                body: JSON.stringify(postData)
              }).then(response => response.json())
              .then(fileUrlsReturn => {
                
                // upload file to cloud storage
                let urls = fileUrlsReturn['urls'];
                
                let uploadPromises = Array.from(uploadedFiles).map((file, index) => {
                  return fetch(urls[index], {
                    method: 'PUT',
                    headers: {
                      'Content-Type': file.type,
                    },
                    body: file
                  }).then(response => {
                    if (response.ok) {
                      console.log("File uploaded successfully");
                    } 
                  }).catch(error => {
                    console.error('Error:', error);
                  });
                });
                
                Promise.all(uploadPromises).then(() => {
                  
                  // Show success message
                  ShowSuccessMsg(jobId);
                    
                  // hide form
                  setEditState(false);
                
                  // enable button and show loading
                  SetButtonLoadingState(false);

                  resetData();
                });
              })
            } else {

              // hide form
              setEditState(false);

              // Show success message
              ShowSuccessMsg(jobId);
            
              // enable button and show loading
              SetButtonLoadingState(false);

              // reset input data 
              resetData();
            }
            
          })
          .catch((error) => {
            console.error('Error:', error);
            
            // enable button and show loading
            SetButtonLoadingState(false);

            // alert
            alert("提交失敗");
          });
      })
    

  };

  // auto select maintype based on job type
  useEffect(() => {
    // return if jobType is empty or undefined
    if (jobType === "" || jobType === undefined || jobType === null) {
      return;
    }

    // skip if mainTypeList is empty
    if (mainTypeList.length != 0) {
      // check with jobTypeList to see if there is a mainTypeID
      for (var i = 0; i < jobTypeList.length; i++) {
        if (jobTypeList[i]["_id"] === jobType["_id"]) {
          // check if mainTypeID field exist, else return
          if (jobTypeList[i].hasOwnProperty("mainTypeID") === false) {
            return;
          } else {
            // find matching mainType obj from mainTypeList and set as mainType
            let mainTypeObject = mainTypeList.find(item => item._id === jobTypeList[i]["mainTypeID"]);
            setMainType(mainTypeObject);
          }
          break;
        }
      }
    }

  }, [jobType]);

  // Setting default values 
  useEffect(() => {
    // set default value for location
    if (Object.keys(jobPrefill).length !== 0) {  // copying existing jobs
      
      if (jobPrefill.hasOwnProperty("locationIDs")) {
        let locationObjects = locationList.filter(item => jobPrefill["locationIDs"].includes(item.value));
        setLocations(locationObjects);
      }
      
      setStaff(jobPrefill["assignedTo"]);
      setEstimatedDate(jobPrefill["meta"]["estimatedDate"]);
      setRequestedDate(jobPrefill["requestedTime"] || null);
      setClientRemark(jobPrefill["meta"]["clientRemark"]);
      setJobRemark(jobPrefill["meta"]["jobRemark"]);
      if (jobPrefill.hasOwnProperty("typeIDs")) {
        let jobTypeObjects = jobTypeList.filter(item => jobPrefill["typeIDs"].includes(item.value));
        setJobTypes(jobTypeObjects);
      }
      if (jobPrefill.hasOwnProperty("mainTypeName")) {
        let mainTypeObject = mainTypeList.find(item => item.name.includes(jobPrefill.mainTypeName));
        setMainType(mainTypeObject);
      }
      setContactPerson(jobPrefill["meta"]["contactPerson"]);
      setContactDetail(jobPrefill["meta"]["contactDetail"]);
      setIssueID(jobPrefill["issueID"]);

      if (jobPrefill.hasOwnProperty("requiredInspection") === true) {
        setRequiredInspection(jobPrefill["requiredInspection"]);
      }

      setFormTitle("複製工作");
    } else {  // creating new job
      if (staff === "") {
        // populate assignedTo based on userid in local storage
        let assignedTo = localStorage.getItem("userid");

        // go through staffList and match id 
        let staffObject = staffList.find(item => item._id === assignedTo);
        if (staffObject) {
          setStaff(staffObject['_id']);
        }
      }

    }
  }, [jobPrefill]);

  // setting values from job templates
  
  useEffect(() => {
    
    // return if selectedTemplate is null
    if (selectedTemplate === null || selectedTemplate === "") {
      return;
    }
    

    // query for job details 
    let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/job/manage-single-template?organizationID=" + organizationID + "&jobId=" + selectedTemplate['value'];
    fetch(url, {
      headers: {
        'Authorization': sessionStorage.getItem('idToken')
      }
    })
      .then(response => response.json())
      .then(rawData => {
        let data = rawData["jobDetail"];

        let locationObjects = locationList.filter(item => data["locationIDs"].includes(item.value));
        setLocations(locationObjects);
        setStaff(data["assignedTo"]);
        setEstimatedDate(data["meta"]["estimatedDate"]);
        setRequestedDate(data["requestedTime"] || null);
        setClientRemark(data["meta"]["clientRemark"]);
        setJobRemark(data["meta"]["jobRemark"]);
        let jobTypeObjects = jobTypeList.filter(item => data["typeIDs"].includes(item.value));
        setJobTypes(jobTypeObjects);
        let mainTypeObject = mainTypeList.find(item => item._id.includes(data.meta.mainType));
        setMainType(mainTypeObject);
        setContactPerson(data["meta"]["contactPerson"]);
        setContactDetail(data["meta"]["contactDetail"]);
        setIssueID(data["issueID"]);

        if (data.hasOwnProperty("meta")) {
          if (data["meta"].hasOwnProperty("requiredInspection")) {
            setRequiredInspection(data['meta']["requiredInspection"]);
          }
        }

        if (data['alertOnly']) {  
          setAlertOnly(data['alertOnly']);
        }

        // set priority 
        setJobPriority(data["jobPriority"]);

        // set statusNotification
        if (data["meta"].hasOwnProperty("statusNotification")) {
          setStatusNotificationList(data["meta"]["statusNotification"]);
        }
      })
      .catch(error => {
        console.log(error);
      });

  }, [selectedTemplate]);
  
  // retrieve user list 
  const [userList, setUserList] = useState([]);
  useEffect(() => {
    let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/users?organizationID=" + organizationID;
    
    // create a fetch request
    fetch(url,{
      headers: {
        'Authorization': sessionStorage.getItem('idToken')
      }
    })
      .then(response => response.json())
      .then(rawData => {
        let data = rawData["users"];

        // keep only active users
        data = data.filter((user) => user.status === "active");

        // convert to {label: <name>, value: <_id>}
        data = data.map((user) => {
          return {
            label: user.displayName,
            value: user._id
          }
        });
      
        // set staff list
        setUserList(data);
      })
      .catch(error => function() {
        // print status code
        console.log(error);
      })
  }, []);

  // handle input change for status inputs
  const handleStatusInputChange = (index, e) => {
      console.log(e.target.value);
      const values = [...statusNotificationList];
      values[index][e.target.name] = e.target.value;
      setStatusNotificationList(values);
  };


  // handle input change when users interact with multiselect
  const handleMultiUserSelect = (ind, selectedOptions) => {
      const values = [...statusNotificationList];
      values[ind]['targets'] = selectedOptions;
      setStatusNotificationList(values);
  }

  // handle adding another set of reminder
  const handleAddAnotherReminder = (e) => {
    e.preventDefault();
    setStatusNotificationList([...statusNotificationList, {"name": "", "targets": []}]);
  };

  // handle removing last set of reminder
  const handleRemoveLastReminder = (e) => {
      e.preventDefault();
      setStatusNotificationList(statusNotificationList.slice(0, -1));
      
  };

  // update estimatedDate when users update requestedDate
  useEffect(() => {
    if (requestedDate) {
      setEstimatedDate(requestedDate.split("T")[0]);
    }
  }, [requestedDate]);

  // handle file upload 
  const [uploadedFiles, setUploadedFiles] = useState(null);

  // handle priority change
  const priorityMapping = { '無': 0, '低': 1, '中': 2, '高': 3 };
  const inversePriorityMapping = { 0: '無', 1: '低', 2: '中', 3: '高' };

  const handlePriorityChange = (newPriority) => {
    setJobPriority(priorityMapping[newPriority]);
  };

  const getButtonClass = (value) => {
    return jobPriority === priorityMapping[value]
      ? "relative inline-flex items-center px-3 py-2 text-sm font-semibold text-white bg-indigo-600 ring-1 ring-inset ring-indigo-300 hover:bg-indigo-700 focus:z-10"
      : "relative inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10";
  };

  // create job type fuzzy query
  const [jobTypeFuzzyQuery, setJobTypeFuzzyQuery] = useState({
    "clientRemark": "",
    "jobRemark": "",
  });
  const handleJobTypeFuzzyQueryChange = (e) => {
    
    if (e.target.name != "job_remark") {
      setJobTypeFuzzyQuery({
        "clientRemark": e.target.value,
        "jobRemark": jobTypeFuzzyQuery["jobRemark"],
      });
    } else {
      setJobTypeFuzzyQuery({
        "clientRemark": jobTypeFuzzyQuery["clientRemark"],
        "jobRemark": e.target.value,
      });
    }
  }

  // store current job setting as templates
  const StoreCurrentJobSetting = () => {

    let organizationID = sessionStorage.getItem("organizationId");

    // get data from form
    var submitData = {
      "jobDetail": {
        "locationIDs": locations.map(location => location["value"]),
        "meta": {
          "clientRemark": clientRemark,
          "contactPerson": contactPerson,
          "contactDetail": contactDetail,
          "jobRemark": jobRemark,
          "requiredInspection": requiredInspection,
          "recurringSchedule": recurringSchedule
        },
        "organizationID": organizationID,
        "sendPhoneNotification": sendPhoneNotification,
        "jobPriority": jobPriority,
        "alertOnly": alertOnly,
        "typeIDs": jobTypes.map(jobType => jobType["value"]),
        "assignedTo": staff.value,
      },  
      "organizationID": organizationID,
      
    };

    // prompt users for template names and add to submitData
    let templateName = prompt("請輸入模板名稱");
    if (templateName === null || templateName === "") {
      return;
    }

    submitData["templateName"] = templateName;

    // verify templateName by making fetch request 
    let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/job/manage-single-template?organizationID=" + organizationID + "&templateName=" + templateName;
    fetch(url, {
      headers: {
        'Authorization': sessionStorage.getItem('idToken')
      }
    })
      .then(response => {
        if (response.status === 200) {
          // prompt users to confirm if they want to overwrite the template
          if (window.confirm("模板已存在, 確定要覆蓋?")) {
            PostRequestForJobTemplate(submitData);
          }
        } else {
          if (response.status == 404) {
            response.json().then(data => {
              if (data.hasOwnProperty("Message")) {
                  if (data["Message"] === "Template not found") {
                      PostRequestForJobTemplate(submitData);
                  }
              }
            });
          } 
        }
      })
      .catch(error => {
        // print status code
        console.log(error);
      });

  }

  // post request for job template
  const PostRequestForJobTemplate = (submitData) => {
    
    // warn users if typeIDs or locationIDs is undefined or length is zero 
    if (submitData['jobDetail']["typeIDs"].length === 0 || submitData['jobDetail']["locationIDs"].length === 0) {
      alert("請確保工作類型和地點已選擇");
      return;
    }

    // check if mainType is not undefined, then add to submitData
    if (mainType != undefined && mainType !== "") {
      submitData['jobDetail']["meta"]["mainType"] = mainType["_id"];
    }

    if (submitData['jobDetail']["requestedTime"] != null) {
      submitData['jobDetail']["requestedTime"] = submitData['jobDetail']["requestedTime"].replace(" ", "T");
    }
    
    // check if users selected any user for status notification
    if (statusNotificationList.length !== 0) {
      // return if the the first status is empty
      if (statusNotificationList[0]["name"] != "") {
        submitData['jobDetail']["meta"]["statusNotification"] = statusNotificationList;
      }
    }

    // store jobs as templates
    let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/job/manage-single-template";
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(submitData)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json()
      })
      .then(data => {
        // display success message
        alert("模板已儲存");
        
        setSelectedTemplate(null);
      })
      .catch((error) => {
        console.error('Error:', error);
        // alert
        alert("模板儲存失敗");
      });

  }

  // store current job setting as recurring schedule
  function VerifyRecurringScheduleInput() {

    // return error if timeOfDay is empty
    if (recurringSchedule.frequency != "" && recurringSchedule.timeOfDay == "") {
      alert("請填寫工作時間");
      return 'shouldReturn';
    }

    // return error if frequency is monthly, weekly or every-two-week and dayOfFreq length is zero
    if (recurringSchedule.frequency === "monthly" || recurringSchedule.frequency === "weekly" || recurringSchedule.frequency === "every-two-week") {
      if (recurringSchedule.dayOfFreq.length === 0) {
        if (recurringSchedule.frequency === "monthly") {
          alert("請提供週期性工作日期");
        }else {
          alert("請提供週期性工作星期")
        }
        return 'shouldReturn';
      }
    }

    return 'noError';
  }
  const StoreCurrentJobSettingAsRecurringSchedule = () => {
    // return error if locations length is zero
    if (locations.length === 0) {
      alert("請確保從清單中選擇地點");
      return;
    }

    // return error if jobTypes length is zero
    if (jobTypes.length === 0) {
      alert("請確保從清單中選擇工作要求");
      return;
    }

    // verify input
    let inputVerify = VerifyRecurringScheduleInput();
    if (inputVerify === 'shouldReturn') {
      return;
    }

    let organizationID = sessionStorage.getItem("organizationId");

    let submitSchedule = recurringSchedule;

    // convert schedule dayOfFreq to a list of value 
    if (recurringSchedule.dayOfFreq.length !== 0) {
      submitSchedule.dayOfFreq = recurringSchedule.dayOfFreq.map(day => day["value"]);
    }

    // return error if users did not select any staff
    if (staff === "") {
      alert("請確保從清單中選擇員工");
      return;
    }

    // get data from form
    var submitData = {
      organizationID: organizationID,
      recurringSchedule: submitSchedule,
      jobDetail: {
        "locationIDs": locations.map(location => location["value"]),
        "meta": {
          "clientRemark": clientRemark,
          "contactPerson": contactPerson,
          "contactDetail": contactDetail,
          "jobRemark": jobRemark,
          "requiredInspection": requiredInspection,
          "recurringSchedule": recurringSchedule
        },
        "organizationID": organizationID,
        "sendPhoneNotification": sendPhoneNotification,
        "jobPriority": jobPriority,
        "alertOnly": alertOnly,
        "typeIDs": jobTypes.map(jobType => jobType["value"]),
        "assignedTo": staff.value,
        "assignedBy": localStorage.getItem("userid"),
      },
    }

    // prompt users confirmation 
    if (!window.confirm("確定要將當前設置存儲為定期安排?")) {
      return
    }

    let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/jobs/recurring-schedule";
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('idToken')
      },
      body: JSON.stringify(submitData)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json()
      })
      .then(data => {
        // display success message
        alert("週期工作安排已儲存");
      })
      .catch((error) => {
        console.error('Error:', error);
        // alert
        alert("週期工作儲存失敗, 請重試");
      });
  }
  // clear dayOfFreq when frequency changes
  useEffect(() => {
    setRecurringSchedule({
      ...recurringSchedule,
      dayOfFreq: []
    });
  }, [recurringSchedule.frequency]);

  // custom value renderer for multi select
  const customValueRenderer = (selected, _options) => {
    if (selected.length === 0) {
      return "";
    } else if (selected.length === 1) {
      if (selected[0].label.length > 30) {
        return selected[0].label.slice(0, 30) + "...";
      } 
      return selected[0].label
    }
    return "已選擇 " + selected.length + " 項";
  }

  // populate main type if jobtype is selected, main type is not selected and there's a related main type
  useEffect(() => {
    if (jobTypes.length > 0 && mainType === "" && mainTypeList.length > 0) {
      const matchedType = mainTypeList.find(type => type.langVar.tc === jobTypes[0]?.type);
      if (matchedType) {
        setMainType(matchedType);
      }
    }
  }, [jobTypes, mainType, mainTypeList])

  return (
    <Transition.Root show={state} as={Fragment}>
      <Dialog as="div" className="relative z-10 dynamic-comp" onClose={setEditState} id="job-edit-form">
        {/* <div className="fixed inset-0" /> */}

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            {formTitle}
                            {/* <span className={`ml-2 text-center min-w-[60px] inline-flex items-center justify-center rounded-md ring-1 ring-inset text-xs font-medium px-2 py-1 ${status}`}>
                                {status}
                            </span> */}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                              onClick={() => setEditState(false)}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative flex-1 px-4 sm:px-6">
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-200">
                            <div className="space-y-6 pb-5 pt-6">
                              <div>
                                <SingleJobTemplateDropdown selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate} t={t} />
                              </div>
                              <div id="estimated_date_wrapper">
                                <label htmlFor="estimated_date_input" className="block text-sm font-medium leading-6 text-gray-900">
                                  {t("預計工作日期")}
                                </label>
                                <div className="mt-2">
                                  <input
                                    type="date"
                                    name="estimated_date"
                                    id="estimated_date_input"
                                    value={estimatedDate}
                                    onChange={e => setEstimatedDate(e.target.value)}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                                {/* <p className="mt-2 text-sm text-gray-500" id="email-description">
                                  We'll only use this for spam.
                                </p> */}
                              </div>
                               <div id="requested_date_wrapper">
                                 <label htmlFor="requested_date_input" className="block text-sm font-medium leading-6 text-gray-900">
                                   {t("客戶要求工作時間")}
                                 </label>
                                 <div className="mt-2">
                                   <input
                                     type="datetime-local"
                                     name="requested_date"
                                     id="requested_date_input"
                                     value={requestedDate}
                                     onChange={e => setRequestedDate(e.target.value)}
                                     className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                   />
                                 </div>
                                 {/* <p className="mt-2 text-sm text-gray-500" id="email-description">
                                   We'll only use this for spam.
                                 </p> */}
                              </div>
                              <hr></hr>
                              <div id="location_wrapper"> 
                                  <JobLocationComponent 
                                    locations={locations}
                                    setLocations={setLocations}
                                    locationList={locationList}
                                  />
                              </div>
                           
                              <div id="client_remark_wrapper">
                                <label htmlFor="client_remark_input" className="block text-sm font-medium leading-6 text-gray-900">
                                  {t("客戶需求")}
                                </label>
                                <div className="mt-2">
                                  <textarea
                                    id="client_remark_input"
                                    name="client_remark"
                                    rows={3}
                                    className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                                    placeholder={t("客戶需求")}
                                    value={clientRemark}
                                    onChange={e => setClientRemark(e.target.value)}
                                    onBlur={e => handleJobTypeFuzzyQueryChange(e)}
                                  />
                                  <RemarkSuggestion 
                                    type={"clientRemark"}
                                    currRemark={clientRemark}
                                    selectedLocs={locations}
                                    setDesc={setClientRemark}
                                    setIsUsedAISuggestion={setIsUsedAISuggestion}
                                    t={t}
                                  />

                                </div>
                                {/* <p className="mt-2 text-sm text-gray-500" id="email-description">
                                  We'll only use this for spam.
                                </p> */}
                              </div>
                              <div id="priority_wrapper">
                                <label htmlFor="priority_input" className="block text-sm font-medium leading-6 text-gray-900">
                                  {t("優先級別")}
                                </label>
                                <div className="mt-2">
                                  <span className="isolate inline-flex rounded-md shadow-sm">
                                    {Object.keys(priorityMapping).map((value) => (
                                      <button
                                        key={value}
                                        type="button"
                                        className={getButtonClass(value)}
                                        onClick={() => handlePriorityChange(value)}
                                      >
                                        {t(value)}
                                      </button>
                                    ))}
                                  </span>
                                </div>
                              </div>

                              <hr></hr>
                              <div id="product_type_wrapper"> 
                                <label htmlFor="mainType_input" className="block text-sm font-medium leading-6 text-gray-900">
                                  {t("主類別")}
                                </label>
                                <div className="mt-2 space-y-2">
                                  <SearchableMenu list={mainTypeList} selectedItem={mainType} setSelectedItem={setMainType} />
                                  <MainTypeAssistant
                                    mainType={mainType}
                                    setMainType={setMainType}
                                    locationIDs={locations}
                                    mainTypeList={mainTypeList}
                                  />
                                </div>
                              </div>
                              <div id="job_type_wrapper"> 
                                <div className="mt-2">
                                  <JobTypeComponent
                                    types={jobTypes}
                                    setTypes={setJobTypes}
                                    typeList={jobTypeList}
                                    setIsUsedAISuggestion={setIsUsedAISuggestion} 
                                  />
                                  {/* info tag that show how many seleceted */}
                                  {/* <p className="text-xs text-gray-500 mt-1 flex items-center">
                                    <div
                                      className='ml-1'
                                    >
                                      <PreviewSelectedOptions options={jobTypes} />
                                    </div>
                                  </p> */}
                                </div>
                                <div
                                  className='mt-2'
                                >
                                  <JobTypeSuggestion
                                    clientRemark={clientRemark}
                                    jobRemark={jobRemark}
                                    setJobType={setJobType}
                                    jobTypeList={jobTypeList}
                                    jobTypeFuzzyQuery={jobTypeFuzzyQuery}
                                    jobTypes={jobTypes}
                                    setJobTypes={setJobTypes}
                                    locations={locations}
                                    setIsUsedAISuggestion={setIsUsedAISuggestion}
                                    globalUserPlan={globalUserPlan}
                                    t={t}
                                  />
                                </div>
                              </div>
                              <div id="required_inspection_wrapper" style={{marginTop: "10px"}}>
                                <fieldset>
                                  <legend className="sr-only">Notifications</legend>
                                  <div className="space-y-5">
                                    <div className="relative flex items-start">
                                      <div className="flex h-6 items-center">
                                        <input
                                          id="required_inspection"
                                          name="required_inspection"
                                          type="checkbox"
                                          checked={requiredInspection}
                                          onChange={e => setRequiredInspection(e.target.checked)}
                                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        />
                                      </div>
                                      <div className="ml-3 text-sm leading-6">
                                        <label htmlFor="required_inspection" className="font-medium text-gray-900">
                                          {t("需要檢查")}
                                        </label>{' '}
                                        <span id="comments-description" className="text-gray-500">
                                          <span className="sr-only">{t("需要檢查")} </span>{t("完成工作前會有一個待檢查狀態")}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </fieldset>
                              </div>
                              {/* Alert Only */}
                              <div id="alert_only_wrapper" style={{marginTop: "8px"}}>
                                <fieldset>
                                  <legend className="sr-only">Notifications</legend>
                                  <div className="space-y-5">
                                    <div className="relative flex items-start">
                                      <div className="flex h-6 items-center">
                                        <input
                                          id="alert_only"
                                          name="alert_only"
                                          type="checkbox"
                                          checked={alertOnly}
                                          onChange={e => setAlertOnly(e.target.checked)}
                                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        />
                                      </div>
                                      <div className="ml-3 text-sm leading-6">
                                        <label htmlFor="alert_only" className="font-medium text-gray-900">
                                          {t("通知")}
                                          <span id="comments-description" className="text-gray-500 ml-1">
                                            <span className="sr-only">{t("通知")} </span>{t("此工作無需狀態更新")}
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </fieldset>
                              </div>
                              <div id="job_remark_wrapper"> 
                                {/* <JobRemarkInput 
                                  jobRemark={jobRemark} 
                                  setJobRemark={setJobRemark} 
                                  handleJobTypeFuzzyQueryChange={handleJobTypeFuzzyQueryChange}
                                  jobTypes={jobTypes}
                                  locationIDs={locations}
                                  setIsUsedAISuggestion={setIsUsedAISuggestion}
                                /> */}
                                <label htmlFor="job_remark_input" className="block text-sm font-medium leading-6 text-gray-900">
                                  {t("工作備忘")}
                                </label>
                                <div className="mt-2">
                                  <textarea
                                      id="job_remark_input"
                                      name="job_remark"
                                      rows={3}
                                      className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                                      onBlur={e => handleJobTypeFuzzyQueryChange(e)}
                                      value={jobRemark}
                                      onChange={e => setJobRemark(e.target.value)}
                                    />
                                </div>
                                <RemarkSuggestion 
                                  type={"jobRemark"}
                                  jobTypes={jobTypes}
                                  currRemark={jobRemark}
                                  selectedLocs={locations}
                                  setDesc={setJobRemark}
                                  appendDesc={true}
                                  setIsUsedAISuggestion={setIsUsedAISuggestion}
                                  t={t}
                                />
                              </div>
                              <div>
                                <label
                                  htmlFor="file"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  {t("工作檔案")}
                                </label>
                                {/* Show simple button on mobile, drag & drop on desktop */}
                                <div className="mt-2">
                                  {/* Mobile view */}
                                  <div className="sm:hidden">
                                    <label
                                      htmlFor="file-upload-mobile"
                                      className="relative w-full cursor-pointer"
                                    >
                                      <div className="inline-flex w-full justify-center items-center px-4 py-2.5 rounded-md bg-indigo-600 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                        <DocumentIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                                        <span>{t("選擇檔案")}</span>
                                        <input
                                          id="file-upload-mobile"
                                          name="file-upload-mobile"
                                          type="file"
                                          className="sr-only"
                                          multiple
                                          onChange={e => setUploadedFiles(e.target.files)}
                                        />
                                      </div>
                                    </label>
                                    <p className="mt-1 text-xs text-gray-500 text-center">
                                      PNG, JPG up to 10MB
                                    </p>
                                  </div>

                                  {/* Desktop view with drag & drop */}
                                  <div className="hidden sm:block">
                                    <div className="mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                      <div className="space-y-1 text-center">
                                        <DocumentIcon className="mx-auto h-12 w-12 text-gray-400" aria-hidden="true" />
                                        <div className="flex text-sm text-gray-600">
                                          <label
                                            htmlFor="file-upload"
                                            className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                          >
                                            <span>{t("上傳一個檔案")}</span>
                                            <input 
                                              id="file-upload" 
                                              name="file-upload" 
                                              type="file" 
                                              className="sr-only" 
                                              multiple 
                                              onChange={e => setUploadedFiles(e.target.files)}
                                            />
                                          </label>
                                          <p className="pl-1">
                                            {t("或者拖放")}
                                          </p>
                                        </div>
                                        <p className="text-xs text-gray-500">PNG, JPG up to 10MB</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div>
                                {/* list out uploaded files by each file names */}
                                <div className="mt-2 max-h-32 overflow-y-auto">
                                  {uploadedFiles && Array.from(uploadedFiles).map((file, index) => (
                                    <div key={index} className="mb-2 mx-2 flex items-center justify-between bg-gray-50 px-3 py-2 rounded-lg">
                                      <div className="flex items-center min-w-0 flex-1">
                                        <DocumentIcon className="h-5 w-5 text-gray-400 flex-shrink-0" aria-hidden="true" />
                                        <p className="ml-2 text-sm text-gray-600 truncate">
                                          {file.name}
                                        </p>
                                      </div>
                                      <TrashIcon 
                                        className="h-5 w-5 text-red-400 cursor-pointer flex-shrink-0 ml-2" 
                                        aria-hidden="true" 
                                        onClick={() => setUploadedFiles(Array.from(uploadedFiles).filter((_, i) => i !== index))}
                                      />
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <hr></hr>
                              <div id="staff-wrapper">
                                <AssignedToList 
                                  mainType={mainType} 
                                  staff={staff} 
                                  setStaff={setStaff} 
                                  sendPhoneNotification={sendPhoneNotification} 
                                  setSendPhoneNotification={setSendPhoneNotification} 
                                  setIsNotAssigningNow={setIsNotAssigningNow} 
                                  isNotAssigningNow={isNotAssigningNow} 
                                  locationIDs={locations}
                                  jobTypes={jobTypes}
                                  setIsUsedAISuggestion={setIsUsedAISuggestion}
                                  t={t}
                                />
                              </div>

                              <hr></hr>
                              <div id="contact_person_wrapper"> 
                                <label htmlFor="contact_person" className="block text-sm font-medium leading-6 text-gray-900">
                                  {t("聯絡人名稱")}
                                </label>
                                <div className="mt-2">
                                  <input
                                    type="text"
                                    name="contact_person"
                                    id="contact_person_input"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    onChange={e => setContactPerson(e.target.value)}
                                    value={contactPerson}
                                  />
                                </div>
                              </div>
                              <div id="contact_detail_wrapper"> 
                                <label htmlFor="contact_detail" className="block text-sm font-medium leading-6 text-gray-900">
                                  {t("聯絡電話")}
                                </label>
                                <div className="mt-2">
                                  <input
                                    type="text"
                                    name="contact_detail"
                                    id="contact_detail_input"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    onChange={e => setContactDetail(e.target.value)}
                                    value={contactDetail}
                                  />
                                </div>
                              </div>
                              <hr></hr>
                              <div id="status_notification_wrapper">
                                {/* allow users to pick a status and a corresponding person to be notified */}
                                <label htmlFor="status_notification" className="block text-sm font-medium leading-6 text-gray-900">
                                  {t("狀態通知")}
                                </label>
                                <p
                                className='text-xs text-gray-500'
                                >{t("選擇一個狀態, 並選擇一個人, 當工作狀態改變時, 這個人將會收到通知")}</p>
                                {statusNotificationList.map((status, index) => (
                                    <div key={index} className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                      <div className="sm:col-span-3">
                                          <label htmlFor="status" className="block text-sm font-medium leading-6 text-gray-900">
                                              {t("狀態")}
                                          </label>
                                          <div className="mt-2">
                                              <select
                                              onChange={event => handleStatusInputChange(index, event)}
                                              value={status.name}
                                              name="name"
                                              className="block w-full rounded border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                              > 
                                                <option value="">{t("請選擇")}</option>
                                                {Object.keys(jobStatus).map((key, index) => (
                                                  <option key={index} value={jobStatus[key]}>{t(key)}</option>
                                                ))}
                                              </select>
                                          </div>
                                      </div>
                                      <div className="sm:col-span-3">
                                          <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                              {t("通知對象")}
                                          </label>
                                          <div className="mt-2">
                                              <MultiSelect 
                                                  options={userList}
                                                  value={status.targets}
                                                  data-id={index}
                                                  labelledBy={"Select"}
                                                  onChange={event => handleMultiUserSelect(index, event)}
                                                  className='text-sm'
                                                  overrideStrings={
                                                    {
                                                      "selectSomeItems": t("選擇一個或多個人"),
                                                      "allItemsAreSelected": t("全部選擇"),
                                                      "selectAll": t("全選"),
                                                      "search": t("搜尋"),
                                                    }
                                                  }
                                              />
                                          </div>
                                      </div>
                                    </div>
                                ))}
                                <button
                                    onClick={handleAddAnotherReminder}
                                    className='mt-2 inline-flex items-center px-4 py-2 border border-transparent text-xs font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700'
                                >
                                    {t("加上另一個逾時提醒")}
                                </button>
                                {/* a secondary button to remove the last reminder */}
                                <button
                                    onClick={handleRemoveLastReminder}
                                    className='ml-2 mt-2 inline-flex items-center px-4 py-2 border border-transparent text-xs font-medium rounded-md shadow-sm text-indigo ring-1 ring-outset ring-indigo-300 hover:bg-indigo-600 hover:text-white'
                                >
                                    {t("移除上一個逾時提醒")}
                                </button>
                                
                              </div>
                              <hr></hr>
                              <div>
                                <label htmlFor="recurring" className="block text-sm font-medium leading-6 text-gray-900">
                                    {t("週期性工作")}
                                </label>
                                <p
                                className='text-xs text-gray-500'
                                >
                                  {t("若輸入一個時間, 系統將會定期生成工作")}
                                </p>
                              </div>
                              <div>
                                <label htmlFor="recurring" className="block text-sm font-medium leading-6 text-gray-900">
                                  {t("週期")}
                                  </label>
                                  <div className="mt-2">
                                    <select
                                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      value={recurringSchedule?.frequency}
                                      onChange={e => setRecurringSchedule({...recurringSchedule, frequency: e.target.value})}
                                    >
                                      <option value="">{t("請選擇")}</option>
                                      <option value="daily">{t("每天")}</option>
                                      <option value="every-two-day">{t("隔天")}</option>
                                      <option value="weekly">{t("每週")}</option>
                                      <option value="every-two-week">{t("每兩週")}</option>
                                      <option value="monthly">{t("每月")}</option>
                                    </select>
                                  </div>
                              </div>
                              {(recurringSchedule?.frequency === "weekly" || recurringSchedule?.frequency === "every-two-week") && (
                                // show monday to sunday selection if weekly or every two week is selected
                                <div>
                                  <label htmlFor="recurring" className="block text-sm font-medium leading-6 text-gray-900">
                                    {t("週期性工作星期")}
                                  </label>
                                  <div className="mt-2">
                                    <MultiSelect 
                                      options={[
                                        {label: t("星期一"), value: "monday"},
                                        {label: t("星期二"), value: "tuesday"},
                                        {label: t("星期三"), value: "wednesday"},
                                        {label: t("星期四"), value: "thursday"},
                                        {label: t("星期五"), value: "friday"},
                                        {label: t("星期六"), value: "saturday"},
                                        {label: t("星期日"), value: "sunday"},
                                      ]}
                                      value={recurringSchedule?.dayOfFreq}
                                      onChange={selected => setRecurringSchedule({...recurringSchedule, dayOfFreq: selected})}
                                      labelledBy={"Select"}
                                      className='text-sm'
                                      overrideStrings={
                                        {
                                          "selectSomeItems": t("選擇一個或多個星期"),
                                          "allItemsAreSelected": t("全部選擇"),
                                          "selectAll": t("全選"),
                                          "search": t("搜尋"),
                                        }
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                              {(recurringSchedule?.frequency === "monthly") && (
                                // show 31 days days selection if monthly is selected in the form of a calendar format
                                <div>
                                  <label htmlFor="recurring" className="block text-sm font-medium leading-6 text-gray-900">
                                    {t("週期性工作日期")}
                                  </label>
                                  <div className="mt-2">
                                    <MultiSelect
                                      options={Array.from({length: 31}, (_, i) => ({label: (i + 1).toString(), value: (i + 1).toString()}))}
                                      value={recurringSchedule?.dayOfFreq}
                                      onChange={selected => setRecurringSchedule({...recurringSchedule, dayOfFreq: selected})}
                                      labelledBy={"Select"}
                                      className='text-sm'
                                      overrideStrings={
                                        {
                                          "selectSomeItems": t("選擇一個或多個日期"),
                                          "allItemsAreSelected": t("全部選擇"),
                                          "selectAll": t("全選"),
                                          "search": t("搜尋"),
                                        }
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                              <div id="recurring_wrapper">
                                {/* users select a time of a day in which this event will re-occur */}
                                <label htmlFor="recurring" className="block text-sm font-medium leading-6 text-gray-900">
                                  {t("工作時間")}
                                </label>
                                <p
                                className='text-xs text-gray-500'
                                >
                                  {t("系統當日會根據此時間生成工作")}
                                </p>
                                <div className="mt-2">
                                  <input 
                                    type="time"
                                    name="recurring"
                                    id="recurring_input"
                                    value={recurringSchedule?.timeOfDay}
                                    onChange={e => setRecurringSchedule({...recurringSchedule, timeOfDay: e.target.value})}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 px-4 py-4 pb-10 md:pb-4 justify-between items-center">
                      {/* <button
                        type="button"
                        className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                        onClick={StoreCurrentJobSetting}
                        ref={saveSettingBtn}
                      >
                        另存模板
                      </button> */}
                      <FurtherActionDropdown 
                        StoreCurrentJobSetting={StoreCurrentJobSetting}
                        StoreCurrentJobSettingAsRecurringSchedule={StoreCurrentJobSettingAsRecurringSchedule}
                        
                      />
                      <div
                        className="flex space-x-2"
                      >
                        <button
                          type="button"
                          id="dismiss-button"
                          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                          onClick={() => setEditState(false)}
                        >
                          {t("取消")}
                        </button>
                        <button
                          type="button"
                          id="submit-button"
                          onClick={submitForm}
                          className="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                        >
                          {t("儲存")}
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
        
      </Dialog>
    </Transition.Root>
  )
}

// assignedTo list component. 
function AssignedToList({
  mainType, 
  staff, 
  setStaff, 
  sendPhoneNotification, 
  setSendPhoneNotification, 
  setIsNotAssigningNow, 
  isNotAssigningNow,
  locationIDs,
  jobTypes,
  setIsUsedAISuggestion,
  t
}) {
  // fetch existing list of staff
  const [staffListOptions, setStaffListOptions] = useState([]);


  // create staff list options 
  useEffect(() => {
    let organizationID = sessionStorage.getItem("organizationId");
    let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/users/retrieve-with-main-type?organizationID=" + organizationID;
    
    // create a fetch request
    fetch(url,{
      headers: {
        'Authorization': sessionStorage.getItem('idToken')
      }
    })
      .then(response => response.json())
      .then(rawData => {
        let data = rawData["users"];


        // convert data to [{label: <name>, options: [{label: <name>, value: <_id>}, ...]}, ...]
        let newStaffListOptions = [];
        for (var key in data) {
          let staffList = data[key];
          let newStaffList = staffList.map((staff) => {
            let group = staff.group || ''; // handle undefined group
            return {
              label: staff.name + (group ? ` (${group})` : ''), // omit parenthesis if group is empty
              value: staff._id
            }
          });
          newStaffListOptions.push({
            label: key,
            options: newStaffList
          });
        }

        setStaffListOptions(newStaffListOptions);

        // find current login users 
        let currentUser = localStorage.getItem("userid");

        // find the staff object from the list and set staff
        for (var i = 0; i < newStaffListOptions.length; i++) {
          let staffList = newStaffListOptions[i]["options"];

          // go through each staff in the list
          for (var j = 0; j < staffList.length; j++) {
            let staff = staffList[j];

            // if current user is found, set the main type and staff
            if (staff.value === currentUser) {
              setStaff(staff);
              return;
            }
          }
        }

      })
      .catch(error => function() {
        // print status code
        console.log(error);
      })
  }, []);

  // handle assignTo selection
  const handleAssignToSelection = (selectedOption) => {
    // return if selectedOption is empty
    if (selectedOption === "" || selectedOption === undefined || selectedOption === null) {
      return;
    }

    setIsUsedAISuggestion(true);

    // unchecked the checkbox if staff is selected
    document.getElementById('hold-off-assignment').checked = false;

    // find object containing the selectedOption
    for (var i = 0; i < staffListOptions.length; i++) {
      let staffList = staffListOptions[i]["options"];

      // go through each staff in the list
      for (var j = 0; j < staffList.length; j++) {
        let staff = staffList[j];

        // if current user is found, set the main type and staff
        if (staff.value === selectedOption.value) {
          setStaff(staff);

          return;
        }
      }
    }

    
  };


  // filter staff list based on mainType
  useEffect(() => {
    if (mainType === "" || mainType === undefined || mainType === null) {
      return;
    }

    let mainTypeName = mainType['langVar']['tc'];
    
    // reorder the list so the mainType is at the top and the rest is sorted alphabetically
    let newStaffListOptions = [];
    for (var i = 0; i < staffListOptions.length; i++) {
      let staffList = staffListOptions[i]["options"];

      // if the mainType is found, add to newStaffListOptions
      if (staffListOptions[i]["label"].includes(mainTypeName)) {
        newStaffListOptions.push({
          label: staffListOptions[i]["label"],
          options: staffList
        });
      }
    }

    // sort the rest of the list alphabetically
    for (var i = 0; i < staffListOptions.length; i++) {
      let staffList = staffListOptions[i]["options"];

      // if the mainType is found, add to newStaffListOptions
      if (!staffListOptions[i]["label"].includes(mainTypeName)) {
        newStaffListOptions.push({
          label: staffListOptions[i]["label"],
          options: staffList
        });
      }
    }

    // update staffListOptions
    setStaffListOptions(newStaffListOptions);

  }, [mainType]);


  // state for working staff 
  const [isWorkingStaff, setIsWorkingStaff] = useState(false);
  useEffect(() => {
    if (!staff) {
      return;
    }
    
    let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/users/latest-status?userId=" + staff.value + "&organizationID=" + sessionStorage.getItem("organizationId");

    // create a fetch request
    fetch(url,{
      headers: {
        'Authorization': sessionStorage.getItem('idToken')
      }
    })
      .then(response => {
        if (response.status === 200) {
            return response.json();
        } else {
          setIsWorkingStaff(false);
        }
      })
      .then(data => {
        // check if data['status'] exists
        if (data['status'] !== undefined) {
          // loop through data['status']
          for (var i = 0; i < data['status'].length; i++) {
            let status = data['status'][i];
            if (status['latestStatus']['status'] === 'In Progress') {
              setIsWorkingStaff(true);
              return;
            }
          }
        }
        setIsWorkingStaff(false);
      })
      .catch(error => function() {
        // print status code
        console.log(error);
      })


  }, [staff]);

  return(
    <>
      <label htmlFor="staff_input" className="block text-sm font-medium leading-6 text-gray-900 mb-2">{t("施工人員")}</label>
      {/* selector for staff */}
      <Select 
        options={staffListOptions}
        value={staff}
        onChange={handleAssignToSelection}
      />
      <div>

      </div>
      <div className="mt-3">
        <AssignedToAISuggestion 
          handleAssignToSelection={handleAssignToSelection}
          locationIDs={locationIDs}
          jobTypes={jobTypes}
          
        />
      </div>
      <div className="mt-3">
        <SelectedStaffCurrStatus 
          staff={staff}
          setSendPhoneNotification={setSendPhoneNotification}
        />
      </div>
      {/* checkbox for holding off assignment */}
      <div className="relative flex items-start mt-3">
        <div className="flex h-6 items-center">
          <input
            id="hold-off-assignment"
            name="hold-off-assignment"
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
            onClick={(e) => {
              // toggle isNotAssigningNow
              if (isNotAssigningNow === false) {
                setIsNotAssigningNow(true);
              } else {
                setIsNotAssigningNow(false);
              }
              // clear staff if checkbox is checked
              if (staff?.value !== "") {
                setStaff("");
              }
            }}
            value={isNotAssigningNow}
          />
        </div>
        <div className="ml-3 text-sm leading-6">
          <label htmlFor="hold-off-assignment" className="font-medium text-gray-900">
            {t("暫時不分配")}
          </label>
          <p id="hold-off-assignment-description" className="text-gray-500">
            <span className="sr-only">{t("暫時不分配 ") + " "}</span>{t("工作將會保持在待分配狀態")}
          </p>
        </div>
       
      </div>
      {/* a checkbox for send phone notification */}
      <div className="relative flex items-start mt-3">
        <div className="flex h-6 items-center">
          <input
            id="send-phone-notification"
            name="send-phone-notification"
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
            onClick={() => {
              // set sendPhoneNotification to true if checkbox is checked
              if (sendPhoneNotification === false) {
                setSendPhoneNotification(true);
              } else {
                setSendPhoneNotification(false);
              }
            }}
            checked={sendPhoneNotification}
          />
        </div>
        <div className="ml-3 text-sm leading-6">
          <label htmlFor="send-phone-notification" className="font-medium text-gray-900">
            {t("發送自動電話通知")}
            {isWorkingStaff && <span className="text-red-600 bg-red-300 ml-1 rounded-sm p-1 text-sm"> {t("注意: 施工人員正在工作")}</span>}
          </label>
          <p id="send-phone-notification-description" className="text-gray-500">
            {t("選擇此選項將會生成一個自動電話到施工人員 (如接單人士跟施工人員不同)")}
          </p>
        </div>
      </div>
    </>
  )
}

// job remark input component
function JobRemarkInput({
  jobRemark, 
  setJobRemark, 
  handleJobTypeFuzzyQueryChange, 
  jobTypes,
  locationIDs,
  setIsUsedAISuggestion,
  t
}) {
  const [recommendations, setRecommendations] = useState(['空房', '客房', '數量:']);

  // retrieve past job remark from past jobs
  useEffect(() => {
    // return 

    let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/groq/job-remark-assistant";

    // get jobTypeID
    if (jobTypes.length > 0) {
      url += "&jobTypeID=" + jobTypes[0].value;
    }

    // get locationID
    if (locationIDs.length > 0) {
      url += "&locationID=" + locationIDs[0].value;
    }

    fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': sessionStorage.getItem('idToken'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "organizationID": sessionStorage.getItem("organizationId"),
        "userID": localStorage.getItem("userid")
      })
    })
      .then(response => response.json())
      .then(data => {
        let initialRecommendations = data["remarks"];

        // remove 錄音文字: from the list
        initialRecommendations = initialRecommendations.map((item) => item.replace("錄音文字:", ""));

        // remove trailing white space
        initialRecommendations = initialRecommendations.map((item) => item.trim());

        // combine initialRecommendations with default recommendations
        let combinedRecommendations = [...['空房', '客房', '數量:'], ...initialRecommendations];
        let uniqueRecommendations = combinedRecommendations.filter((item, index) => combinedRecommendations.indexOf(item) === index);

        setRecommendations(uniqueRecommendations);
      })
      .catch(error => function() {
        // print status code
        console.log(error);
      })
  }, [jobTypes, locationIDs]);
        

  const handleRecommendationClick = (recommendation) => {
    setIsUsedAISuggestion(true);
    setJobRemark(jobRemark + recommendation);
  };

  return (
    <>
      <label htmlFor="job_remark_input" className="block text-sm font-medium leading-6 text-gray-900">
        {t("工作備忘")}
      </label>
      <div className="mt-2">
        <textarea
            id="job_remark_input"
            name="job_remark"
            rows={3}
            className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
            onBlur={e => handleJobTypeFuzzyQueryChange(e)}
            value={jobRemark}
            onChange={e => setJobRemark(e.target.value)}
          />
      </div>
      <span className="text-xs text-gray-500">{t("建議輸入字眼")}: </span>
      {/* a set of buttons with input recommendation (e.g. 空房, 客房, 數量:) */}
      <div>
        {recommendations.map((recommendation, index) => (
          <button
            key={index}
            className='text-left mt-2 mr-1 inline-flex items-center px-4 py-2 border border-indigo-600 text-xs font-medium rounded-md shadow-sm text-indigo-700 bg-white hover:bg-indigo-700 hover:text-white'
            onClick={() => handleRecommendationClick(recommendation)}
          >
            {recommendation}
          </button>
        ))}
      </div>
    </>
  )
}

// job type suggestion (displaying four chips based on users' remarks and client remark input)
function JobTypeSuggestion({
  clientRemark, 
  jobRemark, 
  setJobType, 
  jobTypeList,
  jobTypeFuzzyQuery,
  jobTypes,
  setJobTypes,
  locations,
  setIsUsedAISuggestion,
  globalUserPlan,
  t
}) {
  
  const [recommendations, setRecommendations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  

  // populate recommendations based on client remark and job remark
  useEffect(() => {
    // return if isLoading
    if (isLoading) {
      return;
    }

    // return if user plan is free
    if (globalUserPlan === "free") {
      console.log("user plan is free");
      return;
    }

    let clientRemark = jobTypeFuzzyQuery["clientRemark"];
    let jobRemark = jobTypeFuzzyQuery["jobRemark"];

    // create query text
    let combinedQuery = "";

    combinedQuery = clientRemark + " " + jobRemark;
   
    // remove trailing whitespace
    combinedQuery = combinedQuery.trim();
  

    let locIDList = locations.map((location) => location.value);
    

    let url = GlobalVars.ASSISTANT_DOMAIN + "/api/v1/job/find-job-types-llm";
    let body = {
      "guestRequest": clientRemark,
      "organizationID": sessionStorage.getItem("organizationId"),
      "locationIDs": locIDList,
      "userID": localStorage.getItem("userid")
    }

    setIsLoading(true);
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': sessionStorage.getItem('idToken')
      },
      body: JSON.stringify(body)
    })
      .then(response => response.json())
      .then(data => {
        
        // let jobTypes = data["response"];
        let jobTypes = data;
        
        // return if jobTypes is not a list 
        if (!Array.isArray(jobTypes)) {
          setIsLoading(false);
          console.log("jobTypes is not a list")
          return;
        }

        // go through jobTypeList and match with _id then create recoomendation with name
        let sortedList = jobTypes.map((jobType) => {
          let jobTypeObject = jobTypeList.find(item => item.value === jobType);
          // if undefined then skip
          if (jobTypeObject === undefined) {
            return;
          }
          // check if langVar exist in the object
          // console.log(jobTypeList);
          // if (jobTypeObject.langVar) {
          //   return {
          //     name: jobTypeObject.langVar[currentLang]
          //   }
          // }
          return {
            name: jobTypeObject.label.split("(")[0]
          }
        });
        setRecommendations(sortedList);
      })
      .catch(error => function() {
        console.log("Something went wrong with Job Type Suggestion")
        // print status code
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    // clean up function 
    return () => {
      setIsLoading(false);
    }

  }, [jobTypeFuzzyQuery, locations]);

  // handle recommendation click
  const handleRecommendationClick = (recommendation) => {
    setIsUsedAISuggestion(true);

    // go through jobTypeList and match with name then set jobType
    let jobTypeObject = jobTypeList.find(item => item.label.replace(/\s+/g, '').includes(recommendation.name.replace(/\s+/g, '')));
    if (jobTypeObject === undefined) {
      return;
    }
    
    // check if jobTypeObject already exists in the list jobTypes (selected job types), if so, return
    for (var i = 0; i < jobTypes.length; i++) {
      if (jobTypes[i].value === jobTypeObject.value) {
        console.log("jobTypeObject already exists in the list jobTypes");
        return;
      }
    }
    
    // append the jobTypeObject to existing jobTypes
    setJobTypes(jobTypes => [...jobTypes, jobTypeObject]);

    // remove recommendation from recommendations
    setRecommendations(recommendations.filter(item => item.name !== recommendation.name));
  };

  return (
    <div
      className="flex items-center flex-wrap"
    >
      <span className="text-xs text-gray-500 mr-2">{t("建議工作類型")}: </span>
      {/* loading animation */}
      {isLoading && (
        <div className="flex justify-center items-center mr-2">
          <ArrowPathIcon className="h-6 w-6 animate-spin text-indigo-600" aria-hidden="true" />
          <span
            className="text-sm text-gray-500 whitespace-nowrap"
          >
            {t("正在尋找相關工作...")}
          </span>
        </div>
      )}
      {/* recommendations */}
      {recommendations.map((recommendation, index) => (
        <button
          key={index}
          className='ai-suggestion mt-2 mr-1 inline-flex items-center px-4 py-2 border border-indigo-600 text-xs font-medium rounded-md shadow-sm text-indigo-700 bg-white hover:bg-indigo-700 hover:text-white'
          onClick={() => handleRecommendationClick(recommendation)}
        >
          {recommendation?.name}
        </button>
      ))}
    
    </div>
  )
}

// single job template dropdown component 
function SingleJobTemplateDropdown({selectedTemplate, setSelectedTemplate, t}) {
  const [templateList, setTemplateList] = useState([]);

  // query for existing templates
  useEffect(() => {
    let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/job/manage-single-template?organizationID=" + sessionStorage.getItem("organizationId");
    // create a fetch request
    fetch(url,{
      headers: {
        'Authorization': sessionStorage.getItem('idToken')
      }
    })
      .then(response => response.json())
      .then(rawData => {
        let data = rawData.map(item => ({ label: item.templateName, value: item._id }));
        
        setTemplateList(data);
      })
      .catch(error => function() {
        // print status code
        console.log(error);
      })
  }, [selectedTemplate]);

  return (
    <>
      {templateList.length > 0 && (
        <div className="mt-2">
          <label htmlFor="template" className="block text-sm font-medium leading-6 text-gray-900">
            {t("選擇工作模板")}
        </label>
        <div className="mt-2">
          <Select 
            options={templateList}
            value={selectedTemplate}
            onChange={(selectedOption) => setSelectedTemplate(selectedOption) }
            placeholder={t("選擇一個模板")}
            isClearable
            className="text-[16px]"
          />
          </div>
        </div>
      )}
    </>
  )
}

// preview of selected jobs. It takes in options with label as key name 
function PreviewSelectedOptions({options}) {

  return (
    <Menu as="div" className="relative text-left flex">
      <div>
        <MenuButton className="flex items-center rounded-full bg-white text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
          <span>按此查看已選項目</span>
          <InformationCircleIcon aria-hidden="true" className="h-5 w-5" />
        </MenuButton>
      </div>
      
      <MenuItems
        transition
        className="overflow-y-scroll max-h-[150px] absolute left-0 z-50 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="py-1">
          {options.length === 0 ? (
            <MenuItem>
              <span
                className='px-4 py-2 text-sm text-gray-700 block'
              >未有選擇</span>
            </MenuItem>
          ) : (
            options.map((option, index) => (
              <MenuItem key={index}>
                <span
                  className="px-4 py-2 text-sm text-gray-700 block"
                >
                  {option.label}
                </span>
              </MenuItem>
            ))
          )}
        </div>
      </MenuItems>
    </Menu>
  )
}

// further action dropdown
function FurtherActionDropdown({
  StoreCurrentJobSetting,
  StoreCurrentJobSettingAsRecurringSchedule,
}) {
  


  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <MenuButton className="flex items-center rounded-full bg-gray-100 text-gray-600 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
          <span className="sr-only">Open options</span>
          <EllipsisHorizontalIcon aria-hidden="true" className="h-6 w-6" />
        </MenuButton>
      </div>

      <MenuItems
        transition
        className="absolute left-0 bottom-5 z-10 mb-2 w-56 origin-bottom-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="py-1">
          <MenuItem>
            <a
              onClick={StoreCurrentJobSetting}
              className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
            >
              另存模板
            </a>
          </MenuItem>
          <MenuItem>
            <a
              onClick={StoreCurrentJobSettingAsRecurringSchedule}
              className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
            >
              建立週期性工作
            </a>
          </MenuItem>
        </div>
      </MenuItems>
    </Menu>
  )
}

// remark suggestion (a list of tabs with different types of suggestions)
function RemarkSuggestion({
  type, 
  currRemark, 
  selectedLocs, 
  setDesc, 
  setIsUsedAISuggestion,
  jobTypes,
  t
}) {
  const [defaultSuggestion, setDefaultSuggestion] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // debounce the fetch suggestions from backend
  const debouncedFetchSuggestions = useCallback(
    debounce((remark, locs) => {
      if (!locs.length) return;

      // if type is clientRemark and remark is empty, return
      if (type == "clientRemark" && remark == "") {
        setSuggestions([]);
        return;
      }
  
      setIsLoading(true);
      const url = `${GlobalVars.ASSISTANT_DOMAIN}/api/v1/groq/job-remark-assistant`;
      
      const body = {
        type: type,
        locationIDs: locs.map(loc => loc.value),
        organizationID: sessionStorage.getItem("organizationId"),
        userID: localStorage.getItem("userid"),
        remark: remark,
        presetRemarks: defaultSuggestion,
        userLang: localStorage.getItem("wgLang") || "tw"
      };

      // check if jobTypes is not empty
      if (jobTypes && jobTypes.length > 0) {
        body.jobTypes = jobTypes
      }
  
      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': sessionStorage.getItem('idToken')
        },
        body: JSON.stringify(body)
      })
        .then(response => response.json())
        .then(data => {
          if (!Array.isArray(data)) {
            throw new Error('Data is not a list');
          }
          let updatedList = []
          // Filter out items that are either in defaultSuggestion or are empty strings
          updatedList = data.filter(item => !defaultSuggestion.includes(item) && item !== "");

          setSuggestions(updatedList || [])
        })
        .catch(error => {
          console.error('Error fetching remark suggestions:', error)
        })
        .finally(() => setIsLoading(false));
    }, 500), // 500ms delay
    [type] // Dependencies for useCallback
  );
  
  useEffect(() => {
    debouncedFetchSuggestions(currRemark, selectedLocs);
  
    // Cleanup function to cancel the debounce on unmount
    return () => {
      debouncedFetchSuggestions.cancel();
    };
  }, [currRemark, selectedLocs, debouncedFetchSuggestions, jobTypes]);

  const handleSuggestionClick = (suggestion) => {
    // Handle suggestion click logic here
    setDesc(suggestion);

    setIsUsedAISuggestion(true);
  };

  // query for suggestion preset
  useEffect(() => {
    if (suggestions.length == 0 && type == "jobRemark") {
      let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/jobs/remark-preset" + "?organizationID=" + sessionStorage.getItem("organizationId");

      // create a fetch request
      fetch(url,{
        headers: {
          'Authorization': sessionStorage.getItem('idToken')
        }
      })
        .then(response => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error('Error fetching remark preset');
          }
        })
        .then(data => {
          setDefaultSuggestion(data['remarks']);
        })
        .catch(error => function() {
          // print status code
          console.log(error);
        })

    }
  }, []);

  return (
    <div className="mt-3">
      <span className="text-xs text-gray-500">{t("建議備忘")}: </span>
      <div className="flex items-center flex-wrap">
          {defaultSuggestion.map((suggestion, index) => (
            <button
              key={index}
              className='ai-suggestion text-left mt-2 mr-1 inline-flex items-center px-4 py-2 border border-indigo-600 text-xs font-medium rounded-md shadow-sm text-indigo-700 bg-white hover:bg-indigo-700 hover:text-white'
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {suggestion}
            </button>
          ))}
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center mt-1">
          <ArrowPathIcon className="h-6 w-6 animate-spin text-indigo-600" aria-hidden="true" />
          <span className="text-sm text-gray-500">{t("正在尋找相關備忘...")}</span>
        </div>
      ) : (
        <div className="flex items-center flex-wrap">
          {suggestions.map((suggestion, index) => (
            <button
              key={index}
              className='ai-suggestion text-left mt-2 mr-1 inline-flex items-center px-4 py-2 border border-indigo-600 text-xs font-medium rounded-md shadow-sm text-indigo-700 bg-white hover:bg-indigo-700 hover:text-white'
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {suggestion}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

