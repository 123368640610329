import { useState, useEffect } from 'react';
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { Combobox, ComboboxInput, ComboboxButton, ComboboxOptions, ComboboxOption } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline';
import GlobalVars from '../../../../Config';

export default function SearchBarContainer({filterObj, setFilterObj}) {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  
  // Check if any filter is active
  const hasActiveFilters = () => {
    return (
      filterObj.status !== "" || 
      filterObj.description !== "" || 
      filterObj.humanReadableId !== "" ||
      filterObj.location?._id !== "all" ||
      filterObj.productType?.value !== ""
    );
  };

  return (
    <div className='mt-4'>
      {/* Mobile Filter Toggle Button */}
      <button
        className='w-full flex items-center justify-between sm:hidden bg-white px-4 py-2 rounded-md shadow-sm ring-1 ring-inset ring-gray-300'
        onClick={() => setIsFilterOpen(!isFilterOpen)}
      >
        <div className='flex items-center gap-2'>
          <span className='text-sm font-semibold text-gray-900'>篩選選項</span>
          {hasActiveFilters() && (
            <span className='h-2 w-2 rounded-full bg-indigo-600 animate-pulse'></span>
          )}
        </div>
        <ChevronDownIcon 
          className={`h-5 w-5 text-gray-400 transition-transform duration-200 ${isFilterOpen ? 'transform rotate-180' : ''}`} 
        />
      </button>

      {/* Filter Content */}
      <div className={`sm:block ${isFilterOpen ? 'block' : 'hidden'}`}>
        <h3 className='text-sm font-semibold leading-6 text-gray-900 mb-2 mt-4 sm:mt-0'>
          篩選
        </h3>
        <div className='flex sm:items-center sm:gap-x-2 gap-y-2 flex-col sm:flex-row flex-wrap'>
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <MenuButton className="whitespace-nowrap sm:max-w-[180px] text-left inline-flex  justify-between w-full gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                {filterObj.description ? filterObj.description : "事件描述"}
                <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
              </MenuButton>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <MenuItems className="absolute left-0 z-10 mt-2 w-72 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <MenuItem>
                    {({ active }) => (
                      <div className="m-2">
                      <textarea
                        type="text"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={filterObj.description}
                        rows={"5"}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => {
                          setFilterObj({...filterObj, description: e.target.value});
                        }}
                        onKeyDown={(e) => e.stopPropagation()}
                      />
                    </div>
                    )}
                  </MenuItem>
                  
                </div>
              </MenuItems>
            </Transition>
          </Menu>
          <div className="sm:mt-0 mt-2">
            <select
              name="status"
              value={filterObj.status}
              onChange={(e) => setFilterObj({...filterObj, status: e.target.value})}
              className="skip-trans font-bold block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6"
            >
              <option value="">所有狀態</option>
              <option value="Pending">待處理</option>
              <option value="In Progress">處理中</option>
              <option value="Resolved">已解決</option>
              <option value="Canceled">已取消</option>
              <option value="Cancel Requested">客戶提出工單取消</option>
            </select>
          </div>
          <Menu as="div" className="relative inline-block text-left mt-1 sm:mt-0">
            <div>
              <MenuButton className="inline-flex w-full justify-between gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 whitespace-nowrap">
                {filterObj.humanReadableId ? filterObj.humanReadableId : "工單編號"}
                <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
              </MenuButton>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <div className="m-2">
                      <input
                        type="text"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={filterObj.humanReadableId}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => {
                          setFilterObj({...filterObj, humanReadableId: e.target.value});
                        }}
                      />
                    </div>
                    )}
                  </Menu.Item>
                  
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
          <LocationSearchMenu filterObj={filterObj} setFilterObj={setFilterObj} />
          <ProductSearchMenu filterObj={filterObj} setFilterObj={setFilterObj} />
          <button
            className={`${hasActiveFilters() ? 'bg-red-400 cursor-pointer hover:bg-red-500' : 'bg-gray-300'} text-white rounded-md px-2 py-2 mt-1 sm:mt-0 text-sm duration-300`}
            onClick={() => {
              setFilterObj({
                "status": "",
                "description": "",
                "humanReadableId": "",
                "location": {"_id": "all", "name": "所有地點"},
                "productType": {"label": "所有產品", "value": ""},
              });
              setIsFilterOpen(false); // Close the filter dropdown after clearing
            }}
          >
            清除篩選設定
          </button>
        </div>
      </div>

    </div>
  )
}

function classNames(...classes) {
return classes.filter(Boolean).join(' ')
}


  // location search menu
function LocationSearchMenu({filterObj, setFilterObj}) {
    const [query, setQuery] = useState('')
    // const [selectedPerson, setSelectedPerson] = useState(null)
    const [location, setLocation] = useState([])
    // fetch existing location list 
    useEffect(() => {
      // create GET Request for existing location list
      let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/locations?organizationID=" + localStorage.getItem("organizationId") + "&status=active";
      fetch(url, {
        headers: {
          "Authorization": sessionStorage.getItem("idToken")
        }
      })
        .then(response => {
          if (response.status === 200) {
            response.json().then(data => {
              // add 所有地點
              data['locations'].unshift({"id": "all", "name": "所有地點"});

              // add traditional chinese name to name if langVar exists and langVar.tc exist
              data['locations'].forEach((location) => {
                if (location.hasOwnProperty("langVar")) {
                  if (location.langVar.hasOwnProperty("tc")) {
                    location.name = location.name + "(" + location.langVar.tc + ")";
                  }
                }
              });
              
              setLocation(data['locations']);
            });
          } else {
            console.log("Error: " + response.status);
          }
        }
      ).catch(error => {
          console.log(error);
      });
    }, []);
  
  
    const filteredPeople =
      query === ''
        ? location
        : location.filter((item) => {
            return item.name.toLowerCase().includes(query.toLowerCase())
          })
  
    
  
    return (
      <Combobox
        as="div"
        value={filterObj.location}
        onChange={(item) => {
          setQuery('')
          setFilterObj({...filterObj, location: item})
        }}
      >
        {/* <Label className="block text-sm font-medium leading-6 text-gray-900">Assigned to</Label> */}
        <div className="relative skip-trans">
          <ComboboxInput
            className="mt-1 sm:mt-0  w-full font-semibold rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6"
            onChange={(event) => setQuery(event.target.value)}
            onBlur={() => setQuery('')}
            displayValue={(item) => item?.name}
          />
          <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </ComboboxButton>
  
          {filteredPeople.length > 0 && (
            <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredPeople.map((item) => (
                <ComboboxOption
                  key={item.id}
                  value={item}
                  className={({ focus }) =>
                    classNames(
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                      focus ? 'bg-indigo-600 text-white' : 'text-gray-900'
                    )
                  }
                >
                  {({ focus, selected }) => (
                    <>
                      <span className={classNames('block truncate', selected && 'font-semibold')}>{item.name}</span>
  
                      {selected && (
                        <span
                          className={classNames(
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            focus ? 'text-white' : 'text-indigo-600'
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </ComboboxOption>
              ))}
            </ComboboxOptions>
          )}
        </div>
      </Combobox>
    )
  }


  // Search menu for product type
function ProductSearchMenu({filterObj, setFilterObj}) {
    // useEffect(() => {
    //   setFilterObj({...filterObj,
    //     productType: {"label": "所有產品", "value": ""}
    //   });
    // }, []);
  
    const [prodList, setProdList] = useState([
      { "label": "所有產品", "value": "" },
      { "label": "CABD 公共天線系統", "value": "CABD" },
      { "label": "CCTV 閉路電視", "value": "CCTV" },
      { "label": "Data Switch 數據交換機", "value": "Data Switch" },
      { "label": "Door Lock 門鎖", "value": "Door Lock" },
      { "label": "E-Casual 電子便捷", "value": "E-Casual" },
      { "label": "Email 電子郵件", "value": "Email" },
      { "label": "Firewall 防火牆", "value": "Firewall" },
      { "label": "Front Office/Property Management System (PMS) 酒店前台管理系統", "value": "Front Office System/Property Management System (PMS)" },
      { "label": "Internet Gateway 網絡閘道", "value": "Internet Gateway" },
      { "label": "Kiosk 自助服務機", "value": "Kiosk" },
      { "label": "Network 網絡", "value": "Network" },
      { "label": "NSS Server 通知系統", "value": "NSS Server" },
      { "label": "POS 餐飲系統", "value": "POS" },
      { "label": "PC 個人電腦", "value": "PC" },
      { "label": "PABX 電話系統", "value": "PABX" },
      { "label": "Telephone Set 電話機", "value": "Telephone Set" },
      { "label": "TV 電視", "value": "TV" },
      { "label": "UPS 不間斷電源", "value": "UPS" },
      { "label": "Vending Machine 自助售貨機", "value": "Vending Machine" },
      { "label": "Voice Logger 語音記錄器", "value": "Voice Logger" },
      { "label": "Website 網站", "value": "Website" },
      { "label": "WiFi/AP 無線網絡", "value": "WiFi/AP" },
      { "label": "Voice Mail System 語音信箱系統", "value": "i-Message Voice Mail System" },
      { "label": "iPanel/iSignage 電子廣告板", "value": "i-Panel" },
      { "label": "Call Accounting System 計費系統", "value": "i-interface Call Accounting System" },
      { "label": "Other Products & Services 其他產品及服務", "value": "Other Products & Services" }
    ])
    const [query, setQuery] = useState('')
  
    const filteredList = 
      query === ''
        ? prodList
        : prodList.filter((item) => {
            return item.label.toLowerCase().includes(query.toLowerCase())
          })
  
    return (
      <Combobox
        as="div"
        value={filterObj.productType}
        onChange={(item) => {
          setQuery('')
          setFilterObj({...filterObj, productType: item})
        }}
        className="skip-trans"
      >
        <div className="relative">
          <ComboboxInput
            className="mt-1 sm:mt-0 w-full font-semibold rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm sm:leading-6"
            onChange={(event) => setQuery(event.target.value)}
            onBlur={() => setQuery('')}
            displayValue={(item) => item?.label}
          />
          <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </ComboboxButton>
  
          {filteredList.length > 0 && (
            <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredList.map((item) => (
                <ComboboxOption
                  key={item.value}
                  value={item}
                  className={({ focus }) =>
                    classNames(
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                      focus ? 'bg-indigo-600 text-white' : 'text-gray-900'
                    )
                  }
                >
                  {({ focus, selected }) => (
                    <>
                      <span className={classNames('block truncate', selected && 'font-semibold')}>{item.label}</span>
  
                      {selected && (
                        <span
                          className={classNames(
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            focus ? 'text-white' : 'text-indigo-600'
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </ComboboxOption>
              ))}
            </ComboboxOptions>
          )}
        </div>
      </Combobox>
    )
  
  }
  
  
