import { useEffect, useState } from 'react'
import GlobalVars from '../../../../../../Config'
import { PaperClipIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'

export default function RemarkDialogList({ itemID }) {
    const [remarks, setRemarks] = useState([])
    const [loading, setLoading] = useState(true)
    const [searchQuery, setSearchQuery] = useState('')

    useEffect(() => {
        const fetchRemarks = async () => {
            console.log('Fetching remarks for itemID:', itemID)
            try {
                const url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/board/item-remarks?itemID=${itemID}`
                console.log('Fetching from URL:', url)
                
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                console.log('Response status:', response.status)
                
                const data = await response.json()
                console.log('Received data:', data)
                
                if (response.ok) {
                    setRemarks(data.remarks)
                }
            } catch (error) {
                console.error('Error fetching remarks:', error)
            } finally {
                setLoading(false)
            }
        }

        if (itemID) {
            fetchRemarks()
        } else {
            console.log('No itemID provided')
        }
    }, [itemID])

    const handleFileOpen = async (fileName) => {
        try {
            let organizationID = localStorage.getItem('organizationId')
            const projectID = window.location.pathname.split('/').pop();
            const response = await fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/project/signed-url?fileName=${fileName}&itemID=${itemID}&organizationID=${organizationID}&projectID=${projectID}`, {
                method: 'GET',
                headers: {
                    'Authorization': sessionStorage.getItem('idToken')
                }
            });

            if (response.ok) {
                const { url } = await response.json();
                window.open(url, '_blank');
            } else {
                console.error('Failed to get signed URL');
            }
        } catch (error) {
            console.error('Error getting signed URL:', error);
        }
    };

    // Filter remarks based on search query
    const filteredRemarks = remarks.filter(remark => 
        remark.remarks.toLowerCase().includes(searchQuery.toLowerCase()) ||
        remark.user.toLowerCase().includes(searchQuery.toLowerCase())
    )

    if (loading) {
        return <div className="flex justify-center p-4">Loading...</div>
    }

    return (
        <div className="space-y-3">
            {/* Search input */}
            <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
                </div>
                <input
                    type="text"
                    className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    placeholder="搜尋備註..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>

            {/* Remarks list */}
            {filteredRemarks.map((remark, index) => (
                <div key={index} className="bg-white border border-gray-200 rounded-lg p-3 shadow-sm">
                    <div className="flex items-center gap-2 mb-1.5">
                        <span className="text-sm font-medium text-gray-900">{remark.user}</span>
                        <span className="text-xs text-gray-500">•</span>
                        <span className="text-xs text-gray-500">{remark.createdAt}</span>
                    </div>
                    <p className="text-sm text-gray-700 whitespace-pre-wrap">{remark.remarks}</p>
                    {remark.attachments && remark.attachments.length > 0 && (
                        <div className="mt-2 flex flex-wrap gap-2">
                            {remark.attachments.map((fileName, idx) => (
                                <button
                                    key={idx}
                                    onClick={() => handleFileOpen(fileName)}
                                    className=" inline-flex items-center gap-1 text-xs text-blue-600 hover:text-blue-800 bg-blue-50 px-2 py-1 rounded-md max-w-[200px]"
                                    type="button"
                                    title={fileName}
                                >
                                    <PaperClipIcon className="h-3 w-3 flex-shrink-0" />
                                    <span className="whitespace-nowrap overflow-hidden text-ellipsis">{fileName}</span>
                                </button>
                            ))}
                        </div>
                    )}
                </div>
            ))}
            {filteredRemarks.length === 0 && (
                <div className="text-center text-gray-500 py-8">
                    {searchQuery ? '找不到符合的備註' : '尚無備註'}
                </div>
            )}
        </div>
    )
}
