import GlobalVars from "../../Config";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { initializeApp, getApps } from "firebase/app";
import { getAuth, browserSessionPersistence, browserLocalPersistence, signInWithEmailAndPassword, sendPasswordResetEmail, setPersistence, onAuthStateChanged } from "firebase/auth";
import {Scanner} from '@yudiel/react-qr-scanner';
import LangSwitcher from "./Components/LangSwitcher";
import { useTranslation } from 'react-i18next';

// components
import PasswordReset from "./Components/PasswordReset";


// initialize firebase app if not already initialized
let firebaseApp;
if (!getApps().length) {
  firebaseApp = initializeApp(GlobalVars.firebase);
} else {
  firebaseApp = getApps()[0];
}
const auth = getAuth(firebaseApp);


// function RedirectUserBasedOnRequestedPage() {
//   const navigate = useNavigate();
  
// }

export default function Authentication() {
    const { t } = useTranslation();
    // set page title to "Authentication"
    document.title = t('身分認證');

    // check if users were trying to access a page before login
    // const attemptedPage = sessionStorage.getItem("requestedPage");

    // set state for email and password
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [rememberPassword, setRememberPassword] = useState(true); 

    // ref for login button
    const loginButtonRef = useRef(null);

    // ref for inputs
    const emailRef = useRef(null);
    const passwordRef = useRef(null);

    const navigate = useNavigate();

    // redirect users based on requested page
    const RedirectUserBasedOnRequestedPage = () => {
          // check if users have a requested page in sessionStorage
      let requestedPage = sessionStorage.getItem("requestedPage");
      if (requestedPage !== null && requestedPage !== "" && !(requestedPage.includes("/authentication") || requestedPage.includes("/create-account"))) {
        // redirect to users requested page
        window.location.href = requestedPage;
      } else {
        // check if users in a mobile device or not 
        if (window.innerWidth <= 768) {
          // redirect to my-jobs
          window.location.href = "/my-jobs";
        } else {
          // redirect to /job-list
          window.location.href = "/job-list";
        }
      }
    }

    const handleLogin = () => {
        // change button text to "登入中..."
        loginButtonRef.current.innerHTML = t('登入中...');

        // set persistence
        setPersistence(auth, rememberPassword? browserLocalPersistence:browserSessionPersistence)
          .then(() => {
        
            signInWithEmailAndPassword(auth, email, password)
              .then((userCredential) => {
                  // Signed in 
                  const user = userCredential.user;

                  // get users' ID token and store to session storage
                  user.getIdToken()
                    .then((idToken) => {
                      sessionStorage.setItem("idToken", idToken);
                      // update lastIdTokenUpdateTime
                      sessionStorage.setItem("lastIdTokenUpdateTime", new Date().getTime());
                    })
                    .catch((error) => {
                      console.log(error);
                    });

                  // store uid to session storage
                  sessionStorage.setItem("firebaseUid", user.uid);

                  // fetch organization id and type from backend and store to session
                  let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/user/rights?firebaseID=" + user.uid;
                  fetch(url)
                    .then((response) => {
                      if (!response.ok) { // if HTTP-status is 200-299
                          // print out status code and status text
                          console.log("status code: " + response.status);
                          console.log("status text: " + response.statusText);

                          // get the error message from the server's response
                          alert(t('你的電郵尚未被授權，請聯絡管理員。'));
                      }
                      return response.json();
                    })
                    .then((data) => {

                      let user = data['user'];

                      // store organization id and type to session storage
                      SetStorage(user.organizationID, user._id, user.type).then(() => {

                      // prompt users to active their status
                        if (user.status === "inactive") {
                          let msg = t('是否要將您的狀態設為上線？');
                          if (user.hasOwnProperty("organizationName")) {
                            msg += "\n" + t('組織名稱') + ":" + user.organizationName;
                          }
                          const needActive = window.confirm(msg);
                          if (needActive) {
                            let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/users/active-status";
                            let data = {
                              "userID": user._id,
                              "status": "active",
                              "organizationID": user.organizationID
                            }

                            // send request
                            fetch(url, {
                              method: "PUT",
                              headers: {
                                "Content-Type": "application/json"
                              },
                              body: JSON.stringify(data)
                            }).then((response) => {
                              return response.json();
                            }
                            ).then((data) => {
                              console.log(data);
                            }).catch((error) => {
                              console.log(error);
                            });
                          }

                        }                        

                        // check if users have a requested page in sessionStorage
                        RedirectUserBasedOnRequestedPage();
                      });
                    })
                    .catch((error) => {
                      console.log(error);
                      alert(t('發現未知錯誤, 會嘗試重新登入。'));

                      // window.location.href = "/authentication";
                      navigate("/authentication");
                    });
                  
                  
              })
              .catch((error) => {
                  console.log(error);

                  // if it's a auth/user-not-found error, prompt users to create an account and redirect to /create-account
                  alert(t('登入失敗，請檢查電郵和密碼是否正確。'));

                  // change button text back to "登入"
                  loginButtonRef.current.innerHTML = "登入";

              });
          })
          .catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
          })
          .finally(() => {
            resetUIState();
          });
    };

    // Helper function to reset UI state
    const resetUIState = () => {
        if (loginButtonRef.current) {
            loginButtonRef.current.innerHTML = t('登入');
        }
        if (emailRef.current) {
            emailRef.current.disabled = false;
        }
        if (passwordRef.current) {
            passwordRef.current.disabled = false;
        }
    };

    useEffect(() => {
      const auth = getAuth();

      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          // set login button to logging in
          loginButtonRef.current.innerHTML = t('登入中...');

          // disable inputs
          emailRef.current.disabled = true;
          passwordRef.current.disabled = true;

          // set sessionStorage for firebaseUid and organizationId
          sessionStorage.setItem("firebaseUid", user.uid);
          sessionStorage.setItem("organizationId", localStorage.getItem("organizationId"));
    
          user.getIdToken().then((idToken) => {
            
            sessionStorage.setItem("idToken", idToken);
            // check if organizationId exist in localStorage
            let organizationId = localStorage.getItem("organizationId");
            let userID = localStorage.getItem("userid");
            let userType = localStorage.getItem("userType");
    
            // if organizationId, userID and userType exist in localStorage, redirect to requested page
            const currentPath = window.location.pathname;

            if (organizationId !== null && userID !== null && userType !== null && currentPath === "/authentication") {
              console.log("redirecting to requested page");
              RedirectUserBasedOnRequestedPage();
              return;
            }

          })
          .catch((error) => {
            console.log(error);
            // set login button to login
            resetUIState();
          });
        } else {
          // set login button to login
          resetUIState();
        }
      });
    
      // Cleanup subscription on unmount
      return () => unsubscribe();
    }, [navigate, RedirectUserBasedOnRequestedPage]); // Add navigate to dependencies
    

    // handle password reset 
    const [showPasswordReset, setShowPasswordReset] = useState(false);

    const handlePasswordReset = async (method, contact, recaptchaToken) => {
        try {
            // Only verify reCAPTCHA if not on localhost and token exists
            if (!window.location.hostname.includes('localhost') && recaptchaToken) {
                const verifyUrl = `${GlobalVars.BACKEND_DOMAIN}/api/v1/verify-recaptcha`;
                const verifyResponse = await fetch(verifyUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ token: recaptchaToken })
                });

                if (!verifyResponse.ok) {
                    throw new Error('reCAPTCHA verification failed');
                }
            }

            if (method === 'email') {
                await sendPasswordResetEmail(auth, contact);
                alert(t('已寄出重設密碼信件，請至信箱收取。'));
            } else {
                // Handle phone number reset
                alert(t('手機重設密碼功能即將推出'));
            }
        } catch (error) {
            console.error(error);
            if (error.code === "auth/user-not-found") {
                alert(t('電郵不存在，請檢查電郵是否正確。'));
                return;
            }
            alert(t('重設密碼失敗，請重試'));
        }
    };

    // states for qr code scanner
    const [openScanner, setOpenScanner] = useState(false);

    

    return (
      <>
        {/*
          This example requires updating your template:
  
          ```
          <html class="h-full bg-white">
          <body class="h-full">
          ```
        */}
        <div className="flex min-h-full flex-1 h-[100vh]" id="primary-column">
          <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full max-w-sm lg:w-96">
              <div>
                <img
                  className="h-10 w-auto"
                  src="/logo.png"
                  alt="Your Company"
                />
                <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                  {t('登陸你的帳號')}
                </h2>
                {/* <p className="mt-2 text-sm leading-6 text-gray-500">
                  Not a member?{' '}
                  <a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">
                    Start a 14 day free trial
                  </a>
                </p> */}
              </div>
  
              <div className="mt-10">
                <div>
                  <div className="space-y-6">
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                        {t('電郵')}
                      </label>
                      <div className="mt-2">
                        <input
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            className="px-2 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            ref={emailRef}
                        />
                      </div>
                    </div>
  
                    <div>
                      <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                        {t('密碼')}
                      </label>
                      <div className="mt-2">
                        <input
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            required
                            className="px-2 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            ref={passwordRef}
                        />
                      </div>
                    </div>
  
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <input
                          id="remember-me"
                          name="remember-me"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          checked={rememberPassword}
                          onChange={(e) => setRememberPassword(e.target.checked)}
                        />
                        <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-700">
                          {t('記住密碼')}
                        </label>
                      </div>
  
                      <div className="text-sm leading-6">
                        <button 
                            className="font-semibold text-indigo-600 hover:text-indigo-500" 
                            onClick={() => setShowPasswordReset(true)}
                        >
                          {t('忘記密碼?')}
                        </button>
                      </div>
                    </div>
  
                    <div>
                      <button
                        type="submit"
                        className="dynamic-comp flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={handleLogin}
                        ref={loginButtonRef}
                        id="login-button"
                      >
                        {t('加載中...')}
                      </button>
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="flex w-full justify-center rounded-md bg-gray-200 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-900 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-200"
                        onClick={() => window.location.href = "/create-account"}
                      >
                        {t('建立帳號')}
                      </button>
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="flex w-full justify-center rounded-md bg-gray-200 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-900 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-200"
                        onClick={() => setOpenScanner(true)}
                      >
                        {t('掃描QR')}
                      </button>
                    </div>
                    <hr className="my-4"></hr>
                    <LangSwitcher />
                  </div>
                </div>
  
                {/* <div className="mt-10">
                  <div className="relative">
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                      <div className="w-full border-t border-gray-200" />
                    </div>
                    <div className="relative flex justify-center text-sm font-medium leading-6">
                      <span className="bg-white px-6 text-gray-900">Or continue with</span>
                    </div>
                  </div>
  
                  <div className="mt-6 grid grid-cols-2 gap-4">
                    <a
                      href="#"
                      className="flex w-full items-center justify-center gap-3 rounded-md bg-[#1D9BF0] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#1D9BF0]"
                    >
                      <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                      </svg>
                      <span className="text-sm font-semibold leading-6">Twitter</span>
                    </a>
  
                    <a
                      href="#"
                      className="flex w-full items-center justify-center gap-3 rounded-md bg-[#24292F] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#24292F]"
                    >
                      <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                        <path
                          fillRule="evenodd"
                          d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="text-sm font-semibold leading-6">GitHub</span>
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="relative hidden w-0 flex-1 lg:block">
            <img
              className="absolute inset-0 h-full w-full object-cover"
              src="assets/login-background.png"
              alt=""
            />
          </div>
        </div>
        {openScanner &&
          <QRCodeScanner openScanner={openScanner} setOpenScanner={setOpenScanner} setEmail={setEmail} setPW={setPassword} />
        }
        <PasswordReset 
            open={showPasswordReset} 
            setOpen={setShowPasswordReset}
            onSubmit={handlePasswordReset}
            initialEmail={email}
        />
      </>
    )
}

// sets localStorage and sessionStorage 
function SetStorage(orgId, userId, type) {
  return new Promise((resolve) => {
    localStorage.setItem("organizationId", orgId);

    if (!sessionStorage.getItem("organizationId") || sessionStorage.getItem("organizationId").length < 24) {
      sessionStorage.setItem("organizationId", orgId);
    }

    localStorage.setItem("userid", userId);
    localStorage.setItem("userType", type);
    
    resolve();
  });
}

function QRCodeScanner({openScanner, setOpenScanner, setEmail, setPW}) {
  const { t } = useTranslation();

  const customVideoStyle = {
    height: "259px",
    paddingTop: "65%"
  }

  return (
    <div
      className="fixed top-0 left-0 w-full h-[100svh] bg-black bg-opacity-50 z-50"
    >
      <button
        onClick={() => setOpenScanner(false)}
        className="absolute w-[20%] top-4 left-[40%] bg-indigo-600 p-2 px-5 rounded-full z-50 text-white"
      >
        {t('關閉掃描器')}
      </button>
      <Scanner 
        onResult={(text, result) => {
          let data = text.split(" ");
          setEmail(data[0]);
          setPW(data[1]);
          setOpenScanner(false);
        }}
        onError={(err) => console.error(err)}
        styles={customVideoStyle}
        enabled={openScanner}
      />
    </div>
  )
}


  