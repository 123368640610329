import { useState, useRef, useEffect } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import ReCAPTCHA from "react-google-recaptcha"
import GlobalVars from '../../../../Config'

export default function PasswordReset({ open, setOpen, onSubmit, initialEmail }) {
  const { t } = useTranslation()
  const [method, setMethod] = useState('email')
  const [contact, setContact] = useState(initialEmail || '')
  const recaptchaRef = useRef()
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    // Load the reCAPTCHA Enterprise script
    const script = document.createElement('script')
    script.src = 'https://www.google.com/recaptcha/enterprise.js?render=6Ld-Tb8qAAAAAOv5Qnc_39Pyr91ATf1v7rSNSeKR'
    script.async = true
    document.head.appendChild(script)

    return () => {
      // Cleanup - remove script when component unmounts
      document.head.removeChild(script)
    }
  }, [])

  const handleSubmit = async () => {
    if (!contact) {
      alert(t('請輸入聯絡方式'))
      return
    }

    setIsSubmitting(true)
    try {
      let recaptchaToken = null;
      // Use grecaptcha.enterprise to execute reCAPTCHA
      await new Promise((resolve) => {
        window.grecaptcha.enterprise.ready(async () => {
          recaptchaToken = await window.grecaptcha.enterprise.execute('6Ld-Tb8qAAAAAOv5Qnc_39Pyr91ATf1v7rSNSeKR', { action: 'LOGIN' });
          resolve();
        });
      });

      if (!recaptchaToken) {
        alert(t('請完成驗證'))
        return
      }

      if (method === 'phone') {
        // Make API request for phone number reset
        const response = await fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/users/reset-password-with-phone-number`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            phoneNo: contact,
            recaptchaToken
          })
        });

        if (!response.ok) {
          throw new Error('Phone reset request failed');
        }

        alert(t('重設密碼簡訊已發送'));
      } else {
        await onSubmit(method, contact, recaptchaToken)
      }
      
      setOpen(false)
    } catch (error) {
      console.error('Reset error:', error)
      alert(t('重設密碼失敗，請重試'))
    } finally {
      setIsSubmitting(false)
      // No need to reset the reCAPTCHA here since we are using enterprise version
    }
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="w-[80vw] sm:w-initial relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div>
              <DialogTitle as="h3" className="text-base font-semibold text-gray-900 mb-4">
                {t('重設密碼')}
              </DialogTitle>
              
              <div className="flex gap-4 mb-4">
                <button
                  onClick={() => setMethod('email')}
                  className={`flex-1 flex items-center justify-center gap-2 p-3 rounded-lg border ${
                    method === 'email' ? 'border-indigo-600 bg-indigo-50' : 'border-gray-300'
                  }`}
                >
                  <EnvelopeIcon className="h-5 w-5" />
                  {t('電郵')}
                </button>
                <button
                  onClick={() => setMethod('phone')}
                  className={`flex-1 flex items-center justify-center gap-2 p-3 rounded-lg border ${
                    method === 'phone' ? 'border-indigo-600 bg-indigo-50' : 'border-gray-300'
                  }`}
                >
                  <PhoneIcon className="h-5 w-5" />
                  {t('手機')}
                </button>
              </div>

              <input
                type={method === 'email' ? 'email' : 'tel'}
                placeholder={method === 'email' ? t('請輸入電郵') : t('請輸入手機號碼')}
                value={contact}
                onChange={(e) => setContact(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              />

              {/* {isLocalhost && ( */}
                <div className="mt-4">
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={"6Ld-Tb8qAAAAAOv5Qnc_39Pyr91ATf1v7rSNSeKR"}
              />
            </div>
              {/* )} */}
            </div>
            
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                onClick={handleSubmit}
                disabled={isSubmitting}
                className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto disabled:opacity-50"
              >
                {isSubmitting ? t('處理中...') : t('重設密碼')}
              </button>
              <button
                type="button"
                onClick={() => setOpen(false)}
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
              >
                {t('取消')}
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  )
}
