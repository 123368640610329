import { Menu, Transition, MenuButton, MenuItems, MenuItem } from "@headlessui/react";
import { GlobeAltIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

let countries = [
  {
    code: "zh-TW",
    name: "繁體中文",
    country_code: "tw",
  },
  {
    code: "en-US",
    name: "English",
    country_code: "gb",
  },
  {
    code: "zh-CN",
    name: "简体中文",
    country_code: "cn",
  },
];

const LanguageSwitcher = () => {
    const { t, i18n } = useTranslation();

    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
    useEffect(() => {
        if (i18n.language) {
            // console.log('Language changed to:', i18n.language);
            setCurrentLanguage(i18n.language);
            // Ensure language is stored in localStorage
            localStorage.setItem('i18nextLng', i18n.language);
        }
    }, [i18n.language]);

    const handleLanguageChange = (langCode) => {
        i18n.changeLanguage(langCode);
        localStorage.setItem('i18nextLng', langCode);
    };

    return (
        <>
        <div>
            <Menu
            as="div"
            className="px-3 pl-0 relative flex"
            aria-label="usermenu"
            >
            <MenuButton
                className="group w-full text-sm text-left font-medium text-gray-700 hover:bg-gray-50 rounded-md px-3 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                aria-label="usermenu-button"
            >
                <span className="flex w-full justify-between items-center gap-2">
                    <span className="text-sm text-gray-700">
                        {countries.find(lng => lng.code === currentLanguage)?.name || '繁體中文'}
                    </span>
                    <GlobeAltIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
            </MenuButton>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <MenuItems
                    aria-label="menu-item-container"
                    className="z-10 mx-3 origin-top absolute left-[-36px] sm:left-[-25px] md:left-[-25px] top-[42px] xl:left-[-80px] right-0 min-w-max mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
                >
                <div className="px-1 py-1" aria-label="menu-items">
                    {countries.map((lng) => {
                    return (
                        <MenuItem key={lng.code}>
                        <button
                            className={classNames(
                            "flex items-center px-2 w-full py-2 text-sm cursor-pointer hover:bg-gray-100"
                            )}
                            onClick={() => handleLanguageChange(lng.code)}
                            disabled={i18n.language === lng.code}
                        >
                            <span class={`fi fi-${lng.country_code}`}></span>
                            <span>{lng.name}</span>
                        </button>
                        </MenuItem>
                    );
                    })}
                </div>
                </MenuItems>
            </Transition>
            </Menu>
        </div>
        </>
    );
};

export default LanguageSwitcher;