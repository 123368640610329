import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import GlobalVars from '../../../../../../Config'

export default function ProgressRemarkDialog({
    open,
    setOpen,
    itemId,
    date,
    onSave
}) {
    const [remark, setRemark] = useState('')

    const handleSubmit = async () => {
        try {
            const response = await fetch(`${GlobalVars.BACKEND_DOMAIN}/api/v1/board/item-progress`, {
                method: 'POST',
                headers: {
                    'Authorization': `${sessionStorage.getItem('idToken')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    itemID: itemId,
                    date: date.toISOString().split('T')[0],
                    remark: remark
                })
            });

            if (!response.ok) {
                throw new Error('Failed to save remark');
            }

            onSave && onSave();
            setOpen(false);
            setRemark('');
        } catch (error) {
            console.error('Error saving remark:', error);
        }
    }

    const getRemark = async (itemId, date) => {
        let url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/board/item-progress?itemID=${itemId}&date=${date.toISOString().split('T')[0]}`
        const response = await fetch(url);
        const data = await response.json();
        setRemark(data.progress?.remark || '');
    }

    // preset remark
    useEffect(() => {
        if (itemId && date) {
            getRemark(itemId, date);
        }
    }, [itemId, date]);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-[100]" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            添加進度備註
                                        </Dialog.Title>
                                        <div className="mt-4">
                                            <textarea
                                                rows={4}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                                value={remark}
                                                onChange={(e) => setRemark(e.target.value)}
                                                placeholder="在此輸入備註..."
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2"
                                        onClick={handleSubmit}
                                    >
                                        保存
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                        onClick={() => setOpen(false)}
                                    >
                                        取消
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
