import { useState, useEffect, useRef} from "react";
import { 
    XMarkIcon,
    ChevronUpDownIcon,
    CheckIcon,
    PencilIcon,
    ArrowUpIcon,
    ArrowDownIcon
 } from '@heroicons/react/20/solid';
 import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/react'

import GlobalVars from "../../../../Config";

export default function ChecklistSetting({isSaving, setIsSaving}) {
    const [items, setItems] = useState([]);
    const [jobTypes, setJobTypes] = useState([]);
    const [selectedJobType, setSelectedJobType] = useState(null);
    const [isExpanded, setIsExpanded] = useState(true);
    const [newItemName, setNewItemName] = useState('');
    const inputRef = useRef(null);

    const handleAddItem = (e) => {
        e.preventDefault();
        const trimmedName = newItemName.trim();
        if (trimmedName) {
            // Check if item already exists (case insensitive)
            const isDuplicate = items.some(item => 
                item.name.toLowerCase() === trimmedName.toLowerCase()
            );
            
            if (!isDuplicate) {
                setItems([...items, {
                    id: items.length + 1,
                    name: trimmedName
                }]);
                setNewItemName('');
            }
        }
    };

    const handleDeleteItem = (id, name) => {
        // prompt user to confirm
        if (!window.confirm("確認刪除？\n" + name)) {
            return;
        }

        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/check-list-setting"
        let data = {
            itemID: id,
            organizationID: sessionStorage.getItem("organizationId"),
            jobTypeID: selectedJobType._id
        }
        
        fetch(url, {
            method: 'DELETE',
            headers: {
                'Authorization': sessionStorage.getItem('idToken'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            console.log('Checklist deleted successfully');
            fetchChecklistItems({selectedJobType});
        })
        .catch(error => {
            console.error('Error deleting checklist:', error);
        });
    };

    const handleEditItem = (id) => {
        console.log(id);
        console.log(items);

        // set the value to the new item name
        setNewItemName(items.find((item) => item._id === id).name);
        inputRef.current.focus();
        
        // delete from existing items
        setItems(items.filter((item) => item._id !== id));
        
    };

    const handleSave = () => {
        // return if items is empty
        if (items.length === 0) {
            return;
        }

        // prompt user to confirm
        if (!window.confirm("確認儲存？")) {
            return;
        }

        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/check-list-setting"
        let data = {
            jobTypeID: selectedJobType._id,
            itemList: items,
            organizationID: localStorage.getItem("organizationId")
        }
        
        fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': sessionStorage.getItem('idToken'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            
            console.log('Checklist saved successfully');

        })
        .catch(error => {
            console.error('Error saving checklist:', error);
        })
        .finally(() => {
            setIsSaving(false);
        });
    }

    useEffect(() => {
        if (isSaving) {
            handleSave();
        }
    }, [isSaving]);


    async function fetchJobTypes() {
        const response = await fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/jobtypes?organizationID=" + localStorage.getItem("organizationId")+ "&lang=tc");
        const data = await response.json();
        setJobTypes(data['jobTypes']);
    }

    useEffect(() => {
        fetchJobTypes({selectedJobType});
    }, []);

    async function fetchChecklistItems({selectedJobType}) {
        // return if no job type selected
        if (!selectedJobType) {
            return;
        }

        const response = await fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/check-list-setting?lang=tc&jobTypeID=" + selectedJobType._id, {
            headers: {
                'Authorization': sessionStorage.getItem('idToken')
            }
        });
        const data = await response.json();
        setIsExpanded(true);
        // return if no data
        if (data.length === 0) {
            setItems([]);
            return;
        } else {
            setItems(data[0]['itemList']);
        }
    }
    useEffect(() => {
        fetchChecklistItems({selectedJobType});
    }, [selectedJobType]);

    const handleMoveItem = (id, direction) => {
        const currentIndex = items.findIndex(item => item._id === id);
        if (currentIndex === -1) return; // Item not found
        
        // Don't move if at the edges
        if (direction === 'up' && currentIndex === 0) return;
        if (direction === 'down' && currentIndex === items.length - 1) return;
        
        const newItems = [...items];
        const newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;
        
        // Swap items
        [newItems[currentIndex], newItems[newIndex]] = [newItems[newIndex], newItems[currentIndex]];
        
        setItems(newItems);
    };

    

    return (
        <div className="space-y-4">
            <label className="text-sm font-medium text-gray-900">
                工作類別
            </label>
            <DropdownList options={jobTypes} selected={selectedJobType} setSelected={setSelectedJobType} />
            <hr className="border-t border-gray-200"></hr>
            <p className="text-sm text-gray-500">
                請輸入項目名稱，並按下新增按鈕。
            </p>
            {selectedJobType && (
                <>
                    <form onSubmit={handleAddItem} className="flex gap-2">
                        <input
                        type="text"
                        value={newItemName}
                        onChange={(e) => setNewItemName(e.target.value)}
                        placeholder="輸入項目名稱"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        ref={inputRef}
                    />
                    <button
                        type="submit"
                        className="whitespace-nowrap rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        新增
                        </button>
                    </form>

                    <div className="bg-white shadow rounded-md">
                        <div 
                            className="px-4 py-3 flex justify-between items-center cursor-pointer"
                        onClick={() => setIsExpanded(!isExpanded)}
                    >
                        <h3 className="text-sm font-medium text-gray-900">項目列表 ({items.length})</h3>
                        {/* <button
                            type="button"
                            className="rounded-full p-1 hover:bg-gray-100"
                        >
                            <svg
                                className={`h-5 w-5 transform transition-transform ${isExpanded ? 'rotate-180' : ''}`}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button> */}
                    </div>
                    <div className="px-4 py-2">
                        <p className="text-sm text-gray-500">使用上下箭頭可調整項目順序</p>
                    </div>
                    {isExpanded && (
                        <div className="border-t border-gray-200">
                            <ul className="divide-y divide-gray-200">
                                {items.map((item) => (
                                    <li key={item._id} className="px-4 py-3 flex justify-between items-center">
                                        <span className="text-sm text-gray-900">{item.name}</span>
                                        <div
                                            className="flex gap-2"
                                        >
                                            {items.indexOf(item) !== 0 && (
                                                <button
                                                    type="button"
                                                    className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                                                    onClick={() => handleMoveItem(item._id, 'up')}
                                                >
                                                    <ArrowUpIcon className="h-5 w-5" aria-hidden="true" />
                                                </button>
                                            )}
                                            {items.indexOf(item) !== items.length - 1 && (
                                                <button
                                                    type="button" 
                                                    className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                                                    onClick={() => handleMoveItem(item._id, 'down')}
                                                >
                                                    <ArrowDownIcon className="h-5 w-5" aria-hidden="true" />
                                                </button>
                                            )}
                                            <button
                                                type="button"
                                                className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                                                onClick={() => handleEditItem(item._id)}
                                            >
                                                <PencilIcon className="h-5 w-5" aria-hidden="true" />
                                            </button>
                                            <button
                                                type="button"
                                                className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                                                onClick={() => handleDeleteItem(item._id, item.name)}
                                            >   
                                                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </li>
                                ))}
                                {items.length === 0 && (
                                    <li className="px-4 py-3">
                                        <span className="text-sm text-gray-500">暫無項目</span>
                                    </li>
                                )}
                            </ul>
                        </div>
                    )}
                    </div>
                </>
            )}
        </div>
    );
}




function DropdownList({options, selected, setSelected}) {
  const [query, setQuery] = useState('')

  const filteredOptions =
    query === ''
      ? options
      : options.filter((option) => {
          return option.name.toLowerCase().includes(query.toLowerCase())
        })

  return (
    <Combobox
      as="div"
      value={selected}
      onChange={(option) => {
        setQuery('')
        setSelected(option)
      }}
    >
      <div className="relative mt-2">
        <ComboboxInput
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
          onChange={(event) => setQuery(event.target.value)}
          onBlur={() => setQuery('')}
          displayValue={(option) => option?.name}
        />
        <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </ComboboxButton>

        {filteredOptions.length > 0 && (
          <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredOptions.map((option) => (
              <ComboboxOption
                key={option.id}
                value={option}
                className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white data-[focus]:outline-none"
              >
                <span className="block truncate group-data-[selected]:font-semibold">{option.name}</span>

                <span className="absolute inset-y-0 right-0 hidden items-center pr-4 text-indigo-600 group-data-[selected]:flex group-data-[focus]:text-white">
                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                </span>
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        )}
      </div>
    </Combobox>
  )
}