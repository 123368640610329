import { Fragment, useState, useCallback } from 'react'
import { GridCellKind, DataEditor } from "@glideapps/glide-data-grid";
import "@glideapps/glide-data-grid/dist/index.css";

// Add custom styles to remove focus ring
const customStyles = `
  .gdg-input:focus {
    outline: none !important;
    box-shadow: none !important;
    border: none;
  }
`;

// Create 16 columns with A-P headers
const columns = Array.from({ length: 16 }, (_, index) => ({
    title: String.fromCharCode(65 + index), // Converts 0-15 to A-P
    id: String.fromCharCode(65 + index),
    width: 150 // Set a default column width
}));

export default function DeleteMe() {
    const [gridData, setGridData] = useState(() => {
        const rows = 30;
        const cols = 16;
        return Array(rows).fill().map(() => Array(cols).fill(""));
    });

    const getCellContent = useCallback((cell) => {
        const [col, row] = cell;
        const d = gridData[row][col] || "";
        return {
            kind: GridCellKind.Text,
            allowOverlay: true,
            readonly: false,
            displayData: d,
            data: d,
        };
    }, [gridData]);

    const onCellEdited = useCallback((cell, newValue) => {
        if (newValue.kind !== GridCellKind.Text) {
            return;
        }
        
        const [col, row] = cell;
        setGridData(prevData => {
            const newData = [...prevData];
            newData[row] = [...newData[row]];
            newData[row][col] = newValue.data;
            return newData;
        });
    }, []);

    const getCellsForSelection = useCallback((selection) => {
        console.log(selection);
        return [];
    }, []);

    return (
        <div style={{ height: "800px", width: "100%" }}>
            <style>{customStyles}</style>
            
            <DataEditor 
                getCellContent={getCellContent} 
                getCellsForSelection={true}
                onPaste={true}
                columns={columns}
                rows={30}
                onCellEdited={onCellEdited}
                height={800}
                width="100%"
                rowMarkers="number"  // Shows row numbers
                isDraggable={true}
                smoothScrollX={true}
                smoothScrollY={true}
                keybindings={{ search: true }}
            />
        </div>
    );
}