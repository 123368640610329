import { useState, useEffect, useRef } from "react";
import { 
    EnvelopeIcon,
    ExclamationTriangleIcon,
    PencilIcon
} from "@heroicons/react/24/outline";
import GlobalVars from "../../../../Config";
import SignatureDialog from "./Components/SignatureDialog";
import EmailDialog from "./Components/EmailDialog";

export default function JobSheet({
    jobID,
    saveTriggered,
    setSaveTriggered,
    setOpenModuleNotification
}) {

    const [submitDetails, setSubmitDetails] = useState({
        jobID: jobID,
        customerRequest: '',
        completedTaskRemarks: '',
        contactEmail: '',
        contactName: '',
        signature: ''
    });

    const [jobDetails, setJobDetails] = useState({});
    const [jobSheetID, setJobSheetID] = useState('');
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        // get job details
        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/submodules/job-sheet?jobID=" + jobID;

        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': sessionStorage.getItem('idToken')
            }
        })
        .then(res => res.json())
        .then(data => {
            setSubmitDetails(prev => ({
                ...prev,
                customerRequest: data.customerRequest,
                completedTaskRemarks: data.completedTaskRemarks,
                contactEmail: data.contactEmail,
                contactNumber: data.contactDetail,
                contactName: data.contactName,
                signature: data.signature,
                nextStep: data.nextStep,
                referenceId: data.referenceId
            }));

            // query for job details
            let jobDetailsUrl = GlobalVars.BACKEND_DOMAIN + "/api/v1/jobs?jobID=" + jobID;
            fetch(jobDetailsUrl, {
                method: 'GET',
                headers: {
                    'Authorization': sessionStorage.getItem('idToken')
                }
            })
            .then(res => res.json())
            .then(data => {
                setJobDetails(data['job']);

                // fill the detail if empty
                if (!submitDetails?.customerName && data['job']?.locationName?.[0]?.name) {
                    setSubmitDetails(prev => ({
                        ...prev,
                        customerName: data['job'].locationName[0].name
                    }));
                }

                if (!submitDetails?.contactEmail && data['job'].meta.contactEmail) {
                    setSubmitDetails(prev => ({
                        ...prev,
                        contactEmail: data['job'].meta.contactEmail
                    }));
                }

                if (!submitDetails?.contactNumber) {
                    setSubmitDetails(prev => ({
                        ...prev,
                        contactNumber: data['job'].meta.contactDetail
                    }));
                }

                if (!submitDetails?.contactName && data['job']?.meta?.contactPerson) {
                    setSubmitDetails(prev => ({
                        ...prev,
                        contactName: data['job'].meta.contactPerson
                    }));
                }
            })
            .catch(err => {
                console.log(err);
            });
        })
        .catch(err => {
            console.log(err);
        });

        
    }, [jobID]);


    // Utility function to prepare request details
    const prepareRequestDetails = () => ({
        ...submitDetails,
        organizationID: sessionStorage.getItem('organizationId'),
        createdBy: localStorage.getItem('userid')
    });

    // Utility function to make API request
    const makeJobSheetRequest = async (details) => {
        const url = `${GlobalVars.BACKEND_DOMAIN}/api/v1/submodules/job-sheet`;
        
        try {
            const response = await fetch(url, {
                method: 'POST',
                body: JSON.stringify(details),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': sessionStorage.getItem('idToken')
                }
            });
            if (response.status === 200) {
                console.log('Job sheet request successful');
            }
            return response;
        } catch (error) {
            console.error('Job sheet request failed:', error);
            throw error;
        }
    };

    // Function to save without notification
    const saveJobSheet = async () => {
        setIsSaving(true);
        try {
            const details = prepareRequestDetails();
            const data = await makeJobSheetRequest(details);
            setJobSheetID(data.jobSheetID);
        } catch (error) {
            console.error('Failed to save job sheet:', error);
        } finally {
            setIsSaving(false);
        }
    };

    // Function to save with notification
    const handleSave = async () => {
        try {
            const details = prepareRequestDetails();
            await makeJobSheetRequest(details);
            
            setOpenModuleNotification({
                display: true,
                type: 'success'
            });
            setSaveTriggered(false);
        } catch (error) {
            console.error('Failed to handle save:', error);
            setOpenModuleNotification({
                display: true,
                type: 'fail'
            });
        }
    };

    useEffect(() => {
        if (saveTriggered) {
            handleSave();
        }
    }, [saveTriggered]);

    const [openEmailDialog, setOpenEmailDialog] = useState(false);
    const [openSignatureDialog, setOpenSignatureDialog] = useState(false);

    // save job sheet when email dialog is opened
    useEffect(() => {
        if (openEmailDialog) {
            saveJobSheet();
        }
    }, [openEmailDialog]);

    return (
        <div className="py-2">
            <div className="mb-8">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                    參考編號
                </label>
                <p className="text-sm text-gray-500 mb-2">
                    報價單(Quotation)或採購訂單(Purchase Order)編號
                </p>
                <input
                    type="text"
                    className="w-full p-3 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 text-[16px] sm:text-sm"
                    placeholder="請輸入參考編號..."
                    value={submitDetails.referenceId || ''}
                    onChange={(e) => setSubmitDetails({...submitDetails, referenceId: e.target.value})}
                />
            </div>

            <div className="mb-8">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                    客戶要求
                </label>
                <textarea
                    className="w-full h-48 p-3 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 text-[16px] sm:text-sm"
                    placeholder="請輸入客戶要求..."
                    value={submitDetails.customerRequest}
                    onChange={(e) => setSubmitDetails({...submitDetails, customerRequest: e.target.value})}
                />
                {jobDetails.meta && jobDetails.meta.clientRemark && (
                    <button
                        type="button"
                        className="my-2 inline-flex items-center gap-x-2 rounded-md border border-gray-600 px-3.5 py-2.5 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-50 hover:text-gray-900 hover:border-gray-900 transition-colors duration-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                        onClick={() => setSubmitDetails({...submitDetails, customerRequest: jobDetails.meta.clientRemark})}
                    >
                        填入客戶備註
                    </button>
                )}
            </div>

            <div className="mb-8">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                    工作內容
                </label>
                <textarea
                    className="w-full h-48 p-3 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 text-[16px] sm:text-sm"
                    placeholder="請輸入已完成的工作..."
                    value={submitDetails.completedTaskRemarks}
                    onChange={(e) => setSubmitDetails({...submitDetails, completedTaskRemarks: e.target.value})}
                />
                {jobDetails.meta && jobDetails.meta.jobRemark && (
                    <button
                        type="button"
                        className="mt-2 inline-flex items-center gap-x-2 rounded-md border border-gray-600 px-3.5 py-2.5 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-50 hover:text-gray-900 hover:border-gray-900 transition-colors duration-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                        onClick={() => setSubmitDetails({...submitDetails, completedTaskRemarks: jobDetails.meta.jobRemark})}
                    >
                        填入工作備註
                    </button>
                )}
            </div>

            <div className="mb-8">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                    總結
                </label>
                <p className="text-sm text-gray-500 mb-2">
                    填寫任何總結, 後續行動, 計畫維護或待處理的操作  
                </p>
                <textarea
                    className="w-full h-48 p-3 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 text-[16px] sm:text-sm"
                    placeholder="請輸入總結 (例如: 後續行動、計畫維護或待處理的操作)"
                    value={submitDetails.nextStep}
                    onChange={(e) => setSubmitDetails({...submitDetails, nextStep: e.target.value})}
                />
                <button
                    type="button"
                    className="mt-2 inline-flex items-center gap-x-2 rounded-md border border-gray-600 px-3.5 py-2.5 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-50 hover:text-gray-900 hover:border-gray-900 transition-colors duration-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                    onClick={() => setSubmitDetails({...submitDetails, nextStep: 'N/A'})}
                >
                    總結不適用
                </button>
            </div>

            <div className="mb-8">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                    客戶/公司名稱
                </label>
                <input
                    type="text"
                    className="w-full p-3 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 text-[16px] sm:text-sm"
                    placeholder="請輸入客戶名稱..."
                    value={submitDetails.customerName}
                    onChange={(e) => setSubmitDetails({...submitDetails, customerName: e.target.value})}
                />
            </div>

            <div className="mb-8">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                    聯絡電郵
                </label>
                <input
                    type="email"
                    className="w-full p-3 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 text-[16px] sm:text-sm" 
                    placeholder="請輸入聯絡電郵..."
                    value={submitDetails.contactEmail}
                    onChange={(e) => setSubmitDetails({...submitDetails, contactEmail: e.target.value})}
                />
            </div>

            <div className="mb-8">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                    聯絡電話
                </label>
                <input
                    type="text"
                    className="w-full p-3 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 text-[16px] sm:text-sm"
                    placeholder="請輸入聯絡電話..."
                    value={submitDetails?.contactNumber}
                    onChange={(e) => setSubmitDetails({...submitDetails, contactNumber: e.target.value})}
                />
            </div>

            <div className="mb-8">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                    聯絡人姓名
                </label>
                <input
                    type="text"
                    className="w-full p-3 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 text-[16px] sm:text-sm"
                    placeholder="請輸入聯絡人姓名..."
                    value={submitDetails.contactName}
                    onChange={(e) => setSubmitDetails({...submitDetails, contactName: e.target.value})}
                />
            </div>
        
            <div className="mb-8">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                    簽名
                </label>
                <button
                    type="button"
                    className={`inline-flex items-center gap-x-2 rounded-md px-3.5 py-2.5 text-sm font-semibold shadow-sm ${
                        submitDetails.signature 
                        ? "bg-green-600 text-white hover:bg-green-500 focus-visible:outline-green-600"
                        : "bg-indigo-600 text-white hover:bg-indigo-500 focus-visible:outline-indigo-600"
                    } focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2`}
                    onClick={() => setOpenSignatureDialog(true)}
                >
                    <PencilIcon className="h-5 w-5" aria-hidden="true" />
                    {submitDetails.signature ? '已簽名' : '簽名'}
                </button>
            </div>

            <div className="fixed bottom-20 right-4">
                <button
                    type="button"
                    className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => {
                        saveJobSheet();
                        setOpenEmailDialog(true)
                    }}
                >
                    <EnvelopeIcon className="h-5 w-5" aria-hidden="true" />
                    發送電郵
                </button>
            </div>
            <EmailDialog
                open={openEmailDialog}
                setOpen={setOpenEmailDialog}
                submitDetails={submitDetails}
                isSaving={isSaving}
            />
            <SignatureDialog
                open={openSignatureDialog}
                setOpen={setOpenSignatureDialog}
                submitDetails={submitDetails}
                setSubmitDetails={setSubmitDetails}
            />
        </div>
    )
}





