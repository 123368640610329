import { useState, useEffect, useRef } from "react";
import GlobalVars from "../../../../Config";
import { 
    PencilIcon, 
    PaperClipIcon, 
    PlusIcon, 
    XMarkIcon,
    EnvelopeIcon
} from "@heroicons/react/24/outline";
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'

export default function Checklist({
    saveTriggered,
    setSaveTriggered,
    setOpenModuleNotification,
    jobID
}) {
    const [items, setItems] = useState([]);

    const [jobDetail, setJobDetail] = useState(null);

    // query checklist items from backend
    useEffect(() => {
        // query for job detail using jobID
        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/jobs?jobID=" + jobID;
        fetch(url,{
            headers:{
                "Authorization": sessionStorage.getItem('idToken')
            }
        })
            .then(response => {
                if (response.status === 200) {  
                    return response.json();
                } else {
                    throw new Error('Failed to fetch job detail');
                }
            })
            .then(data => {
                setJobDetail(data['job']);
                // fetch checklist setting from backend
                let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/check-list-setting?jobTypeID=" + data['job']['typeIDs'][0];
                fetch(url, {
                    headers:{
                        "Authorization": sessionStorage.getItem('idToken')
                    }
                })
                    .then(response => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            console.log(response.json());
                            throw new Error('Failed to fetch checklist items');
                        }
                    })
                    .then(data => {
                        // fetch checklist result from backend with jobID
                        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/check-list-result?jobID=" + jobID;
                        fetch(url, {
                            headers:{
                                "Authorization": sessionStorage.getItem('idToken')
                            }
                        })
                        .then(response => {
                            if (response.status === 200) {
                                return response.json();
                            } else {
                                throw new Error('Failed to fetch checklist result');
                            }
                        })
                        .then(checkListResultData => {
                            setItems(checkListResultData['itemList']);
                        })
                        .catch(error => {
                            console.error('Error fetching checklist result:', error);
                            console.log(data[0]['itemList']);
                            setItems(data[0]['itemList']);
                        });
                    })
                    .catch(error => {
                        console.error('Error fetching checklist setting:', error);
                        setItems([]);
                    });
                    
            })
            .catch(error => {
                console.error('Error fetching job detail:', error);
            });
            
    }, []);

    const [openDialog, setOpenDialog] = useState(false);
    const [focusedItem, setFocusedItem] = useState(null);
    const [isFile, setIsFile] = useState(false);

    const uploadFile = (file, signedUrl, type) => {
        // return if file is not a file type
        if (!(file instanceof File)) {
            console.log('Not a file:', file);
            return;
        }

        return fetch(signedUrl, {
            method: 'PUT',
            body: file,
            headers: {
                "Content-Type": type
            }
        });
    }

    const handleGetUploadFileSignedUrl = (fileList) => {
        let fileNameList = fileList.map(file => file.name);
        let fileTypeList = fileList.map(file => file.type);

        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/check-list/signed-url";
        let data = {
            fileList: fileNameList,
            organizationID: localStorage.getItem('organizationId'),
            jobID: jobID,
            fileTypeList: fileTypeList,
        }
        fetch(url, {
            headers:{
                "Authorization": sessionStorage.getItem('idToken'),
                "Content-Type": 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(data)
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error('Failed to get signed url');
            }
        })
        .then(data => {
            // Create array of upload promises
            const uploadPromises = fileList.map((file, index) => 
                // skip if file is not a file type
                uploadFile(file, data['urls'][index], fileTypeList[index])
            );

            // Wait for all uploads to complete
            return Promise.all(uploadPromises);
        })
        .then(data => {
            // All uploads completed successfully
            setOpenModuleNotification({
                "display": true,
                "type": "success",
            });
        })
        .catch(error => {
            console.error('Error getting signed url:', error);
            setOpenModuleNotification({
                "display": true,
                "type": "fail",
            });
        });
    }

    const handleSubmitChecklist = () => {
        let fileList = []
        
        // add files to fileList and replace the file in items with the file name
        items.forEach(item => {
            if (item.file && Array.isArray(item.file) && item.file.length > 0) {
                item.file.forEach(file => {
                    fileList.push(file);
                });
                item.file = item.file.map(file => ({ name: file.name }));
            } else {
                // remove file prop from item
                delete item.file;
            }
        });

        let data = {
            "jobID": jobID,
            "organizationID": localStorage.getItem('organizationId'),
            "userID": localStorage.getItem('userid'),
            "itemList": items,
        }

        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/check-list-result";

        fetch(url, {
            method: 'POST',
            headers: {
                "Authorization": sessionStorage.getItem('idToken'),
                "Content-Type": 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error('Failed to submit checklist');
            }
        })
        .then(data => {
            
            if (isFile) {
                handleGetUploadFileSignedUrl(fileList);
            } else {
                setOpenModuleNotification({
                    "display": true,
                    "type": "success",
                });
            }
        })
        .catch(error => {
            console.error('Error submitting checklist:', error);
            setOpenModuleNotification({
                "display": true,
                "type": "fail",
            });
        })
        .finally(() => {
            setSaveTriggered(false);
            
        });

    }

    // when saveTriggered is true, submit the checklist
    useEffect(() => {
        if (saveTriggered) {
            handleSubmitChecklist();
        }
    }, [saveTriggered]);

    const [openEmailDialog, setOpenEmailDialog] = useState(false);
    const handleOpenEmailDialog = () => {
        setOpenEmailDialog(true);
    }
    
    return (
        <div className="overflow-x-auto">
            <div className="min-w-full inline-block align-middle">
                <div className="mb-4 flex gap-4 justify-between items-center">
                    <div className="flex gap-2">
                        <div className="text-sm text-gray-600">
                            未選擇: {items.filter(item => !item.result).length}
                        </div>
                        <div className="text-sm text-gray-600">
                            通過: {items.filter(item => item.result === 'success').length}
                        </div>
                        <div className="text-sm text-gray-600">
                            不通過: {items.filter(item => item.result === 'failed').length}
                        </div>
                    </div>
                    <button className="border border-indigo-600 text-indigo-600 hover:bg-indigo-200 text-sm px-4 py-2 rounded-md duration-300" onClick={handleOpenEmailDialog}>
                        電郵清單結果
                    </button>
                </div>
                <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                        <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">項目</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 whitespace-nowrap">結果</th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 whitespace-nowrap">備註</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {items.length === 0 && (
                            <tr>
                                <td colSpan="3" className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
                                    無項目或是存取檔案出現問題
                                </td>
                            </tr>
                        )}
                        {items.map((item, index) => (
                            <tr key={index}>
                                <td className=" py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
                                    {item.name}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm">
                                    <div className="flex gap-4 flex-col">
                                        <label className="inline-flex items-center">
                                            <input 
                                                type="radio" 
                                                name={`result-${index}`} 
                                                className="form-radio text-indigo-600" 
                                                value="success"
                                                checked={item.result === 'success'}
                                                onChange={() => {
                                                    setItems(prevItems => {
                                                        const newItems = [...prevItems];
                                                        newItems[index] = {...item, result: 'success'};
                                                        return newItems;
                                                    });
                                                }}
                                            />
                                            <span className="ml-2">通過</span>
                                        </label>
                                        <label className="inline-flex items-center">
                                            <input 
                                                type="radio" 
                                                name={`result-${index}`} 
                                                className="form-radio text-red-600" 
                                                value="failed"
                                                checked={item.result === 'failed'}
                                                onChange={() => {
                                                    setItems(prevItems => {
                                                        const newItems = [...prevItems];
                                                        newItems[index] = {...item, result: 'failed'};
                                                        return newItems;
                                                    });
                                                }}
                                            />
                                            <span className="ml-2">不通過</span>
                                        </label>
                                    </div>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm">
                                    <button onClick={() => {
                                        setFocusedItem(item);
                                        setOpenDialog(true);
                                    }}>
                                        {item.comment || item.file ? <PencilIcon className="w-5 h-5" /> : <PlusIcon className="w-5 h-5" />}
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <ChecklistItemDetailDialog 
                    open={openDialog} 
                    setOpen={setOpenDialog} 
                    focusedItem={focusedItem} 
                    setItems={setItems} 
                    setIsFile={setIsFile} 
                    jobID={jobID}
                />
                <EmailChecklistResultDialog 
                    open={openEmailDialog} 
                    setOpen={setOpenEmailDialog}
                    itemList={items}
                    jobDetail={jobDetail}
                />
            </div>
        </div>
    );
}

// custom dialog for comments and attachments 
function ChecklistItemDetailDialog({open, setOpen, focusedItem, setItems, setIsFile, jobID}) {
    
    const commentBoxRef = useRef(null);
    // focus on the comment box when the dialog is opened
    useEffect(() => {
        if (open && commentBoxRef.current) {
            commentBoxRef.current.focus();
        }
    }, [open, commentBoxRef]);

    const [file, setFile] = useState(null);
    const [comment, setComment] = useState('');

    // fill the comment and file to the focused item when focusedItem is not null
    useEffect(() => {
        if (focusedItem) {
            if (focusedItem.comment) {
                setComment(focusedItem.comment);
            } else {
                setComment('');
            }
            if (focusedItem.file) {
                setFile(focusedItem.file);
            } else {
                setFile(null);
            }
        } else {
            setComment('');
            setFile(null);
        }
    }, [focusedItem]);

    const handleSave = () => {
        setOpen(false);
        setItems(prevItems => prevItems.map(item => item.name === focusedItem.name ? { ...item, comment: comment, file: file } : item));
    }

    useEffect(() => {
        if (setIsFile) {
            setIsFile(file ? true : false);
        }
    }, [file]);

    const handleFileOpen = (f) => {
        let url = GlobalVars.BACKEND_DOMAIN + "/api/v1/check-list/signed-url?fileID=" + localStorage.getItem('organizationId') + "/" + jobID + "/" + f.name;
        // fetch signed url
        fetch(url, {
            headers: {
                "Authorization": sessionStorage.getItem('idToken')
            }
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error('Failed to get signed url');
            }
        })
        .then(data => {
            window.open(data['url'], '_blank');
        })
        .catch(error => {
            console.error('Error getting signed url:', error);
        });
    }

    return (
        <Dialog open={open} onClose={setOpen} className="relative z-50">
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center text-center sm:items-center w-full p-4">
                <DialogPanel
                    transition
                    className="w-full relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                >
                    <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:size-10">
                        <PencilIcon aria-hidden="true" className="size-6 text-indigo-600" />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                        <DialogTitle as="h3" className="text-base font-semibold text-gray-900">
                            輸入備註
                        </DialogTitle>
                        <div className="mt-2 w-full">
                        <div className="space-y-4 w-full">
                            <textarea
                                ref={commentBoxRef}
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                className="w-full px-3 py-2 text-sm text-gray-700 border rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-500"
                                rows="4"
                                placeholder="輸入備註..."
                            />
                            <div className="space-y-4">
                                <div className="flex items-center w-full">
                                    <label className="block w-full">
                                        <div className="flex items-center w-full justify-between">
                                            <span className="text-sm text-gray-500
                                                file:mr-4 file:py-2 file:px-4
                                                file:rounded-md file:border-0
                                                file:text-sm file:font-semibold
                                                file:bg-indigo-50 file:text-indigo-700
                                                hover:file:bg-indigo-100 cursor-pointer flex items-center bg-indigo-50 rounded-md px-2 py-3 whitespace-nowrap flex-shrink-0">
                                                <PaperClipIcon className="w-5 h-5 mr-2" />
                                                選擇檔案
                                            </span>
                                            <span className="text-sm text-gray-500">
                                                {file && file.length + " 個檔案"}
                                            </span>
                                        </div>
                                        <input
                                            type="file"
                                            className="hidden"
                                            onChange={(e) => setFile([...(file || []), ...Array.from(e.target.files)])}
                                            multiple
                                        />
                                    </label>
                                </div>
                                {file && file.length > 0 && (
                                    <div className="space-y-2 max-h-[150px] overflow-y-auto">
                                        {file.map((f, index) => (
                                            <div key={index} className="flex items-center justify-between bg-gray-50 rounded-md px-3 py-2">
                                                <button 
                                                    type="button"
                                                    onClick={() => handleFileOpen(f)}
                                                    className="text-sm text-gray-500  flex-1 whitespace-nowrap text-ellipsis overflow-hidden text-left hover:text-indigo-600 hover:underline duration-300"
                                                >
                                                    {f.name}
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() => setFile(file.filter((_, i) => i !== index))}
                                                    className="ml-2 text-gray-400 hover:text-gray-500"
                                                >
                                                    <XMarkIcon className="h-5 w-5" />
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                        type="button"
                        onClick={handleSave}
                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                    >
                        確認
                    </button>
                    <button
                        type="button"
                        data-autofocus
                        onClick={() => setOpen(false)}
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    >
                        取消
                    </button>
                    </div>
                </DialogPanel>
                </div>
            </div>
        </Dialog>
    )
}

// email checklist result dialog
function EmailChecklistResultDialog({open, setOpen, itemList, jobDetail}) {

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');

    const [senderName, setSenderName] = useState('');
    const [btnText, setBtnText] = useState('發送電郵');

    // fetch sender name from local storage
    useEffect(() => {
        fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/users?userID=" + localStorage.getItem('userid'), {
            headers: {
                "Authorization": sessionStorage.getItem('idToken')
            }
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error('Failed to fetch sender name');
            }
        })
        .then(data => {
            setSenderName(data['user']['displayName']);
        })
        .catch(error => {
            console.error('Error fetching sender name:', error);
        });
    }, []);

    // fetc
    
    // dynamically generate message based on itemList
    useEffect(() => {
        let message = "Dear " + name + ",\n\n";
        message += "We have recently performed a routine maintenance on your equipment" + (jobDetail?.typeName ? " (" + jobDetail.typeName[0] + ")" : "") + ". Please find the result below:\n\n";
        
        itemList.forEach(item => {
            // skip if result is undefined
            if (item.result === undefined) {
                return;
            }

            message += "Item: " + item.name + "\n";
            message += "Result: " + item.result + "\n";
            message = message.replace("success", "passed");

            if (item.comment) {
                message += "Comment: " + item.comment + "\n";
            }
            message += "\n";
        });

        message += "If you have any questions, feel free to contact us.\n";
        message += "Best regards,\n";
        message += senderName;

        setMessage(message);
    }, [itemList, name, senderName, jobDetail]);

    const handleSendEmail = () => {
        // check users at least included email and name
        if (!email || !name) {
            alert("請輸入電郵地址和收件人姓名");
            return;
        }

        // prompt user to confirm sending email
        if (!window.confirm("確認發送電郵？")) {
            return;
        }        

        setBtnText('發送中...');

        // send email
        fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/email/checklist-result", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': sessionStorage.getItem('idToken')
            },
            body: JSON.stringify({
                jobID: jobDetail._id,
                targetName: name,
                targetEmail: email,
                userID: sessionStorage.getItem('userID'),
                emailContent: message
            })
        })
        .then(response => {
            if (response.status === 200) {
                alert("電郵已發送");
                setOpen(false);
            } else {
                throw new Error('Failed to send email');
            }
        })
        .catch(error => {
            console.error('Error sending email:', error);
            alert("發送電郵失敗");
        })
        .finally(() => {
            setBtnText('發送電郵');
        });
    }

    return (
        <Dialog open={open} onClose={setOpen} className="relative z-50">
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <DialogPanel
                    transition
                    className="w-[90vw] relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                >
                    <div>
                    <div className="mx-auto flex size-12 items-center justify-center rounded-full bg-indigo-100">
                        <EnvelopeIcon aria-hidden="true" className="size-6 text-indigo-600" />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                        <DialogTitle as="h3" className="text-base font-semibold text-gray-900">
                            電郵清單結果
                        </DialogTitle>
                        <div className="mt-4 space-y-4">
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700 text-left">
                                    電郵地址
                                </label>
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    name="email"
                                    id="email"
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    placeholder="you@example.com"
                                />
                            </div>
                            <div>
                                <label htmlFor="name" className="block text-sm font-medium text-gray-700 text-left">
                                    收件人姓名
                                </label>
                                <input
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    name="name"
                                    id="name"
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    placeholder="收件人姓名"
                                />
                            </div>
                            <div>
                                <label htmlFor="message" className="block text-sm font-medium text-gray-700 text-left">
                                    電郵內容
                                </label>
                                <textarea
                                    id="message"
                                    name="message"
                                    value={message}
                                    rows={4}
                                    onChange={(e) => setMessage(e.target.value)}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    placeholder="在此輸入電郵內容..."
                                />
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="mt-5 sm:mt-6">
                    <button
                        type="button"
                        onClick={handleSendEmail}
                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        {btnText}
                    </button>
                    </div>
                </DialogPanel>
                </div>
            </div>
        </Dialog>
    )
}