import { Menu } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import NewLinkDialog from '../NewLinkDialog'

export default function NewDocDropdown() {
    const [isNewLinkDialogOpen, setIsNewLinkDialogOpen] = useState(false)
    const navigate = useNavigate()

    const handleCreateNewDocument = () => {
        navigate('/knowledge-base/create-new-document')
    }

    const handleCreateNewLink = () => {
        setIsNewLinkDialogOpen(true)
    }

    return (
        <>
            <Menu as="div" className="relative inline-block text-left">
                <Menu.Button className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    新增文章
                    <ChevronDownIcon className="-mr-1 ml-1.5 h-5 w-5" aria-hidden="true" />
                </Menu.Button>

                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    onClick={handleCreateNewDocument}
                                    className={`${
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                                    } block w-full px-4 py-2 text-left text-sm`}
                                >
                                    新增文章
                                </button>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    onClick={handleCreateNewLink}
                                    className={`${
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                                    } block w-full px-4 py-2 text-left text-sm`}
                                >
                                    新增連結
                                </button>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Menu>

            <NewLinkDialog 
                isOpen={isNewLinkDialogOpen}
                setIsOpen={setIsNewLinkDialogOpen}
            />
        </>
    )
}